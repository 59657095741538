import { get, post,put,del } from '@/utils'
import axios from '@/utils/request'

const baseApi = 'system/user/'

export const fetchUserList = () => {
    return axios.get(baseApi + 'data',{},{Headers:  {'Content-Type': 'multipart/form-data'}})
}

//角色列表
export const roleList = () =>{
    return get('system/role/data')
}
// 角色新增
export const roleAdd = (params)=>{
    return post('system/role/save',params)
}

// 角色编辑
export const roleModify = (params)=>{
    return put('system/role/update',params)
}

// 角色删除
export const roledel = (params)=>{
    return del('system/role/remove',params)
}

// 角色授权列表
export const rolePageList =() =>{
    return get('system/role/getRolePower/2','')
}

//用户新增
export const userAdd = (params)=>{
    return post('system/user/save',params)
}

//用户新增
export const usermodify = (params)=>{
    return put('system/user/update',params)
}

// 用户禁用
export const userDisable= (id)=>{
    return put('system/user/disable',{userId: id})
}

//用户修改密码
export const userPassword= (params)=>{
    return put('system/user/editPassword',params)
}