import axios from './request'
import qs from 'qs'

export function get(url, params){   
    return new Promise((resolve, reject) =>{   
        axios({url,method: 'GET', params: params}).then(res => {
            resolve(res.data);
        }).catch(err =>{
            reject(err.data)        
        })
    })    
}

export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params).then(res => {
            resolve(res.data);
        })
        .catch(err =>{
            reject(err.data)
        })
    });
}


export function put(url,params){
    return new Promise((resolve, reject) => {
        axios.put(url, {params}).then(res => {
            resolve(res.data);
        })
        .catch(err =>{
            reject(err.data)
        })
    });
}

export function del(url,Id){
    return new Promise((resolve, reject) => {
        axios.delete(url + '/'+ Id).then(res => {
            resolve(res.data);
        })
        .catch(err =>{
            reject(err.data)
        })
    });
}