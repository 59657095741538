import { get, post } from '@/utils'

// 发货单批量管理
// searchPath: 'stk/outcus/b/detail/search', //主表获取数据接口地址
// insertPath: 'stk/outcus/b/detail/update', //主表保存数据接口地址
// deletePath: 'stk/outcus/b/detail/del',
export const fetchOutcus = (params)=>{
    return get('api/stk/outcus/b/detail/search',params)
}

export const editOutcus = (params)=>{
    return post('api/stk/outcus/b/detail/update',params)
}

export const delOutcus = (params)=>{
    return post('api/stk/outcus/b/detail/del',params)
}

