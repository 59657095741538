<template>
<div class="page-login-v3">
    <div class="container">
        <div id="wrapper" class="login-body">
            <div class="login-content">
                <div class="brand">
                    <img alt="logo" class="brand-img" src="@/assets/img/login/logo.png">
                    <h2 class="brand-text">上海万顺集团ERP信息管理系统</h2>
                </div>
                <form id="login-form" class="login-form">
                    <div class="form-group">
                        <input v-model="formData.username" placeholder="请输入用户名" type="text" required>
                    </div>
                    <div class="form-group">
                        <input v-model="formData.password"  placeholder="请输入密码" type="password" required>
                    </div>
                    <div class="form-group" @click="submit">
                        <button type="submit" >
                            登录
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    import {login} from "@/api/login"
    export default{
        data(){
            return {
                formData:{
                    username: 'admin',
                    password: '123456'
                }
            }
        },
        created(){
            window.localStorage.removeItem('currentIdx')
        },
        methods:{
            submit(){
                login(this.formData).then(res=>{
                    console.log(res)
                    this.$message({
                        message: res.msg,
                        type: res.success ? 'success' : 'error'
                    });
                    if(res.success){
                        window.localStorage.setItem('token',res.data.access_token)
                        this.$router.push('/transport_mode')
                    }
                }) 
            }
        }
    }
</script>
<style scoped>
@import '@/assets/css/style.css';
</style>