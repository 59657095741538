<template>
    <div>
        <div class="top">
            <div class="f-14 col-main f-b">{{appname}}</div>
            <div class="dis-flex flex-x-end flex-y-center">
                <el-button type="warning"  @click="addRow"  icon="el-icon-circle-plus-outline" size="mini" >新增</el-button>
            </div>
        </div>
        <el-table size="mini"
            :data="tableData"
            height="250" style="width:100%">
            <el-table-column
            prop="cCAYTYPE_NO"
            label="管理员ID"
            width="200">
            </el-table-column>
            <el-table-column
            prop="cCAYTYPE_NM"
            label="用户名"
            width="200">
            </el-table-column>
            <el-table-column
            prop="BOPOMOFO"
            label="姓名"
            width="200">
            </el-table-column>
            <el-table-column
            prop="cSYS_TAG"
            label="添加时间"
            >
            </el-table-column>
            <el-table-column label="操作">
            </el-table-column>
        </el-table>
        <el-drawer
            title="添加管理员"
            :visible.sync="visible"
            direction="rtl"
            :before-close="handleClose">
            <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="ruleForm.username"></el-input>
                </el-form-item>
                <el-form-item label="所属角色" prop="region">
                    <el-select v-model="ruleForm.roleIds" placeholder="点击选择">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                    <span style="margin-left: 10px;">注：支持多选</span>
                </el-form-item>
                <el-form-item label="登录密码" prop="password">
                    <el-input v-model="ruleForm.password"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmPassword">
                    <el-input v-model="ruleForm.confirmPassword"></el-input>
                </el-form-item>
                <el-form-item label="姓名" prop="realname">
                    <el-input v-model="ruleForm.realname"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button size="mini"  type="primary" @click="submitForm('ruleForm')">提交</el-button>
                </el-form-item>
            </el-form>
        </el-drawer>
    </div>
</template>
<script>
    import {fetchUserList} from '@/api/role'
    export default{
        data(){
            return {
                appname: '管理员列表',
                tableData: [],
                visible: false,
                ruleForm:{}
            }
        },
        created(){
            // fetchUserList().then(res=>{
            //     console.log(res)
            // })
        },
        methods:{
            addRow(){
                this.visible = true
            },
            saveRow(){

            },
            handleClose(){
                this.visible = false
            },
            submitForm(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>