<template>
    <div class="am-g tpl-g">
        <!-- 头部 -->
        <header class="tpl-header" :style="{marginLeft : folder ? '0px' : '150px'}">
            <!-- 右侧内容 -->
            <div class="tpl-header-fluid" :class="folder ? 'folder' : ''">
                <!-- 侧边切换 -->
                <div class="am-fl tpl-header-button switch-button" @click="handleFoldSwitch">
                    <i class="iconfont icon-menufold"></i>
                </div>
                <!-- 刷新页面 -->
                <div class="am-fl tpl-header-button refresh-button" @click="handleRefresh">
                    <i class="iconfont icon-refresh"></i>
                </div>
                <!-- 其它功能-->
                <div class="am-fr tpl-header-navbar">
                    <ul>
                        <!-- 欢迎语 -->
                        <li class="am-text-sm tpl-header-navbar-welcome">
                            <a href="index.php?s=/store/store.user/renew">欢迎你，<span>admin</span>
                            </a>
                        </li>
                        <!-- 退出 -->
                        <li class="am-text-sm" @click.stop="logout" style="cursor: pointer;">
                            <a>
                                <i class="iconfont icon-tuichu"></i> 退出
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
        <!-- 侧边导航栏 -->
        <div class="left-sidebar dis-flex" :class="folder ? 'siderBarHidden' : ''">
            <div class="sidebar-scroll" style="z-index: 9999;">
                <!-- 一级菜单 -->
                <ul class="sidebar-nav">
                    <li class="sidebar-nav-heading">菜单列表</li>            
                    <li class="sidebar-nav-link" @click.stop="handleSwitchSystem(key)" v-for="(item,key) in systems" :key="key">
                        <a :class="item.selected ? 'active' : ''">
                            <i class="iconfont sidebar-nav-link-logo am-icon-truck"></i>{{ item.title }}
                        </a>
                    </li>                      
                </ul>

                <div class="bottom_bar">
                    <ul class="sidebar-nav">
                        <li class="sidebar-nav-heading">上海万顺集团</li>
                        <li class="sidebar-nav-link" @click="fetchAdminList">
                            <a :class="isAdminActive ? 'active': ''"><i class="iconfont sidebar-nav-link-logo icon-guanliyuan"></i>管理员</a>
                        </li>
                        <li class="sidebar-nav-link" @click.stop="logout">
                            <a class=""><i class="iconfont sidebar-nav-link-logo am-icon-sign-out" style=""></i>退出</a>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 子级菜单-->
            <div class="sidebar-second-scroll">
                <ul class="left-sidebar-second">
                    <li class="sidebar-second-title" v-show="!isAdminActive">{{ selectedSystem.title }}</li>
                    <li class="sidebar-second-item" v-for="(item,key) in menus" :key="key">
                        <!-- 三级菜单-->
                        <div class="sidebar-third-item">
                            <!-- active -->
                            <a href="javascript:void(0);" @click.stop="handleChildFolder(item)" class="sidebar-nav-sub-title" :class="item.meta.affix ? 'active' : ''">
                                <i class="iconfont icon-caret"></i>{{ item.meta.title }}
                            </a>
                            <ul class="sidebar-third-nav-sub">
                                <!-- class="active" -->
                                <li v-for="(child,idx) in item.children" :key="idx" @click.stop="handleRouteChange(item,child,idx,key)"><a :class="child.meta.affix ? 'active':''">{{ child.meta.title }}</a></li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 中间内容 -->
        <div class="tpl-content-wrapper" :style="{marginLeft : folder ? '0' : '270px'}">
            <div style="padding: 10px;">
                <router-view/>
            </div>
        </div>
</div>
</template>
<script>
import {Routes,roleRoutes} from '@/router';
import _ from 'lodash';
import {logout} from '@/api/login';

export default{
    data(){
        return{
            folder: false,
            systems:[
                {
                    title: '物流运输',
                    selected: true
                },
                {
                    title: '仓储系统',
                    selected: false
                }
            ],
            selectedSystem: '',
            currentPath: '',
            menus:[],
            currentIdx: {
                firstIdx: 0,
                secondIdx: 0,
                thirdIdx: 0
            },
            isAdminActive: false
        }
    },
    created(){
        this.currentPath = this.$route.path  //当前处于
        let initIdx = 0
        if(!!localStorage.getItem('currentIdx')){
            let currentRouter = JSON.parse(localStorage.getItem('currentIdx'))
            this.currentIdx = currentRouter
            this.initMenus(currentRouter)
        }else{
            this.menus =_.cloneDeep(Routes[initIdx])
            this.selectedSystem = this.systems[initIdx]
        }
    },
    methods:{
        fetchAdminList(){
            if(this.isAdminActive) return
            this.clearSystem()
            let path = '/user_index';
            this.currentPath = path;
            this.menus = _.cloneDeep(roleRoutes)
            this.isAdminActive = true
            this.currentIdx = {
                firstIdx: 2,
                secondIdx: 0,
                thirdIdx: 0
            }
            localStorage.setItem('currentIdx',JSON.stringify(this.currentIdx))
            this.$router.push(path)
        },
        clearSystem(){
            this.systems = this.systems.map(v=>({
                ...v,
                selected: false
            }))
        },
        initMenus(obj){
            // 一级菜单
            this.clearSystem()
            if(obj.firstIdx == 2){
                this.isAdminActive = true
                this.menus = _.cloneDeep(roleRoutes)
            }else{
                this.systems[obj.firstIdx].selected = true
                this.selectedSystem = this.systems[obj.firstIdx]
                this.menus = _.cloneDeep(Routes[obj.firstIdx])
            }
            // 二级菜单
            this.menus = this.menus.map(v=>({
                ...v,
                meta:{
                    title: v.meta.title,
                    affix: false
                }
            }))
            this.menus[obj.secondIdx].meta.affix = true

            // 三级菜单
            this.menus[obj.secondIdx].children = this.menus[obj.secondIdx].children.map(v=>({
                ...v,
                meta:{
                    title: v.meta.title,
                    affix: false
                }
            }))
            this.menus[obj.secondIdx].children [obj.thirdIdx].meta.affix = true
        },
        // 展开收起
        handleFoldSwitch(){
            this.folder = !this.folder
        },
        // 一级菜单切换
        handleSwitchSystem(idx){
            this.currentIdx = {
                firstIdx: idx,
                secondIdx: 0,
                thirdIdx: 0
            }
            this.isAdminActive = false
            this.clearSystem()
            this.systems[idx].selected = true
            this.selectedSystem = this.systems[idx]
            this.menus =_.cloneDeep(Routes[idx])
            this.menus[0].meta.affix = true
            this.menus[0].children[0].meta.affix = true
            this.currentPath = this.menus[0].children[0].path
            localStorage.setItem('currentIdx',JSON.stringify(this.currentIdx))
            this.$router.push(this.currentPath)
        },
        // 刷新
        handleRefresh(){
           window.location.reload()
        },
        // 三级菜单切换
        handleRouteChange(parent,child,idx,secondIdx){
            this.currentIdx = {
                firstIdx: !this.isAdminActive ? this.currentIdx.firstIdx : 2,
                secondIdx: secondIdx,
                thirdIdx: idx
            }
            if(this.currentPath == child.path) return
            this.currentPath = child.path
            // 清除其他菜单的样式
            parent.children = parent.children.map(v=>({
                ...v,
                meta: {
                    title: v.meta.title,
                    affix: false
                }
            }))
            let menus_len = this.menus.length;
            for(let i =0 ; i<menus_len;i++){
                this.menus[i].children = this.menus[i].children.map(v=>({
                    ...v,
                    meta: {
                        title: v.meta.title,
                        affix: false
                    }
                }))
            }
            parent.children[idx].meta.affix = true
            // 存储当前状态
            localStorage.setItem('currentIdx',JSON.stringify(this.currentIdx))
            this.$router.push(child.path)
        },
        // 二级菜单切换
        handleChildFolder(item){
            item.meta.affix = !item.meta.affix
        },
        // 退出
        logout(){
            logout().then(res=>{
                console.log(res)
                this.$message({
                    message: res.msg,
                    type: res.success ? 'success' : 'error'
                });
                if(res.success){
                    localStorage.removeItem('token')
                    this.$router.replace('/login')
                }
            })
        }
    },
}
</script>
<style scoped>
.siderBarHidden{
    display: none;
}
</style>