<template>
    <el-popover
        placement="bottom-start"
        :width="role.optionTableWidth ? role.optionTableWidth : 400"
        trigger="click" 
    >
        <el-row>
            <el-col :span="5">
                <el-tree 
                    :data="role.option" 
                    @node-click="apiAreaTreeHandleNodeClick"></el-tree>
            </el-col>
            <el-col :span="19">
                <el-table @selection-change="apiPopoverTableRowClick" :data="role.areaoption" size="mini" height="350" >
                    <el-table-column :key="index" v-for="item8,index in role.optionTableRole" :width="item8.width" :fixed="item8.fixed" :label="item8.title" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div v-for="(item2,index8) in scope.row" :key="index8" v-if="index8 == item8.field">
                                <span>{{scope.row[index8]}}</span>
                            </div>
                        </template>    
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <el-input v-model="scoperow[role.showfield]" slot="reference" size="mini" placeholder="请点击选择"></el-input>
    </el-popover>
</template>
<script>
import axios from '@/utils/request';
export default{
    props: {
        role: {
            type: Object,
            default: {}
        },
        scoperow: {
            type: Object,
            default: {}
        },
    },
    data() {
        return {
            tableShow:false,
        };
    },
    methods: {
        //地区树状菜单点击
        apiAreaTreeHandleNodeClick(obj,e,e1){
            let role = this.role;
            let feilds = this.scoperow;
            if(!obj.hasOwnProperty('children')){
                //获取区县信息
                let params = {
                    action:'info/apana/search',
                    extra_where:[['cCITY_NO','=',obj['cCITY_NO']]],
                    pagesize: 999999,
                    page: 1
                };

                request(params, function(resalt) {
                    var data = resalt.data.list.data;
                    if (data) {
                        role.areaoption = data;
                    }
                });
            }
        },

        //内 表格 被点击 带出字段 / 方法处理后带出字段
        apiPopoverTableRowClick(row, column, e) {
            let _this = this;
            let role = this.role;
            let feilds = this.scoperow;
            let tableRole = role.optionTableRole;

            tableRole.forEach(function(obj) {
                if (obj.toInsert) { //普通带出
                    _this.$set(feilds, obj.toInsert, row[obj.field]);
                }
                if (obj.toFunctionIncert) { //方法处理后带出
                    let newdata = eval(obj.toFunctionIncert[0] + "(row[obj.field],tableRole)");
                    _this.$set(feilds, obj.toFunctionIncert[1], newdata);
                }
            })
        },
    }
}
</script>
