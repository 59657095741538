<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form :searchbox="topsearchBox" :isedit="isEdit"></top-form>
        </div>
        <el-row>
            <el-col :span="8">
                <mytable :tablelet="lefttable" ref="lefttable" :isEdit="isEdit" @selectedRows="handleleftselectedRows"></mytable>
            </el-col>
            <el-col :span="16">
                <top-form :searchbox="bottomsearchBox"></top-form>
                <mytable :tablelet="righttable" :height="100" ref="righttable" :isEdit="isEdit" @selectedRows="handlerightselectedRows"></mytable>
                <mytable :tablelet="bottomtable" :height="100" ref="bottomtable" :isEdit="isEdit" @selectedRows="handleBottomselectedRows"></mytable>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '承运商资料',
            topsearchBox: [
                {
                    type: 'text',
                    title: '公司',
                    dec: '',
                    field: '',
                    value: '',
                },
                {
                    type: 'select_api', //类型 -> 输入框 + 弹出表格
                    title: '承运商类别',
                    icon: 'el-icon-menu',
                    option: [], //固定项
                    value: '',  //固定项
                    //弹出内表格设置
                    action: 'supplier/kind/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                    //弹出内表格布局规则
                    optionTableRole: [
                        {
                            title: '编号',			 //表头名称
                            field: 'cSUPLKIND_NO', 	 //显示字段 (调用对象基础表的字段)
                            width: 150,				 //列宽
                            toInsert: 'cSUPLKIND_NO', //带出写入字段 (选中后,要将此字段的值作为筛选参数)
                        }, {
                            title: '承运商分类',
                            field: 'cSUPLKIND_NM',
                            width: 150,
                        },
                    ],
                    showfield: 'cSUPLKIND_NM',
                    filtefield: 'cSUPLKIND_NM',
                    //因为与下面的"承运商"关联 所以当前值变化后,要将关联的其他列的字段清空
                    clearfield: ['cSUPPLIER_NO', 'cSUPPLIER_NM'], //为空时,需要 关联清空的字段 (主表字段)
                },
                {
                    type: 'text', 		//类型 -> 输入框
                    title: '承运商名称',	//输入框标题
                    dec: '输入叫车人员',	//输入框提示
                    field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                    icon: 'el-icon-user-solid',	//小图标
                    value: '',	//固定项
                },
                {
                    type: 'select', //类型 -> 固定值选择
                    title: '当前状态',
                    field: 'orderstate', //表格展示字段 (主表字段)
                    //
                    fixed: false, 	//是否浮动
                    width: 110, 	//列宽
                    //筛选值
                    option: [{
                        value: '0',
                        label: '未使用'
                    }, {
                        value: '1',
                        label: '使用'
                    }],
                    value: '', 		//固定项
                    noedit: true,	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                },
                {
                    type: 'select', //类型 -> 固定值选择
                    title: '审核状态',
                    field: 'orderstate', //表格展示字段 (主表字段)
                    //
                    fixed: false, 	//是否浮动
                    width: 110, 	//列宽
                    //筛选值
                    option: [{
                        value: '0',
                        label: '已审核'
                    },
                    {
                        value: '1',
                        label: '未审核'
                    },
                    {
                        value: '2',
                        label: '审核未通过'
                    }],
                    value: '', 		//固定项
                    noedit: true,	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                },
            ],
            bottomsearchBox: //顶部筛选配置
                [{
                    type: 'text',
                    title: '承运商编码', 		//输入框标题
                    //  dec: '输入承运商名称', //输入框提示
                    field: 'cSUPPLIER_NO',
                    //   option: [], //固定项
                    value: '', //固定项
                    width: 150,
                }, {
                    type: 'text',
                    title: '承运商名称', 		//输入框标题
                    dec: '输入承运商名称', //输入框提示
                    field: 'cSUPPLIER_NM',
                    // option: [], //固定项
                    value: '', //固定项
                    width: 150,
                    zjm:'BOPOMOFO',
                }, {
                    type: 'text', //类型 -> 数字
                    title: '助记码',
                    field: 'BOPOMOFO',
                    //
                    fixed: false,
                    width: 100,
                    //
                    value: '', //日期型 默认值为 0.000
                }, {
                    type: 'select_api', //类型 -> 输入框 + 弹出表格 
                    title: '类别', 		//输入框标题
                    dec: '输入客户名称', //输入框提示   
                    width: 100,
                    option: [],
                    value: '',
                    action: 'supplier/kind/search',
                    optionBoxWidth: 380,
                    optionTableRole: [
                        {
                            title: 'ID', 	//表头
                            field: 'idx', 	//表格展示字段 (主表字段)
                            fixed: true, 	//是否浮动
                            width: 80, 		//列宽
                            noedit: true, 	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                            defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                        },
                        {
                            title: '类别编码',
                            field: 'cSUPLKIND_NO',
                            width: 150,
                            toInsert: '',
                        }, {
                            title: '类别名称',
                            field: 'cSUPLKIND_NM',
                            width: 150,
                        },
                    ],
                    showfield: 'cSUPLKIND_NM', 	//在内表格中显示的字段 (调用对象基础表的字段)
                    filtefield: 'cSUPLKIND_NM', 	//筛选字段 当输入框的值改变时,用来跟显示字段比对筛选的字段(调用对象基础表字段)
                    // insert_deafault_field: {
                    //     'cSUPLKIND_NO': 'cSUPLKIND_NO', //主表字段':'调用对象基础表字段
                    // },
                }, {
                    type: 'text',
                    title: '承运商简称', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cSUPPLIER_SNM',
                    // option: [], //固定项
                    value: '', //固定项
                    width: 150,
                }, {
                    type: 'select', //类型 -> 固定值选择 
                    title: '承运商性质_', 		//输入框标题
                    field: '',
                    option: [
                        {
                            value: '0',
                            label: '股份'
                        }, {
                            value: '1',
                            label: '国有'
                        }, {
                            value: '2',
                            label: '民营'
                        }, {
                            value: '3',
                            label: '合资'
                        }, {
                            value: '4',
                            label: '外资'
                        }, {
                            value: '5',
                            label: '港资'
                        }, {
                            value: '6',
                            label: '台资'
                        }, {
                            value: '7',
                            label: '澳资'
                        },
                    ],
                    value: '', 		//固定项
                    // noedit: false,	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                }, {
                    type: 'text',
                    title: '法定代表', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cRIGHT_MEMBER',  //不确定
                    // option: [], //固定项
                    value: '', //固定项
                    width: 150,
                }, {
                    type: 'text',
                    title: '注册资本', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'nREGIST_CAPITAL',
                    // option: [], //固定项
                    value: '', //固定项
                    width: 150,
                }, {
                    type: 'text',
                    title: '注册证号', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cREGIST_CERTNO',
                    // option: [], //固定项
                    value: '', //固定项
                    width: 150,
                }, {
                    type: 'select', //类型 -> 固定值选择 
                    title: '合作类型', 		//输入框标题
                    field: 'cCOOPERATE_KIND',
                    option: [
                        {
                            value: '0',
                            label: '采购'
                        }, {
                            value: '1',
                            label: '直销'
                        }, {
                            value: '2',
                            label: '代销'
                        }, {
                            value: '3',
                            label: '其他'
                        },
                    ],
                    value: '', 		//固定项
                    // noedit: false,	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                }, {
                    type: 'text',
                    title: '所属行业_', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: '',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                }, {
                    type: 'text',
                    title: '经营范围', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cMANAG_RANGE',
                    // option: [], //固定项
                    value: '', //固定项
                    width: 150,
                    isedit: true,
                }, {
                    type: 'select', //类型 -> 固定值选择 
                    title: '纳税类型', 		//输入框标题
                    field: 'cTAX_TYPE',
                    option: [
                        {
                            value: '0',
                            label: '一般纳税人'
                        }, {
                            value: '1',
                            label: '小规模纳税人'
                        }, {
                            value: '2',
                            label: '中规模纳税人'
                        }, {
                            value: '3',
                            label: '大规模纳税人'
                        },
                    ],
                    value: '', 		//固定项
                    // noedit: false,	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                }, {
                    type: 'text',
                    title: '国税号', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cNATIONTAX_NO',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                }, {
                    type: 'text',
                    title: '地税号', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cLOCATETAX_NO',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                }, {
                    type: 'select_api',
                    title: '所属国家',
                    //  field: 'cSTNATION_NM',
                    // fixed: false,
                    width: 150,
                    option: [],
                    value: '',
                    action: 'info/nation/search',
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx',
                            width: 100,
                            toInsert: 'idx',
                        },
                        {
                            title: '国家代码',
                            field: 'cNATION_NO',
                            width: 150,
                            toInsert: 'cSTNATION_NO', //带出写入
                            stype: 'value',
                        }, {
                            title: '国家名称',
                            field: 'cNATION_NM',
                            width: 150,
                            toInsert: 'cSTNATION_NM', //带出写入
                            stype: 'label',
                        },
                    ],
                    showfield: 'cNATION_NM', //展示字段
                    filtefield: 'cNATION_NM', //筛选字段(用于选择的数据的某个字段)
                    clearfield: ['cSTPROV_NO', 'cSTPROV_NM', 'cSTCITY_NO', 'cSTCITY_NM', 'cSTAPANA_NO', 'cSTAPANA_NM'], //为空时,需要 关联清空的字段 
                    default_prama: [
                        ['cSTNATION_NO', 'cNATION_NO']
                    ]
                }, {
                    type: 'text',
                    title: '', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cNATION_NM',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                },
                {
                    type: 'select_api',
                    title: '所在省份',
                    // field: 'cSTPROV_NO',
                    width: 150,
                    // fixed: false,
                    option: [],
                    value: '',
                    action: 'info/prov/search', //调用方法
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx',
                            width: 80,
                            toInsert: 'idx',

                        },
                        {
                            title: '省份代码',
                            field: 'cPROV_NO',
                            width: 150,
                            toInsert: ' cSTPROV_NO', //带出写入
                            stype: 'value',
                        }, {
                            title: '省份名称',
                            field: 'cPROV_NM',
                            width: 150,
                            toInsert: 'cSTPROV_NM',
                            stype: 'label',

                        },
                    ],
                    showfield: 'cPROV_NM', //展示字段
                    filtefield: 'cPROV_NM',
                    parentField: [
                        ['cSTNATION_NO', 'cNATION_NO'], //['数据字段','基础表字段']   关联上级字段 (不会预加载)
                    ],
                    clearfield: ['cSTCITY_NO', 'cSTCITY_NM', 'cSTAPANA_NO', 'cSTAPANA_NM'], //为空时,需要 关联清空的字段
                }, {
                    type: 'text',
                    title: '', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cPROV_NM',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                }, {
                    title: '所在城市',
                    //    field: 'cSTCITY_NO',
                    //  fixed: false,
                    width: 150,
                    type: 'select_api',
                    option: [],
                    value: '',
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx',
                            width: 80,
                            toInsert: 'idx',

                        },
                        {
                            title: '城市代码',
                            field: 'cCITY_NO',
                            width: 150,
                            toInsert: 'cSTCITY_NO', //带出写入
                            stype: 'value',
                        }, {
                            title: '城市名称',
                            field: 'cCITY_NM',
                            width: 150,
                            toInsert: 'cSTCITY_NM', //带出写入
                            stype: 'label',
                        },
                    ],
                    showfield: 'cCITY_NM', //展示字段
                    filtefield: 'cCITY_NM', //筛选字段(用于选择的数据的某个字段)
                    action: 'info/city/search', //调用方法
                    parentField: [
                        ['cSTPROV_NO', 'cPROV_NO'], //['数据字段','基础表字段']   关联上级字段 (不会预加载)
                    ],
                    clearfield: ['cSTAPANA_NO', 'cSTAPANA_NM'], //为空时,需要 关联清空的字段 
                }, {
                    type: 'text',
                    title: '', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cCITY_NM',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                },
                {
                    title: '属地',
                    // field: 'cAPANA_SNM',
                    //  fixed: false,
                    width: 150,
                    type: 'select_api',
                    option: [],
                    value: '',
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx',
                            width: 80,
                            toInsert: 'idx',

                        },
                        {
                            title: '区县代码',
                            field: 'cAPANA_NO',
                            width: 150,
                            toInsert: 'cSTAPANA_NO', //带出写入
                            stype: 'value',
                        }, {
                            title: '县区名称',
                            field: 'cAPANA_SNM',
                            width: 150,
                            toInsert: 'cSTAPANA_NM', //带出写入
                            stype: 'label',
                        }
                    ],
                    optionTableWidth: 400, //内表格宽度
                    showfield: 'cAPANA_SNM', //展示字段
                    filtefield: 'cAPANA_SNM', //筛选字段(用于选择的数据的某个字段)
                    action: 'info/apana/search', //调用方法
                    parentField: [
                        ['cSTCITY_NO', 'cCITY_NO'], //['数据字段','基础表字段']
                    ] //关联上级字段 (不会预加载)

                }, {
                    type: 'text',
                    title: '', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cAPANA_SNM   ',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                }, {
                    type: 'text',
                    title: '地址', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cADDRESS',
                    // option: [], //固定项
                    value: '', //固定项
                    //  isedit:true,
                    width: 150,
                }, {
                    type: 'text',
                    title: '邮政编码', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cPOSTALCODE',
                    // option: [], //固定项
                    value: '', //固定项
                    //  isedit:true,
                    width: 150,
                }, {
                    type: 'text',
                    title: '联系人', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cLINKMAN',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                }, {
                    type: 'text',
                    title: '联系电话', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cTEL_NO',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                }, {
                    type: 'text',
                    title: '联系手机', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cMOBIL_TEL',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                }, {
                    type: 'text',
                    title: '传真电话', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cFAX_NO',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                }, {
                    type: 'text',
                    title: '电子信箱', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'E_mail',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                }, {
                    type: 'text',
                    title: 'QQ号码', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cQQ_NO',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                }, {
                    type: 'text',
                    title: '厂商网址', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cWEBSITE',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                }, {
                    type: 'number',
                    title: '排序号', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'iSORT_ID',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                },
                {
                    type: 'checkbox',
                    title: '客户共用',
                    field: 'cSHARE_TAG',
                    value: [],
                    option: [{
                        value: 0,
                        label: ''
                    }],
                },
                {
                    type: 'checkbox',
                    title: '停用：',
                    field: 'cSTOP_TAG',
                    value: [],
                    option: [{
                        value: 0,
                        label: ''
                    }],
                }, {
                    type: 'checkbox',
                    title: '参与竞价：',
                    field: 'cCMPPRC_TAG',
                    value: [],
                    option: [{
                        value: 0,
                        label: ''
                    }],
                }, {
                    type: 'text',
                    title: '资质描述_', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: '',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                }, {
                    type: 'text',
                    title: '备注', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cREMARK',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                }, {
                    type: 'checkbox',
                    title: '运费价格受控',
                    field: 'cCAYCHG_CTL',
                    value: [],
                    option: [{
                        value: 0,
                        label: ''
                    }],
                },
                {
                    type: 'text',
                    title: '创建人', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cESTUSER_NM',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                }, {
                    type: 'text',
                    title: '创建时间', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'tESTDATA_DT',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                }, {
                    type: 'text',
                    title: '维护人', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'cUSER_NM',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                }, {
                    type: 'text',
                    title: '维护时间', 		//输入框标题
                    //    dec: '输入承运商名称', //输入框提示
                    field: 'tMODIFY_DT',
                    // option: [], //固定项
                    value: '', //固定项
                    isedit: true,
                    width: 150,
                },

            ],
            lefttable:{
                tableRole: [
                    {
                        type: 'text',
                        title: '序号',
                        field: 'idx',
                        fixed: false,
                        width: 50,
                        value: '',
                    }, {
                        type: 'text',
                        title: '厂商名称',
                        field: 'cSUPPLIER_NM',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'text',
                        title: '厂商编码',
                        field: 'cSUPPLIER_NO',
                        fixed: false,
                        width: 100,
                        value: '',
                    }
                ],
                tableData: [],
                pagination_show: false 
            },
            righttable:{
                tableRole: [
                    {
                        title: 'ID', 	//表头
                        field: 'idx', 	//表格展示字段 (主表字段)
                        fixed: true, 	//是否浮动
                        width: 80, 		//列宽
                        noedit: true, 	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                        defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                    },
                    {
                        type: 'text',
                        title: '联系人',
                        field: 'cLINKMAN',
                        fixed: false,
                        width: 100,
                        value: '',
                    },
                    {
                        type: 'select',
                        title: '性别',
                        field: 'cGENDER',
                        fixed: false,
                        width: 110,
                        option: [{
                            value: '0',
                            label: '男'
                        }, {
                            value: '1',
                            label: '女'
                        },],
                        value: '',
                    }, {
                        type: 'text',
                        title: '固定电话',
                        field: 'cTEL_NO',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'number',
                        title: '手机',
                        field: 'cMOBIL_TEL',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'text',
                        title: '电子邮箱',
                        field: 'E_mail',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'text',
                        title: 'QQ号码',
                        field: 'cQQ_NO',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'text',
                        title: '备注',
                        field: 'cREMARK',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'checkbox',
                        title: '停用',
                        field: 'cSTOP_TAG',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                    }, {
                        type: 'text',
                        title: '异动人',
                        field: 'cUSER_NM',
                        fixed: false,
                        width: 100,
                        value: '',
                    },
                ],
                tableData: [],
                pagination_show: true 
            },
            bottomtable:{
                tableRole: [
                    {
                        title: 'ID', 	//表头
                        field: 'idx', 	//表格展示字段 (主表字段)
                        fixed: true, 	//是否浮动
                        width: 80, 		//列宽
                        noedit: true, 	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                        defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                    },
                    {
                        type: 'select',
                        title: '证件编码',
                        field: 'cCERT_NO',
                        fixed: false,
                        width: 200,
                        option: [],
                        value: '',
                        action: 'db03/info/emp/search',
                        optionTableRole: [
                            {
                                title: '证件类别代码',
                                field: 'cCERT_NO',
                                width: 150,
                                toInsert: 'cCERT_NO',
                                stype: 'value',

                            }, {
                                title: '证件名称',
                                field: 'cCERT_KIND',
                                width: 150,
                                toInsert: 'cCERT_KIND',
                                stype: 'label',
                            }
                        ],
                        showfield: 'cCERT_NO',
                        filtefield: 'cCERT_NO',
                        parentField: [
                            ['cCERT_NO', 'cCERT_NO'],
                        ]
                    }, {
                        type: 'text',
                        title: '证件名称',
                        field: 'cCERT_KIND',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'text',
                        title: '图片',
                        field: '',   //没有字段
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'checkbox',
                        title: '停用',
                        field: 'cSTOP_TAG',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                    }, {
                        type: 'text',
                        title: '备注',
                        field: 'cREMARK',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'text',
                        title: '维护人',
                        field: 'cUSER_NM',
                        fixed: false,
                        width: 100,
                        value: '',
                    },
                    {
                        type: 'date',
                        title: '维护时间',
                        field: 'tMODIFY_DT',
                        width: 150,
                        value: '',
                        fixed: false,
                        defautvalue: this.$getNowTime(true, true, true),
                        format: "yyyy-MM-dd",
                        // noedit: true,
                    },
                ],
                tableData: [],
                pagination_show: true  
            }
        }
    },
    methods:{
        handleleftselectedRows(rows){

        },
        handlerightselectedRows(rows){},
        handleBottomselectedRows(rows){}
    }   
}
</script>