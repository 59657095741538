<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <el-row :gutter="20">
            <el-col :span="4">
                <myTree :treename="TreeName" @sendMsg="handleTreeNode"></myTree>
            </el-col>
            <el-col :span="20">
                <div class="top-form-wrapper">
                    <top-form :searchbox="searchBox" ref="form" :isedit="isEdit"></top-form>
                </div>
                <mytable :tablelet="table" ref="table" :height="550" :pageInfo="pageInfo" :isEdit="isEdit" :tablerule="rules" @selectedRows="handleselectedRows"></mytable>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
// import {Tree} from '@/components/common/tree'
import {fetchCustomDestAddress,editCustomDestAddress,delCustomDestAddress} from '@/api/transport/base'
import {fetchNations,fetchProvs,fetchCitys,fetchAreas} from '@/api/common'
export default{
    mixins: [transportMix],
    // components:{Tree},
    data(){
        return {
            appName: '客户送达地点',
            TreeName: ['cCUST_NO'],
            //顶部筛选配置
            searchBox: [
                {
                    type: 'text',
                    title: '送货地址', 		//输入框标题
                    dec: '输入送货地址', //输入框提示
                    field: 'cADDRESS',
                    option: [], //固定项
                    value: '', //固定项
                },
                {
                    //基础设置
                    type: 'select', //类型 -> 固定值选择 
                    title: '状态', 		//输入框标题
                    icon: 'el-icon-user-solid', //小图标
                    field: 'cSTOP_TAG',
                    option: [{
                        value: '0',
                        label: '使用'
                    }, {
                        value: '1',
                        label: '停用'
                    }, {
                        value: '2',
                        label: '全部'
                    }
                    ],
                    value: '1', 		//固定项
                    noedit: false,	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                }
            ],
            table: {
                searchPath: 'cust/destaddress/search', 	//主表获取数据接口地址 （数据从后端传递到前端）
                insertPath: 'cust/destaddress/update',		//主表保存数据接口地址 （数据从前端存到后端）
                deletePath: 'cust/destaddress/del',     //删除接口
                //主表配置
                tableRole: [
                    {
                        title: 'ID', 	//表头
                        field: 'idx', 	//表格展示字段 (主表字段)
                        fixed: true, 	//是否浮动
                        width: 80, 		//列宽
                        noedit: true, 	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                        defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                    },
                    {
                        type: 'select_api',
                        title: '所在国家',
                        field: 'cNATION_NM',
                        fixed: false,
                        width: 200,
                        option: [],
                        value: '',
                        action: 'info/nation/search',
                        optionTableRole: [
                            {
                                title: '国家代码',
                                field: 'cNATION_NO',
                                width: 150,
                                toInsert: 'cNATION_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '国家名称',
                                field: 'cNATION_NM',
                                width: 150,
                                toInsert: 'cNATION_NM',
                                stype: 'label',
                            }
                        ],
                        showfield: 'cNATION_NM', //展示字段
                        filtefield: 'cNATION_NM', //筛选字段(用于选择的数据的某个字段)
                        clearfield: ['cPROV_NO', 'cPROV_NM', 'cCITY_NO', 'cCITY_NM', 'cAPANA_NO', 'cAPANA_NM'], //为空时,需要 关联清空的字段 
                    },
                    {
                        type: 'select_api',
                        title: '所在省份',
                        field: 'cPROV_NM',
                        fixed: false,
                        width: 200,
                        option: [],
                        value: '',
                        action: 'info/prov/search',
                        optionTableRole: [
                            {
                                title: '省份代码',
                                field: 'cPROV_NO',
                                width: 150,
                                toInsert: 'cPROV_NO',
                                stype: 'value',
                            }, {
                                title: '省份名称',
                                field: 'cPROV_NM',
                                width: 150,
                                toInsert: 'cPROV_NM',
                                stype: 'label',
                            }
                        ],
                        showfield: 'cPROV_NM', //展示字段
                        filtefield: 'cPROV_NM', //筛选字段(用于选择的数据的某个字段)
                        parentField: [
                            ['cNATION_NO', 'cNATION_NO'],
                        ],
                        clearfield: ['cCITY_NO', 'cCITY_NM', 'cAPANA_NO', 'cAPANA_NM'], //为空时,需要 关联清空的字段 
                    },
                    {
                        type: 'select_api',
                        title: '所在城市',
                        field: 'cCITY_NM',
                        fixed: false,
                        width: 200,
                        option: [],
                        value: '',
                        action: 'info/city/search',
                        optionTableRole: [
                            {
                                title: '城市代码',
                                field: 'cCITY_NO',
                                width: 150,
                                toInsert: 'cCITY_NO',
                                stype: 'value',
                            }, {
                                title: '城市名称',
                                field: 'cCITY_NM',
                                width: 150,
                                toInsert: 'cCITY_NM',
                                stype: 'label',
                            }
                        ],
                        showfield: 'cCITY_NM',
                        filtefield: 'cCITY_NM',
                        parentField: [
                            ['cPROV_NO', 'cPROV_NO'],
                        ],
                        clearfield: ['cAPANA_NO', 'cAPANA_NM'],
                    },
                    {
                        type: 'select_api',
                        title: '所在县区',
                        field: 'cAPANA_NM',
                        fixed: false,
                        width: 200,
                        option: [],
                        value: '',
                        action: 'info/apana/search',
                        optionTableRole: [
                            {
                                title: '县区代码',
                                field: 'cAPANA_NO',
                                width: 150,
                                toInsert: 'cAPANA_NO',
                                stype: 'value',

                            }, {
                                title: '县区名称',
                                field: 'cAPANA_NM',
                                width: 150,
                                toInsert: 'cAPANA_NM',
                                stype: 'label',
                            }
                        ],
                        showfield: 'cAPANA_NM',
                        filtefield: 'cAPANA_NM',
                        parentField: [
                            ['cCITY_NO', 'cCITY_NO'],
                        ]
                    },
                    {
                        type: 'text',
                        title: '收货地点',
                        field: 'cADDRESS',
                        fixed: false,
                        width: 300,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '联系固话',
                        field: 'cTEL_NO',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'text',
                        title: '联系手机',
                        field: 'cMOBIL_TEL',
                        fixed: false,
                        width: 100,
                        value: '',
                    },
                    {
                        type: 'select_api',
                        title: '所属客户',
                        field: 'cCUST_NO',
                        fixed: false,
                        width: 100,
                        option: [],
                        value: '',
                        action: 'cust/search',
                        optionTableRole: [
                            {
                                title: '客户编号',
                                field: 'cCUST_NO',
                                width: 150,
                                toInsert: 'cCUST_NO',
                            }, {
                                title: '客户名称',
                                field: 'cCUST_SNM',
                                width: 150,
                                toInsert: 'cCUST_SNM',
                            }
                        ],
                        showfield: 'cCUST_NO',
                        filtefield: 'cCUST_NO',
                        //   default_prama: [['cCUST_NO', 'cCUST_NO']],
                        clearfield: ['cCUST_SNM'],
                    },
                    {
                        type: 'text',
                        title: '客户名称',
                        field: 'cCUST_SNM',
                        fixed: false,
                        width: 100,
                        value: '',
                    },
                    {
                        type: 'checkbox',
                        title: '缺省',
                        field: 'cDEFAULT',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1',
                        falsenumber: '0',
                    }, {
                        type: 'checkbox',
                        title: '停用',
                        field: 'cSTOP_TAG',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1',
                        falsenumber: '0',
                    },
                    {
                        type: 'text',
                        title: '备注',
                        field: 'cREMARK',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'text',
                        title: '收货地点编码',
                        field: 'cDESTADDR_NO',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'text',
                        title: '维护人',
                        field: 'cUSER_NM',
                        value: 'immobilization',
                        width: 150,
                        //  noedit: true,
                    }, {
                        type: 'date',  //类型 -> 日期
                        title: '维护时间',
                        field: 'tMODIFY_DT',
                        fixed: false,
                        width: 150,
                        value: 'immobilization',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                    //     noedit: true,
                    }
                ],
                pagination_show: true,
                tableData: []
            },
        }
    },
    methods:{
        fetchNationsTree(){
            fetchNations().then(res=>{
                console.log(res)
            })
        },
        handleTreeNode(node){
            console.log(node)
        },
        tableSearch(){
            let params = {
                ...this.$refs.form.searchdata,
                page_index: this.pageInfo.index,
                page_size: this.pageInfo.size
            }
            fetchCustomDestAddress(params).then(res=>{
                this.table.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.pageInfo = res.data.page
            })
            this.fetchNationsTree()
        },
        handleselectedRows(rows){
            console.log(rows)
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data: {
                    idx: this.selectedRows.map(v=>v.idx)
                }
            };
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                delCustomDestAddress(params).then(res=>{
                    this.$message.success('删除成功!')
                    this.tableSearch()
                })
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                this.$message.error("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let idx = this.isAdd ? 0 : this.editIdx
            let currentData = this.$refs.table.tableData[idx]
            let editdata = [{
                ...currentData,
                idx: !!currentData.idx ? currentData.idx: '',
                cSTOP_TAG: currentData.cSTOP_TAG ? 1: 0,
                cDEFAULT: currentData.cDEFAULT ? 1 : 0
            }]
            editCustomDestAddress({data: {lstdata: editdata}}).then(res=>{
                this.isEdit = false
                this.tableSearch()
            })
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>