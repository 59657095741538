import { get, post } from '@/utils'

// 仓库货物资料
export const fetchMats = (params)=>{
    return get('api/stk/mat/search',params)
}

export const editMats = (params)=>{
    return post('api/stk/mat/update',params)
}

export const delMats = (params)=>{
    return post('api/stk/mat/del',params)
}