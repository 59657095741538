<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form ref="form":searchbox="toptable.searchBox" :isedit="isEdit"></top-form>
            <mytable :tablelet="toptable" ref="toptable" :pageInfo="pageInfo" :height="250" :isEdit="isEdit" :tablerule="rules" @selectedRows="handletopselectedRows"></mytable>
        </div>
        <div class="top-form-wrapper">
            <top-form ref="bottomForm" :searchbox="bottomtable.searchBox" :isedit="isEdit"></top-form>
            <mytable :tablelet="bottomtable" ref="bottomtable" :height="250" :isEdit="isEdit" :tablerule="rules" @selectedRows="handlebottomselectedRows"></mytable>
        </div>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '货物入库管理',
            toptable:{
                searchBox: [
                    {
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '客户', 		//输入框标题
                        dec: '请点击选择', //输入框提示
                        icon: 'el-icon-user-solid', //小图标
                        option: [], //固定项
                        value: '', //固定项
                        //弹出内表格设置
                        action: 'cust/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        //弹出内表格布局规则
                        optionBoxWidth: 500,
                        optionTableRole: [
                            {
                                title: '编号', 			//表头名称
                                field: 'cCUST_NO', 		//显示字段 (调用对象基础表的字段)
                                width: 150,				//列宽
                                toInsert: 'cCUST_NO', 	//带出写入字段 (选中后,要将此字段的值作为筛选参数)
                            }, {
                                title: '客户名称',
                                field: 'cCUST_NM',
                                width: 150,
                            },
                        ],
                        showfield: 'cCUST_NM', 	//在内表格中显示的字段 (调用对象基础表的字段)
                        filtefield: 'cCUST_NM', 	//筛选字段 当输入框的值改变时,用来跟显示字段比对筛选的字段(调用对象基础表字段)
                        //是否将部分字段作为全局筛选条件,如果是,则增加下方格式代码(此全局筛选条件,将在表格中被使用)
                        insert_deafault_field: {
                            'cCUST_NO': 'cCUST_NO', //主表字段':'调用对象基础表字段
                        },
                    }, {
                        type: 'text',
                        title: '入库单号',
                        dec: '输入入库单号',
                        field: '',
                        value: '',
                    }, {
                        type: 'text',
                        title: '订单编号',
                        dec: '输入订单编号',
                        field: '',
                        value: '',
                    }, {
                        type: 'text',
                        title: '系统单号',
                        dec: '输入系统单号',
                        field: '',
                        value: '',
                    }, {
                        type: 'select_api',
                        title: '仓储区域',
                        dec: '请点击选择',
                        option: [],
                        value: '',
                        action: '',
                        optionBoxWidth: 500,
                        optionTableRole: [
                            {
                                title: '序号',
                                field: '',
                                width: 150,
                                toInsert: '',
                            }, {
                                title: '仓储片区',
                                field: '',
                                width: 150,
                            },
                        ],
                        showfield: '',
                        filtefield: '',
                        insert_deafault_field: {
                            '': '',
                        },
                    }, {
                        type: 'daterange',
                        title: '入库日期',
                        dec: '输入年月',
                        field: '',
                        value: '',
                        format: 'yyyy-MM/dd',
                    }, {
                        type: 'select_api',
                        title: '入库类别',
                        dec: '请点击选择',
                        option: [],
                        value: '',
                        action: 'stk/incls/search',
                        optionBoxWidth: 500,
                        optionTableRole: [
                            {
                                title: '入库类别编码',
                                field: '',
                                width: 150,
                                toInsert: '',
                            }, {
                                title: '入库类别名称',
                                field: '',
                                width: 150,
                            },
                        ],
                        showfield: '',
                        filtefield: '',
                        insert_deafault_field: {
                            '': '',
                        },
                    }, {
                        type: 'select',
                        title: '审核状态',
                        field: '',
                        value: [],
                        option: [{
                            value: 0,
                            label: '未审核'
                        }, {
                            value: 1,
                            label: '已审核'
                        }, {
                            value: 3,
                            label: '全部'
                        }],
                    }
                ],
                tableRole: [
                    {
                        title: 'ID', 	//表头
                        field: '', 	//表格展示字段 (主表字段)
                        fixed: true, 	//是否浮动
                        width: 80, 		//列宽
                        noedit: true, 	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                        defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                    }, {
                        type: 'select_api',
                        title: '货物名称',
                        field: '',
                        fixed: false,
                        width: 200,
                        option: [],
                        value: '',
                        action: '',
                        optionTableRole: [
                            {
                                title: '编号',
                                field: '',
                                width: 150,
                                toInsert: '',
                                stype: 'value'
                            }, {
                                title: '货物名称',
                                field: '',
                                width: 150,
                                toInsert: '',
                                stype: 'label'
                            },
                        ],
                        showfield: '',
                        filtefield: '',
                        hasCheck: true,
                        default_prama: [
                            ['', ''],
                        ],
                    }, {
                        type: 'text',
                        title: '货物编码',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '规格说明',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'select_api',
                        title: '类别',
                        field: '',
                        fixed: false,
                        width: 200,
                        option: [],
                        value: '',
                        action: '',
                        optionTableRole: [
                            {
                                title: '类别编码',
                                field: '',
                                width: 150,
                                toInsert: '',
                                stype: 'value'
                            }, {
                                title: '类别名称',
                                field: '',
                                width: 150,
                                toInsert: '',
                                stype: 'label'
                            },
                        ],
                        showfield: '',
                        filtefield: '',
                        hasCheck: true,
                        default_prama: [
                            ['', ''],
                        ],
                    }, {
                        type: 'text',
                        title: '批次',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '货物状态',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '正品',
                    }, {
                        type: 'date',
                        title: '先进先出日',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: this.$getNowTime(true, true, true),
                    }, {
                        type: 'text',
                        title: '包装编号',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '计量单位',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'checkbox',
                        title: '粒转箱',
                        field: '',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1',
                        falsenumber: '0',
                        insertfieldtime: '',
                    }, {
                        type: 'number',
                        title: '数量',
                        field: '',
                        fixed: false,
                        width: 70,
                        value: '',
                        defautvalue: 0,
                    }, {
                        type: 'number',
                        title: '整件数',
                        field: '',
                        fixed: false,
                        width: 70,
                        value: '',
                        defautvalue: 0,
                    }, {
                        type: 'number',
                        title: '零头数量',
                        field: '',
                        fixed: false,
                        width: 70,
                        value: '',
                        defautvalue: 0,
                    }, {
                        type: 'number',
                        title: '单件货数',
                        field: '',
                        fixed: false,
                        width: 70,
                        value: '',
                        defautvalue: 0,
                    }, {
                        type: 'text',
                        title: '存放单位',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'number',
                        title: '单重',
                        field: '',
                        fixed: false,
                        width: 70,
                        value: '',
                        defautvalue: 0,
                    }, {
                        type: 'number',
                        title: '净重',
                        field: '',
                        fixed: false,
                        width: 70,
                        value: '',
                        defautvalue: 0,
                    }, {
                        type: 'number',
                        title: '毛重',
                        field: '',
                        fixed: false,
                        width: 70,
                        value: '',
                        defautvalue: 0,
                    }, {
                        type: 'number',
                        title: '长',
                        field: '',
                        fixed: false,
                        width: 70,
                        value: '',
                        defautvalue: 0,
                    }, {
                        type: 'number',
                        title: '宽',
                        field: '',
                        fixed: false,
                        width: 70,
                        value: '',
                        defautvalue: 0,
                    }, {
                        type: 'number',
                        title: '高',
                        field: '',
                        fixed: false,
                        width: 70,
                        value: '',
                        defautvalue: 0,
                    }, {
                        type: 'number',
                        title: '体积',
                        field: '',
                        fixed: false,
                        width: 70,
                        value: '',
                        defautvalue: 0,
                    }, {
                        type: 'number',
                        title: '拒收数量',
                        field: '',
                        fixed: false,
                        width: 70,
                        value: '',
                        defautvalue: 0,
                    }, {
                        type: 'text',
                        title: '拒收原因',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '包装方式',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '运输编排号',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '字符备一',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'number',
                        title: '数字备一',
                        field: '',
                        fixed: false,
                        width: 70,
                        value: '',
                        defautvalue: 0,
                    }, {
                        type: 'text',
                        title: '备注',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '系统物料号',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }
                ],
                pagination_show: true,
                tableData: []
            },
            bottomtable:{
                searchBox: [
                    {
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '客户', 		//输入框标题
                        dec: '请点击选择', //输入框提示
                        icon: 'el-icon-user-solid', //小图标
                        option: [], //固定项
                        value: '', //固定项
                        //弹出内表格设置
                        action: 'cust/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        //弹出内表格布局规则
                        optionBoxWidth: 500,
                        optionTableRole: [
                            {
                                title: '编号', 			//表头名称
                                field: 'cCUST_NO', 		//显示字段 (调用对象基础表的字段)
                                width: 150,				//列宽
                                toInsert: 'cCUST_NO', 	//带出写入字段 (选中后,要将此字段的值作为筛选参数)
                            }, {
                                title: '客户名称',
                                field: 'cCUST_NM',
                                width: 150,
                            },
                        ],
                        showfield: 'cCUST_NM', 	//在内表格中显示的字段 (调用对象基础表的字段)
                        filtefield: 'cCUST_NM', 	//筛选字段 当输入框的值改变时,用来跟显示字段比对筛选的字段(调用对象基础表字段)
                        //是否将部分字段作为全局筛选条件,如果是,则增加下方格式代码(此全局筛选条件,将在表格中被使用)
                        insert_deafault_field: {
                            'cCUST_NO': 'cCUST_NO', //主表字段':'调用对象基础表字段
                        },
                    }, {
                        type: 'text',
                        title: '入库单号',
                        dec: '输入入库单号',
                        field: '',
                        value: '',
                    }, {
                        type: 'text',
                        title: '客户订单号',
                        dec: '输入客户订单号',
                        field: '',
                        value: '',
                    }, {
                        type: 'date',
                        title: '入库日期',
                        dec: '输入年月',
                        field: '',
                        value: '',
                        format: 'yyyy-MM/dd',
                    }, {
                        type: 'select_api',
                        title: '入库类别',
                        dec: '请点击选择',
                        option: [],
                        value: '',
                        action: '',
                        optionBoxWidth: 500,
                        optionTableRole: [
                            {
                                title: '入库类别编码',
                                field: '',
                                width: 150,
                                toInsert: '',
                            }, {
                                title: '入库类别名称',
                                field: '',
                                width: 150,
                            },
                        ],
                        showfield: '',
                        filtefield: '',
                        insert_deafault_field: {
                            '': '',
                        },
                    }, {
                        type: 'text',
                        title: '车牌号',
                        dec: '输入车牌号',
                        field: '',
                        value: '',
                    }, {
                        type: 'number',
                        title: '入库数量/重量',
                        dec: '输入车牌号',
                        field: '',
                        value: '',
                    }, {
                        type: 'text',
                        title: '系统单号',
                        dec: '输入系统单号',
                        field: '',
                        value: '',
                    }, {
                        type: 'select_api',
                        title: '仓储区域',
                        dec: '请点击选择',
                        option: [],
                        value: '',
                        action: '',
                        optionBoxWidth: 500,
                        optionTableRole: [
                            {
                                title: '序号',
                                field: '',
                                width: 150,
                                toInsert: '',
                            }, {
                                title: '仓储片区',
                                field: '',
                                width: 150,
                            },
                        ],
                        showfield: '',
                        filtefield: '',
                        insert_deafault_field: {
                            '': '',
                        },
                    }, {
                        type: 'text',
                        title: '备注',
                        dec: '输入备注',
                        field: '',
                        value: '',
                    }, {
                        type: 'text',
                        title: '创建人',
                        dec: '',
                        field: '',
                        value: '',
                        noedit: true,
                    }, {
                        type: 'date',
                        title: '创建时间',
                        dec: '输入年月',
                        field: '',
                        value: '',
                        defautvalue: this.$getNowTime(true, true, true),
                        format: 'yyyy-MM/dd',
                    }, {
                        type: 'text',
                        title: '维护人',
                        dec: '',
                        field: '',
                        value: '',
                        noedit: true,
                    }, {
                        type: 'date',
                        title: '维护时间',
                        dec: '输入年月',
                        field: '',
                        value: '',
                        defautvalue: this.$getNowTime(true, true, true),
                        format: 'yyyy-MM/dd',
                    }
                ],
                tableRole: [
                    {
                        title: 'ID',
                        field: 'idx',
                        fixed: true,
                        width: 80,
                        noedit: true,
                        defautvalue: 0,
                    }, {
                        type: 'text',
                        title: '客户',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'date',
                        title: '入库日期',
                        dec: '输入年月',
                        field: '',
                        value: '',
                        format: 'yyyy-MM/dd',
                    }, {
                        type: 'text',
                        title: '入库单号',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '客户订单号',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '入库类别',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'number',
                        title: '入库数量',
                        field: '',
                        fixed: false,
                        width: 70,
                        value: '',
                        defautvalue: 0,
                    }, {
                        type: 'text',
                        title: '备注',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '审核状态',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '系统单号',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '维护人',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'date',
                        title: '维护时间',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                        format: "yyyy-MM-dd",
                    },
                ],
                pagination_show: true,
                tableData: []
            },
            bottomselectedRows: ''
        }
    },
    methods: {
        tableSearch(){
            
        },
        handletopselectedRows(rows){
            console.log(rows)
            this.selectedRows = rows
        },
        handlebottomselectedRows(rows){
            console.log(rows)
            this.bottomselectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
                })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data:{
                    idx: this.selectedRows.map(v=>v.idx)
                }
            };
            if (this.selectedRows.length == 0) {
                alert("未选择删除数据！");
            } else {
                
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                alert("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let data = this.tableData;
            let lstdata = [];
            if (!this.isAdd) {//编辑
            } else {//新增
            }
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>