<template>
    <div>
        <el-form :model="tablelet" ref="paramsForm" :rules="tablerule" size="mini">
            <el-table :data="tableData" ref="mytable" :span-method="arraySpanMethod" 
                v-loading="loading"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"  
                :height="height" style="width: 100%;"
                @selection-change="selectChange"
            >
                <el-table-column type="selection" width="40" />
                <el-table-column :key="indexT"
                    v-for="item,indexT in tablelet.tableRole" 
                    :width="item.width" 
                    :fixed="item.fixed"  :label="item.title"
                >
                    <template slot-scope="scope">
                        <el-form-item :prop="item.field">
                            <div v-if="item.type == 'text' || item.type == 'number'">
                                <div v-if="scope.row.isEdit">
                                    <el-input v-if="item.value == 'immobilization'" disabled size="mini"></el-input>
                                    <el-input :type="item.type" v-else v-model="scope.row[item.field]" @blur="handleBlurChange(scope.row,item)"></el-input> 
                                </div>
                                <span v-else>{{scope.row[item.field]}}</span>
                            </div>
                            <!-- 单选框 -->
                            <el-checkbox v-if="item.type == 'checkbox'" :disabled="!isEdit" @change="scope.row[item.field]" v-model="scope.row[item.field]"></el-checkbox>
                            <!-- 日期 -->
                            <div v-if="item.type == 'date'  || item.type == 'datetime'">
                                <div v-if="scope.row.isEdit">
                                    <div v-if="item.value !== 'immobilization'">
                                        <el-date-picker 
                                            v-if="item.type == 'date'"
                                            v-model="scope.row[item.field]" 
                                            :value-format="item.format ? item.format : 'yyyy-MM-dd'" 
                                            :format="item.format ? item.format : 'yyyy-MM-dd'" 
                                        >
                                        </el-date-picker>
                                        <el-date-picker 
                                            v-else
                                            type="datetime" 
                                            v-model="scope.row[item.field]" 
                                            size="mini"
                                            :value-format="item.format ? item.format : 'yyyy-MM-dd HH:mm:ss'" 
                                            :format="item.format ? item.format : 'yyyy-MM-dd HH:mm:ss'" 
                                        >
                                        </el-date-picker>
                                    </div>
                                    <div v-else>{{ item.defautvalue }}</div>
                                </div>
                                <span v-else>{{ scope.row[item.field]}}</span>
                            </div>
                            <!-- 固定select选择 -->
                            <div v-if="item.type == 'select'">
                                <el-select filterable  size="mini" v-if="scope.row.isEdit" v-model="scope.row[item.field]">
                                    <el-option v-for="item3 in item.option" :key="item3.value" :label="item3.label" :value="item3.value">
                                    </el-option>
                                </el-select>
                                <span v-else>{{ matchSelectedData(item.option,scope.row[item.field]) }}</span>
                            </div>
                            <!-- api选择 -->
                            <div v-if="item.type == 'select_api'">
                                <td-api-select v-if="scope.row.isEdit" :role="item" :scoperow="scope.row"> </td-api-select>
                                <span v-else>{{ scope.row[item.field] }}</span>
                            </div>
                            <!-- 地区选择 -->
                            <div v-if="item.type == 'area'" >
                                <td-area :role="item" v-if="scope.row.isEdit" :scoperow="scope.row"> </td-area>
                                <span v-else>{{ scope.row[item.field] }}</span>
                            </div>
                            <span v-if="!item.type">{{ scope.row[item.field]  }}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
            </el-table>
        </el-form>
        <el-row>
            <el-col :span="16" v-if="tablelet.pagination_show">
                <el-pagination :disabled="isEdit" @size-change="tableSizeChange" @current-change="tableHandleCurrentChange" :current-page="page" :page-sizes="[10,30, 50, 70, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-col>
        </el-row>
    </div>
</template>
<script>
    import _ from 'lodash'
    import tdApiSelect from './apiselect.vue'
    import tdArea from './area.vue'
    import pinyin from 'pinyin'
    export default {
        components: {tdApiSelect,tdArea},
        props:{
            tablelet:{
                type: Object,
                default: () => { return {} }
            },
            tablerule: {
                type: Object,
                default: () => { return {} }
            },
            isEdit: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            height:{
                type: Number,
                default: 300
            },
            arraySpanMethod: {
                type: Function
            },
            pageInfo: {
                type: Object,
                default: () => { return {
                    index: 1,
                    total: 0,
                    size: 10
                }} 
            }
        },
        data(){
            return{
                page: 1,
                pageSize: 10,
                tableData: []
            }
        },
        created(){
            this.page = this.pageInfo.index
            this.pageSize = this.pageInfo.size
        },
        methods:{
            matchSelectedData(options,val){
                if(val !== ''){
                    let obj = options.find(v=>v.value == val)
                    return !!obj ? obj.label : ''
                }else{
                    return ''
                }
            },
            handleBlurChange(row,item){
                //是否有助记码
                if(item.zjm == 'BOPOMOFO'){
                    row['BOPOMOFO'] = this.transferPinyin(row[item.field])
                }
            },
            transferPinyin(str){
                let result = pinyin(str).flat(Infinity).map(str => str.charAt(0));
                let len = result.length, zjm= '';
                for(let i=0; i<len;i++){
                    zjm += result[i]
                }
                return zjm
            },
            //修改分页条数
            tableSizeChange(val) {
                this.pageSize = val;
                this.page = 1;
                this.$emit('search',{
                    page: this.pageSize,
                    index: this.page
                })
            },

            //点击分页
            tableHandleCurrentChange(val) {
                this.page = val;
                this.$emit('search',{
                    page: this.pageSize,
                    index: this.page
                })
            },
            selectChange(rows){
                this.$emit('selectedRows',rows);
            }
        },
        watch: {
            'tablelet.tableData': {
                handler: function (newVal, oldVal) {
                    this.tableData = newVal
                },
                deep: true // 开启深度监听
            }
        },
        computed:{
            total(){
                return this.pageInfo.total
            }
        }
    }
</script>