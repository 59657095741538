<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form ref="form" :searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" ref="table" :height="550" :pageInfo="pageInfo" :isEdit="isEdit" :tablerule="rules" @selectedRows="handleselectedRows"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
import {fetchWPStates,editWPStates,delWPStates} from '@/api/storage/base'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '仓库类别资料',
            //检索配置
            searchBox: [{
                //基础设置
                type: 'text', 
                title: '仓位类别', //输入框标题
                width: 150,
                field: 'cWPSTATE_NM',
                dec: '输入客户名称', //输入框提示
                value: '', //固定项
            }],
            table: {
                //主表配置
                tableRole: [
                    {
                        title: 'ID', //表头
                        field: 'idx', //表格展示字段 (主表字段)
                        fixed: true, //是否浮动
                        width: 80, //列宽
                        noedit: false, //是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                        defautvalue: 0, //默认值,当为输入内容时的默认的值
                    }, {
                        type: 'text',
                        title: '仓位类别编码',
                        field: 'cWPSTATE_NO',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '仓位类别名称',
                        field: 'cWPSTATE_NM',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text', //类型 -> 输入框
                        title: '助记码', //输入框标题
                        field: 'BOPOMOFO', //对应筛选字段 (主表字段)
                        value: '', //固定项
                    }, {
                        type: 'text1',
                        title: '系统',
                        field: 'cSYS_TAG',
                        fixed: false,
                        width: 130,
                        value: '',
                    }, {
                        type: 'checkbox', //类型 -> 单选
                        title: '缺省',
                        field: 'cDEFAULT',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                    }, {
                        type: 'checkbox', //类型 -> 单选
                        title: '停用',
                        field: 'cSTOP_TAG',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                    }, {
                        type: 'date', //类型 -> 日期
                        title: '停用时间',
                        field: 'tSTOP_DT',
                        fixed: false,
                        width: 150,
                        value: '',
                        defautvalue: '', //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                        // noedit: true,
                    }, {
                        type: 'text', //类型 -> 输入框
                        title: '备注', //输入框标题
                        field: 'cREMARK', //对应筛选字段 (主表字段)
                        value: '', //固定项
                    }, {
                        type: 'text', //类型 -> 输入框
                        title: '创建人', //输入框标题
                        field: 'cESTUSER_NM', //对应筛选字段 (主表字段)
                        value: '', //固定项
                    }, {
                        type: 'date', //类型 -> 日期
                        title: '创建时间',
                        field: 'tESTDATA_DT',
                        fixed: false,
                        width: 150,
                        value: 'immobilization',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                    }, {
                        type: 'text', //类型 -> 输入框
                        title: '维护人', //输入框标题
                        field: 'cUSER_NM', //对应筛选字段 (主表字段)
                        value: '', //固定项
                    }, {
                        type: 'date', //类型 -> 日期
                        title: '维护时间',
                        field: 'tMODIFY_DT',
                        fixed: false,
                        width: 150,
                        value: '',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                    },
                ],
                pagination_show: true,
                tableData: []
            },
        }
    },
    methods: {
        tableSearch(){
            let params = {
                ...this.$refs.form.searchdata,
                page_index: this.pageInfo.index,
                page_size: this.pageInfo.size
            }
            fetchWPStates(params).then(res=>{
                this.table.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.pageInfo = res.data.page
            })  
        },
        handleselectedRows(rows){
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data:{
                    idx: this.selectedRows.map(v=>v.idx)
                }
            };
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                delWPStates(params).then(res=>{
                    this.$message.success('删除成功')
                    this.tableSearch()
                })
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                this.$message.error("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let idx = this.isAdd ? 0 : this.editIdx
            let currentData = this.$refs.table.tableData[idx]
            let editdata = [{
                ...currentData,
                idx: !!currentData.idx ? currentData.idx: '',
                cSTOP_TAG: currentData.cSTOP_TAG ? 1: 0,
                cDEFAULT: currentData.cDEFAULT ? 1 : 0
            }]
            editWPStates({data: {lstdata: editdata}}).then(res=>{
                this.isEdit = false
                this.tableSearch()
            })
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>