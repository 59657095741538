<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form ref="form" :searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" ref="table" :height="550" :isEdit="isEdit" :tablerule="rules" @selectedRows="handleselectedRows"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
import{fetchwhpos,editwhpos,delwhpos} from '@/api/storage/base'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '仓库仓位划分',
            //检索配置
            searchBox: [
                {
                    type: 'select_api',
                    title: '仓储片区',
                    icon: 'el-icon-menu',
                    option: [], //固定项
                    value: '',  //固定项
                    action: 'ware/waret/search',
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx', 		//显示字段 (调用对象基础表的字段)
                            width: 60,
                        }, {
                            title: '仓储片区代码', 			//表头名称
                            field: 'cWARET_NO', 		//显示字段 (调用对象基础表的字段)
                            width: 120,				//列宽
                            toInsert: 'cWARET_NO', 	//带出写入字段 (选中后,要将此字段的值作为筛选参数)
                        }, {
                            title: '仓储片区',
                            field: 'cWARET_NM',
                            width: 150,
                            toInsert: 'cWARET_NM',
                        },
                    ],
                    showfield: 'cWARET_NM',
                    filtefield: 'cWARET_NM',
                    //因为与下面的"仓库"关联 所以当前值变化后,要将关联的其他列的字段清空
                    clearfield: ['cWAREH_NO', 'cWAREH_NO'], //为空时,需要 关联清空的字段 (主表字段)
                    //
                }, {
                    type: 'select_api',
                    title: '仓库',
                    field: 'cWAREH_NO',
                    dec: '请输入仓库',
                    option: [], //固定项
                    value: '', //固定项
                    action: 'ware/wareh/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx',
                            width: 60,
                        }, {
                            title: '仓库代码',
                            field: 'cWAREH_NO', 		//显示字段 (调用对象基础表的字段)
                            width: 120,
                            toInsert: 'cWAREH_NO', 	//带出写入字段 (选中后,要将此字段的值作为筛选参数)
                        }, {
                            title: '仓库名称',
                            field: 'cWAREH_NM',
                            width: 150,
                            toInsert: 'cWAREH_NM',
                        },
                    ],
                    showfield: 'cWAREH_NM', //展示字段
                    filtefield: 'cWAREH_NM', //筛选字段(用于选择的数据的某个字段)
                }, {
                    type: 'select', //类型 -> 固定值选择
                    title: '使用状态',
                    field: 'cSTOP_TAG',
                    fixed: false,
                    width: 50,
                    option: [{
                        value: '0',
                        label: '停用'
                    }, {
                        value: '1',
                        label: '使用中'
                    }, {
                        value: '2',
                        label: '全部'
                    }],
                    value: '1', 		//固定项
                }
            ],
            table: {
                //主表配置
                tableRole: [
                    {
                        title: '序号',
                        field: 'idx',
                        fixed: true,
                        width: 50,
                        noedit: true,
                        defautvalue: 0,
                    }, {
                        type: 'text',
                        title: '仓位号',
                        field: 'cWHLPOS_NO',
                        value: '',
                        width: 120,
                    }, {
                        type: 'text',
                        title: '仓位说明',
                        field: 'cWHPOS_NM',
                        value: '',
                        width: 120,
                    }, {
                        type: 'select_api',
                        title: '仓位标椎',
                        field: 'cWHPOSCLS_NM',       // 应该与仓位类别标准联动，且类型应该是下拉选择
                        fixed: false,
                        width: 150,
                        value: '',
                        option: [],
                        action: 'ware/whposcls/search',
                        optionTableRole: [
                                {
                                title: '编号',
                                field: 'cWHPOSCLS_NO',
                                width: 150,
                                toInsert: 'cWHPOSCLS_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '仓位类别',
                                field: 'cWHPOSCLS_NM',
                                width: 150,
                                toInsert: 'cWHPOSCLS_NM',
                                stype: 'label',
                            },
                        ],
                        showfield: 'cWHPOSCLS_NM',
                        filtefield: 'cWHPOSCLS_NM',
                        hasCheck: true,
                        //  default_prama: [['cCUST_NO', 'cCUST_NO']],
                    },
                    {
                        type: 'number',
                        title: '长',
                        field: 'nLENGTH',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '宽',
                        field: 'nWIDTH',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '高',
                        field: 'nHEIGHT',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '面积',
                        field: 'nAREA',
                        value: '',
                        width: 100,
                    }, {
                        type: 'number',
                        title: '容积',
                        field: 'nVOLUME',
                        value: '',
                        width: 120,
                    }, {
                        type: 'number',
                        title: '已存货量',
                        field: 'nMAT_QTY',
                        value: '',
                        width: 120,
                        noedit: true,
                    }, {
                        type: 'number',
                        title: '存货种数',
                        field: 'iMATCLS_QTY',//字段未知
                        value: '',
                        width: 120,
                        noedit: true,
                    }, {
                        type: 'progress',
                        title: '存满率',
                        field: 'nFULL_RATE',
                        value: '',
                        width: 120,
                        noedit: true,
                    }, {
                        type: 'select_api',
                        title: '所属仓库',
                        field: 'cWAREH_NM',
                        fixed: false,
                        width: 200,
                        sort: false,
                        option: [],
                        value: '',
                        action: 'ware/wareh/search',
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'idx',
                                width: 80,
                            }, {
                                title: '仓库编码',
                                field: 'cWAREH_NO',
                                width: 150,
                                toInsert: 'cWAREH_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '仓库名称',
                                field: 'cWAREH_NM',
                                width: 150,
                                toInsert: 'cWAREH_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cWAREH_NM',
                        filtefield: 'cWAREH_NM',
                        hasCheck: true,
                        // default_prama: [['cWAREH_NO', 'cWAREH_NM']]
                    }, {
                        type: 'select_api',
                        title: '存货类别',
                        field: 'cSTKMATCLS_NO',
                        fixed: false,
                        width: 200,
                        sort: false,
                        option: [],
                        value: '',
                        action: 'stk/mat/search', //接口未知，数据来源？
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'idx',
                                width: 80,
                            }, {
                                title: '存货类别编码',
                                field: 'cSTKMATCLS_NO',
                                width: 120,
                                toInsert: 'cSTKMATCLS_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '存货类别名称',
                                field: 'cSTKMATCLS_NM',
                                width: 150,
                                toInsert: 'cSTKMATCLS_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cSTKMATCLS_NO',
                        filtefield: 'cSTKMATCLS_NO',
                        hasCheck: true,
                        // default_prama: [['', '']]
                    }, {
                        type: 'select_api',
                        title: '客户',
                        field: 'cCUST_NM',
                        fixed: false,
                        width: 150,
                        sort: false,
                        option: [],
                        value: '',
                        action:'cust/search',
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'idx',
                                width: 80,
                            }, {
                                title: '客户编码',
                                field: 'cCUST_NO',
                                width: 120,
                            //     toInsert: 'cCUST_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '客户名称',
                                field: 'cCUST_NM',
                                width: 150,
                                toInsert: 'cCUST_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cCUST_NM',
                        filtefield: 'cCUST_NM',
                        hasCheck: true,
                        //  default_prama: [['cCUST_NO', 'cCUST_NM']]
                    }, {
                        type: 'text',
                        title: '仓位类别',
                        field: 'cWPSTATE_NO',//字段未知
                        value: '',
                        width: 120,
                    }, {
                        type: 'checkbox', //类型 -> 单选
                        title: '停用',
                        field: 'cSTOP_TAG',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'text',
                        title: '备注',
                        field: 'cREMARK',
                        value: '',
                        width: 150,
                    }, {
                        type: 'date',
                        title: '最近入库日',
                        field: 'cINTO_DATE',
                        value: '',
                        width: 150,
                        noedit: true,
                    }, {
                        type: 'date',
                        title: '最近出库日',
                        field: 'cOUT_DATE',
                        value: '',
                        width: 150,
                        noedit: true,
                    }, {
                        type: 'text',
                        title: '主要存货名称',
                        field: 'cSTKMAT_NM',
                        value: '',
                        width: 150,
                        noedit: true,
                    }, {
                        type: 'text',
                        title: '仓位系统编码',
                        field: 'cWHPOS_NO',
                        value: '',
                        width: 150,
                        noedit: true,
                    }, {
                        type: 'text',
                        title: '创建人',
                        field: 'cESTUSER_NM',
                        value: 'immobilization',
                        width: 150,
                        
                    }, {
                        type: 'date',  //类型 -> 日期
                        title: '创建时间',
                        field: 'tESTDATA_DT',
                        fixed: false,
                        width: 150,
                        value: 'immobilization',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                        
                    }, {
                        type: 'text',
                        title: '维护人',
                        field: 'cUSER_NM',
                        value: 'immobilization',
                        width: 150,
                        
                    }, {
                        type: 'date',  //类型 -> 日期
                        title: '维护时间',
                        field: 'tMODIFY_DT',
                        fixed: false,
                        width: 150,
                        value: 'immobilization',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                        
                    },
                ],
                pagination_show: true,
                tableData: []
            },
        }
    },
    methods: {
        tableSearch(){
            let params = {
                ...this.$refs.form.searchdata,
                page_index: this.pageInfo.index,
                page_size: this.pageInfo.size
            }
            fetchwhpos(params).then(res=>{
                this.table.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.pageInfo = res.data.page
            })
        },
        handleselectedRows(rows){
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data:{
                    idx: this.selectedRows.map(v=>v.idx)
                }
            };
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                delwhpos(params).then(res=>{
                    this.$message.success('删除成功!')
                    this.tableSearch()
                })
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                this.$message.error("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let idx = this.isAdd ? 0 : this.editIdx
            let currentData = this.$refs.table.tableData[idx]
            let editdata = [{
                ...currentData,
                idx: !!currentData.idx ? currentData.idx: '',
                cSTOP_TAG: currentData.cSTOP_TAG ? 1: 0,
                cDEFAULT: currentData.cDEFAULT ? 1 : 0
            }]
            editwhpos({data: {lstdata: editdata}}).then(res=>{
                this.isEdit = false
                this.tableSearch()
            }) 
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>