<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form ref="form" :searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" ref="table" :height="480" :isEdit="isEdit" :pageInfo="pageInfo" :tablerule="rules" @selectedRows="handleselectedRows"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
import {fetchSupplierPrice,editSupplierPrice,delSupplierPrice} from '@/api/transport/base'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '承运商承运价',
            searchBox: [
                {
                    type: 'select_api', // 下拉选择
                    title: '客户',
                    dec: '输入客户名称',
                    icon: 'el-icon-user-solid',
                    option: [],
                    value: '',
                    action: 'cust/search',
                    optionBoxWidth: 300,
                    optionTableRole: [
                        {
                            title: '编号',
                            field: 'cCUST_NO',
                            width: 80,
                            toInsert: 'cCUST_NO',
                        }, {
                            title: '客户名称',
                            field: 'cCUST_NM',
                            width: 150,
                            toInsert: 'cCUST_NM',
                        },
                    ],
                    showfield: 'cCUST_NM',
                    filtefield: 'cCUST_NM',
                    visible: false
                }, {
                    type: 'select_api', //类型 -> 输入框 + 弹出表格 
                    title: '起点:国家:',
                    dec: '请输入国家',
                    icon: 'el-icon-menu',
                    option: [], //固定项
                    value: '', //固定项
                    //弹出内表格设置
                    action: 'info/nation/search', //请求对象接口地址 -> 获取国家表信息
                    optionBoxWidth: 350, // 弹出表格宽度
                    //弹出内表格布局规则
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx',
                            width: 80,
                        }, {
                            title: '国家代码',
                            field: 'cNATION_NO',
                            width: 80,
                            toInsert: 'cSTNATION_NO',
                        }, {
                            title: '国家名称',
                            field: 'cNATION_NM',
                            width: 130,
                            toInsert: 'cSTNATION_NM',
                        },
                    ],
                    showfield: 'cNATION_NM',
                    filtefield: 'cNATION_NM',
                    //因为与下面的"省份"关联 所以当前值变化后,要将关联的其他列的字段清空
                    clearfield: ['cSTPROV_NO', 'cSTPROV_NM', 'cSTCITY_NO', 'cSTCITY_NM'], //为空时,需要 关联清空的字段 (主表字段)
                }, {
                    type: 'select_api',
                    title: '省份:',
                    field: 'cSTPROV_NM',
                    icon: 'el-icon-user-solid',
                    option: [], //固定项
                    value: '', //固定项
                    action: '/info/prov/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                    //弹出内表格布局规则
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx',
                            width: 80,
                        }, {
                            title: '省份代码',
                            field: 'cPROV_NO',
                            width: 80,
                            toInsert: 'cSTPROV_NO',
                        }, {
                            title: '省份名称',
                            field: 'cPROV_NM',
                            width: 130,
                            toInsert: 'cSTPROV_NM',
                        },
                    ],
                    showfield: 'cPROV_NM', //展示字段 (调用对象基础表的字段)
                    filtefield: 'cPROV_NM', //筛选字段(用于选择的数据的某个字段) (调用对象基础表的字段)
                    //关联上级字段 (不会预加载,仅当点击此选项的输入框时,自动加载)
                    parentField: [
                        ['cSTNATION_NO', 'cNATION_NO'], //['主表表字段','调用对象基础表字段']   
                    ],
                    clearfield: ['cSTCITY_NO', 'cSTCITY_NM'], //为空时,需要 关联清空的字段 (主表字段)
                }, {
                    type: 'select_api',
                    title: '城市:',
                    field: 'cSTCITY_NM',
                    icon: 'el-icon-user-solid',
                    option: [], //固定项
                    value: '', //固定项
                    action: 'info/city/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                    //弹出内表格布局规则
                    optionTableRole: [
                        {
                            title: '市区代码',
                            field: 'cCITY_NO',
                            width: 80,
                            toInsert: 'cSTCITY_NO',
                        }, {
                            title: '市区名称',
                            field: 'cCITY_NM',
                            width: 130,
                            toInsert: 'cSTCITY_NM',
                        },
                    ],
                    showfield: 'cSTCITY_NM', //展示字段 (调用对象基础表的字段)
                    filtefield: 'cSTCITY_NM', //筛选字段(用于选择的数据的某个字段) (调用对象基础表的字段)
                    //关联上级字段 (不会预加载,仅当点击此选项的输入框时,自动加载)
                    parentField: [
                        ['cSTPROV_NO', 'cPROV_NO'], //['主表表字段','调用对象基础表字段']   
                    ],
                }, {
                    type: 'select_api', //类型 -> 输入框 + 弹出表格 
                    title: '范围', //输入框标题
                    dec: '输入范围', //输入框提示
                    icon: 'el-icon-user-solid', //小图标
                    option: [], //固定项
                    value: '', //固定项
                    action: 'cay/range/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                    //弹出内表格布局规则
                    optionBoxWidth: 300,
                    optionTableRole: [
                        {
                            title: '序号', //表头名称
                            field: 'cCAYRANG_NO', //显示字段 (调用对象基础表的字段)
                            width: 125, //列宽
                            toInsert: 'cCAYRANG_NO', //带出写入字段 (选中后,要将此字段的值作为筛选参数)
                        }, {
                            title: '运送范围',
                            field: 'cCAYRANG_NM',
                            width: 125,
                            toInsert: 'cCAYRANG_NM',
                        },
                    ],
                    showfield: 'cCAYRANG_NM', //在内表格中显示的字段 (调用对象基础表的字段)
                    filtefield: 'cCAYRANG_NM', //筛选字段 当输入框的值改变时,用来跟显示字段比对筛选的字段(调用对象基础表字段)
                    //是否将部分字段作为全局筛选条件,如果是,则增加下方格式代码(此全局筛选条件,将在表格中被使用)
                    insert_deafault_field: {
                        'cCAYRANG_NO': 'cCAYRANG_NO', //主表字段':'调用对象基础表字段
                    },
                }, {
                    type: 'select', //类型 -> 固定值选择
                    title: '审核标志',
                    field: '', //表格展示字段 (主表字段)  表格字段未有
                    fixed: false, //是否浮动
                    width: 50, //列宽
                    //筛选值
                    option: [{
                        value: '0',
                        label: '未审核'
                    }, {
                        value: '1',
                        label: '已审核'
                    }, {
                        value: '2',
                        label: '审核通过'
                    }],
                    value: '0', //固定项
                }, {
                    type: 'text', //类型 -> 输入框
                    title: '系统单号', //输入框标题
                    dec: '请输入单号', //输入框提示
                    field: '', //对应筛选字段 (主表字段)(暂无系统单号)
                    value: '', //固定项
                }, {
                    type: 'select_api',
                    title: '承运商',
                    field: 'cSUPPLIER_NM',
                    fixed: false,
                    width: 200,
                    option: [],
                    value: '',
                    action: 'supplier/search',
                    optionTableRole: [
                        {
                            title: '编号',
                            field: 'cSUPPLIER_NO',
                            width: 150,
                            toInsert: 'cSUPPLIER_NO', //带出写入
                            stype: 'value',
                        }, {
                            title: '供应商',
                            field: 'cSUPPLIER_NM',
                            width: 150,
                            toInsert: 'cSUPPLIER_NM', //带出写入
                            stype: 'label',
                        },
                    ],
                    showfield: 'cSUPPLIER_NM',
                    filtefield: 'cSUPPLIER_NM',
                    hasCheck: true
                }, {
                    type: 'select_api', //类型 -> 输入框 + 弹出表格 
                    title: '终点:国家:',
                    dec: '请输入国家',
                    field: 'cEDNATION_NM',
                    icon: 'el-icon-menu',
                    option: [], //固定项
                    value: '', //固定项
                    //弹出内表格设置
                    action: 'info/nation/search', //请求对象接口地址 -> 获取国家表信息
                    optionBoxWidth: 350, // 弹出表格宽度
                    //弹出内表格布局规则
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx',
                            width: 80,
                        }, {
                            title: '国家代码',
                            field: 'cNATION_NO',
                            width: 80,
                            toInsert: 'cEDNATION_NO',
                        }, {
                            title: '国家名称',
                            field: 'cNATION_NM',
                            width: 130,
                            toInsert: 'cEDNATION_NM',
                        },
                    ],
                    showfield: 'cNATION_NM',
                    filtefield: 'cNATION_NM',
                    //因为与下面的"省份"关联 所以当前值变化后,要将关联的其他列的字段清空
                    clearfield: ['cEDPROV_NO', 'cEDPROV_NM', 'cEDCITY_NO', 'cEDCITY_NM'], //为空时,需要 关联清空的字段 (主表字段)
                }, {
                    type: 'select_api',
                    title: '省份:',
                    field: 'cEDPROV_NM',
                    icon: 'el-icon-user-solid',
                    option: [], //固定项
                    value: '', //固定项
                    action: '/info/prov/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                    //弹出内表格布局规则
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx',
                            width: 80,
                        }, {
                            title: '省份代码',
                            field: 'cPROV_NO',
                            width: 80,
                            toInsert: 'cEDPROV_NO',
                        }, {
                            title: '省份名称',
                            field: 'cPROV_NM',
                            width: 130,
                            toInsert: 'cEDPROV_NM',
                        },
                    ],
                    showfield: 'cPROV_NM', //展示字段 (调用对象基础表的字段)
                    filtefield: 'cPROV_NM', //筛选字段(用于选择的数据的某个字段) (调用对象基础表的字段)
                    //关联上级字段 (不会预加载,仅当点击此选项的输入框时,自动加载)
                    parentField: [
                        ['cEDNATION_NO', 'cNATION_NO'], //['主表表字段','调用对象基础表字段']   
                    ],
                    clearfield: ['cEDCITY_NO', 'cEDCITY_NM'],
                }, {
                    type: 'select_api',
                    title: '城市:',
                    field: 'cEDCITY_NM',
                    icon: 'el-icon-user-solid',
                    option: [], //固定项
                    value: '', //固定项
                    action: 'info/city/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                    //弹出内表格布局规则
                    optionTableRole: [
                        {
                            title: '市区代码',
                            field: 'cCITY_NO',
                            width: 80,
                            toInsert: 'cEDCITY_NO',
                        }, {
                            title: '市区名称',
                            field: 'cCITY_NM',
                            width: 130,
                            toInsert: 'cEDCITY_NM',
                        },
                    ],
                    showfield: 'cCITY_NM', //展示字段 (调用对象基础表的字段)
                    filtefield: 'cCITY_NM', //筛选字段(用于选择的数据的某个字段) (调用对象基础表的字段)
                    //关联上级字段 (不会预加载,仅当点击此选项的输入框时,自动加载)
                    parentField: [
                        ['cEDPROV_NO', 'cPROV_NO'], //['主表表字段','调用对象基础表字段']   
                    ],
                }, {
                    type: 'select_api',
                    title: '方式',
                    dec: '请输入运输方式',
                    icon: 'el-icon-user-solid',
                    option: [],
                    value: '',
                    action: 'cay/type/search',
                    optionBoxWidth: 300,
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'cCAYTYPE_NO',
                            width: 80,
                            toInsert: 'cCAYTYPE_NO',
                        }, {
                            title: '运输方式',
                            field: 'cCAYTYPE_NM',
                            width: 120,
                        },
                    ],
                    showfield: 'cCAYTYPE_NM',
                    filtefield: 'cCAYTYPE_NM',
                    // 是否将部分字段作为全局筛选条件，如果是，则增加下方格式代码（全局筛选条件，将在表格中使用）
                    insert_default_field: {
                        'cCAYTYPE_NO': 'cCAYTYPE_NO', // '主表字段':'调用对象基础表字段'
                    },
                }, {
                    type: 'select', //类型 -> 固定值选择
                    title: '状态',
                    field: 'cSTOP_TAG', //表格展示字段 (主表字段)
                    fixed: false, //是否浮动
                    width: 50, //列宽
                    //筛选值
                    option: [{
                        value: '0',
                        label: '停用'
                    }, {
                        value: '1',
                        label: '使用中'
                    }],
                    value: '1', //固定项
                },
            ],
            table: {
               //主表配置
               tableRole: [
                    {
                        title: '序号', //表头
                        field: 'idx', //表格展示字段 (主表字段)
                        fixed: true, //是否浮动
                        width: 50, //列宽
                        noedit: true, //是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                        defautvalue: 0, //默认值,当为输入内容时的默认的值
                    }, {
                        type: 'select_api',
                        title: '承运商',
                        field: 'cSUPPLIER_NM',
                        fixed: false,
                        width: 200,
                        // sort: false,
                        option: [],
                        value: '',
                        action: 'supplier/search',
                        optionTableRole: [
                            {
                                title: '编号',
                                field: 'cSUPPLIER_NO',
                                width: 150,
                                toInsert: 'cSUPPLIER_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '供应商',
                                field: 'cSUPPLIER_NM',
                                width: 150,
                                toInsert: 'cSUPPLIER_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cSUPPLIER_NM',
                        filtefield: 'cSUPPLIER_NM',
                        hasCheck: true,
                        //default_prama: [ ['cSUPPLIER_NM', 'cSUPPLIER_NM']
                    }, {
                        type: 'text',
                        title: '承运商代码',
                        field: 'cSUPPLIER_NO',
                        value: '',
                        noedit: true,
                        width: 90,
                    }, {
                        type: 'select_api',
                        title: '客户名称',
                        field: 'cCUST_NM',
                        fixed: false,
                        width: 200,
                        sort: false,
                        option: [],
                        value: '',
                        optionTableRole: [
                            {
                                title: '编号',
                                field: 'cCUST_NO',
                                width: 150,
                                toInsert: 'cCUST_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '客户名称',
                                field: 'cCUST_NM',
                                width: 150,
                                toInsert: 'cCUST_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cCUST_NM',
                        filtefield: 'cCUST_NM',
                        hasCheck: true,
                        action: 'cust/search',
                        //   default_prama: [['cCUST_NO', 'cCUST_NO']]
                    }, {
                        type: 'text',
                        title: '客户代码',
                        field: 'cCUST_NO',
                        value: '',
                        noedit: true,
                        width: 75,
                    }, {
                        type: 'select_api',
                        title: '货物类型',
                        field: 'cGOODS_NM',
                        fixed: false,
                        width: 200,
                        sort: false,
                        option: [],
                        value: '',
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'idx',
                                width: 80,
                                // stype: 'value',
                            }, {
                                title: '货物类型编码',
                                field: 'cGOODS_TYPE',
                                width: 120,
                                toInsert: 'cGOODS_TYPE', //带出写入
                                stype: 'value',
                            }, {
                                title: '货物类型名称',
                                field: 'cGOODS_NM',
                                width: 150,
                                toInsert: 'cGOODS_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cGOODS_NM',
                        filtefield: 'cGOODS_NM',
                        hasCheck: true,
                        action: 'goodstype/search',
                    }, {
                        type: 'select_api',
                        title: '计费项目',
                        field: 'cPAYITEM_NM',
                        fixed: false,
                        width: 100,
                        sort: false,
                        option: [],
                        value: '',
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'idx',
                                width: 80,
                            }, {
                                title: '项目编码',
                                field: 'cPAYITEM_NO',
                                width: 120,
                                toInsert: 'cPAYITEM_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '项目名称',
                                field: 'cPAYITEM_NM',
                                width: 150,
                                toInsert: 'cPAYITEM_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cPAYITEM_NM',
                        filtefield: 'cPAYITEM_NM',
                        hasCheck: true,
                        action: 'account/payitem/search',
                    }, {
                        type: 'text',
                        title: '项目代码',
                        field: 'cPAYITEM_NO',
                        value: '',
                        noedit: true,
                        width: 75,
                    }, {
                        type: 'select_api',
                        title: '运输方式',
                        field: 'cCAYTYPE_NM',
                        fixed: false,
                        width: 100,
                        option: [],
                        value: '',
                        action: 'cay/type/search', //请求对象接口地址
                        optionBoxWidth: 200,
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'cCAYTYPE_NO',
                                width: 100,
                                toInsert: 'cCAYTYPE_NO',
                                stype: 'value' //作为未编辑状态时的显示内容的 对比值
                            }, {
                                title: '运输方式',
                                field: 'cCAYTYPE_NM',
                                width: 100,
                                toInsert: 'cCAYTYPE_NM',
                                stype: 'label' //作为未编辑状态时的显示内容的展示值
                            },
                        ],
                        showfield: 'cCAYTYPE_NM',
                        filtefield: 'cCAYTYPE_NM',
                        hasCheck: true,
                    }, {
                        type: 'select_api',
                        title: '启运国家',
                        field: 'cSTNATION_NM',
                        fixed: false,
                        width: 150,
                        option: [],
                        value: '',
                        action: 'info/nation/search',
                        optionTableRole: [
                            {
                                title: '国家代码',
                                field: 'cNATION_NO',
                                width: 150,
                                toInsert: 'cSTNATION_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '国家名称',
                                field: 'cNATION_NM',
                                width: 150,
                                toInsert: 'cSTNATION_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cSTNATION_NM', //展示字段
                        filtefield: 'cSTNATION_NM', //筛选字段(用于选择的数据的某个字段)
                        clearfield: ['cSTPROV_NO', 'cSTPROV_NM', 'cSTCITY_NO', 'cSTCITY_NM', 'cSTAPANA_NO', 'cSTAPANA_NM'], //为空时,需要 关联清空的字段 
                        default_prama: [
                            ['cSTNATION_NO', 'cNATION_NO']
                        ]
                    }, {
                        type: 'select_api',
                        title: '启运省份',
                        field: 'cSTPROV_NM',
                        fixed: false,
                        width: 200,
                        option: [],
                        value: '',
                        action: 'info/prov/search',
                        optionTableRole: [
                            {
                                title: '省份代码',
                                field: 'cPROV_NO',
                                width: 150,
                                toInsert: 'cSTPROV_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '省份名称',
                                field: 'cPROV_NM',
                                width: 150,
                                toInsert: 'cSTPROV_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cSTPROV_NM', //展示字段
                        filtefield: 'cSTPROV_NM', //筛选字段(用于选择的数据的某个字段)
                        parentField: [
                            ['cSTNATION_NO', 'cNATION_NO'], //['数据字段','基础表字段']   关联上级字段 (不会预加载)
                        ],
                        clearfield: ['cSTCITY_NO', 'cSTCITY_NM', 'cSTAPANA_NO', 'cSTAPANA_NM'], //为空时,需要 关联清空的字段 
                    }, {
                        title: '启运城市',
                        field: 'cSTCITY_NM',
                        fixed: false,
                        width: 230,
                        sort: false,
                        type: 'select_api',
                        option: [],
                        value: '',
                        optionTableRole: [
                            {
                                title: '城市代码',
                                field: 'cCITY_NO',
                                width: 150,
                                toInsert: 'cSTCITY_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '城市名称',
                                field: 'cCITY_NM',
                                width: 150,
                                toInsert: 'cSTCITY_NM', //带出写入
                                stype: 'label',
                            }
                        ],
                        showfield: 'cSTCITY_NM', //展示字段
                        filtefield: 'cSTCITY_NM', //筛选字段(用于选择的数据的某个字段)
                        action: 'info/city/search', //调用方法
                        parentField: [
                            ['cSTPROV_NO', 'cPROV_NO'], //['数据字段','基础表字段']   关联上级字段 (不会预加载)
                        ],
                        clearfield: ['cSTAPANA_NO', 'cSTAPANA_NM'], //为空时,需要 关联清空的字段 
                    }, {
                        title: '所在区县',
                        field: 'cSTAPANA_NM',
                        fixed: false,
                        width: 230,

                        type: 'select_api',
                        option: [],
                        value: '',
                        optionTableRole: [{
                            title: '区县代码',
                            field: 'cAPANA_NO',
                            width: 150,
                            toInsert: 'cSTAPANA_NO', //带出写入
                            stype: 'value',
                        }, {
                            title: '县区名称',
                            field: 'cAPANA_SNM',
                            width: 150,
                            toInsert: 'cSTAPANA_NM', //带出写入
                            stype: 'label',
                        }],
                        optionTableWidth: 300, //内表格宽度
                        showfield: 'cSTAPANA_NM', //展示字段
                        filtefield: 'cSTAPANA_NM', //筛选字段(用于选择的数据的某个字段)
                        action: 'info/apana/search', //调用方法
                        parentField: [
                            ['cSTCITY_NO', 'cCITY_NO'], //['数据字段','基础表字段']
                        ] //关联上级字段 (不会预加载)
                    },
                    {
                        type: 'select_api',
                        title: '到达国家',
                        field: 'cEDNATION_NM',
                        fixed: false,
                        width: 200,
                        option: [],
                        value: '',
                        action: 'info/nation/search',
                        optionTableRole: [
                            {
                                title: '国家代码',
                                field: 'cNATION_NO',
                                width: 150,
                                toInsert: 'cEDNATION_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '国家名称',
                                field: 'cNATION_NM',
                                width: 150,
                                toInsert: 'cEDNATION_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cEDNATION_NM', //展示字段
                        filtefield: 'cEDNATION_NM', //筛选字段(用于选择的数据的某个字段)
                        clearfield: ['cEDPROV_NO', 'cEDPROV_NM', 'cEDCITY_NO', 'cEDCITY_NM', 'cEDTOWN_NO', 'cEDTOWN_NM'], //为空时,需要 关联清空的字段 
                    }, {
                        type: 'select_api',
                        title: '到达省份',
                        field: 'cEDPROV_NM',
                        fixed: false,
                        width: 200,
                        option: [],
                        value: '',
                        action: 'info/prov/search',
                        optionTableRole: [
                            {
                                title: '省份代码',
                                field: 'cPROV_NO',
                                width: 150,
                                toInsert: 'cEDPROV_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '省份名称',
                                field: 'cPROV_NM',
                                width: 150,
                                toInsert: 'cEDPROV_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cEDPROV_NM', //展示字段
                        filtefield: 'cEDPROV_NM', //筛选字段(用于选择的数据的某个字段)
                        parentField: [
                            ['cEDNATION_NO', 'cNATION_NO'], //['数据字段','基础表字段']   关联上级字段 (不会预加载)
                        ],
                        clearfield: ['cEDCITY_NO', 'cEDCITY_NM', 'cEDTOWN_NO', 'cEDTOWN_NM'], //为空时,需要 关联清空的字段 
                    }, {
                        title: '到达城市',
                        field: 'cEDCITY_NM',
                            fixed: false,
                            width: 230,
                            sort: false,
                            type: 'select_api',
                            option: [],
                            value: '',
                            optionTableRole: [
                                {
                                    title: '城市代码',
                                    field: 'cCITY_NO',
                                    width: 150,
                                    toInsert: 'cEDCITY_NO', //带出写入
                                    stype: 'value',
                                }, {
                                    title: '城市名称',
                                    field: 'cCITY_NM',
                                    width: 150,
                                    toInsert: 'cEDCITY_NM', //带出写入
                                    stype: 'label',
                                }
                            ],
                            showfield: 'cEDCITY_NM', //展示字段
                            filtefield: 'cEDCITY_NM', //筛选字段(用于选择的数据的某个字段)
                            action: 'info/city/search', //调用方法
                            parentField: [
                                ['cEDPROV_NO', 'cPROV_NO'], //['数据字段','基础表字段']   关联上级字段 (不会预加载)
                            ],
                            clearfield: ['cEDTOWN_NO', 'cEDTOWN_NM'], //为空时,需要 关联清空的字段 
                        }, {
                            title: '到达县区',
                            field: 'cEDAPANA_NM',
                            fixed: false,
                            width: 230,
                            sort: false,
                            type: 'select_api',
                            option: [],
                            value: '',
                            optionTableRole: [
                                {
                                    title: '区县代码',
                                    field: 'cAPANA_NO',
                                    width: 150,
                                    toInsert: 'cEDAPANA_NO', //带出写入
                                    stype: 'value',
                                }, {
                                    title: '县区名称',
                                    field: 'cAPANA_SNM',
                                    width: 150,
                                    toInsert: 'cEDAPANA_NM', //带出写入
                                    stype: 'label',
                                }
                            ],
                            optionTableWidth: 400, //内表格宽度
                            showfield: 'cEDAPANA_NM', //展示字段
                            filtefield: 'cEDAPANA_NM', //筛选字段(用于选择的数据的某个字段)
                            action: 'info/apana/search', //调用方法
                            parentField: [
                                ['cEDCITY_NO', 'cCITY_NO'], //['数据字段','基础表字段']
                            ] //关联上级字段 (不会预加载)
                        }, {
                            type: 'select_api',
                            title: '范围',
                            field: 'cCAYRANG_NM',
                            fixed: false,
                            width: 80,
                            option: [],
                            value: '',
                            action: 'cay/range/search', //请求对象接口地址
                            optionBoxWidth: 200,
                            optionTableRole: [
                                {
                                    title: '序号',
                                    field: 'cCAYRANG_NO',
                                    width: 80,
                                    toInsert: 'cCAYRANG_NO',
                                    stype: 'value' //作为未编辑状态时的显示内容的 对比值
                                }, {
                                    title: '范围名称',
                                    field: 'cCAYRANG_NM',
                                    width: 80,
                                    toInsert: 'cCAYRANG_NM',
                                    stype: 'label' //作为未编辑状态时的显示内容的展示值
                                },
                            ],
                            showfield: 'cCAYRANG_NM',
                            filtefield: 'cCAYRANG_NM',
                            hasCheck: true,
                        }, {
                            type: 'number',
                            title: '距离',
                            field: 'cDISTANCE_QTY',
                            value: '',
                            noedit: true,
                            width: 80,
                        }, {
                            title: '时效(小时)',
                            field: 'nHOUR_QTY',
                            fixed: false,
                            width: 80,
                            type: 'number',
                            value: '',
                            defautvalue: '',
                        }, {
                            type: 'select_api',
                            title: '单位',
                            field: 'cUNIT_NM',
                            fixed: false,
                            width: 80,
                            sort: false,
                            option: [],
                            value: '',
                            optionBoxWidth: 200,
                            optionTableRole: [
                                {
                                    title: '单位名称',
                                    field: 'cUNIT_NM',
                                    width: 150,
                                    toInsert: 'cUNIT_NM', //带出写入
                                    stype: 'value',
                                },
                            ],
                            showfield: 'cUNIT_NM',
                            filtefield: 'cUNIT_NM',
                            hasCheck: true,
                            action: 'info/unit/search',
                        }, {
                            type: 'select_api',
                            title: '计费依据',
                            field: 'cPRCBY_NM',
                            fixed: false,
                            width: 80,
                            sort: false,
                            option: [],
                            value: '',
                            optionBoxWidth: 200,
                            optionTableRole: [
                                {
                                    title: '计费依据编号',
                                    field: 'cPRCBY_NO',
                                    width: 50,
                                    toInsert: 'cPRCBY_NO', //带出写入
                                    stype: 'value',
                                },
                                {
                                    title: '计费依据名称',
                                    field: 'cPRCBY_NM',
                                    width: 150,
                                    toInsert: 'cPRCBY_NM', //带出写入
                                    stype: 'label',
                                },
                            ],
                            showfield: 'cPRCBY_NM',
                            filtefield: 'cPRCBY_NM',
                            hasCheck: true,
                            action: 'info/prcby/search',
                            default_prama: [
                                ['cPRC_BY', 'cPRCBY_NM']
                            ]
                        }, {
                            type: 'number',
                            title: '最小数量',
                            field: 'nSTART_QTY',
                            value: '',
                            width: 80,
                        }, {
                            type: 'number',
                            title: '最大数量',
                            field: 'nEND_QTY',
                            value: '',
                            width: 100,
                        }, {
                            type: 'number',
                            title: '单价',
                            field: 'nUNIT_PRICE',
                            value: '',
                            width: 120,
                        }, {
                            type: 'select',
                            title: '价格变更原因',
                            field: 'cRWSEL',
                            fixed: false,
                            width: 100,
                            option: [{
                                value: '0',
                                label: '线路新加'
                            }, {
                                value: '1',
                                label: '价格调整'
                            }, {
                                value: '2',
                                label: '新承运商'
                            }],
                            value: '', //固定项
                            // noedit: true,	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                        }, {
                            type: 'text',
                            title: '申请人',
                            field: '', // 字段未有
                            value: '',
                            width: 120,
                        }, {
                            type: 'number',
                            title: '市场价',
                            field: 'nMARKET_PRICE',
                            value: '',
                            noedit: true,
                            width: 100,
                        }, {
                            type: 'checkbox', //类型 -> 单选
                            title: '停用',
                            field: 'cSTOP_TAG',
                            fixed: false,
                            width: 50,
                            value: '',
                        }, {
                            type: 'text',
                            title: '备注',
                            field: 'cREMARK',
                            value: '',
                            width: 300,
                        }, {
                            type: 'text',
                            title: '报价单号',
                            field: 'cPRCRSN_KIND', // 字段未有
                            value: '',
                            width: 150,
                        }, {
                            type: 'text',
                            title: '创建人',
                            field: 'cESTUSER_NM',
                            value: '',
                            width: 150,
                            noedit: true,
                        }, {
                            type: 'date', //类型 -> 日期
                            title: '创建时间',
                            field: 'tESTDATA_DT',
                            fixed: false,
                            width: 150,
                            value: '',
                            defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                            format: "yyyy-MM-dd", //日期格式设置
                            noedit: true,
                        }, {
                            type: 'text',
                            title: '维护人',
                            field: 'cUSER_NM',
                            value: '',
                            width: 150,
                            noedit: true,
                        }, {
                            type: 'date', //类型 -> 日期
                            title: '维护时间',
                            field: 'tMODIFY_DT',
                            fixed: false,
                            width: 150,
                            value: '',
                            defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                            format: "yyyy-MM-dd", //日期格式设置
                            noedit: true,
                        }, {
                            type: 'text',
                            title: '审核标志',
                            field: '', // 字段未有
                            value: '',
                            width: 150,
                            noedit: true,
                        }, {
                            type: 'text',
                            title: '审核人',
                            field: '', // 字段未有
                            value: '',
                            width: 150,
                            noedit: true,
                        }, {
                            type: 'date', //类型 -> 日期
                            title: '审核时间',
                            field: '', // 字段未有
                            fixed: false,
                            width: 150,
                            value: '',
                            defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                            format: "yyyy-MM-dd", //日期格式设置
                            noedit: true,
                        },
                ], 
                pagination_show: true,
                tableData: []
            }
        }
    },
    methods: {
        tableSearch(){
            let params = {
                ...this.$refs.form.searchdata,
                page_index: this.pageInfo.index,
                page_size: this.pageInfo.size
            }
            fetchSupplierPrice(params).then(res=>{
                this.table.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.pageInfo = res.data.page
            })
        },
        handleselectedRows(rows){
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data: {
                    idx: this.selectedRows.map(v=>v.idx)
                } 
            };
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                delSupplierPrice(params).then(res=>{
                    this.$message.success('删除成功!')
                    this.tableSearch()
                })
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                this.$message.error("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let idx = this.isAdd ? 0 : this.editIdx
            let currentData = this.$refs.table.tableData[idx]
            let editdata = [{
                ...currentData,
                idx: !!currentData.idx ? currentData.idx: '',
                cSTOP_TAG: currentData.cSTOP_TAG ? 1: 0,
                cDEFAULT: currentData.cDEFAULT ? 1 : 0
            }]
            editSupplierPrice({data: {lstdata: editdata}}).then(res=>{
                this.isEdit = false
                this.tableSearch()
            })
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>