<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form :searchbox="searchBox" ref="form" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" ref="table" :pageInfo="pageInfo"  :height="550" :isEdit="isEdit" :tablerule="rules" @selectedRows="handleselectedRows"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
import {fetchWarets,editWarets,delWarets} from '@/api/storage/base'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '仓储片区设置',
            //检索配置
            searchBox: [
                {
                    type: 'text', 		//类型 -> 输入框、
                    title: '仓储片区名称',	//输入框标题
                    dec: '输入仓储片区名称',	//输入框提示
                    field: 'cWARET_NM', 	//对应筛选字段 (主表字段)
                    value: '',	//固定项
                }, {
                    type: 'text',
                    title: '仓储片区编码',
                    dec: '输入仓储片区编码',
                    field: 'cWARET_NO',
                    value: '',
                },
            ],
            table:{
                //主表配置
                tableRole: [
                    {
                        title: 'ID', 	//表头
                        field: 'idx', 	//表格展示字段 (主表字段)
                        fixed: true, 	//是否浮动
                        width: 80, 		//列宽
                        noedit: true, 	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                        defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                    }, {
                        type: 'text',
                        title: '仓储片区代码',
                        field: 'cWARET_NO',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '仓储片区名称',
                        field: 'cWARET_NM',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'number',
                        title: '仓库数量',
                        field: 'iWARE_QTY',
                        fixed: false,
                        width: 130,
                        value: '0.000'
                    }, {
                        type: 'number',
                        title: '面积（平方米）',
                        field: 'nAREA',
                        fixed: false,
                        width: 130,
                        value: '0.000'
                    }, {
                        type: 'number',
                        title: '容积（立方米）',
                        field: 'nVOLUME',
                        fixed: false,
                        width: 130,
                        value: '0.000'
                    }, {
                        type: 'select',
                        title: '使用类别',
                        field: 'cUSE_KIND',
                        fixed: false,
                        width: 110,
                        option: [{
                            value: '0',
                            label: '集团共用'
                        }, {
                            value: '1',
                            label: '独自使用'
                        },],
                    }, {
                        type: 'checkbox',
                        title: '停用',
                        field: 'cSTOP_TAG',
                        fixed: false,
                        width: 100,
                        value: '',
                        insertfieldtime: 'tSTOP_DT', //选中后,带出日期到该行其他字段
                    }, {
                        type: 'text',
                        title: '详介',
                        field: 'cSTOP_REASON',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '简介',
                        field: 'cPURPOSE',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'select_api',
                        title: '所在省份',
                        field: 'cPROV_NM',
                        fixed: false,
                        width: 150,
                        option: [],
                        value: '',
                        action: 'info/prov/search',
                        optionTableRole: [
                            {
                                title: '省份代码',
                                field: 'cPROV_NO',
                                width: 150,
                                toInsert: 'cPROV_NO',
                                stype: 'value',
                            }, {
                                title: '省份名称',
                                field: 'cPROV_NM',
                                width: 150,
                                toInsert: 'cPROV_NM',
                                stype: 'label',
                            }
                        ],
                        showfield: 'cPROV_NM', //展示字段
                        filtefield: 'cPROV_NM', //筛选字段(用于选择的数据的某个字段)
                        clearfield: ['cCITY_NO', 'cCITY_NM', 'cAPANA_NO', 'cAPANA_NM'], //为空时,需要 关联清空的字段 
                    }, {
                        type: 'select_api',
                        title: '所在城市',
                        field: 'cCITY_NM',
                        fixed: false,
                        width: 150,
                        option: [],
                        value: '',
                        action: 'info/city/search',
                        optionTableRole: [
                            {
                                title: '城市代码',
                                field: 'cCITY_NO',
                                width: 150,
                                toInsert: 'cCITY_NO',
                                stype: 'value',
                            }, {
                                title: '城市名称',
                                field: 'cCITY_NM',
                                width: 150,
                                toInsert: 'cCITY_NM',
                                stype: 'label',
                            }
                        ],
                        showfield: 'cCITY_NM',
                        filtefield: 'cCITY_NM',
                        parentField: [
                            ['cPROV_NO', 'cPROV_NO'],
                        ],
                        clearfield: ['cAPANA_NO', 'cAPANA_NM'],
                    }, {
                        type: 'select_api',
                        title: '所在县区',
                        field: 'cAPANA_NM',
                        fixed: false,
                        width: 150,
                        option: [],
                        value: '',
                        action: 'info/apana/search',
                        optionTableRole: [
                            {
                                title: '县区代码',
                                field: 'cAPANA_NO',
                                width: 150,
                                toInsert: 'cAPANA_NO',
                                stype: 'value',

                            }, {
                                title: '县区名称',
                                field: 'cAPANA_SNM',
                                width: 150,
                                toInsert: 'cAPANA_NM',
                                stype: 'label',
                            }
                        ],
                        showfield: 'cAPANA_NM',
                        filtefield: 'cAPANA_NM',
                        parentField: [
                            ['cCITY_NO', 'cCITY_NO'],
                        ]
                    }, {
                        type: 'text',
                        title: '地址',
                        field: 'cADDRESS',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    },
                    {
                        type: 'text',
                        title: '对应客户系统编码',
                        field: 'cCUSWRT_NO',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    },
                    {
                        type: 'text',
                        title: '创建人',
                        field: 'cESTUSER_NM',
                        fixed: false,
                        width: 100,
                        value: 'immobilization',
                        defautvalue: '',
                        
                    }, {
                        type: 'date',
                        title: '创建时间',
                        field: 'createtime',
                        value: 'immobilization',
                        width: 150,
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                        
                    }, {
                        type: 'text',
                        title: '维护人',
                        field: 'cUSER_NM',
                        fixed: false,
                        width: 100,
                        value: 'immobilization',
                        
                    },
                    {
                        type: 'date',
                        title: '维护时间',
                        field: 'tMODIFY_DT',
                        width: 150,
                        value: 'immobilization',
                        fixed: false,
                        defautvalue: this.$getNowTime(true, true, true),
                        format: "yyyy-MM-dd",
                        
                    },
                ],
                pagination_show: true,
                tableData: []
            }
        }
    },
    methods: {
        tableSearch(){
            let params = {
                ...this.$refs.form.searchdata,
                page_index: this.pageInfo.index,
                page_size: this.pageInfo.size
            }
            fetchWarets(params).then(res=>{
                this.table.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.pageInfo = res.data.page
            }) 
        },
        handleselectedRows(rows){
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data:{
                    idx: this.selectedRows.map(v=>v.idx)
                }
            };
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                delWarets(params).then(res=>{
                    this.$message.success('删除成功')
                    this.tableSearch()
                })
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                this.$message.error("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let idx = this.isAdd ? 0 : this.editIdx
            let currentData = this.$refs.table.tableData[idx]
            let editdata = [{
                ...currentData,
                idx: !!currentData.idx ? currentData.idx: '',
                cSTOP_TAG: currentData.cSTOP_TAG ? 1: 0,
                cDEFAULT: currentData.cDEFAULT ? 1 : 0
            }]
            editWarets({data: {lstdata: editdata}}).then(res=>{
                this.isEdit = false
                this.tableSearch()
            })
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>