import { get, post } from '@/utils'

// 运输方式
export const fetchModes = (params)=>{
    return get('api/cay/type/search',params)
}

export const editModes = (params)=>{
    return post('api/cay/type/update',params)
}

export const delModes = (params)=>{
    return post('api/cay/type/del',params)
}

// 运输范围
export const fetchScopes = (params)=>{
    return get('api/cay/range/search',params)
}

export const editScopes = (params)=>{
    return get('api/cay/range/update',params)
}

export const delScope = (params)=>{
    return post('api/cay/range/del',params)
}

// 运输流程
export const fetchFlows= (params)=>{
    return get('api/cay/course/search',params)
}

export const editFlows= (params)=>{
    return get('api/cay/course/update',params)
}

export const delFlows= (params)=>{
    return post('api/cay/course/del',params)
}

// 物流追踪点
export const fetchLogics = (params)=>{
    return get('api/cay/track/search',params)
}

export const editLogics = (params)=>{
    return post('api/cay/track/update',params)
}

export const delLogics = (params)=>{
    return post('api/cay/track/del',params)
}

// 承运商分类
export const fetchSuppliers = (params)=>{
    return get('api/supplier/kind/search',params)
}

export const editSuppliers = (params)=>{
    return post('api/supplier/kind/update',params)
}

export const delSuppliers = (params)=>{
    return post('api/supplier/kind/del',params)
}

// 承运商资料
export const fetchSuppliersInfo = (params)=>{
    return get('api/supplier/search',params)
}

export const editSuppliersInfo = (params)=>{
    return post('api/supplier/update',params)
}

export const delSuppliersInfo = (params)=>{
    return post('api/supplier/del',params)
}

// 承运商承运价
export const fetchSupplierPrice = (params)=>{
    return get('api/supplier/cay/purprc/search',params)
}

export const editSupplierPrice = (params)=>{
    return post('api/supplier/cay/purprc/update',params)
}

export const delSupplierPrice = (params)=>{
    return post('api/supplier/cay/purprc/del',params)
}


// 车辆类别设置
export const fetchVehicles = (params)=>{
    return get('api/vehicle/kind/search',params)
}

export const editVehicles = (params)=>{
    return post('api/vehicle/kind/update',params)
}

export const delVehicles = (params)=>{
    return post('api/vehicle/kind/del',params)
}

// 车辆长度设置 -- // 车辆尺寸设置
export const fetchVehicleSize = (params)=>{
    return get('api/vehicle/size/search',params)
}

export const editVehicleSize = (params)=>{
    return post('api/vehicle/size/update',params)
}

export const delVehicleSize = (params)=>{
    return post('api/vehicle/size/del',params)
}

// 承运商简易资料
export const fetchSupplierpaper = (params)=>{
    return get('api/supplier/simple/search',params)
}

export const editSupplierpaper = (params)=>{
    return post('api/supplier/simple/update',params)
}

export const delSupplierpaper = (params)=>{
    return post('api/supplier/simple/del',params)
}

// 承运商运输车辆--车辆资料管理
export const fetchSuppliercars = (params)=>{
    return get('api/supplier/cay/track/search',params)
}

export const editSuppliercars = (params)=>{
    return post('api/supplier/cay/track/update',params)
}

export const delSuppliercars = (params)=>{
    return post('api/supplier/cay/track/del',params)
}

// 客户货物品类
export const fetchgoods = (params)=>{
    return get('api/cust/matcls/search',params)
}

export const editgoods = (params)=>{
    return post('api/cust/matcls/update',params)
}

export const delgoods = (params)=>{
    return post('api/cust/matcls/del',params)
}

// 客户存货地点
export const fetchCustomAddress = (params)=>{
    return get('api/cust/stk/search',params)
}

export const editCustomAddress = (params)=>{
    return post('api/cust/stk/update',params)
}

export const delCustomAddress = (params)=>{
    return post('api/cust/stk/del',params)
}

// 客户送达地点
export const fetchCustomDestAddress = (params)=>{
    return get('api/cust/destaddress/search',params)
}

export const editCustomDestAddress = (params)=>{
    return post('api/cust/destaddress/update',params)
}

export const delCustomDestAddress = (params)=>{
    return post('api/cust/destaddress/del',params)
}

// 送达客户资料
export const fetchSendClients = (params)=>{
    return get('api/cust/client/search',params)
}

export const editSendClients = (params)=>{
    return post('api/cust/client/update',params)
}

export const delSendClients = (params)=>{
    return post('api/cust/client/del',params)
}

// 送达客户--客户地址信息
export const fetchSendClientsAddr = (params)=>{
    return get('api/cust/destaddress/search',params)
}

export const editSendClientsAddr = (params)=>{
    return post('api/cust/destaddress/update',params)
}

export const delSendClientsAddr = (params)=>{
    return post('api/cust/destaddress/del',params)
}

// 提货单位资料
export const fetchDeliveryUnit = (params)=>{
    return get('api/cust/pickco/search',params)
}

export const editDeliveryUnit = (params)=>{
    return post('api/cust/pickco/update',params)
}

export const delDeliveryUnit = (params)=>{
    return post('api/cust/pickco/del',params)
}

// 运输货物资料
export const fetchCargo = (params)=>{
    return get('api/cay/mat/search',params)
}

export const editCargo = (params)=>{
    return post('api/cay/mat/update',params)
}

export const delCargo = (params)=>{
    return post('api/cay/mat/del',params)
}










