<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form ref="form" :searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" ref="table" :height="550" :tablerule="rules" :pageInfo="pageInfo" @selectedRows="handleselectedRows"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
import {fetchLogics,editLogics,delLogics} from '@/api/transport/base'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '物流追踪点设置', 
            searchBox: [
                {
                    type: 'text', 		//类型 -> 输入框
                    title: '追踪点名称',	//输入框标题
                    dec: '输入追踪点名称',	//输入框提示
                    field: 'cCAYTRACK_NM', 	//对应筛选字段 (主表字段)
                    // icon: 'el-icon-user-solid',	//小图标
                    value: '',	//固定项
                },
            ],
            table: {
                //主表配置
                tableRole: [
                    {
                        title: '序号', 	//表头
                        field: 'idx', 	//表格展示字段 (主表字段)
                        fixed: true, 	//是否浮动
                        width: 80, 		//列宽
                        noedit: false, 	//是否禁止编辑 值为false 则 禁止编辑, true 或 删除此项 则可编辑
                        defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                    },
                    {
                        type: 'number', //类型 -> 数字
                        title: '追踪点编码',
                        field: 'cCAYTRACK_NO',
                        fixed: false,
                        width: 100,
                    },
                    {
                        type: 'text',
                        title: '追踪点名称',
                        field: 'cCAYTRACK_NM',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                        zjm:'BOPOMOFO',
                    },
                    {
                        type: 'text',
                        title: '助记码',
                        field: 'BOPOMOFO',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    },
                    {
                        type: 'text',
                        title: '追踪别',
                        field: 'cTRACK_KIND',
                        fixed: false,
                        width: 130,
                        value: '1',
                        defautvalue: '',
                    },{
                        type: 'checkbox', //类型 -> 单选
                        title: '短信通知',
                        field: 'cNOTESD_TAG',         
                        fixed:false,
                        width: 80,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                        defautvalue: '',
                    },
                    {
                        type: 'checkbox', //类型 -> 单选
                        title: '需回复确认',
                        field: 'cAFFIRM_TAG',         
                        fixed:false,
                        width: 120,
                        value: '自定义',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                        defautvalue: '',
                    },
                    {
                        type: 'checkbox', //类型 -> 单选
                        title: '客户',
                        field: 'cCUS_TAG',         
                        fixed:false,
                        width: 80,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                        defautvalue: '',
                    },
                    
                    {
                        type: 'text1',
                        title: '系统',
                        field: 'cSYS_TAG',
                        fixed: false,
                        width: 130,
                        value: '',
                    },
                    {
                        type: 'checkbox', //类型 -> 单选
                        title: '缺省',
                        field: 'cDEFAULT',
                        fixed: false,
                        width: 80,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                    },
                    {
                        type: 'checkbox', //类型 -> 单选
                        title: '停用',
                        field: 'cSTOP_TAG',
                        fixed: false,
                        width: 80,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                    },
                    {
                        type: 'text',
                        title: '备注',
                        field: 'cREMARK', //接口不知道
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '创建人',
                        field: 'cESTUSER_NM', //接口不知道
                        fixed: false,
                        width: 130,
                        value: 'immobilization',
                        defautvalue: '',
                    },
                    {
                        type: 'date',  //类型 -> 日期
                        title: '创建时间',
                        field: 'tESTDATA_DT',//接口不知道
                        fixed: false,
                        width: 150,
                        value: 'immobilization',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置 
                    }, {
                        type: 'text',
                        title: '维护人',
                        field: 'cUSER_NM', //接口不知道
                        fixed: false,
                        width: 130,
                        value: 'immobilization',
                        defautvalue: '',
                    },
                    {
                        type: 'date',  //类型 -> 日期
                        title: '维护时间',
                        field: 'tMODIFY_DT',//接口不知道
                        fixed: false,
                        width: 150,
                        value: 'immobilization',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置 
                    }
                ],
                tableData: [],
                pagination_show: true
            },
        }
    },
    methods: {
        tableSearch(){
            let params = {
                ...this.$refs.form.searchdata,
                page_index: this.pageInfo.index,
                page_size: this.pageInfo.size
            }
            fetchLogics(params).then(res=>{
                this.table.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.pageInfo = res.data.page
            })
        },
        handleselectedRows(rows){
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data: {
                    idx: this.selectedRows.map(v=>v.idx)
                } 
            };
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                delLogics(params).then(res=>{
                    this.$message.success('删除成功!')
                    this.tableSearch()
                })
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                this.$message.error("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            // TODO
            let idx = this.isAdd ? 0 : this.editIdx
            let currentData = this.$refs.table.tableData[idx]
            let editdata = [{
                ...currentData,
                idx: !!currentData.idx ? currentData.idx: '',
                cSTOP_TAG: currentData.cSTOP_TAG ? 1: 0,
                cDEFAULT: currentData.cDEFAULT ? 1 : 0
            }]
            editLogics({data: {lstdata: editdata}}).then(res=>{
                this.isEdit = false
                this.tableSearch()
            })
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>