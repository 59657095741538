<template>
    <div>
        <div class="top">
            <div class="f-14 col-main f-b">{{appname}}</div>
            <div class="dis-flex flex-x-end flex-y-center">
                <el-button type="warning"  @click="addRow"  icon="el-icon-circle-plus-outline" size="mini" >新增</el-button>
            </div>
        </div>
        <el-table size="mini"
            :data="tableData"
            height="250" style="width:100%">
            <el-table-column
            prop="id"
            label="角色ID"
            width="200">
            </el-table-column>
            <el-table-column
            prop="name"
            label="角色名称"
            width="200">
            </el-table-column>
            <el-table-column
            prop="sort"
            label="排序"
            width="200">
            </el-table-column>
            <el-table-column
            prop="create_time"
            label="添加时间"
            >
            </el-table-column>
        </el-table>
        <el-drawer
            title="添加角色"
            :visible.sync="visible"
            v-if="visible"
            direction="rtl"
            :before-close="handleClose">
            <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="角色名称" prop="roleName" required>
                    <el-input v-model="ruleForm.roleName"></el-input>
                </el-form-item>
                <el-form-item label="角色code" required>
                    <el-input v-model="ruleForm.roleCode"></el-input>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                    <el-input v-model="ruleForm.sort"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="details">
                    <el-input v-model="ruleForm.details"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button size="mini"  type="primary" @click="submitForm('ruleForm')">提交</el-button>
                    <el-button size="mini"  type="default" @click="resetForm('ruleForm')">取消</el-button>
                </el-form-item>
            </el-form>
        </el-drawer>
    </div>
</template>
<script>
    import {roleList,roleAdd} from '@/api/role'
    export default{
        data(){
            return {
                appname: '角色列表',
                tableData: [],
                visible: false,
                ruleForm:{}
            }
        },
        created(){
            this.fetchRoleList()
        },
        methods:{
            fetchRoleList(){
                roleList().then(res=>{
                    this.tableData = res.data
                })
            },
            addRow(){
                this.visible = true
            },
            handleClose(){
                this.visible = false
            },
            submitForm(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let params = this.ruleForm
                        roleAdd(params).then(res=>{
                            this.$message({
                                message: res.msg,
                                type: res.success ? 'success' : 'error'
                            });
                            this.fetchRoleList()
                        })
                    } else {
                        return false;
                    }
                });
            },
            resetForm(formName){
                this.$refs[formName].resetFields();
                this.visible = false
            }
        }
    }
</script>