<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form :searchbox="table.searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" ref="table" :height="250" :isEdit="isEdit" :tablerule="rules" @selectedRows="handleselectedRows"></mytable>
        <div class="top-form-wrapper" style="margin-top: 10px;">
            <top-form :searchbox="bottomtable.searchBox"></top-form>
        </div>
        <mytable :tablelet="bottomtable" ref="table" :height="250" :isEdit="isEdit" :tablerule="rules"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '仓库存货调整',
            table: {
                searchPath: '',  //主表获取数据接口地址
                insertPath: '',     //主表保存数据接口地址
                //顶部筛选配置
                searchBox: [
                    {
                        //基础设置
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '公司', 		//输入框标题
                        dec: '输入公司编号', //输入框提示
                        icon: 'el-icon-user-solid', //小图标
                        //
                        option:[], //固定项
                        value: '', //固定项
                        //
                        //弹出内表格设置
                        action: 'cust/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        //弹出内表格布局规则
                        optionBoxWidth:500,
                        optionTableRole: [
                            {
                                title: '公司编号', 			//表头名称
                                field: 'cCUST_NO', 		//显示字段 (调用对象基础表的字段)
                                width: 150,				//列宽
                                toInsert:'cCUST_NO', 	//带出写入字段 (选中后,要将此字段的值作为筛选参数)
                            },{
                                title: '公司名称',
                                field: 'cCUST_NM',
                                width: 150,
                            },
                        ], 
                        showfield:'cCUST_NM', 	//在内表格中显示的字段 (调用对象基础表的字段)
                        filtefield:'cCUST_NM', 	//筛选字段 当输入框的值改变时,用来跟显示字段比对筛选的字段(调用对象基础表字段)
                        //是否将部分字段作为全局筛选条件,如果是,则增加下方格式代码(此全局筛选条件,将在表格中被使用)
                        insert_deafault_field:{
                            'cCUST_NO':'cCUST_NO', //主表字段':'调用对象基础表字段
                        },
                    },
                    {
                        //基础设置
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '仓储区域', 		//输入框标题
                        dec: '', //输入框提示
                        icon: 'el-icon-user-solid', //小图标
                        //
                        option:[], //固定项
                        value: '', //固定项
                        //
                        //弹出内表格设置
                        action: 'cust/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        //弹出内表格布局规则
                        optionBoxWidth:500,
                        optionTableRole: [
                            {
                                title: '序号', 	//表头
                                field: 'idx', 	//表格展示字段 (主表字段)
                                //
                                fixed: true, 	//是否浮动
                                width: 80, 		//列宽
                                //
                                noedit: true, 	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                                defautvalue:0, 	//默认值,当为输入内容时的默认的值
                            },{
                                title: '仓储片区',
                                field: 'cCUST_NM',
                                width: 150,
                            },
                        ], 
                        showfield:'cCUST_NM', 	//在内表格中显示的字段 (调用对象基础表的字段)
                        filtefield:'cCUST_NM', 	//筛选字段 当输入框的值改变时,用来跟显示字段比对筛选的字段(调用对象基础表字段)
                        //是否将部分字段作为全局筛选条件,如果是,则增加下方格式代码(此全局筛选条件,将在表格中被使用)
                        insert_deafault_field:{
                            'cCUST_NO':'cCUST_NO', //主表字段':'调用对象基础表字段
                        },
                    },
                    {
                        type: 'daterange', 	//类型 -> 日期 (2023-01-02)
                        title: '处理日期',	//输入框标题
                        dec: '输入发货日期',	//输入框提示
                        field: 'cCAYING_DATE',	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                        format: 'yyyy-MM/dd', 	//日期展示规则 (yyyy-MM/dd)
                    },
                    {
                        type: 'select', //类型 -> 固定值选择
                        title: '审核状态',
                        field: 'orderstate', //表格展示字段 (主表字段)
                        //
                        fixed: false, 	//是否浮动
                        width: 110, 	//列宽
                        //筛选值
                        option: [{
                            value: '0',
                            label: '未审核'
                        }, {
                            value: '1',
                            label: '已审核'
                        }, {
                            value: '2',
                            label: '全部'
                        }],
                        value: '', 		//固定项
                        noedit:true,	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '调整日期',	//输入框标题
                        dec: '',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: '',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        dec: '',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: '',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '报废类别',	//输入框标题
                        dec: '',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: '',	//小图标
                        value: '',	//固定项
                    },{
                        type: 'text', 		//类型 -> 输入框
                        title: '调整数量',	//输入框标题
                        dec: '',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: '',	//小图标
                        value: '',	//固定项
                    },{
                        type: 'text', 		//类型 -> 输入框
                        title: '调整单号',	//输入框标题
                        dec: '',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: '',	//小图标
                        value: '',	//固定项
                    },{
                        type: 'text', 		//类型 -> 输入框
                        title: '仓储片区',	//输入框标题
                        dec: '',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: '',	//小图标
                        value: '',	//固定项
                    },{
                        type: 'text', 		//类型 -> 输入框
                        title: '备注',	//输入框标题
                        dec: '',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: '',	//小图标
                        value: '',	//固定项
                    },{
                        type: 'text', 		//类型 -> 输入框
                        title: '创建人',	//输入框标题
                        dec: '',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },{
                        type: 'text', 		//类型 -> 输入框
                        title: '创建时间',	//输入框标题
                        dec: '',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: '',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '维护人',	//输入框标题
                        dec: '',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },{
                        type: 'text', 		//类型 -> 输入框
                        title: '维护时间',	//输入框标题
                        dec: '',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: '',	//小图标
                        value: '',	//固定项
                    },
                    
                ],
                //主表配置
                tableRole: [
                    {
                        title: 'ID', 	//表头
                        field: 'idx', 	//表格展示字段 (主表字段)
                        //
                        fixed: true, 	//是否浮动
                        width: 80, 		//列宽
                        //
                        noedit: true, 	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                        defautvalue:0, 	//默认值,当为输入内容时的默认的值
                    },{
                        type: 'text', //类型 -> 数字
                        title: '仓库', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'text', //类型 -> 数字
                        title: '仓位', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'text', //类型 -> 数字
                        title: '货物名称', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'text', //类型 -> 数字
                        title: '货物编码', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'text', //类型 -> 数字
                        title: '批次', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'number', //类型 -> 数字
                        title: '当前数量', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'number', //类型 -> 数字
                        title: '调后数量', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'text', //类型 -> 数字
                        title: '型号', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'text', //类型 -> 数字
                        title: '类别', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'number', //类型 -> 数字
                        title: '货物状态', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'text', //类型 -> 数字
                        title: '先进先出日', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'number', //类型 -> 数字
                        title: '当前数量', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'number', //类型 -> 数字
                        title: '当前件数', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'number', //类型 -> 数字
                        title: '当前零头数', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'number', //类型 -> 数字
                        title: '单件货数', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'number', //类型 -> 数字
                        title: '调后数量', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'number', //类型 -> 数字
                        title: '调后件数', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'number', //类型 -> 数字
                        title: '调后零头', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'text', //类型 -> 数字
                        title: '备注', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },
                ],
                pagination_show: true,
                tableData: []
            },
            bottomtable: {
                searchPath: '',  //主表获取数据接口地址
                insertPath: '',     //主表保存数据接口地址
                //顶部筛选配置
                searchBox: [
                    {
                        //基础设置
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '仓库', 		//输入框标题
                        dec: '', //输入框提示
                        icon: 'el-icon-user-solid', //小图标
                        //
                        option:[], //固定项
                        value: '', //固定项
                        //
                        //弹出内表格设置
                        action: 'cust/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        //弹出内表格布局规则
                        optionBoxWidth:300,
                        optionTableRole: [
                            {
                                title: '仓库编号', 			//表头名称
                                field: 'cCUST_NO', 		//显示字段 (调用对象基础表的字段)
                                width: 150,				//列宽
                                toInsert:'cCUST_NO', 	//带出写入字段 (选中后,要将此字段的值作为筛选参数)
                            },{
                                title: '仓库名称',
                                field: 'cCUST_NM',
                                width: 150,
                            },
                        ], 
                        showfield:'cCUST_NM', 	//在内表格中显示的字段 (调用对象基础表的字段)
                        filtefield:'cCUST_NM', 	//筛选字段 当输入框的值改变时,用来跟显示字段比对筛选的字段(调用对象基础表字段)
                        //是否将部分字段作为全局筛选条件,如果是,则增加下方格式代码(此全局筛选条件,将在表格中被使用)
                        insert_deafault_field:{
                            'cCUST_NO':'cCUST_NO', //主表字段':'调用对象基础表字段
                        },
                    },
                    {
                        //基础设置
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '客户', 		//输入框标题
                        dec: '输入客户名称', //输入框提示
                        icon: 'el-icon-user-solid', //小图标
                        //
                        option:[], //固定项
                        value: '', //固定项
                        //
                        //弹出内表格设置
                        action: 'cust/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        //弹出内表格布局规则
                        optionBoxWidth:200,
                        optionTableRole: [
                            {
                                title: '客户简称',
                                field: 'cCUST_NM',
                                width: 200,
                            },
                        ], 
                        showfield:'cCUST_NM', 	//在内表格中显示的字段 (调用对象基础表的字段)
                        filtefield:'cCUST_NM', 	//筛选字段 当输入框的值改变时,用来跟显示字段比对筛选的字段(调用对象基础表字段)
                        //是否将部分字段作为全局筛选条件,如果是,则增加下方格式代码(此全局筛选条件,将在表格中被使用)
                        insert_deafault_field:{
                            'cCUST_NO':'cCUST_NO', //主表字段':'调用对象基础表字段
                        },
                    },
                    {
                        //基础设置
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '货物类别', 		//输入框标题
                        dec: '', //输入框提示
                        icon: 'el-icon-user-solid', //小图标
                        //
                        option:[], //固定项
                        value: '', //固定项
                        //
                        //弹出内表格设置
                        action: 'cust/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        //弹出内表格布局规则
                        optionBoxWidth:300,
                        optionTableRole: [
                            {
                                title: '存货类别编码', 			//表头名称
                                field: 'cCUST_NO', 		//显示字段 (调用对象基础表的字段)
                                width: 150,				//列宽
                                toInsert:'cCUST_NO', 	//带出写入字段 (选中后,要将此字段的值作为筛选参数)
                            },{
                                title: '存货类别名称',
                                field: 'cCUST_NM',
                                width: 150,
                            },
                        ], 
                        showfield:'cCUST_NM', 	//在内表格中显示的字段 (调用对象基础表的字段)
                        filtefield:'cCUST_NM', 	//筛选字段 当输入框的值改变时,用来跟显示字段比对筛选的字段(调用对象基础表字段)
                        //是否将部分字段作为全局筛选条件,如果是,则增加下方格式代码(此全局筛选条件,将在表格中被使用)
                        insert_deafault_field:{
                            'cCUST_NO':'cCUST_NO', //主表字段':'调用对象基础表字段
                        },
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '货物型号',	//输入框标题
                        dec: '',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: '',	//小图标
                        value: '',	//固定项
                    },
                    {
                        //基础设置
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '货物状态', 		//输入框标题
                        dec: '', //输入框提示
                        icon: '', //小图标
                        //
                        option:[], //固定项
                        value: '', //固定项
                        //
                        //弹出内表格设置
                        action: 'cust/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        //弹出内表格布局规则
                        optionBoxWidth:200,
                        optionTableRole: [
                            {   
                                title: '货物状态',
                                field: 'cCUST_NM',
                                width: 200,
                            },
                        ], 
                        showfield:'cCUST_NM', 	//在内表格中显示的字段 (调用对象基础表的字段)
                        filtefield:'cCUST_NM', 	//筛选字段 当输入框的值改变时,用来跟显示字段比对筛选的字段(调用对象基础表字段)
                        //是否将部分字段作为全局筛选条件,如果是,则增加下方格式代码(此全局筛选条件,将在表格中被使用)
                        insert_deafault_field:{
                            'cCUST_NO':'cCUST_NO', //主表字段':'调用对象基础表字段
                        },
                    },
                    {
                        //基础设置
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '仓位', 		//输入框标题
                        dec: '', //输入框提示
                        icon: 'el-icon-user-solid', //小图标
                        //
                        option:[], //固定项
                        value: '', //固定项
                        //
                        //弹出内表格设置
                        action: 'cust/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        //弹出内表格布局规则
                        optionBoxWidth:300,
                        optionTableRole: [
                            {
                                title: '仓位编号', 			//表头名称
                                field: 'cCUST_NO', 		//显示字段 (调用对象基础表的字段)
                                width: 150,				//列宽
                                toInsert:'cCUST_NO', 	//带出写入字段 (选中后,要将此字段的值作为筛选参数)
                            },{
                                title: '仓位名称',
                                field: 'cCUST_NM',
                                width: 150,
                            },{
                                type: 'number', //类型 -> 数字
                                title: '存满率', 
                                field: 'nMAT_QTY',
                                //
                                fixed: false,
                                width: 100,
                                //
                                value: '0.000', //日期型 默认值为 0.000
                            },{
                                type: 'number', //类型 -> 数字
                                title: '物种数', 
                                field: 'nMAT_QTY',
                                //
                                fixed: false,
                                width: 100,
                                //
                                value: '0.000', //日期型 默认值为 0.000
                            },{
                                type: 'number', //类型 -> 数字
                                title: '存货量', 
                                field: 'nMAT_QTY',
                                //
                                fixed: false,
                                width: 100,
                                //
                                value: '0.000', //日期型 默认值为 0.000
                            },{
                                title: '主要库存货物', 			//表头名称
                                field: 'cCUST_NO', 		//显示字段 (调用对象基础表的字段)
                                width: 150,				//列宽
                                toInsert:'cCUST_NO', 	//带出写入字段 (选中后,要将此字段的值作为筛选参数)
                            },
                        ], 
                        showfield:'cCUST_NM', 	//在内表格中显示的字段 (调用对象基础表的字段)
                        filtefield:'cCUST_NM', 	//筛选字段 当输入框的值改变时,用来跟显示字段比对筛选的字段(调用对象基础表字段)
                        //是否将部分字段作为全局筛选条件,如果是,则增加下方格式代码(此全局筛选条件,将在表格中被使用)
                        insert_deafault_field:{
                            'cCUST_NO':'cCUST_NO', //主表字段':'调用对象基础表字段
                        },
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '货物编码',	//输入框标题
                        dec: '',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: '',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '货物名称',	//输入框标题
                        dec: '',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: '',	//小图标
                        value: '',	//固定项
                    },{
                        //基础设置
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '批次', 		//输入框标题
                        dec: '', //输入框提示
                        icon: '', //小图标
                        //
                        option:[], //固定项
                        value: '', //固定项
                        //
                        //弹出内表格设置
                        action: 'cust/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        //弹出内表格布局规则
                        optionBoxWidth:200,
                        optionTableRole: [
                            {   
                                title: '不分批次',
                                width: 200,
                            },
                        ], 
                        showfield:'cCUST_NM', 	//在内表格中显示的字段 (调用对象基础表的字段)
                        filtefield:'cCUST_NM', 	//筛选字段 当输入框的值改变时,用来跟显示字段比对筛选的字段(调用对象基础表字段)
                        //是否将部分字段作为全局筛选条件,如果是,则增加下方格式代码(此全局筛选条件,将在表格中被使用)
                        insert_deafault_field:{
                            'cCUST_NO':'cCUST_NO', //主表字段':'调用对象基础表字段
                        },
                    },
                    {
                        type: 'checkbox', 		//类型 -> 多选
                        title: '',		//输入框标题
                        field: 'cCAY_STATUS',	//对应筛选字段 (主表字段)
                        icon: 'el-icon-message-solid',	//小图标
                        value: [],	//固定项
                        //内容项
                        option: [{
                            value: 0, 		//选中后的值
                            label: '有存货' //展示的选项名称
                        }, {
                            value: 1,
                            label: '无存货'
                        }, {
                            value: 3,
                            label: '欠存货'
                        }],
                    },
                ],
                //主表配置
                tableRole: [
                    {
                        title: '序号', 	//表头
                        field: 'idx', 	//表格展示字段 (主表字段)
                        //
                        fixed: true, 	//是否浮动
                        width: 80, 		//列宽
                        //
                        noedit: true, 	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                        defautvalue:0, 	//默认值,当为输入内容时的默认的值
                    },{
                        type: 'text', //类型 -> 数字
                        title: '冻结拣货', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'text', //类型 -> 数字
                        title: '仓库', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'text', //类型 -> 数字
                        title: '仓位', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'text', //类型 -> 数字
                        title: '货物编码', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'text', //类型 -> 数字
                        title: '货物名称', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'text', //类型 -> 数字
                        title: '类别', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'text', //类型 -> 数字
                        title: '批次', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'text', //类型 -> 数字
                        title: '包装编码', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'text', //类型 -> 数字
                        title: '货物状态', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'text', //类型 -> 数字
                        title: '先进先出日', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },
                    {
                        type: 'number', //类型 -> 数字
                        title: '当前库存', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'number', //类型 -> 数字
                        title: '当前件数', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'number', //类型 -> 数字
                        title: '当前零头数', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'number', //类型 -> 数字
                        title: '已拣货数', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },{
                        type: 'number', //类型 -> 数字
                        title: '单件货数', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },
                    {
                        type: 'text', //类型 -> 数字
                        title: '备注', 
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '0.000', //日期型 默认值为 0.000
                    },
                ],
                pagination_show: true,
                tableData: []
            },
        }
    },
    methods: {
        tableSearch(){
            
        },
        handleselectedRows(rows){
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data: {
                    idx: this.selectedRows.map(v=>v.idx)
                },    
            }; 
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                alert("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let data = this.tableData;
            let lstdata = [];
            if (!this.isAdd) {//编辑
            } else {//新增
            }
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>