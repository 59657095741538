<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form :searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" ref="table" :height="450" :isEdit="isEdit" :tablerule="rules" @selectedRows="handleselectedRows"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '库区区间移库',
            //顶部筛选配置
            searchBox: [
                {
                    type: 'select_api',
                    title: '仓储区域',
                    fixed: false,
                    width: 100,
                    option: [],
                    value: '',
                    action: '',
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx',
                            width: 150,

                        }, {
                            title: '仓储片区',
                            field: 'cWARET_NM',
                            width: 150,
                            toInsert: '',
                        },
                    ],
                    showfield: '',
                    filtefield: '',
                }, {
                    type: 'select_api',
                    title: '移入库区',
                    fixed: false,
                    width: 100,
                    option: [],
                    value: '',
                    action: '',
                    optionTableRole: [
                        {
                            title: '序号',
                            field: '',
                            width: 150,
                        }, {
                            title: '仓储片区编码',
                            field: '',
                            width: 150,
                            toInsert: '',
                        }, {
                            title: '仓储片区名称',
                            field: '',
                            width: 150,
                            toInsert: '',
                        }
                    ],
                    showfield: '',
                    filtefield: '',
                }, {
                    type: 'daterange', //时间范围
                    title: '发货日期',
                    field: '',
                    icon: 'el-icon-date',
                    value: '',
                }, {
                    type: 'select', //类型 -> 固定值选择
                    title: '审核状态',
                    field: '', //表格展示字段 (主表字段)
                    //
                    fixed: false, 	//是否浮动
                    width: 110, 	//列宽
                    //筛选值
                    option: [{
                        value: '0',
                        label: '未审核'
                    }, {
                        value: '1',
                        label: '已审核'
                    }, {
                        value: '2',
                        label: '全部'
                    }],
                    value: '2', 		//固定项
                    noedit: true,
                }, {
                    type: 'date',
                    title: '移库日期',
                    field: '', // 字段未有
                    value: '',
                    width: 120,
                }, {
                    type: 'text',
                    title: '移库数量',
                    field: '', // 字段未有
                    value: '',
                    width: 120,
                }, {
                    type: 'text',
                    title: '移库单号',
                    field: '', // 字段未有
                    value: '',
                    width: 120,
                    noedit: 'true',
                }, {
                    type: 'text',
                    title: '备注',
                    field: '', // 字段未有
                    value: '',
                    width: 120,
                }, {
                    type: 'text',
                    title: '创建人',
                    field: '', // 字段未有
                    value: '',
                    width: 120,
                }, {
                    type: 'date',
                    title: '创建时间',
                    field: '', // 字段未有
                    value: '',
                    width: 120,
                }, {
                    type: 'text',
                    title: '维护人',
                    field: '', // 字段未有
                    value: '',
                    width: 120,
                }, {
                    type: 'date',
                    title: '维护时间',
                    field: '', // 字段未有
                    value: '',
                    width: 120,
                },
            ],
            table:{
                tableRole: [
                    {
                        title: '序号', 	//表头

                        field: 'idx', 	//表格展示字段 (主表字段)

                        fixed: true, 	//是否浮动

                        width: 50, 		//列宽

                        noedit: true, 	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑

                        defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                    },
                    {
                        type: 'text',

                        title: '货物编码',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    }, {
                        type: 'text',

                        title: '货物名称',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    }, {
                        type: 'text',

                        title: '类别',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    }, {
                        type: 'text',

                        title: '批次',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    }, {
                        type: 'text',

                        title: '货物状态',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    }, {
                        type: 'text',

                        title: '包装编码',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    }, {
                        type: 'text',

                        title: '移出仓库',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    }, {
                        type: 'text',

                        title: '移出仓位',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    },{
                        type: 'daterange', //时间范围
                        title: '先进先出日',
                        field: '',
                        icon: 'el-icon-date',
                        value: '',
                    },{
                        type: 'number',

                        title: '当前数量',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    },{
                        type: 'number',

                        title: '当前件数',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    },{
                        type: 'number',

                        title: '当前零头数',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    },{
                        type: 'number',

                        title: '移库数量',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    },{
                        type: 'number',

                        title: '移库件数',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    },{
                        type: 'number',

                        title: '移库零头数',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    },{
                        type: 'number',

                        title: '单件货数',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    },{
                        type: 'text',

                        title: '移入库区',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    },{
                        type: 'text',

                        title: '移入仓库',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    },{
                        type: 'text',

                        title: '移入仓位',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    },{
                        type: 'text',

                        title: '备注',

                        field: '', // 字段未有

                        value: '',

                        width: 120,
                    },
                ],
                pagination_show: true,
                tableData: []
            }
        }
    },
    methods: {
        tableSearch(){
            
        },
        handleselectedRows(rows){
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data: {
                    idx: this.selectedRows.map(v=>v.idx)
                },    
            }; 
            if (this.selectedRows.length == 0) {
                alert("未选择删除数据！");
            } else {
                
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                alert("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let data = this.tableData;
            let lstdata = [];
            if (!this.isAdd) {//编辑
            } else {//新增
            }
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>