<template>
    <el-popover
        v-if="role.type == 'select_api'" 
        placement="bottom-start"
        :width="role.optionBoxWidth ? role.optionBoxWidth : 400"
        trigger="focus"
    >  
        <el-table v-if="tableShow" @row-click="(row,column,e)=>apiPopoverTableRowClick(row,column,e)" :data="myData()" size="mini" height="200" >
            <el-table-column :key="index" v-for="item8,index in role.optionTableRole" :width="item8.width" :fixed="item8.fixed" :label="item8.title" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div v-for="(item2,index8) in scope.row" :key="index8">
                        <template  v-if="index8 == item8.field">
                            <span>{{scope.row[index8]}}</span>
                        </template>
                    </div>
                </template>    
            </el-table-column>
        </el-table>
        <el-input 
            @focus="apiSelectInputFocus" 
            @change="apiChangeInputFocus" 
            v-model="scoperow[role.showfield]"  
            slot="reference" 
            size="mini" 
            placeholder="请点击选择"  
        ></el-input>
    </el-popover>
</template>
<script>
import axios from '@/utils/request';
export default{
    props: {
        role: {
            type: Object,
            default: {}
        },
        scoperow: {
            type: Object,
            default: {}
        },
        scrolling:{
            type: Boolean,
            default: false
        },
    },
    computed: {
        myData() {
            return function() {
                let val = this.scoperow[this.role.option.showfield];
                if (val && val != '') {
                    return this.role.option.filter((item, i, arr) => {
                        return item[this.role.filtefield].includes(val)
                    })
                } else {
                    return this.role.option;
                }
            };
        },
        myValue() {
            return function() {
                let val = this.scoperow[this.role.option.showfield];
                if(val == ''){
                    this.clearInsertField();
                    return '';
                }else{
                    return val;
                }
            };
        },
        //滚动方式
        myBlur() {
            return function() {
                return this.scrolling;
            };
        },
    },
    data() {
        return {
            tableShow:false,
        };
    },
    methods: {
        //内 表格 被点击
        apiPopoverTableRowClick(row, column, e) {
            let role = this.role;
            let feilds = this.scoperow;
            let _this = this;
            let tableRole = role.optionTableRole;
            console.log(role,'role');//获取表格的一行数据
            console.log(feilds,'feilds');//获取字段
            this.clearInsertField() ; //清除之前的数据
            tableRole.forEach(function(obj) {
                if (obj.toInsert) { //普通带出
                    _this.$set(feilds, obj.toInsert, row[obj.field]);
                }
                if (obj.toFunctionIncert) { //方法处理后带出
                    let newdata = eval(obj.toFunctionIncert[0] + "(row[obj.field],tableRole)");
                    _this.$set(feilds, obj.toFunctionIncert[1], newdata);
                }
            })
        },
        /*
            input 获得焦点 -- 是否需要额外处理
            e       input对象
            role    规则对象
            feilds  本行数据row
        */
        apiSelectInputFocus(e) {
            //启动表格
            this.tableShow = true;
            let role = this.role;
            let feilds = this.scoperow;
            let params = {
                action:'api/'+ role.action,
                pagesize: 200,
                page: 1
            };
            //判断是否是关联数据的下级
            if(role.parentField){
                let where = [];
                role.parentField.forEach(function(item){
                    let pfield = feilds[item[0]]; //数据字段值
                    where.push([item[1],'=',pfield]);
                })

                if(where.length > 0){
                    params.extra_where = where;
                    console.log(params,'params');
                    axios.get(params.action,params).then(resalt=> {
                        var data = resalt.data.data;
                        if (data) {
                            role.option = data;
                        }
                    });
                }
            }else{
                axios.get(params.action,params).then(resalt=> {
                    role.option = resalt.data.data.data || [];
                    role.visible = true
                });
            }
        },
        /*
           input 变化 -- 内容是否符合规则
           val     值
           role    规则对象
           feilds  本行数据row
       */
      //
        apiChangeInputFocus(val) {        
            let role = this.role;
            let feilds = this.scoperow;
            if (val != '') {
                let arr = role.option;
                if (!arr.find(v => v[role.showfield] == val)) {
                    this.$message.error('您输入的信息,不符规则!');
                    //清空对应 带入字段的值
                    this.clearInsertField();
                    this.$set(feilds, role.showfield, '');
                }
            }else{
                this.clearInsertField();
            }
        },
        //辅助
        //清空对应 带入字段的值
        clearInsertField(){
            let role = this.role;
            let feilds = this.scoperow;
            let _this = this;
            if(role.optionTableRole){
                role.optionTableRole.forEach(function(item){
                    if(item.toInsert && item.toInsert!=''){
                        _this.$set(feilds, item.toInsert, '');
                    }
                })
            }
            if(role.clearfield){
                role.clearfield.forEach(function(item){
                    _this.$set(feilds, item, '');
                })
            }
        },
    }
}
</script>
