<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form ref="form" :searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" ref="table" :height="550" :isEdit="isEdit" :tablerule="rules" @selectedRows="handleselectedRows"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
import {fetchwhposcls,editwhposcls,delwhposcls} from '@/api/storage/base'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '仓位划分标准',
            //检索配置
            searchBox: [
                {
                    type: 'text', 		//类型 -> 输入框、
                    title: '仓位标准名称',	//输入框标题
                    dec: '输入仓位标准名称',	//输入框提示
                    field: 'cWHPOSCLS_NM', 	//对应筛选字段 (主表字段)
                    value: '',	//固定项
                }, {
                    type: 'text',
                    title: '仓位标准编码',
                    dec: '输入仓位标准编码',
                    field: 'cWHPOSCLS_NO',
                    value: '',
                },
            ],
            table:{
                //主表配置
                tableRole: [
                    {
                        title: 'ID', 	//表头
                        field: 'idx', 	//表格展示字段 (主表字段)
                        fixed: true, 	//是否浮动
                        width: 80, 		//列宽
                        noedit: true, 	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                        defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                    }, {
                        type: 'text',
                        title: '仓位标准代码',
                        field: 'cWHPOSCLS_NO',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '仓位标准名称',
                        field: 'cWHPOSCLS_NM',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'number',
                        title: '长（米）',
                        field: 'nLENGTH',
                        fixed: false,
                        width: 100,
                        value: '0.000'
                    }, {
                        type: 'number',
                        title: '宽（米）',
                        field: 'nWIDTH',
                        fixed: false,
                        width: 100,
                        value: '0.000'
                    }, {
                        type: 'number',
                        title: '高（米）',
                        field: 'nHEIGHT',
                        fixed: false,
                        width: 130,
                        value: '0.000'
                    }, {
                        type: 'number',
                        title: '面积（平方米）',
                        field: 'nAREA',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'number',
                        title: '容积（立方米）',
                        field: 'nVOLUME',
                        fixed: false,
                        width: 130,
                        value: '0.000'
                    },
                    {
                        type: 'select_api',
                        title: '存货类别',
                        field: 'cSTKMATCLS_NM',
                        fixed: false,
                        width: 100,
                        sort: false,
                        option: [],
                        value: '',
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'idx',
                                width: 70,
                            },{
                                title: '货物类别编码',
                                field: 'cSTKMATCLS_NO',
                                width: 120,
                                toInsert: 'cSTKMATCLS_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '货物类别名称',
                                field: 'cSTKMATCLS_NM',
                                width: 184,
                                toInsert: 'cSTKMATCLS_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cSTKMATCLS_NM',
                        filtefield: 'cSTKMATCLS_NM',
                        hasCheck: true,
                        action: 'stk/matcls/search', //接口未知
                    },
                    {
                        type: 'select_api',
                        title: '客户名称',
                        field: 'cCUST_SNM',
                        fixed: false,
                        width: 200,
                        sort: false,
                        option: [],
                        value: '',
                        optionTableRole: [
                            {
                                title: '编号',
                                field: 'cCUST_NO',
                                width: 100,
                                toInsert: 'cCUST_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '客户名称',
                                field: 'cCUST_SNM',
                                width: 274,
                                toInsert: 'cCUST_SNM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cCUST_SNM',
                        filtefield: 'cCUST_SNM',
                        hasCheck: true,
                        action: 'cust/search',
                    //    default_prama: [['cCUST_NO', 'cCUST_NO']]
                    }, {
                        type: 'checkbox',
                        title: '停用',
                        field: 'cSTOP_TAG',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                    }, {
                        type: 'checkbox',
                        title: '缺省',
                        field: 'cDEFAULT',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                    }, {
                        type: 'text',
                        title: '停用原因（详介）',
                        field: 'cSTOP_REASON',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '简介',
                        field: 'cPURPOSE', //在主表中无法找到字段
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '创建人',
                        field: 'cESTUSER_NM',
                        fixed: false,
                        width: 130,
                        value: 'immobilization',
                        defautvalue: '',
                        
                    }, {
                        type: 'date',
                        title: '创建时间',
                        field: 'tESTDATA_DT',
                        fixed: false,
                        width: 130,
                        value: 'immobilization',
                        defautvalue: '',
                        
                    }, {
                        type: 'text',
                        title: '维护人',
                        field: 'cUSER_NM',
                        fixed: false,
                        width: 130,
                        value: 'immobilization',
                        defautvalue: '',
                        
                    }, {
                        type: 'date',
                        title: '维护时间',
                        field: 'tMODIFY_DT',
                        fixed: false,
                        width: 130,
                        value: 'immobilization',
                        defautvalue: '',
                        
                    },
                ],
                pagination_show: true,
                tableData: []
            }
        }
    },
    methods: {
        tableSearch(){
            let params = {
                ...this.$refs.form.searchdata,
                page_index: this.pageInfo.index,
                page_size: this.pageInfo.size
            }
            fetchwhposcls(params).then(res=>{
                this.table.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.pageInfo = res.data.page
            })  
        },
        handleselectedRows(rows){
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data:{
                    idx: this.selectedRows.map(v=>v.idx)
                }
            };
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                delwhposcls(params).then(res=>{
                    this.$message.success('删除成功')
                    this.tableSearch()
                })
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                this.$message.error("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let idx = this.isAdd ? 0 : this.editIdx
            let currentData = this.$refs.table.tableData[idx]
            let editdata = [{
                ...currentData,
                idx: !!currentData.idx ? currentData.idx: '',
                cSTOP_TAG: currentData.cSTOP_TAG ? 1: 0,
                cDEFAULT: currentData.cDEFAULT ? 1 : 0
            }]
            editwhposcls({data: {lstdata: editdata}}).then(res=>{
                this.isEdit = false
                this.tableSearch()
            })
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>