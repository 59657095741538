import _ from 'lodash';
export default{
    data(){
        return {
            loading: false,
            isEdit: false,
            pageInfo: {
                index: 1,
                total: 0,
                size: 10
            },
            rules:{  // 待添加表单校验规则
            },
            selectedRows: [], // 选中的表单数据
            editIdx: -1,
            isAdd: true  //当前处于新增或编辑状态
        }
    },
    mounted(){
        this.tableSearch()
    },
    methods:{
        commonAdd(table){
            let initData = _.cloneDeep(table.tableData)
            let role = table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(table, 'tableData', initData);
        },
        handlePaginationSearch(item){
            this.pageInfo.index = item.index
            this.pageInfo.size = item.page
            this.tableSearch()
        },
    }
}