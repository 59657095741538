import { get, post } from '@/utils'

const baseApi = 'system/passport/'

export const login = (params)=>{
    return post(baseApi + 'login',{data: params})
}

export const logout = ()=>{
    return post(baseApi + 'logout')
}

