<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form :searchbox="toptable.searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="toptable" ref="toptable" :height="250" :isEdit="isEdit" @selectedRows="handletopselectedRows"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '出库单管理',
            toptable: {
                searchPath: '/ware/wareh/search',  //主表获取数据接口地址
                insertPath: '',     //主表保存数据接口地址
                //顶部筛选配置
                searchBox: [
                    {
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '公司：',
                        icon: 'el-icon-menu',
                        option: [], //固定项
                        value: '',  //固定项
                        //弹出内表格设置
                        action: 'supplier/kind/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        //弹出内表格布局规则
                        optionTableRole: [
                            {
                                title: '编号',			 //表头名称
                                field: 'cSUPLKIND_NO', 	 //显示字段 (调用对象基础表的字段)
                                width: 150,				 //列宽
                                toInsert: 'cSUPLKIND_NO', //带出写入字段 (选中后,要将此字段的值作为筛选参数)
                            }, {
                                title: '承运商分类',
                                field: 'cSUPLKIND_NM',
                                width: 150,
                            },
                        ],
                        showfield: 'cSUPLKIND_NM',
                        filtefield: 'cSUPLKIND_NM',
                        //因为与下面的"承运商"关联 所以当前值变化后,要将关联的其他列的字段清空
                        clearfield: ['cSUPPLIER_NO', 'cSUPPLIER_NM'], //为空时,需要 关联清空的字段 (主表字段)
                    },
                    {
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '客户：',
                        field: 'cCUST_NO',
                        fixed: false,
                        width: 200,
                        option: [],
                        value: '',
                        //弹出内表格设置
                        action: 'cust/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        //弹出内表格布局规则
                        optionTableRole: [
                            {
                                title: '编号',		//表头名称
                                field: 'cCUST_NO',	//显示字段 (调用对象基础表的字段)
                                width: 150,			//列宽
                                toInsert: 'cCUST_NO', //带出写入字段 (选中后,要将此字段的值赋值给当前列)
                                stype: 'value'		//作为未编辑状态时的显示内容的 对比值
                            }, {
                                title: '客户名称',
                                field: 'cCUST_NM',
                                width: 150,
                                toInsert: 'cCUST_NM', //带出写入
                                stype: 'label' //作为未编辑状态时的显示内容的展示值
                            },
                        ],
                        showfield: 'cCUST_NM', //展示字段
                        filtefield: 'cCUST_NM', //筛选字段(用于选择的数据的某个字段)
                        hasCheck: true, //是否检查所输入内容为在内表格选择列表中的项
                        //调用全局参数作为筛选项
                        default_prama: [
                            ['cCUST_NO', 'cCUST_NO'],  //['主表字段','调用对象基础表的字段']
                        ],
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '发货单号：',	//输入框标题
                        dec: '输入发货单号',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '车牌号：',	//输入框标题
                        dec: '输入车牌号',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '系统单号：',	//输入框标题
                        dec: '输入系统单号',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '仓储区域：',
                        icon: 'el-icon-menu',
                        //
                        option: [], //固定项
                        value: '',  //固定项
                        //弹出内表格设置
                        action: 'supplier/kind/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        //弹出内表格布局规则
                        optionTableRole: [
                            {
                                title: '编号',			 //表头名称
                                field: 'cSUPLKIND_NO', 	 //显示字段 (调用对象基础表的字段)
                                width: 150,				 //列宽
                                toInsert: 'cSUPLKIND_NO', //带出写入字段 (选中后,要将此字段的值作为筛选参数)
                            }, {
                                title: '承运商分类',
                                field: 'cSUPLKIND_NM',
                                width: 150,
                            },
                        ],
                        showfield: 'cSUPLKIND_NM',
                        filtefield: 'cSUPLKIND_NM',
                        //因为与下面的"承运商"关联 所以当前值变化后,要将关联的其他列的字段清空
                        clearfield: ['cSUPPLIER_NO', 'cSUPPLIER_NM'], //为空时,需要 关联清空的字段 (主表字段)
                        //
                    },
                    {
                        type: 'daterange',  //类型 -> 日期
                        title: '出库日期：',
                        field: 'cCAYING_DATE',
                        //
                        fixed: false,
                        width: 150,
                        //
                        value: '',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '承运单位：',	//输入框标题
                        dec: '输入叫车人员',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'select', //类型 -> 固定值选择
                        title: '审核状态',
                        field: 'orderstate', //表格展示字段 (主表字段)
                        //
                        fixed: false, 	//是否浮动
                        width: 110, 	//列宽
                        //筛选值
                        option: [{
                            value: '0',
                            label: '未调度'
                        }, {
                            value: '1',
                            label: '已调度'
                        }, {
                            value: '2',
                            label: '已完成'
                        }],
                        value: '', 		//固定项
                        noedit: true,	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                    },
                    {
                        //基础设置
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '客户', 		//输入框标题
                        dec: '输入客户名称', //输入框提示
                        icon: 'el-icon-user-solid', //小图标
                        //
                        option: [], //固定项
                        value: '', //固定项
                        //
                        //弹出内表格设置
                        action: 'cust/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        //弹出内表格布局规则
                        optionBoxWidth: 500,
                        optionTableRole: [
                            {
                                title: '编号', 			//表头名称
                                field: 'cCUST_NO', 		//显示字段 (调用对象基础表的字段)
                                width: 150,				//列宽
                                toInsert: 'cCUST_NO', 	//带出写入字段 (选中后,要将此字段的值作为筛选参数)
                            }, {
                                title: '客户名称',
                                field: 'cCUST_NM',
                                width: 150,
                            },
                        ],
                        showfield: 'cCUST_NM', 	//在内表格中显示的字段 (调用对象基础表的字段)
                        filtefield: 'cCUST_NM', 	//筛选字段 当输入框的值改变时,用来跟显示字段比对筛选的字段(调用对象基础表字段)
                        //是否将部分字段作为全局筛选条件,如果是,则增加下方格式代码(此全局筛选条件,将在表格中被使用)
                        insert_deafault_field: {
                            'cCUST_NO': 'cCUST_NO', //主表字段':'调用对象基础表字段
                        },
                    },
                    {
                        type: 'date',  //类型 -> 日期
                        title: '叫车日期',
                        field: 'cCAYING_DATE',
                        //
                        fixed: false,
                        width: 150,
                        //
                        value: '',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                    },
                    {
                        //基础设置
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '出库类别：', 		//输入框标题
                        dec: '输入出库类别', //输入框提示
                        icon: 'el-icon-user-solid', //小图标
                        //
                        option: [], //固定项
                        value: '', //固定项
                        //
                        //弹出内表格设置
                        action: 'cust/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        //弹出内表格布局规则
                        optionBoxWidth: 500,
                        optionTableRole: [
                            {
                                title: '编号', 			//表头名称
                                field: 'cCUST_NO', 		//显示字段 (调用对象基础表的字段)
                                width: 150,				//列宽
                                toInsert: 'cCUST_NO', 	//带出写入字段 (选中后,要将此字段的值作为筛选参数)
                            }, {
                                title: '客户名称',
                                field: 'cCUST_NM',
                                width: 150,
                            },
                        ],
                        showfield: 'cCUST_NM', 	//在内表格中显示的字段 (调用对象基础表的字段)
                        filtefield: 'cCUST_NM', 	//筛选字段 当输入框的值改变时,用来跟显示字段比对筛选的字段(调用对象基础表字段)
                        //是否将部分字段作为全局筛选条件,如果是,则增加下方格式代码(此全局筛选条件,将在表格中被使用)
                        insert_deafault_field: {
                            'cCUST_NO': 'cCUST_NO', //主表字段':'调用对象基础表字段
                        },
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '出库人员：',	//输入框标题
                        dec: '输入叫车人员',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '',	//输入框标题
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '出库单号：',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        //基础设置
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '存储片区：', 		//输入框标题
                        dec: '输入客户名称', //输入框提示
                        icon: 'el-icon-user-solid', //小图标
                        //
                        option: [], //固定项
                        value: '', //固定项
                        //
                        //弹出内表格设置
                        action: 'cust/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        //弹出内表格布局规则
                        optionBoxWidth: 500,
                        optionTableRole: [
                            {
                                title: '编号', 			//表头名称
                                field: 'cCUST_NO', 		//显示字段 (调用对象基础表的字段)
                                width: 150,				//列宽
                                toInsert: 'cCUST_NO', 	//带出写入字段 (选中后,要将此字段的值作为筛选参数)
                            }, {
                                title: '客户名称',
                                field: 'cCUST_NM',
                                width: 150,
                            },
                        ],
                        showfield: 'cCUST_NM', 	//在内表格中显示的字段 (调用对象基础表的字段)
                        filtefield: 'cCUST_NM', 	//筛选字段 当输入框的值改变时,用来跟显示字段比对筛选的字段(调用对象基础表字段)
                        //是否将部分字段作为全局筛选条件,如果是,则增加下方格式代码(此全局筛选条件,将在表格中被使用)
                        insert_deafault_field: {
                            'cCUST_NO': 'cCUST_NO', //主表字段':'调用对象基础表字段
                        },
                    },
                    {
                        type: 'date',  //类型 -> 日期
                        title: '要求发货日：',
                        field: 'cCAYING_DATE',
                        fixed: false,
                        width: 150,
                        value: '',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                    },
                    {
                        type: 'date',  //类型 -> 日期
                        title: '要求到达日：',
                        field: 'cCAYING_DATE',
                        fixed: false,
                        width: 150,
                        value: '',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '出库数量：',	//输入框标题
                        dec: '输入叫车人员',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '拣货数量：',	//输入框标题
                        dec: '输入叫车人员',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '发货单号：',	//输入框标题
                        dec: '输入叫车人员',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '拣货单号：',	//输入框标题
                        dec: '输入叫车人员',	//输入框提示
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '承运类别：',
                        icon: 'el-icon-menu',
                        //
                        option: [], //固定项
                        value: '',  //固定项
                        //弹出内表格设置
                        action: 'supplier/kind/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        //弹出内表格布局规则
                        optionTableRole: [
                            {
                                title: '编号',			 //表头名称
                                field: 'cSUPLKIND_NO', 	 //显示字段 (调用对象基础表的字段)
                                width: 150,				 //列宽
                                toInsert: 'cSUPLKIND_NO', //带出写入字段 (选中后,要将此字段的值作为筛选参数)
                            }, {
                                title: '承运商分类',
                                field: 'cSUPLKIND_NM',
                                width: 150,
                            },
                        ],
                        showfield: 'cSUPLKIND_NM',
                        filtefield: 'cSUPLKIND_NM',
                        //因为与下面的"承运商"关联 所以当前值变化后,要将关联的其他列的字段清空
                        clearfield: ['cSUPPLIER_NO', 'cSUPPLIER_NM'], //为空时,需要 关联清空的字段 (主表字段)
                        //
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '车牌号：',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '联系电话：',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '协议单号：',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '创建人：',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '创建时间：',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '维护人：',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '维护时间：',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    }
                ],
                //主表配置
                tableRole: [
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '序号',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '货物名称',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '货物编码',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '类别',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '批次',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '货物状态',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },

                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '拣货件数',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '拣货零头',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '仓库',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '仓位',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '包装编号',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '超拣数量',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '超拣零头数',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '单间货数',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '存放单位',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '先进先出日',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '拣货数量',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '单重',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '净重',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '毛重',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '长',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '宽',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '高',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '体积',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '编排序号',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '包装方式',	//输入框标题

                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '备注',	//输入框标题
                        field: 'cEMP_NO', 	//对应筛选字段 (主表字段)
                        icon: 'el-icon-user-solid',	//小图标
                        value: '',	//固定项
                    },

                ],
                pagination_show: true,
                tableData: []
            }
        }
    },
    methods: {
        tableSearch(){
            
        },
        handletopselectedRows(rows){
            this.selectedRows = rows
        },
        handlebottomselectedRows(rows){
            this.bottomselectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            this.commonAdd(this.table)
        },
        //删除数据
        delRow() {
            let params = {
                data:{
                    idx:this.selectedRows.map(v=>v.idx)
                }
            };
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                this.$message.error("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>