import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)

import router from '@/router/index.js'

import topForm from "./components/common/topForm.vue";
import topBanner from "./components/common/topBanner.vue";
import tdArea from "./components/common/area.vue";
import tdApiSelect from './components/common/apiselect.vue';
import myTable from "./components/common/mytable.vue";
import myTree from "./components/common/tree.vue";

Vue.component('top-form',topForm)
Vue.component('top-banner',topBanner)
Vue.component('td-area',tdArea)
Vue.component('td-api-select',tdApiSelect)
Vue.component('mytable',myTable)
Vue.component('myTree',myTree)

import {getNowTime} from '@/utils/common'

Vue.prototype.$getNowTime = getNowTime

Vue.config.productionTip = false

Vue.directive('blur', function (el) {
  el.querySelector('input').blur()
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
