<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form ref="form" :searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" ref="table" @search="handlePaginationSearch" :pageInfo="pageInfo" :height="550" :isEdit="isEdit" :tablerule="rules" @selectedRows="handleselectedRows" ></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
import {fetchMats,editMats,delMats} from '@/api/storage/transfer'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '仓库货物资料',
            //顶部筛选配置
            searchBox: [
                {
                    type: 'select_api', //  
                    title: '存货类别',
                    dec: '请输入存货类别',
                    icon: 'el-icon-user-solid',
                    option: [],
                    value: '',
                    action: 'stk/matcls/search',
                    optionBoxWidth: 380,
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx',
                            width: 80,
                            toInsert: 'idx',
                        }, {
                            title: '存货类别编码',
                            field: 'cSTKMATCLS_NO',
                            width: 150,
                            toInsert: 'cSTKMATCLS_NO',
                            stype: 'value',
                        },
                        {
                            title: '存货类别名称',
                            field: 'cSTKMATCLS_NM',
                            width: 150,
                            toInsert: 'cSTKMATCLS_NM',
                            stype: 'label',
                        },
                    ],
                    showfield: 'cSTKMATCLS_NM',
                    filtefield: 'cSTKMATCLS_NM',
                    hasCheck: true,
                    // 是否将部分字段作为全局筛选条件，如果是，则增加下方格式代码（全局筛选条件，将在表格中使用）
                }, {
                    type: 'select_api', //  
                    title: '客户',
                    dec: '请输入客户名称',
                    icon: 'el-icon-user-solid',
                    option: [],
                    value: '',
                    action: 'stk/mat/search',
                    optionBoxWidth: 230,
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx',
                            width: 80,
                            toInsert: 'idx',
                            stype: 'value',
                        }, {
                            title: '客户简称',
                            field: 'cCUST_SNM',
                            width: 150,
                            toInsert: 'cCUST_SNM',
                            stype: 'label',
                        },

                    ],
                    showfield: 'cCUST_SNM',
                    filtefield: 'cCUST_SNM',
                    hasCheck: true,
                    // 是否将部分字段作为全局筛选条件，如果是，则增加下方格式代码（全局筛选条件，将在表格中使用）
                },
                {
                    type: 'text',
                    title: '仓库存货',
                    field: 'cCUSMAT_NO',
                    value: '',
                    width: 150,
                },
                {
                    type: 'select',
                    title: '使用状态',
                    field: '',
                    fixed: false,
                    width: 150,
                    option: [{
                        value: '0',
                        label: '未使用',
                    }, {
                        value: '1',
                        label: '使用中',
                    },
                    {
                        value: '2',
                        label: '已使用',
                    },],
                    value: '1',
                },
            ],
            table: {
                //主表配置
                tableRole: [
                    {
                        type: 'text',
                        title: '序号',
                        field: 'idx',
                        width: 80,
                        toInsert: 'idx',
                    }, {
                        type: 'select_api', //  
                        title: '所属客户',
                        width: '150',
                        field: 'cCUST_SNM',
                        option: [],
                        value: '',
                        action: 'cust/search',
                        optionBoxWidth: 300,
                        optionTableRole: [
                            {
                                title: '客户编号',
                                field: 'cCUST_NO',
                                width: 150,
                                toInsert: 'cCUST_NO',
                                style: 'value',
                            }, {
                                title: '客户简称',
                                field: 'cCUST_SNM',
                                width: 150,
                                toInsert: 'cCUST_SNM',
                                stype: 'label',
                            },

                        ],
                        showfield: 'cCUST_SNM',
                        filtefield: 'cCUST_SNM',
                        hasCheck: true,
                        default_prama: [
                            ['', ''],
                        ],
                        // 是否将部分字段作为全局筛选条件，如果是，则增加下方格式代码（全局筛选条件，将在表格中使用）
                    },
                    {
                        type: 'text',
                        title: '客户编码',
                        field: 'cCUST_NO',
                        width: 150,
                        value: '',
                        defautvalue: '',
                    },
                    {
                        type: 'text',
                        title: '客户货物编码',
                        field: 'cCUSMAT_NO',
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '货物名称',
                        field: 'cSTKMAT_NM',
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '货物别名',
                        field: 'cSTKMAT_ALIASNM',       //没有字段
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '货物英文名',
                        field: 'cSTKMAT_ENM',
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '规格说明',
                        field: 'cSTKMATSPEC_NM',
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '规格编码',
                        field: 'cSTKMATSPEC_NO',
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '海关编码',
                        field: 'cHS_CODE',
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '包装方式',
                        field: 'cPAKG_DESC',
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '货物描述',
                        field: 'cDESCRIBE',
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'select_api', //  
                        title: '存货类别',
                        width: '150',
                        field: 'cSTKMATCLS_NM',
                        option: [],
                        value: '',
                        action: 'stk/matcls/search',
                        optionBoxWidth: 300,
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'idx',
                                width: 80,
                                toInsert: 'idx',
                            }, {
                                title: '存货类别编码',
                                field: 'cSTKMATCLS_NO',
                                width: 110,
                                toInsert: 'cSTKMATCLS_NO',
                                stype: 'value',

                            },
                            {
                                title: '存货类别名称',
                                field: 'cSTKMATCLS_NM',
                                width: 110,
                                toInsert: 'cSTKMATCLS_NM',
                                stype: 'label',
                            },

                        ],
                        showfield: 'cSTKMATCLS_NM',
                        filtefield: 'cSTKMATCLS_NM',
                        hasCheck: true,
                        // 是否将部分字段作为全局筛选条件，如果是，则增加下方格式代码（全局筛选条件，将在表格中使用）
                    },
                    {
                        type: 'select_api', //  
                        title: '存放单位',
                        width: '150',
                        field: 'cSTOUNIT_UM',
                        option: [],
                        value: '',
                        action: 'info/unit/search',
                        optionBoxWidth: 300,
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'idx',
                                width: 80,
                                toInsert: 'idx',
                            }, {
                                title: '单位名称',
                                field: 'cUNIT_NM',
                                width: 110,
                                toInsert: 'cSTOUNIT_UM',
                                stype: 'value',

                            },
                            {
                                title: '类别',
                                field: '',
                                width: 110,
                                toInsert: '',
                                stype: 'label',
                            },

                        ],
                        showfield: 'cSTOUNIT_UM',
                        filtefield: 'cSTOUNIT_UM',
                        hasCheck: true,
                        // 是否将部分字段作为全局筛选条件，如果是，则增加下方格式代码（全局筛选条件，将在表格中使用）
                    },
                    {
                        type: 'select_api', //  
                        title: '计量单位',
                        width: '150',
                        field: 'cUNIT_NM',
                        option: [],
                        value: '',
                        action: 'info/unit/search',
                        optionBoxWidth: 300,
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'idx',
                                width: 80,
                                toInsert: 'idx',
                            }, {
                                title: '单位名称',
                                field: 'cUNIT_NM',
                                width: 110,
                                toInsert: 'cUNIT_NM',
                                stype: 'value',

                            },
                            {
                                title: '类别',
                                field: '',   //该接口下无类别字段
                                width: 110,
                                toInsert: '',
                                stype: 'label',
                            },

                        ],
                        showfield: 'cUNIT_NM',
                        filtefield: 'cUNIT_NM',
                        hasCheck: true,
                        // 是否将部分字段作为全局筛选条件，如果是，则增加下方格式代码（全局筛选条件，将在表格中使用）
                    },
                    {
                        type: 'number',
                        title: '单件货数',
                        field: 'nUNIT_QTY',
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '件数单位',
                        field: 'cPIEUNIT_NM',
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'number',
                        title: '单货再分个数',
                        field: 'nPERBOX_QTY',
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'number',
                        title: '长',
                        field: 'nLENGTH',
                        fixed: false,
                        width: 100,
                        value: '',
                    },
                    {
                        type: 'number',
                        title: '宽',
                        field: 'nWIDTH',
                        fixed: false,
                        width: 100,
                        value: '',
                    },
                    {
                        type: 'number',
                        title: '高',
                        field: 'nHEIGHT',
                        fixed: false,
                        width: 100,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '面积',
                        field: 'nAREA',
                        fixed: false,
                        width: 100,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '容积',
                        field: 'nVOLUME',
                        fixed: false,
                        width: 100,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '体积单位',
                        field: 'cVOLUNIT_NM',
                        fixed: false,
                        width: 100,
                        value: '',
                    },
                    {
                        type: 'number',
                        title: '净重',
                        field: 'nNET_WEIGHT ',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'number',
                        title: '毛重',
                        field: 'nGROSS_WEIGHT',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'text',
                        title: '重量单位',
                        field: 'cWTUNIT_NM',
                        fixed: false,
                        width: 100,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '客户物料类别编码',
                        field: 'cCUSMATCLS_NO',
                        fixed: false,
                        width: 150,
                        value: '',
                    }, {
                        type: 'text',
                        title: '客户物料类别名称',
                        field: 'cCUSMATCLS_NM',
                        fixed: false,
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '供应商编码',
                        field: 'cSUPPLIER_NO',
                        fixed: false,
                        width: 150,
                        value: '',
                    }, {
                        type: 'text',
                        title: '供应商名称',
                        field: 'cSUPPLIER_NM',
                        fixed: false,
                        width: 150,
                        value: '',
                    }, {
                        type: 'number',
                        title: '销售单价',
                        field: 'nUNIT_PRICE',
                        fixed: false,
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'number',
                        title: '打折单价',
                        field: 'nAGIO_PRICE',
                        fixed: false,
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'number',
                        title: '会员单价',
                        field: 'nMEMB_PRICE',
                        fixed: false,
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'number',
                        title: '成本单价',
                        field: 'nCOST_PRICE',
                        fixed: false,
                        width: 150,
                        value: '',
                    }, {
                        type: 'select_api', //  
                        title: '出口国家',
                        width: '150',
                        field: 'cEXPNATION_NM',
                        option: [],
                        value: '',
                        action: 'info/nation/search',
                        optionBoxWidth: 300,
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'idx',
                                width: 80,
                                toInsert: 'idx',
                            }, {
                                title: '国家代码',
                                field: 'cNATION_NO',
                                width: 110,
                                toInsert: 'cNATION_NO',
                                stype: 'value',

                            },
                            {
                                title: '国家名称',
                                field: 'cNATION_NM',
                                width: 110,
                                toInsert: 'cEXPNATION_NM',
                                stype: 'label',
                            },

                        ],
                        showfield: 'cEXPNATION_NM',
                        filtefield: 'cEXPNATION_NM',
                        hasCheck: true,
                        // 是否将部分字段作为全局筛选条件，如果是，则增加下方格式代码（全局筛选条件，将在表格中使用）
                    },
                    {
                        type: 'text',
                        title: '出口国家说明',
                        field: 'cEXPORT_CUNTY',    //没有字段
                        value: '',
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'select',
                        title: '使用范围',
                        field: 'cUSE_RANG',
                        fixed: false,
                        width: 150,
                        option: [{
                            value: '0',
                            label: '客户独用',
                        }, {
                            value: '1',
                            label: '指定共用',
                        },
                        {
                            value: '2',
                            label: '共同享用',
                        },],
                        value: '0',
                    },
                    {
                        type: 'text',
                        title: '指定共享客户列表',
                        field: 'cSHARECUST_NMS',
                        value: '',
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'select_api', //  
                        title: '入库默认状态',
                        width: '150',
                        field: 'cSTKSTATEIN_NM',
                        option: [],
                        value: '',
                        action: 'stk/state/search',
                        optionBoxWidth: 230,
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'idx',
                                width: 80,
                                toInsert: 'idx',
                                stype: 'value',
                            }, {
                                title: '货物状态',
                                field: 'cSTKSTATE_NM',
                                width: 150,
                                toInsert: 'cSTKSTATEIN_NM',
                                stype: 'label',

                            },
                        ],
                        showfield: 'cSTKSTATEIN_NM',
                        filtefield: 'cSTKSTATEIN_NM',
                        hasCheck: true,
                    }, {
                        type: 'select_api', //  
                        title: '出库默认状态',
                        width: '150',
                        field: 'cSTKSTATEOUT_NM',
                        option: [],
                        value: '',
                        action: 'stk/state/search',
                        optionBoxWidth: 230,
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'idx',
                                width: 80,
                                toInsert: 'idx',
                                stype: 'value',
                            }, {
                                title: '货物状态',
                                field: 'cSTKSTATE_NM',
                                width: 150,
                                toInsert: 'cSTKSTATEOUT_NM',
                                stype: 'label',

                            },
                        ],
                        showfield: 'cSTKSTATEOUT_NM',
                        filtefield: 'cSTKSTATEOUT_NM',
                        hasCheck: true,
                    }, {
                        type: 'text',
                        title: '字符记录一',
                        field: 'cSTANDBY_ONE',
                        value: '',
                        width: 150,
                    }, {
                        type: 'text',
                        title: '字符记录二',
                        field: 'cSTANDBY_TWO',
                        value: '',
                        width: 150,
                    }, {
                        type: 'number',
                        title: '数字记录一',
                        field: 'nSTANDBY_ONE',
                        value: '',
                        width: 150,
                    }, {
                        type: 'number',
                        title: '数字记录二',
                        field: 'nSTANDBY_TWO',
                        value: '',
                        width: 150,
                    },
                    {
                        type: 'checkbox',
                        title: '停用',
                        field: 'cSTOP_TAG',
                        value: '',
                        width: 100,
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                    },
                    {
                        type: 'text',
                        title: '备注',
                        field: 'cREMARK',
                        value: '',
                        width: 150,
                    }, {
                        type: 'text',
                        title: '存货系统编码',
                        field: 'cSTKMAT_NO',
                        value: '',
                        width: 150,
                    },
                    {
                        type: 'text',
                        title: '创建人',
                        field: 'cESTUSER_NM',
                        value: 'immobilization',
                        width: 150,
                        
                    },
                    {
                        type: 'data',
                        title: '创建时间',
                        field: 'tESTDATA_DT',
                        value: 'immobilization',
                        width: 150,
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                        
                    },
                    {
                        type: 'text',
                        title: '维护人',
                        field: 'cUSER_NM',
                        value: 'immobilization',
                        width: 150,
                        
                    },
                    {
                        type: 'data',
                        title: '维护时间',
                        field: 'tMODIFY_DT',
                        value: 'immobilization',
                        width: 150,
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                    
                    },

                ],
                pagination_show: true,
                tableData: []
            },
        }
    },
    methods: {
        tableSearch(){
            let params = {
                ...this.$refs.form.searchdata,
                page_index: this.pageInfo.index,
                page_size: this.pageInfo.size
            }
            fetchMats(params).then(res=>{
                this.table.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.pageInfo = res.data.page
            })  
        },
        handleselectedRows(rows){
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data: {
                    idx: this.selectedRows.map(v=>v.idx)
                },    
            }; 
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                delMats(params).then(res=>{
                    this.$message.success('删除成功')
                    this.tableSearch()
                })
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                this.$message.error("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let idx = this.isAdd ? 0 : this.editIdx
            let currentData = this.$refs.table.tableData[idx]
            let editdata = [{
                ...currentData,
                idx: !!currentData.idx ? currentData.idx: '',
                cSTOP_TAG: currentData.cSTOP_TAG ? 1: 0,
                cDEFAULT: currentData.cDEFAULT ? 1 : 0
            }]
            editMats({data: {lstdata: editdata}}).then(res=>{
                this.isEdit = false
                this.tableSearch()
            })
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>