import axios from 'axios'
import {
  baseURL,
  contentType,
  requestTimeout,
} from '@/config'
import qs from 'qs'

const instance = axios.create({
  baseURL,
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType,
    'JAuthorization': ''
  },
})

instance.interceptors.request.use(
  (config) => {
    if (!config.data) {
      config.data = true // 解决请求没有参数时添加不上Content-Type问题
    }
    const token = localStorage.getItem('token');      
		token && (config.headers.JAuthorization ='Bearer ' + token);  
    if (config.data && config.headers['Content-Type'] === 'application/x-www-form-urlencoded;charset=UTF-8'){
      config.data = qs.stringify(config.data)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.log(err)
    return Promise.reject(error)
  }
)

export default instance
