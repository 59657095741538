
<template>
    <el-tree :style="{height: height + 'px'}" :data="data" :props="defaultProps"  @node-click="handleNodeClick" :treename="treename"></el-tree>
</template>
<script>
import axios from '@/utils/request'
export default{
    data() {
        return {
            data:[],
            defaultProps: {
                children: 'children',
                label: 'label'
            },
        };
    },
    props: {
        treename:{
            type: String,
            default:''
        },
        role: {
            type: Object,
            default: () => { return {} }
        },
        scoperow: {
            type: Object,
            default: () => { return {} }
        },
        height:{
            type: Number,
            default: 650
        }
    },
    created(){
        let _this = this;
        if(_this.treename=='cCUST_NO'){
            let params = {
                action:'api/cust/search',
                pagesize: 999999,
                page: 1
            };           
            axios.get(params.action,params).then(resalt=>{
                let data = resalt.data.data.data;
                data.map(function(item){
                    let option = [{
                        label: item['cCUST_SNM'],
                        children: [{
                          label:item['cNATION_NM'],
                        }]
                    }]
                   
                   for(let i=0;i<option.length;i++){
                        _this.data.push(option[i]);
                   }
                })
            });
        }
    },
    methods: {
        handleNodeClick(data) {
            this.$emit('sendMsg',data);
        },
    }
}
</script>