import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import Layout from '@/layouts/layout'
// import EmptyLayout from '@/layouts/EmptyLayout.vue'

import Login from '@/views/loginPage.vue'

// 管理员
import userList from '@/views/role/management';
import roleManagement from '@/views/role/rolemanagement';

//物流运输 -- 基础资料
import transportMode from '@/views/transport/transport_mode.vue'; // 运输方式
import transportScope from '@/views/transport/transport_scope.vue'; //运输范围
import transportFlow from '@/views/transport/transport_flow.vue'; //运输流程
import logisticsTrackingPoint from '@/views/transport/logistics_tracking_point.vue'; //物流追踪点
import carrierClassification from '@/views/transport/carrier_classification.vue'; //承运商分类
import supplierInfo from '@/views/transport/supplierInfo.vue'; //运营商资料
import supplierCayPur from '@/views/transport/supplier_cay_pur_prc.vue'; //承运商承运价
import vehicleKind from '@/views/transport/vehicle_kind.vue'; //车辆类别设置
import vehicleSizeSettings from '@/views/transport/vehicle_size_settings.vue'; //车辆尺寸设置
import vehicleInfoSettings from '@/views/transport/vehicle_info_settings.vue'; //车辆资料管理
import custMat from '@/views/transport/cust_mat.vue'; //客户货物品类
import custStk from '@/views/transport/cust_stk.vue'; //客户存货地点
import custDestAddress from '@/views/transport/cust_dest_address.vue'; //客户送达地点
import custClient from '@/views/transport/cust_client.vue'; //送达客户资料
import custPickco from '@/views/transport/cust_pickco.vue'; //提货单位资料
import custSimpleData from '@/views/transport/cust_simple_data.vue'; //承运商简易资料
import transportMat from '@/views/transport/transport_mat.vue'; //运输货物资料

// 物流资料 -- 国内物流
import orderDiaodu from '@/views/transport/order_diaodu.vue'; //订单调度分流
import carEmergency from '@/views/transport/car_emergency.vue'; // 车辆紧急调度

//仓储系统
import waretInfo from '@/views/storage/baseInfo/waret_info.vue'; //仓储片区设置
import warehInfo from '@/views/storage/baseInfo/wareh_info.vue'; //仓库资料设置
import wpstateInfo from '@/views/storage/baseInfo/wpstate_info.vue'; //仓位类别资料
import whposclsInfo from '@/views/storage/baseInfo/whposcls_info.vue'; //仓位划分标准
import whposInfo from '@/views/storage/baseInfo/whpos_info.vue'; //仓库仓位划分
import whposVirtual from '@/views/storage/baseInfo/whpos_virtual.vue'; //虚拟仓位设置
import wareInfo from '@/views/storage/transInfo/ware_info.vue'; //仓库货物资料

// 入库作业管理
import goodStrManage from '@/views/storage/inboundManagement/good_str_manage.vue'; //货物入库管理
import manualLoad from '@/views/storage/inboundManagement/manual_load.vue'; //货物手动上架
import storderBatchManage from '@/views/storage/inboundManagement/storder_batch_manage.vue'; //入库单批量管理

// 出库作业管理
import invoiceBatchManage from '@/views/storage/outboundManagement/invoice_batch_manage.vue'; //发货单批量管理
import pickListManage from '@/views/storage/outboundManagement/pick_list_manage.vue'; //拣货单管理
import outListManage from '@/views/storage/outboundManagement/out_list_manage.vue'; //出库单管理

//仓库作业管理
import transferBetweenWare from '@/views/storage/warehouseManagement/transfer_between_ware.vue'; //库区区间移库
import wareInventoryAdjust from '@/views/storage/warehouseManagement/ware_inventory_adjust.vue'; //仓库存货调整

// 仓库系统查询
import locaInventoryQuery from '@/views/storage/warehouseSystem/loca_inventory_query.vue'; //仓位存货查询
import wareOccur from '@/views/storage/warehouseSystem/ware_occur.vue'; //仓库发生业务

export const roleRoutes = [
   {
        path: '',
        component: Layout,
        meta: {title: '管理员',affix: true},
        children: [
            {path: '/user_index', component: userList,meta: {title: '管理员列表',affix: true}},
            {path: '/role_index', component: roleManagement, meta: {title: '角色管理',affix: false}},
        ]
   } 
]

const storageRoutes = [
    {
        path: '',
        component: Layout,
        meta: {title: '仓储基本资料',affix: true},
        children: [
            {path: '/waret_info', component: waretInfo,meta: {title: '仓储片区设置',affix: true}},
            {path: '/wareh_info', component: warehInfo, meta: {title: '仓库资料设置',affix: false}},
            {path: '/wpstate_info', component: wpstateInfo, meta: {title: '仓库类别资料',affix: false}},
            {path: '/whposcls_info', component: whposclsInfo, meta: {title: '仓位划分标准',affix: false}},
            {path: '/whpos_info', component: whposInfo, meta: {title: '仓库仓位划分',affix: false}},
            {path: '/whpos_virtual', component: whposVirtual, meta: {title: '虚拟仓位设置',affix: false}}
        ]
    },
    {
        path: '',
        component: Layout,
        meta: {title: '仓储转运资料',affix: false},
        children: [
            {path: '/ware_info', component: wareInfo,meta: {title: '仓库货物资料',affix: false}},
        ]
    },
    {
        path: '',
        component: Layout,
        meta: {title: '入库作业管理',affix: false},
        children: [
            {path: '/good_str_manage', component: goodStrManage,meta: {title: '货物入库管理',affix: false}},
            {path: '/manual_load', component: manualLoad,meta: {title: '货物手动上架',affix: false}},
            {path: '/storder_batch_manage', component: storderBatchManage,meta: {title: '入库单批量管理',affix: false}},
        ]  
    },{
        path: '',
        component: Layout,
        meta: {title: '出库作业管理',affix: false},
        children: [
            {path: '/invoice_batch_manage', component: invoiceBatchManage,meta: {title: '发货单批量管理',affix: false}},
            {path: '/pick_list_manage', component: pickListManage,meta: {title: '拣货单管理',affix: false}},
            {path: '/out_list_manage', component: outListManage,meta: {title: '出库单管理',affix: false}},
        ] 
    },
    {
        path: '',
        component: Layout,
        meta: {title: '仓库作业管理',affix: false},
        children: [
            {path: '/transfer_between_ware', component: transferBetweenWare,meta: {title: '库区区间移库',affix: false}},
            {path: '/ware_inventory_adjust', component: wareInventoryAdjust,meta: {title: '仓库存货调整',affix: false}},
        ]
    },
    {
        path: '',
        component: Layout,
        meta: {title: '仓库系统查询',affix: false},
        children: [
            {path: '/loca_inventory_query', component: locaInventoryQuery,meta: {title: '仓位存货查询',affix: false}},
            {path: '/ware_occur', component: wareOccur,meta: {title: '仓库发生业务',affix: false}}
        ]
    }
]

const transportRoutes = [
    {   path: '',
        component: Layout,
        meta: {title: '基础资料',affix: true},
        children: [
            // 物流基础资料
            {path: '/transport_mode', component: transportMode,meta: {title: '运输方式',affix: true}},
            {path: '/transport_scope', component: transportScope,meta: {title: '运输范围',affix: false}},
            {path: '/transport_flow', component: transportFlow,meta: {title: '运输流程',affix: false}},
            {path: '/logistics_tracking_point', component: logisticsTrackingPoint,meta: {title: '物流追踪点',affix: false}},
            {path: '/carrier_classification', component: carrierClassification,meta: {title: '承运商分类',affix: false}},
            {path: '/supplier_info', component: supplierInfo,meta: {title: '承运商资料',affix: false}},
            {path: '/supplier_cay_pur_prc', component: supplierCayPur,meta: {title: '承运商承运价',affix: false}},
            {path: '/vehicle_kind', component: vehicleKind,meta: {title: '车辆类别设置',affix: false}},
            {path: '/vehicle_size_settings', component: vehicleSizeSettings,meta: {title: '车辆尺寸设置',affix: false}},
            {path: '/vehicle_info_settings', component: vehicleInfoSettings,meta: {title: '车辆资料管理',affix: false}},
            {path: '/cust_mat', component: custMat,meta: {title: '客户货物品类',affix: false}},
            {path: '/cust_stk', component: custStk,meta: {title: '客户存货地点',affix: false}},
            {path: '/cust_dest_address', component: custDestAddress,meta: {title: '客户送达地点',affix: false}},
            {path: '/cust_client', component: custClient,meta: {title: '送达客户资料',affix: false}},
            {path: '/cust_pickco', component: custPickco,meta: {title: '提货单位资料',affix: false}},
            {path: '/cust_simple_data', component: custSimpleData,meta: {title: '承运商简易资料',affix: false}},
            {path: '/transport_mat', component: transportMat,meta: {title: '运输货物资料',affix: false}},
        ]
    },{
        path: '',
        component: Layout,
        meta: {title: '国内物流',affix: false},
        children: [
            {path: '/order_diaodu', component: orderDiaodu,meta: {title: '订单调度分流',affix: false}},
            {path: '/car', component: carEmergency,meta: {title: '车辆紧急调度',affix: false}},
        ] 
    }
]

export const Routes = [transportRoutes, storageRoutes]


const router = new VueRouter({
    routes: [
        {
            path: '/login',
            component: Login,
            hidden: true,
        },
        ...roleRoutes,
        ...transportRoutes,
        ...storageRoutes
    ]
})




//
router.beforeEach((to, from, next)=>{
    // 获取token
    const token = window.localStorage.getItem('token');
    // 有token
    if (token) {
        // 直接放行
        next();
    } else {  // 否则是没有
        // 如果去的是登录页
        if (to.path === '/login') {
            // 直接放行
            next();
        } else {
            // 如果去的是其他页,跳转到登录页
            // 跳转到登录页
            return next({ "path": "/login" })
        }
    }

})
export default router