<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <el-row :gutter="20">
            <el-col :span="4">
                <myTree :treename="TreeName" @sendMsg="handleTreeNode"></myTree>
            </el-col>
            <el-col :span="20">
                <div class="top-form-wrapper">
                    <top-form :searchbox="searchBox" ref="form" :isedit="isEdit"></top-form>
                </div>
                <mytable :tablelet="table" ref="table" :height="550" :pageInfo="pageInfo" :isEdit="isEdit" :tablerule="rules" @selectedRows="handleselectedRows"></mytable>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
import {Tree} from '@/components/common/tree'
import {fetchCustomAddress,editCustomAddress,delCustomAddress} from '@/api/transport/base'
import {fetchNations,fetchProvs,fetchCitys,fetchAreas} from '@/api/common'
export default{
    mixins: [transportMix],
    components:{Tree},
    data(){
        return {
            appName: '客户存货地点',
            TreeName: 'cCUST_NO',
            searchBox: [
                {
                    type: 'text',
                    title: '存货地点：',
                    dec: '请输入存货地点',
                    field: 'cADDR',
                    value: '',
                }, {
                    type: 'select',
                    title: '状态',
                    field:'cSTOP_TAG',
                    dec: '',
                    //内容项
                    option: [{
                        value: '0',
                        label: '使用',
                    }, {
                        value: '1',
                        label: '停用',
                    }, {
                        value: '2',
                        label: '全部',
                    }],
                    value: '2', 		//固定项
                    noedit: false,	//
                },
            ],
            table: {
                tableRole: [
                    {
                        type: 'text',
                        title: '序号',
                        field: 'idx',
                        fixed: false,
                        width: 80,
                    },
                    {
                        type: 'select_api',
                        title: '所在国家',
                        field: 'cSTNATION_NM',
                        fixed: false,
                        width: 150,
                        option: [],
                        value: '',
                        action: 'info/nation/search',
                        optionTableRole: [
                            {
                                title: '国家代码',
                                field: 'cNATION_NO',
                                width: 150,
                                toInsert: 'cSTNATION_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '国家名称',
                                field: 'cNATION_NM',
                                width: 150,
                                toInsert: 'cSTNATION_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cSTNATION_NM', //展示字段
                        filtefield: 'cSTNATION_NM', //筛选字段(用于选择的数据的某个字段)
                        clearfield: ['cSTPROV_NO', 'cSTPROV_NM', 'cSTCITY_NO', 'cSTCITY_NM', 'cSTAPANA_NO', 'cSTAPANA_NM'], //为空时,需要 关联清空的字段 
                        default_prama: [
                            ['cSTNATION_NO', 'cNATION_NO']
                        ]
                    },
                    {
                        type: 'select_api',
                        title: '所在省份',
                        field: 'cSTPROV_NM',
                        width: 150,
                        fixed: false,
                        option: [],
                        value: '',
                        action: 'info/prov/search', //调用方法
                        optionTableRole: [
                            {
                                title: '省份代码',
                                field: 'cPROV_NO',
                                width: 150,
                                toInsert: ' cSTPROV_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '省份名称',
                                field: 'cPROV_NM',
                                width: 150,
                                toInsert: 'cSTPROV_NM',
                                stype: 'label',

                            },
                        ],
                        showfield: 'cSTPROV_NM', //展示字段
                        filtefield: 'cSTPROV_NM',
                        parentField: [
                            ['cSTNATION_NM', 'cNATION_NM'], //['数据字段','基础表字段']   关联上级字段 (不会预加载)
                        ],
                        clearfield: ['cSTCITY_NO', 'cSTCITY_NM', 'cSTAPANA_NO', 'cSTAPANA_NM'], //为空时,需要 关联清空的字段
                    }, {
                        title: '所在城市',
                        field: 'cSTCITY_NM',
                        fixed: false,
                        width: 150,
                        type: 'select_api',
                        option: [],
                        value: '',
                        optionTableRole: [
                            {
                                title: '城市代码',
                                field: 'cCITY_NO',
                                width: 150,
                                toInsert: 'cSTCITY_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '城市名称',
                                field: 'cCITY_NM',
                                width: 150,
                                toInsert: 'cSTCITY_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cSTCITY_NM', //展示字段
                        filtefield: 'cSTCITY_NM', //筛选字段(用于选择的数据的某个字段)
                        action: 'info/city/search', //调用方法
                        parentField: [
                            ['cSTPROV_NM', 'cPROV_NM'], //['数据字段','基础表字段']   关联上级字段 (不会预加载)
                        ],
                        clearfield: ['cSTAPANA_NO', 'cSTAPANA_NM'], //为空时,需要 关联清空的字段 
                    },
                    {
                        title: '所在区县',
                        field: 'cSTAPANA_NM',
                        fixed: false,
                        width: 150,
                        type: 'select_api',
                        option: [],
                        value: '',
                        optionTableRole: [
                            {
                                title: '区县代码',
                                field: 'cAPANA_NO',
                                width: 150,
                                toInsert: 'cSTAPANA_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '县区名称',
                                field: 'cAPANA_SNM',
                                width: 150,
                                toInsert: 'cSTAPANA_NM', //带出写入
                                stype: 'label',
                            }
                        ],
                        optionTableWidth: 300, //内表格宽度
                        showfield: 'cSTAPANA_NM', //展示字段
                        filtefield: 'cSTAPANA_NM', //筛选字段(用于选择的数据的某个字段)
                        action: 'info/apana/search', //调用方法
                        parentField: [
                            ['cSTCITY_NM', 'cCITY_NM'], //['数据字段','基础表字段']
                        ] //关联上级字段 (不会预加载)
                    },
                    {
                        type: 'text',
                        title: '存货地点',
                        field: 'cADDR',
                        fixed: false,
                        width: 200,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '联系固话',
                        field: 'cTEL_NO',
                        fixed: false,
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '联系手机',
                        field: 'cMOBIL_TEL',
                        fixed: false,
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'select_api',
                        title: '所属客户',
                        field: 'cCUST_NO',
                        fixed: false,
                        width: 100,
                        option: [],
                        value: '',
                        action: 'cust/search',
                        optionTableRole: [
                            {
                                title: '客户编号',
                                field: 'cCUST_NO',
                                width: 150,
                                toInsert: 'cCUST_NO',
                            }, {
                                title: '客户名称',
                                field: 'cCUST_SNM',
                                width: 150,
                                toInsert: 'cCUST_SNM',
                            }
                        ],
                        showfield: 'cCUST_NO',
                        filtefield: 'cCUST_NO',
                        clearfield: ['cCUST_SNM'],
                    }, {
                        type: 'text',
                        title: '客户名称',
                        field: 'cCUST_SNM',
                        fixed: false,
                        width: 100,
                        value: '',
                    },
                    {
                        type: 'checkbox',
                        title: '缺省_缺少字段',
                        field: 'cDEFAULT',  //表格里面没有字段
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1',
                        falsenumber: '0',
                        insertfieldtime: '',
                    },
                    {
                        type: 'checkbox', //类型 -> 单选
                        title: '停用_缺少字段',
                        field: 'cSTOP_TAG',  //表格里面没有字段
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1',
                        falsenumber: '0',
                        insertfieldtime: '',
                    },
                    {
                        type: 'text',
                        title: '存货地点编码',
                        field: 'cCUSTSTK_NO',
                        fixed: false,
                        width: 150,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '维护人',
                        field: 'cUSER_NM',  //没有字段
                        value: 'immobilization',
                        fixed: false,
                        width: 80,
                    },
                    {
                        type: 'date',  //类型 -> 日期
                        title: '维护时间',
                        field: 'tMODIFY_DT',
                        fixed: false,
                        width: 150,
                        value: 'immobilization',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                    },
                ],
                pagination_show: true,
                tableData: []
            }
        }
    }, 
    methods:{
        fetchNationsTree(){
            fetchNations({page:1,pagesize: 999999}).then(res=>{
                console.log(res)
            })
        },
        handleTreeNode(node){
            console.log(node)
        },
        tableSearch(){
            let params = {
                ...this.$refs.form.searchdata,
                page_index: this.pageInfo.index,
                page_size: this.pageInfo.size
            }
            fetchCustomAddress(params).then(res=>{
                this.table.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.pageInfo = res.data.page
            })
            this.fetchNationsTree()
        },
        handleselectedRows(rows){
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data: {
                    idx: this.selectedRows.map(v=>v.idx)
                } 
            };
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                delCustomAddress(params).then(res=>{
                    this.$message.success('删除成功!')
                    this.tableSearch()
                })
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                this.$message.error("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let idx = this.isAdd ? 0 : this.editIdx
            let currentData = this.$refs.table.tableData[idx]
            let editdata = [{
                ...currentData,
                idx: !!currentData.idx ? currentData.idx: '',
                cSTOP_TAG: currentData.cSTOP_TAG ? 1: 0,
                cDEFAULT: currentData.cDEFAULT ? 1 : 0
            }]
            editCustomAddress({data: {lstdata: editdata}}).then(res=>{
                this.isEdit = false
                this.tableSearch()
            })
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>