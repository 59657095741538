<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form ref="form":searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" ref="table" :height="550" :pageInfo="pageInfo" :isEdit="isEdit" :tablerule="rules" @selectedRows="handleselectedRows"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
import {fetchWarehs,editWarehs,delWarehs} from '@/api/storage/base'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '仓库资料设置',
            //检索配置
            searchBox: [
                {
                    type: 'select_api',
                    title: '仓储片区',
                    icon: 'el-icon-menu',
                    option: [], //固定项
                    value: '', //固定项
                    action: 'ware/waret/search',
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx', //显示字段 (调用对象基础表的字段)
                            width: 90,
                        }, {
                            title: '仓储片区代码', //表头名称
                            field: 'cWARET_NO', //显示字段 (调用对象基础表的字段)
                            width: 142, //列宽
                            toInsert: 'cWARET_NO', //带出写入字段 (选中后,要将此字段的值作为筛选参数)
                        }, {
                            title: '仓储片区',
                            field: 'cWARET_NM',
                            width: 142,
                            toInsert: 'cWARET_NM',
                        },
                    ],
                    showfield: 'cWARET_NM',
                    filtefield: 'cWARET_NM',
                    //因为与下面的"仓库"关联 所以当前值变化后,要将关联的其他列的字段清空
                    clearfield: ['cWAREH_NO', 'cWAREH_NO'], //为空时,需要 关联清空的字段 (主表字段)
                }, {
                    type: 'text',
                    title: '仓库',
                    field: 'cWAREH_NM',
                    dec: '请输入仓库名称',
                    width: 150,
                    value: '', //固定项
                }, {
                    type: 'text', //类型 -> 输入框
                    title: '仓库编码', //输入框标题
                    field: 'cWHLAN_NO', //对应筛选字段 (主表字段)
                    value: '', //固定项
                },
            ],
            table: {
                //主表配置
                tableRole: [
                    {
                        title: 'ID', //表头
                        field: 'idx', //表格展示字段 (主表字段)
                        fixed: true, //是否浮动
                        width: 80, //列宽
                        noedit: false, //是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                        defautvalue: 0, //默认值,当为输入内容时的默认的值
                    }, {
                        type: 'text', //类型 -> 输入框
                        title: '仓库编码', //输入框标题
                        field: 'cWHLAN_NO',
                        value: '', //固定项
                    }, {
                        type: 'text', //类型 -> 输入框
                        title: '仓库名称', //输入框标题
                        width: 130,
                        field: 'cWAREH_NM', //对应筛选字段 (主表字段)
                        value: '', //固定项
                    }, {
                        type: 'number', //类型 -> 数字
                        title: '仓位数量',
                        field: 'iWPOS_QTY',
                        fixed: false,
                        width: 100,
                        value: '0.000', //日期型 默认值为 0.000
                    }, {
                        type: 'number', //类型 -> 数字
                        title: '面积(平方米)',
                        field: 'nAREA',
                        fixed: false,
                        width: 100,
                        value: '0.000', //日期型 默认值为 0.000
                    }, {
                        type: 'number', //类型 -> 数字
                        title: '容积（平方米）',
                        field: 'nVOLUME',
                        fixed: false,
                        width: 100,
                        value: '0.000', //日期型 默认值为 0.000
                    }, {
                        type: 'select_api',
                        title: '所属片区',
                        field: 'cWARET_NM',
                        fixed: false,
                        width: 200,
                        sort: false,
                        option: [],
                        value: '',
                        action: 'ware/waret/search',
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'idx',
                                width: 90,
                            }, {
                                title: '仓储片区编码',
                                field: 'cWARET_NO',
                                width: 142,
                                toInsert: 'cWARET_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '仓储片区名称',
                                field: 'cWARET_NM',
                                width: 142,
                                toInsert: 'cWARET_NM', //带出写入
                                stype: 'label',
                            },

                        ],
                        showfield: 'cWARET_NM',
                        filtefield: 'cWARET_NM',
                        hasCheck: true,
                        // default_prama: [
                        // 	['cWARET_NO', 'cWARET_NO']
                        // ]
                    }, {
                        type: 'checkbox', //类型 -> 单选
                        title: '停用',
                        field: 'cSTOP_TAG',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                        // insertfieldtime: 'tSTOP_DT', //选中后,带出日期到该行其他字段
                    }, {
                        type: 'text', //类型 -> 输入框
                        title: '详介', //输入框标题
                        field: 'cSTOP_REASON',
                        value: '', //固定项
                    }, {
                        type: 'text', //类型 -> 输入框
                        title: '简介', //输入框标题
                        field: 'cPURPOSE', //对应筛选字段 (主表字段)
                        value: '', //固定项
                    }, {
                        type: 'text', //类型 -> 输入框
                        title: '仓库系统编码', //输入框标题
                        width: 130,
                        field: 'cWAREH_NO', //对应筛选字段 (主表字段)
                        value: '', //固定项
                    }, {
                        type: 'text', //类型 -> 输入框
                        title: '创建人', //输入框标题
                        field: 'cESTUSER_NM', //对应筛选字段 (主表字段)
                        value: 'immobilization', //固定项
                    }, {
                        type: 'date', //类型 -> 日期
                        title: '创建时间',
                        field: 'tESTDATA_DT',
                        fixed: false,
                        width: 150,
                        value: 'immobilization',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                    }, {
                        type: 'text', //类型 -> 输入框
                        title: '维护人', //输入框标题
                        field: 'cUSER_NM', //对应筛选字段 (主表字段)
                        value: 'immobilization', //固定项
                    }, {
                        type: 'date', //类型 -> 日期
                        title: '维护时间',
                        field: 'tMODIFY_DT',
                        fixed: false,
                        width: 150,
                        value: 'immobilization',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                    },
                ],
                pagination_show: true,
                tableData: []
            },
        }
    },
    methods: {
        tableSearch(){
            let params = {
                ...this.$refs.form.searchdata,
                page_index: this.pageInfo.index,
                page_size: this.pageInfo.size
            }
            fetchWarehs(params).then(res=>{
                this.table.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.pageInfo = res.data.page
            }) 
        },
        handleselectedRows(rows){
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data:{
                    idx: this.selectedRows.map(v=>v.idx)
                }
            };
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                delWarehs(params).then(res=>{
                    this.$message.success('删除成功')
                    this.tableSearch()
                })
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                this.$message.error("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let idx = this.isAdd ? 0 : this.editIdx
            let currentData = this.$refs.table.tableData[idx]
            let editdata = [{
                ...currentData,
                idx: !!currentData.idx ? currentData.idx: '',
                cSTOP_TAG: currentData.cSTOP_TAG ? 1: 0,
                cDEFAULT: currentData.cDEFAULT ? 1 : 0
            }]
            editWarehs({data: {lstdata: editdata}}).then(res=>{
                this.isEdit = false
                this.tableSearch()
            })
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>