<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form :searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" ref="table" :height="480" :isEdit="isEdit" :tablerule="rules" @selectedRows="handleselectedRows"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '仓库发生业务',
            //顶部筛选配置
            searchBox: [
                {
                    type: 'select_api', // 下拉选择
                    title: '客户',
                    dec: '输入客户名称',
                    option: [],
                    value: '',
                    action: 'cust/search',
                    optionTableWidth: 300,
                    optionTableRole: [
                        {
                            title: '编号',
                            field: 'cCUST_NO',
                            width: 80,
                            toInsert: 'cCUST_NO',
                        }, {
                            title: '客户名称',
                            field: 'cCUST_NM',
                            width: 150,
                        },
                    ],
                    showfield: 'cCUST_NM',
                    filtefield: 'cCUST_NM',
                    // 是否将部分字段作为全局筛选条件，如果是，则增加下方格式代码（全局筛选条件，将在表格中使用）
                    insert_default_field: {
                        'cCUST_NO': 'cCUST_NO', // '主表字段':'调用对象基础表字段'
                    },
                }, {
                    type: 'select_api', // 下拉选择
                    title: '货物类别',
                    option: [],
                    value: '',
                    action: '',   //接口
                    optionTableWidth: 300,
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx',
                            width: 50,
                        }, {
                            title: '存货类别编码',
                            field: 'cSTKMATCLS_NO',
                            width: 80,
                            toInsert: '',
                        }, {
                            title: '货物类别名称',
                            field: 'cSTKMATCLS_NM',
                            width: 150,
                        },
                    ],
                    showfield: 'cSTKMATCLS_NM',
                    filtefield: 'cSTKMATCLS_NM',
                    insert_default_field: {
                        '': '', // '主表字段':'调用对象基础表字段'
                    },
                }, {
                    type: 'text',
                    title: '货物型号',
                    dec: '请输入货物型号',
                    field: 'cSTKMATSPEC_NM',
                    value: '',
                }, {
                    type: 'text',
                    title: '货物编码',
                    dec: '请输入货物编码',
                    field: 'cCUSMAT_NO',
                    value: '',
                }, {
                    type: 'daterange',
                    title: '发生日期',
                    field: '',
                    icon: 'el-icon-date',
                    value: '',
                },{
                    type: 'select_api', //  
                    title: '仓储区域',
                    dec: '请输入仓储区域名称',
                    icon: 'el-icon-user-solid',
                    option: [],
                    value: '',
                    action: 'ware/waret/search', //接口未知
                    optionBoxWidth: 230,
                    optionTableRole: [{
                        title: '序号',
                        field: 'idx',
                        width: 80,
                        toInsert: 'idx',
                        stype: 'value',
                    }, {
                        title: '仓储片区',
                        field: 'cWARET_NM',
                        width: 150,
                        toInsert: 'cWARET_NM',
                        stype: 'label',
                    }, ],
                    showfield: 'cWARET_NM',
                    filtefield: 'cWARET_NM',
                    // 是否将部分字段作为全局筛选条件，如果是，则增加下方格式代码（全局筛选条件，将在表格中使用）
                }, {
                    type: 'select_api',
                    title: '仓库',
                    field: '',
                    option: [],
                    value: '',
                    action: '',
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx',
                            width: 50,

                        }, {
                            title: '仓库编码',
                            field: 'cWAREH_NO',
                            width: 80,
                            toInsert: '', //带出写入字段 (选中后,要将此字段的值作为筛选参数)
                        }, {
                            title: '仓库名称',
                            field: 'cWAREH_NM',
                            width: 150,
                        }
                    ],
                    showfield: 'cWAREH_NM', //展示字段
                    filtefield: 'cWAREH_NM', //筛选字段(用于选择的数据的某个字段)
                    //关联上级字段 (不会预加载,仅当点击此选项的输入框时,自动加载)
                    parentField: [
                        // ['cSUPLKIND_NO', 'cSUPLKIND_NO'], //['主表表字段','调用对象基础表字段']   
                    ],
                }, {
                    type: 'select_api',
                    title: '仓位',
                    field: '',
                    option: [],
                    value: '',
                    action: '',
                    optionTableWidth:400,
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx',
                            width: 50,

                        }, {
                            title: '仓位编码',
                            field: 'cWHPOS_NO',
                            width: 80,
                            toInsert: '', //带出写入字段 (选中后,要将此字段的值作为筛选参数)
                        }, {
                            title: '仓位名称',
                            field: 'cWHPOS_NM',
                            width: 150,
                        }, {
                            title: '存满率',
                            field: '',
                            width: 80,
                        }, {
                            title: '物种数',
                            field: '',
                            width: 80,
                        }, {
                            title: '存货量',
                            field: '',
                            width: 80,
                        }, {
                            title: '主要库存货物',
                            field: '',
                            width: 150,
                        }
                    ],
                    showfield: '', //展示字段
                    filtefield: '', //筛选字段(用于选择的数据的某个字段)
                    //关联上级字段 (不会预加载,仅当点击此选项的输入框时,自动加载)
                    parentField: [
                        // ['cSUPLKIND_NO', 'cSUPLKIND_NO'], //['主表表字段','调用对象基础表字段']   
                    ],
                }, {
                    type: 'text',
                    title: '货物名称',
                    dec: '请输入货物名称',
                    field: 'cSTKMAT_NM',
                    value: '',
                }, {
                    type: 'select_api', //  
                    title: '货物状态',
                    width: 150,
                    option: [],
                    value: '',
                    action: '', //接口未知
                    optionBoxWidth: 230,
                    optionTableRole: [{
                        title: '序号',
                        field: 'idx',
                        width: 80,
                        toInsert: 'idx',
                        stype: 'value',
                    }, {
                        title: '货物状态',
                        field: 'cSTKSTATE_NM',
                        width: 150,
                        toInsert: 'cSTKSTATE_NM',
                        stype: 'label',
                    }, ],
                    showfield: 'cSTKSTATE_NM',
                    filtefield: 'cSTKSTATE_NM',
                    // 是否将部分字段作为全局筛选条件，如果是，则增加下方格式代码（全局筛选条件，将在表格中使用）
                }, {
                    type: 'text',
                    title: '批次',
                    dec: '请输入批次',
                    field: 'cBATCH_NO',
                    value: '',
                },
                // {
                //     type: 'select',
                //     title: '批次',
                //     field: '',
                //     fixed: false,
                //     width: 50,
                //     option: [{
                //         value: '0',
                //         label: '不分批次'
                //     }],
                //     value: '0',
                // },
            ],
            table: {
                searchPath: '',  //主表获取数据接口地址
                insertPath: '',     //主表保存数据接口地址
                //主表配置
                tableRole: [
                    {
                        title: '序号',
                        field: 'idx',
                        fixed: true,
                        width: 50,
                        noedit: true,
                        defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                    }, {
                        type: 'select_api',
                        title: '仓位',
                        field: '',
                        fixed: false,
                        width: 100,
                        option: [],
                        value: '',
                        action: '',
                        optionTableWidth: 200,
                        optionTableRole: [
                            {
                                title: '仓位编码',
                                field: 'cWHPOS_NO',
                                width: 80,
                                toInsert: '',
                                stype: 'value'
                            }, {
                                title: '仓位名称',
                                field: 'cWHPOS_NM',
                                width: 100,
                                toInsert: '',
                                stype: 'label'
                            },
                        ],
                        showfield: 'cWHPOS_NM',
                        filtefield: 'cWHPOS_NM',
                        hasCheck: true,
                        default_prama: [
                            ['', ''],  //['主表字段','调用对象基础表的字段']
                        ],
                    }, {
                        type: 'text',
                        title: '客户货物编码',
                        width: 150,
                        field: 'cSTKMAT_NO',
                        value: '',
                    }, {
                        type: 'text',
                        title: '货物名称',
                        width: 200,
                        field: 'cSTKMAT_NM',
                        value: '',
                    }, {
                        type: 'text',
                        title: '型号',
                        width: 150,
                        field: 'cSTKMATSPEC_NM',
                        value: '',
                    }, {
                        type: 'select_api',
                        title: '类别',
                        field: '',
                        fixed: false,
                        width: 150,
                        option: [],
                        value: '',
                        action: '',
                        optionTableWidth: 200,
                        optionTableRole: [
                            {
                                title: '货物类别编码',
                                field: 'cSTKMATCLS_NO',
                                width: 100,
                                toInsert: '',
                                stype: 'value'
                            }, {
                                title: '货物类别名称',
                                field: 'cSTKMATCLS_NM',
                                width: 100,
                                toInsert: '',
                                stype: 'label'
                            },
                        ],
                        showfield: 'cSTKMATCLS_NM',
                        filtefield: 'cSTKMATCLS_NM',
                        hasCheck: true,
                        default_prama: [
                            ['', ''],  //['主表字段','调用对象基础表的字段']
                        ],
                    }, {
                        type: 'text',
                        title: '批次',
                        field: 'cBATCH_NO',
                        value: '',
                    }, {
                        type: 'select_api',
                        title: '货物状态',
                        field: '',
                        fixed: false,
                        width: 100,
                        option: [],
                        value: '',
                        action: '',
                        optionTableWidth: 200,
                        optionTableRole: [
                            {
                                title: '货物状态编码',
                                field: 'cSTKSTATE_N',
                                width: 90,
                                toInsert: '',
                                stype: 'value'
                            }, {
                                title: '货物状态',
                                field: 'cSTKSTATE_NM',
                                width: 100,
                                toInsert: '',
                                stype: 'label'
                            },
                        ],
                        showfield: 'cSTKSTATE_NM',
                        filtefield: 'cSTKSTATE_NM',
                        hasCheck: true,
                        default_prama: [
                            ['', ''],  //['主表字段','调用对象基础表的字段']
                        ],
                    }, {
                        type: 'number',
                        title: '入库数量',
                        field: 'nINSTK_QTY',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '出库数量',
                        field: 'nOUTSTK_QTY',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '剔除数量',
                        field: 'nSCRAP_QTY',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '调整数量',
                        field: 'nADJSTK_QTY',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '移库数量',
                        field: 'nMOVSTK_QTY',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '调状态数量',
                        field: 'nMODSTA_QTY',
                        value: '',
                        width: 90,
                    }, {
                        type: 'number',
                        title: '盘盈亏数量',
                        field: 'nOVERLOS_QTY',
                        value: '',
                        width: 90,
                    }, {
                        type: 'date',
                        title: '账款年月',
                        field: '',
                        fixed: false,
                        width: 120,
                        value: '',
                        defautvalue: '', //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM",
                        noedit: true,
                    }, {
                        type: 'select',
                        title: '业务类型',
                        field: 'cJOB_KIND',
                        fixed: false,
                        width: 110,
                        option: [{
                            value: '0',
                            label: '入库单'
                        }, {
                            value: '1',
                            label: '出库单'
                        }, {
                            value: '2',
                            label: '移库单'
                        }, {
                            value: '3',
                            label: '调整单'
                        }],
                        value: '',
                        // noedit: true,	
                    }, {
                        type: 'select_api', //此处也有可能是select类型，无法点击新增，修改，不知具体是什么类型，也有可能是text类型
                        title: '单据类别',
                        field: '',
                        fixed: false,
                        width: 100,
                        option: [],
                        value: '',
                        action: '',
                        optionTableWidth: 200,
                        optionTableRole: [
                            {
                                title: '单据类别编码',
                                field: '',
                                width: 100,
                                toInsert: '',
                                stype: 'value'
                            }, {
                                title: '单据类别名称',
                                field: '',
                                width: 100,
                                toInsert: '',
                                stype: 'label'
                            },
                        ],
                        showfield: '',
                        filtefield: '',
                        hasCheck: true,
                        default_prama: [
                            ['', ''],  //['主表字段','调用对象基础表的字段']
                        ],
                    }, {
                        type: 'text',
                        title: '系统单号',
                        width: 100,
                        field: '',
                        value: '',
                    }, {
                        type: 'date',
                        title: '业务发生日',
                        field: '',
                        fixed: false,
                        width: 150,
                        value: '',
                        defautvalue: '', //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd",
                        noedit: true,
                    },
                    {
                        type: 'date',
                        title: '资料产生时间',
                        field: '',
                        fixed: false,
                        width: 150,
                        value: '',
                        defautvalue: '', //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd HH-mm-ss",
                        noedit: true,
                    }, {
                        type: 'text',
                        title: '客户发货入库单号',
                        width: 300,
                        field: 'cSTKINCUS_NO',
                        value: '',
                    }, {
                        type: 'select_api',
                        title: '仓库',
                        field: 'cWAREH_NO',
                        dec: '请输入仓库',
                        width: 120,
                        option: [],
                        value: '',
                        action: 'ware/wareh/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        optionTableWidth: 250,
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'idx',
                                width: 60,
                            }, {
                                title: '仓库代码',
                                field: 'cWAREH_NO', 		//显示字段 (调用对象基础表的字段)
                                width: 120,
                                toInsert: 'cWAREH_NO', 	//带出写入字段 (选中后,要将此字段的值作为筛选参数)
                            }, {
                                title: '仓库名称',
                                field: 'cWAREH_NM',
                                width: 150,
                                toInsert: 'cWAREH_NM',
                            },
                        ],
                        showfield: 'cWAREH_NM',
                        filtefield: 'cWAREH_NM',
                        hasCheck: true,
                        default_prama: [
                            ['', ''],  //['主表字段','调用对象基础表的字段']
                        ],
                    }, {
                        type: 'text',
                        title: '备注',
                        width: 100,
                        field: 'cREMARK',
                        value: '',
                    }

                ],
                pagination_show: true,
                tableData: []
            },

        }
    },
    methods: {
        tableSearch(){
            
        },
        handleselectedRows(rows){
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data: {
                    idx: this.selectedRows.map(v=>v.idx)
                },    
            }; 
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                alert("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let data = this.tableData;
            let lstdata = [];
            if (!this.isAdd) {//编辑
            } else {//新增
            }
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>