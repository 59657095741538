<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form ref="form" :searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" ref="table" :height="550" @search="handlePaginationSearch" :isEdit="isEdit" :pageInfo="pageInfo" :tablerule="rules" @selectedRows="handleselectedRows"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
import {fetchgoods,editgoods,delgoods} from '@/api/transport/base'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '客户货物品类',
            searchBox: [
                {
                    type: 'text',
                    title: '货物品名名称：',
                    dec: '请输入货物品名',
                    field: 'cMATCLS_NM',
                    value: '',
                }, {
                    type: 'select',
                    title: '状态',
                    field: 'cSTOP_TAG',
                    dec: '',
                    option: [{
                        value: '0',
                        label: '使用',
                    }, {
                        value: '1',
                        label: '停用',
                    }, {
                        value: '2',
                        label: '全部',
                    }],
                    value: '2',
                    noedit:true,
                },
            ],
            table:{
                tableRole: [
                    {
                        title: '序号',
                        field: 'idx',
                        fixed: false,
                        width: 80,
                    },
                    {
                        type: 'text',
                        title: '货物品名名称',
                        field: 'cMATCLS_NM',
                        fixed: false,
                        width: 150,
                        value: '',
                        zjm:'BOPOMOFO',
                    }, {
                        type: 'text',
                        title: '助记码',
                        field: 'BOPOMOFO',
                        fixed: false,
                        width: 150,
                        value: '',

                    }, {
                        type: 'select_api',
                        title: '单位',
                        field: 'cUNIT_NM',
                        fixed: false,
                        width: 120,
                        sort: false,
                        option: [],
                        value: '',
                        optionBoxWidth: 320,
                        optionTableRole: [
                            {
                                title: '序号',			 //表头名称
                                field: 'idx', 	 //显示字段 (调用对象基础表的字段)
                                width: 80,				 //列宽
                                //  toInsert: '', //带出写入字段 (选中后,要将此字段的值作为筛选参数)

                            },
                            {
                                title: '单位名称',
                                field: 'cUNIT_NM',
                                width: 120,
                                toInsert: 'cUNIT_NM', //带出写入
                                stype: 'value',
                            }, {
                                title: '类别',
                                field: '',
                                width: 120,
                                toInsert: '', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cUNIT_NM',
                        filtefield: 'cUNIT_NM',
                        hasCheck: true,
                        action: 'info/unit/search',
                        // default_prama: [
                        //     ['cUNIT_NM', 'cUNIT_NM']
                        // ]
                    },
                    {
                        type: 'checkbox',
                        title: '可倒放',
                        field: 'cPUTDOWN_TAG',
                        fixed: false,
                        width: 80,
                        value: '',
                        truenumber: '1',
                        falsenumber: '0',
                    },
                    {
                        type: 'text',
                        title: '可叠层数',
                        field: 'iCASCADE_QTY',
                        fixed: false,
                        width: 100,
                        value: '',

                    }, {
                        type: 'select_api',
                        title: '所属客户',
                        field: 'cCUST_NO',
                        fixed: false,
                        width: 100,
                        option: [],
                        value: '',
                        action: 'cust/search',
                        optionTableRole: [
                            {
                                title: '客户编号',
                                field: 'cCUST_NO',
                                width: 150,
                                toInsert: 'cCUST_NO',
                            }, {
                                title: '客户名称',
                                field: 'cCUST_SNM',
                                width: 150,
                                toInsert: 'cCUST_SNM',
                            }
                        ],
                        showfield: 'cCUST_NO',
                        filtefield: 'cCUST_NO',
                        clearfield: ['cCUST_SNM'],
                    }, {
                        type: 'text',
                        title: '客户名称',
                        field: 'cCUST_SNM',
                        fixed: false,
                        width: 100,
                        value: '',

                    },
                    {
                        type: 'checkbox',
                        title: '缺省',
                        field: 'cDEFAULT',
                        width: 100,
                        fixed: false,
                        value: '',
                        truenumber: '1',
                        falsenumber: '0',
                    },
                    {
                        type: 'checkbox',
                        title: '停用',
                        field: 'cSTOP_TAG',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1',
                        falsenumber: '0',
                    },

                    {
                        type: 'text',
                        title: '备注',
                        field: 'cREMARK', //表格里面没有字段 cREMARK
                        fixed: false,
                        width: 150,
                        value: '',

                    },
                    {
                        type: 'text',
                        title: '存货地点编码',
                        field: 'cMATCLS_NO',
                        fixed: false,
                        width: 150,

                    },
                    {
                        type: 'text',
                        title: '维护人',
                        field: 'cUSER_NM',
                        value: '',
                        width: 150,
                        noedit: true,
                    }, {
                        type: 'date',  //类型 -> 日期
                        title: '维护时间',
                        field: 'tMODIFY_DT',
                        fixed: false,
                        width: 150,
                        value: '',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                        noedit: true,
                    }
                ],
                pagination_show: true,
                tableData: []
            }
        }
    },
    methods:{
        tableSearch(){
            let params = {
                ...this.$refs.form.searchdata,
                page_index: this.pageInfo.index,
                page_size: this.pageInfo.size
            }
            fetchgoods(params).then(res=>{
                this.table.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.pageInfo = res.data.page
            })
        },
        handleselectedRows(rows){
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data:{
                    idx: this.selectedRows.map(v=>v.idx)
                }
            };
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                delgoods(params).then(res=>{
                    this.$message.success('删除成功!')
                    this.tableSearch()
                })
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                this.$message.error("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let idx = this.isAdd ? 0 : this.editIdx
            let currentData = this.$refs.table.tableData[idx]
            let editdata = [{
                ...currentData,
                idx: !!currentData.idx ? currentData.idx: '',
                cSTOP_TAG: currentData.cSTOP_TAG ? 1: 0,
                cDEFAULT: currentData.cDEFAULT ? 1 : 0
            }]
            editgoods({data: {lstdata: editdata}}).then(res=>{
                this.isEdit = false
                this.tableSearch()
            })
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>