<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form :searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" ref="table" :height="450" :isEdit="isEdit" :tablerule="rules" @selectedRows="handleselectedRows"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '仓位存货查询',
            //顶部筛选配置
            searchBox: [
                {
                    type: 'select_api', // 下拉选择
                    title: '客户',
                    dec: '输入客户名称',
                    option: [],
                    value: '',
                    action: 'cust/search',
                    optionTableWidth: 300,
                    optionTableRole: [
                        {
                            title: '编号',
                            field: 'cCUST_NO',
                            width: 80,
                            toInsert: 'cCUST_NO',
                        }, {
                            title: '客户名称',
                            field: 'cCUST_NM',
                            width: 150,
                        },
                    ],
                    showfield: 'cCUST_NM',
                    filtefield: 'cCUST_NM',
                    // 是否将部分字段作为全局筛选条件，如果是，则增加下方格式代码（全局筛选条件，将在表格中使用）
                    insert_default_field: {
                        'cCUST_NO': 'cCUST_NO', // '主表字段':'调用对象基础表字段'
                    },
                }, {
                    type: 'text',
                    title: '货物编码',
                    dec: '请输入货物编码',
                    field: 'cSTKMAT_NO',
                    value: '',
                }, {
                    type: 'text',
                    title: '批次',
                    dec: '请输入批次',
                    field: 'cBATCH_NO',
                    value: '',
                }, {
                    type: 'select_api', // 下拉选择
                    title: '货物状态',
                    option: [],
                    value: '',
                    action: 'stk/state/search',   //接口
                    optionTableWidth: 300,
                    optionTableRole: [
                        {
                            title: '编号',
                            field: 'idx',
                            width: 80,
                            toInsert: '',
                        }, {
                            title: '货物状态',
                            field: 'cSTKSTATE_NM',
                            width: 150,
                        },
                    ],
                    showfield: 'cSTKSTATE_NM',
                    filtefield: 'cSTKSTATE_NM',
                    insert_default_field: {
                        'cSTKSTATE_NM': 'cSTKSTATE_NM', // '主表字段':'调用对象基础表字段'
                    },
                }, {
                    type: 'select_api', // 下拉选择
                    title: '仓储区域',
                    option: [],
                    value: '',
                    action: 'ware/waret/search',   //接口
                    optionTableWidth: 300,
                    optionTableRole: [
                        {
                            title: '编号',
                            field: 'idx',
                            width: 80,
                            toInsert: '',
                        }, {
                            title: '仓储片区',
                            field: 'cWARET_NM',
                            width: 150,
                            toInsert: 'cWARET_NM',
                        },
                    ],
                    showfield: 'cWARET_NM',
                    filtefield: 'cWARET_NM',
                    // clearfield: ['cWAREH_NO', 'cWAREH_NO'],
                    //因为与下面的"仓库，仓位"关联 所以当前值变化后,要将关联的其他列的字段清空
                    // clearfield: ['cSUPPLIER_NO', 'cSUPPLIER_NM','',''], //为空时,需要 关联清空的字段 (主表字段)
                }, {
                    type: 'select_api',
                    title: '仓库',
                    field: '',
                    option: [],
                    value: '',
                    action: '',
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx',
                            width: 50,

                        }, {
                            title: '仓库编码',
                            field: 'cWAREH_NO',
                            width: 80,
                            toInsert: '', //带出写入字段 (选中后,要将此字段的值作为筛选参数)
                        }, {
                            title: '仓库名称',
                            field: 'cWAREH_NM',
                            width: 150,
                        }
                    ],
                    showfield: 'cWAREH_NM', //展示字段
                    filtefield: 'cWAREH_NM', //筛选字段(用于选择的数据的某个字段)
                    //关联上级字段 (不会预加载,仅当点击此选项的输入框时,自动加载)
                    parentField: [
                        // ['cSUPLKIND_NO', 'cSUPLKIND_NO'], //['主表表字段','调用对象基础表字段']   
                    ],
                }, {
                    type: 'select_api',
                    title: '仓位',
                    field: '',
                    option: [],
                    value: '',
                    action: '',
                    optionTableWidth:400,
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx',
                            width: 50,

                        }, {
                            title: '仓位编码',
                            field: 'cWHPOS_NO',
                            width: 80,
                            toInsert: '', //带出写入字段 (选中后,要将此字段的值作为筛选参数)
                        }, {
                            title: '仓位名称',
                            field: 'cWHPOS_NM',
                            width: 150,
                        }, {
                            title: '存满率',
                            field: '',
                            width: 80,
                        }, {
                            title: '物种数',
                            field: '',
                            width: 80,
                        }, {
                            title: '存货量',
                            field: '',
                            width: 80,
                        }, {
                            title: '主要库存货物',
                            field: '',
                            width: 150,
                        }
                    ],
                    showfield: '', //展示字段
                    filtefield: '', //筛选字段(用于选择的数据的某个字段)
                    //关联上级字段 (不会预加载,仅当点击此选项的输入框时,自动加载)
                    parentField: [
                        // ['cSUPLKIND_NO', 'cSUPLKIND_NO'], //['主表表字段','调用对象基础表字段']   
                    ],
                }, {
                    type: 'text',
                    title: '货物型号',
                    dec: '请输入货物型号',
                    field: '',
                    value: '',
                }, {
                    type: 'text',
                    title: '货物名称',
                    dec: '请输入货物名称',
                    field: 'cSTKMAT_NM',
                    value: '',
                }, {
                    type: 'daterange',
                    title: '进出仓日',
                    field: '',
                    icon: 'el-icon-date',
                    value: '',
                }, {
                    type: 'daterange',
                    title: '未出库日',
                    field: '',
                    icon: 'el-icon-date',
                    value: '',
                }, {
                    type: 'daterange',
                    title: '入库日',
                    field: '',
                    icon: 'el-icon-date',
                    value: '',
                }, {
                    type: 'select_api', // 下拉选择
                    title: '货物类别',
                    option: [],
                    value: '',
                    action: '',   //接口
                    optionTableWidth: 300,
                    optionTableRole: [
                        {
                            title: '序号',
                            field: 'idx',
                            width: 50,
                        }, {
                            title: '存货类别编码',
                            field: 'cSTKMATCLS_NO',
                            width: 80,
                            toInsert: '',
                        }, {
                            title: '货物类别名称',
                            field: 'cSTKMATCLS_NM',
                            width: 150,
                        },
                    ],
                    showfield: '',
                    filtefield: '',
                    insert_default_field: {
                        '': '', // '主表字段':'调用对象基础表字段'
                    },
                }, {
                    type: 'checkbox',
                    title: '显示有库存货物',
                    field: '',
                    value: [],
                    option: [{
                        value: 0,
                        label: ''
                    }],
                }
            ],
            table: {
                searchPath: '',  //主表获取数据接口地址
                insertPath: '',     //主表保存数据接口地址
                //主表配置
                tableRole: [
                    {
                        title: '序号',
                        field: 'idx',
                        fixed: true,
                        width: 50,
                        noedit: true,
                        defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                    }, {
                        type: 'checkbox',
                        title: '冻结拣货', //单选框未出现
                        field: 'cPIC_FREEZE',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                    }, {
                        type: 'select_api',
                        title: '仓位',
                        field: 'cWHPOS_NM',
                        fixed: false,
                        width: 100,
                        option: [],
                        value: '',
                        action: '',
                        optionTableWidth: 400,
                        optionTableRole: [
                            {
                                title: '仓位编码',
                                field: 'cWHPOS_NO',
                                width: 80,
                                toInsert: '',
                                stype: 'value'
                            }, {
                                title: '仓位名称',
                                field: 'cWHPOS_NM',
                                width: 100,
                                toInsert: '',
                                stype: 'label'
                            },
                        ],
                        showfield: 'cWHPOS_NM',
                        filtefield: 'cWHPOS_NM',
                        hasCheck: true,
                        default_prama: [
                            ['', ''],  //['主表字段','调用对象基础表的字段']
                        ],
                    }, {
                        type: 'text',
                        title: '货物编码',
                        width: 150,
                        field: 'cSTKMAT_NO',
                        value: '',
                    }, {
                        type: 'text',
                        title: '货物名称',
                        width: 200,
                        field: 'cSTKMAT_NM',
                        value: '',
                    }, {
                        type: 'select_api',
                        title: '类别',
                        // field: 'cSTKMATCLS_NM',
                        fixed: false,
                        width: 150,
                        option: [],
                        value: '',
                        action: '',
                        optionTableWidth: 200,
                        optionTableRole: [
                            {
                                title: '货物类别编码',
                                field: 'cSTKMATCLS_NO',
                                width: 100,
                                toInsert: '',
                                stype: 'value'
                            }, {
                                title: '货物类别名称',
                                field: 'cSTKMATCLS_NM',
                                width: 100,
                                toInsert: '',
                                stype: 'label'
                            },
                        ],
                        showfield: 'cSTKMATCLS_NM',
                        filtefield: 'cSTKMATCLS_NM',
                        hasCheck: true,
                        default_prama: [
                            ['', ''],  //['主表字段','调用对象基础表的字段']
                        ],
                    }, {
                        type: 'text',
                        title: '批次',
                        field: 'cBATCH_NO',
                        value: '',
                    }, {
                        type: 'text',
                        title: '包装编码',
                        width: 150,
                        field: 'cPARKING_NO',
                        value: '',
                    }, {
                        type: 'number',
                        title: '当前库存',
                        field: 'nSTK_QTY',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '当前件数',
                        field: '',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '当前零头数',
                        field: 'nODD_QTY',
                        value: '',
                        width: 90,
                    }, {
                        type: 'number',
                        title: '单件货数',
                        field: 'nUNIT_QTY',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '期初数量',
                        field: 'nUNIT_QTY',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '入库数量',
                        field: 'nINSTK_QTY',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '出库数量',
                        field: 'nOUTSTK_QTY',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '剔除数量',
                        field: 'nSCRAP_QTY',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '调整数量',
                        field: 'nADJSTK_QTY',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '移库数量',
                        field: 'nMOVSTK_QTY',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '调状态数量',
                        field: 'nMODSTA_QTY',
                        value: '',
                        width: 90,
                    }, {
                        type: 'number',
                        title: '拆出数量',
                        field: 'nUPKG_QTY',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '拆入数量',
                        field: 'nIPKG_QTY',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '盘盈亏数量',
                        field: 'nOVERLOS_QTY',
                        value: '',
                        width: 90,
                    }, {
                        type: 'number',
                        title: '盘盈亏件数',
                        field: 'nOVERLOSUNIT_QTY',
                        value: '',
                        width: 90,
                    }, {
                        type: 'number',
                        title: '盘盈亏零头数',
                        field: 'nOVERLOSODD_QTY',
                        value: '',
                        width: 100,
                    }, {
                        type: 'date',
                        title: '库存年月',
                        field: 'cFIN_MON',
                        fixed: false,
                        width: 120,
                        value: '',
                        defautvalue: '', //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM",
                        noedit: true,
                    }, {
                        type: 'date',
                        title: '先进先出日',
                        field: 'cFIFO_DATE',
                        fixed: false,
                        width: 120,
                        value: '',
                        defautvalue: '', //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd",
                        noedit: true,
                    }, {
                        type: 'number',
                        title: '已拣货数',
                        field: 'nPICKED_QTY',
                        value: '',
                        width: 80,
                    }, {
                        type: 'text',
                        title: '备注',
                        width: 100,
                        field: 'cREMARK',
                        value: '',
                    }, {
                        type: 'date',
                        title: '批次周转日期',
                        field: 'cBACT_INTO_DATE',
                        fixed: false,
                        width: 120,
                        value: '',
                        defautvalue: '', //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd",
                        noedit: true,
                    }, {
                        type: 'text',
                        title: '系统号',
                        width: 150,
                        field: '',
                        value: '',
                    }, {
                        type: 'select_api',
                        title: '仓库',
                        field: 'cWAREH_NO',
                        dec: '请输入仓库',
                        width: 120,
                        option: [],
                        value: '',
                        action: 'ware/wareh/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        optionTableWidth: 250,
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'idx',
                                width: 60,
                            }, {
                                title: '仓库代码',
                                field: 'cWAREH_NO', 		//显示字段 (调用对象基础表的字段)
                                width: 120,
                                toInsert: 'cWAREH_NO', 	//带出写入字段 (选中后,要将此字段的值作为筛选参数)
                            }, {
                                title: '仓库名称',
                                field: 'cWAREH_NM',
                                width: 150,
                                toInsert: 'cWAREH_NM',
                            },
                        ],
                        showfield: 'cWAREH_NM',
                        filtefield: 'cWAREH_NM',
                        hasCheck: true,
                        default_prama: [
                            ['', ''],  //['主表字段','调用对象基础表的字段']
                        ],
                    }, {
                        type: 'text',
                        title: '单体积',
                        field: 'nVOLUME',
                        value: '',
                        width: 80,
                    }, {
                        type: 'text',
                        title: '单重量',
                        field: 'nWEIGHT',
                        value: '',
                        width: 80,
                    }, {
                        type: 'text',
                        title: '存货总体积',
                        field: 'nTOTVOLUME',
                        value: '',
                        width: 90,
                    }, {
                        type: 'text',
                        title: '体积单位',
                        field: 'cVOLUNIT_NM',
                        value: '',
                        width: 80,
                    }, {
                        type: 'text',
                        title: '存货总重量',
                        field: 'nTOTWEIGHT',
                        value: '',
                        width: 90,
                    }, {
                        type: 'text',
                        title: '重量单位',
                        field: 'cWTUNIT_NM',
                        value: '',
                        width: 80,
                    },
                ],
                pagination_show: true,
                tableData: []
            },
        }
    },
    methods: {
        tableSearch(){
            
        },
        handleselectedRows(rows){
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data: {
                    idx: this.selectedRows.map(v=>v.idx)
                },    
            }; 
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                alert("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let data = this.tableData;
            let lstdata = [];
            if (!this.isAdd) {//编辑
            } else {//新增
            }
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>