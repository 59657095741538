<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form ref="form" :searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" ref="table" :pageInfo="pageInfo" :height="550" :isEdit="isEdit" :tablerule="rules" @selectedRows="handleselectedRows"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
import {fetchSuppliercars,editSuppliercars,delSuppliercars} from '@/api/transport/base'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '车辆资料管理',
            searchBox: [
                {
                    //基础设置
                    type: 'select_api', //类型 -> 输入框 + 弹出表格 
                    title: '车辆类别', 		//输入框标题
                    dec: '输入车辆类别', //输入框提示
                    icon: 'el-icon-user-solid', //小图标
                    option: [], //固定项
                    value: '', //固定项
                    action: 'vehicle/kind/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                    //弹出内表格布局规则
                    optionBoxWidth: 300,
                    optionTableRole: [
                        {
                            title: '序号', 	//表头
                            field: 'idx', 	//表格展示字段 (主表字段)

                            width: 80, 		//列宽

                        }, {
                            title: '车辆类别名称',
                            field: 'cVEHKIND_NM',
                            width: 150,
                            toInsert: 'cVEHKIND_NM',
                        },
                    ],
                    showfield: 'cVEHKIND_NM', 	//在内表格中显示的字段 (调用对象基础表的字段)
                    filtefield: 'cVEHKIND_NM', 	//筛选字段 当输入框的值改变时,用来跟显示字段比对筛选的字段(调用对象基础表字段)
                    //是否将部分字段作为全局筛选条件,如果是,则增加下方格式代码(此全局筛选条件,将在表格中被使用)
                    hasCheck: true,
                    // insert_deafault_field: {
                    //     'cVEHKIND_NO': 'cVEHKIND_NO', //主表字段':'调用对象基础表字段
                    // },
                }, {
                    type: 'text', 		//类型 -> 输入框
                    title: '司机',	//输入框标题
                    dec: '输入司机名称',	//输入框提示
                    field: 'cVEHMAN_NM', 	//对应筛选字段 (主表字段)
                    value: '',	//固定项
                }, {
                    type: 'text', 		//类型 -> 输入框
                    title: '车辆尺寸名称',	//输入框标题
                    dec: '输入车辆尺寸名称',	//输入框提示
                    field: 'cVEHSIZE_NM', 	//对应筛选字段 (主表字段)
                    value: '',	//固定项
                },
            ],
            table:{
                //主表配置
                tableRole: [
                    {
                        title: '序号', 	//表头
                        field: 'idx', 	//表格展示字段 (主表字段)
                        fixed: true, 	//是否浮动
                        width: 80, 		//列宽
                        noedit: false, 	//是否禁止编辑 值为false 则 禁止编辑, true 或 删除此项 则可编辑
                        defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                    }, {
                        type: 'text',
                        title: '车牌号码',
                        field: 'cVEHPLATE_NO',
                        fixed: false,
                        value: '',
                        width: 100,
                        defautvalue: '',
                    }, {
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '车辆类别',
                        field: 'cVEHKIND_NM',
                        fixed: false,
                        width: 200,
                        option: [],
                        value: '',
                        //弹出内表格设置
                        action: 'vehicle/kind/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        //弹出内表格布局规则
                        optionBoxWidth: 230,
                        optionTableRole: [
                            {
                                title: '序号',		//表头名称
                                field: 'idx',	//显示字段 (调用对象基础表的字段)
                                width: 80,			//列宽
                                //      toInsert: 'cVEHKIND_NO', //带出写入字段 (选中后,要将此字段的值赋值给当前列)
                                stype: 'value'		//作为未编辑状态时的显示内容的 对比值
                            }, {
                                title: '车辆类别名称',
                                field: 'cVEHKIND_NM',
                                width: 150,
                                toInsert: 'cVEHKIND_NM', //带出写入
                                stype: 'label' //作为未编辑状态时的显示内容的展示值
                            },
                        ],
                        showfield: 'cVEHKIND_NM', //展示字段
                        filtefield: 'cVEHKIND_NM', //筛选字段(用于选择的数据的某个字段)
                        hasCheck: true,
                        // default_prama: [
                        //     ['cVEHKIND_NO', 'cVEHKIND_NO'],  //['主表字段','调用对象基础表的字段']
                        // ],
                    }, {
                        title: '尺寸',
                        field: 'cVEHSIZE_NM',
                        fixed: false,
                        width: 200,
                        type: 'select_api',
                        option: [],
                        value: '',
                        //选择内表格布局规则
                        action: 'vehicle/size/search', //调用方法
                        maxNum: 200,
                        optionBoxWidth: 350,
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'idx',
                                width: 100,

                            }, {
                                title: '车辆尺寸',
                                field: 'cVEHSIZE_NM',
                                width: 150,
                                toInsert: 'cVEHSIZE_NM', //带出写入
                                stype: 'label'
                            },
                            {
                                title: '类别',
                                field: '',
                                width: 100,
                                toInsert: '', //带出写入
                                stype: 'value'
                            }
                        ],
                        showfield: 'cVEHSIZE_NM', //展示字段
                        filtefield: 'cVEHSIZE_NM', //筛选字段(用于选择的数据的某个字段)
                        // parentField: [
                        //     ['cVEHKIND_NM', 'cVEHKIND_NM'], //['数据字段','基础表字段']   关联上级字段 (不会预加载)
                        // ],
                    }, {
                        type: 'text',
                        title: '车辆描述',
                        field: 'cCAYTRUCK_NM', //无字段
                        fixed: false,
                        width: 150,
                        value: '',
                        //  defautvalue: '',
                    }, {
                        type: 'number', //类型 -> 数字
                        title: '长',
                        field: 'nLENGTH',
                        fixed: false,
                        width: 100,
                    }, {
                        type: 'number', //类型 -> 数字
                        title: '宽',
                        field: 'nWIDTH',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'number', //类型 -> 数字
                        title: '高',
                        field: 'nHEIGHT',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'number', //类型 -> 数字
                        title: '容积',
                        field: 'nVOLUME',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'number', //类型 -> 数字
                        title: '载重/吨',
                        field: 'nLOAD_QTY',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '产权别',
                        field: 'cSUPLKIND_NM',
                        width: 120,
                        option: [], //固定项
                        value: '',  //固定项supplier/kind/search
                        action: 'supplier/kind/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        optionTableRole: [
                            {
                                title: '编号',			 //表头名称
                                field: 'cSUPLKIND_NO', 	 //显示字段 (调用对象基础表的字段)
                                width: 150,				 //列宽
                                // toInsert: 'cSUPLKIND_NO', //带出写入字段 (选中后,要将此字段的值作为筛选参数)
                                stype: 'value',
                            }, {
                                title: '承运商分类',
                                field: 'cSUPLKIND_NM',
                                width: 150,
                                toInsert: 'cSUPLKIND_NM',
                                stype: 'label',
                            },
                        ],
                        showfield: 'cSUPLKIND_NM',
                        filtefield: 'cSUPLKIND_NM',
                        // hasCheck: true,
                        // default_prama: [
                        //     ['cSUPLKIND_NM', 'cSUPLKIND_NM']
                        // ],
                    }, {
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '车辆归属',
                        field: 'cSUPPLIER_SNM',
                        width: 120,
                        option: [], //固定项
                        value: '',  //固定项
                        action: 'supplier/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        optionTableRole: [
                            {
                                title: '序号',			 //表头名称
                                field: 'idx', 	 //显示字段 (调用对象基础表的字段)
                                width: 150,				 //列宽
                                //  toInsert: '', //带出写入字段 (选中后,要将此字段的值作为筛选参数)
                                stype: 'value',
                            }, {
                                title: '供应商',
                                field: 'cSUPPLIER_NM',
                                width: 150,
                                toInsert: 'cSUPPLIER_SNM',
                                stype: 'label',
                            },
                        ],
                        showfield: 'cSUPPLIER_SNM',
                        filtefield: 'cSUPPLIER_SNM',
                        hasCheck: true,
                        // default_prama: [
                        //     ['cSUPPLIER_NM', 'cSUPPLIER_NM']
                        // ],
                    },
                    {
                        type: 'text', //类型 -> 数字
                        title: '承运商代码',
                        field: 'cSUPPLIER_NO',
                        fixed: false,
                        width: 100,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text', //类型 -> 数字
                        title: '司机',
                        field: 'cVEHMAN_NM',
                        fixed: false,
                        width: 100,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text', //类型 -> 数字
                        title: '手机',
                        field: 'cMOBIL_TEL',
                        fixed: false,
                        width: 100,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'date',  //类型 -> 日期
                        title: '出厂日期',
                        field: 'cBUY_DATE',
                        fixed: false,
                        width: 150,
                        value: '',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                    }, {
                        type: 'text1',
                        title: '系统',
                        field: 'cSYS_TAG',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'checkbox', //类型 -> 单选
                        title: '缺省',
                        field: 'cDEFAULT',
                        fixed: false,
                        width: 80,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                    },
                    {
                        type: 'date',  //类型 -> 日期
                        title: '停用',
                        field: 'cSTOP_TAG',
                        //
                        fixed: false,
                        width: 80,
                        //
                        value: '',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                        noedit: true,

                    }, {
                        type: 'text',
                        title: '当前状态',
                        field: 'cVEH_STATUS',
                        fixed: false,
                        width: 120,
                        value: '',
                        defautvalue: '',
                    }, {
                        title: '备注',
                        field: 'cREMARK',
                        fixed: false,
                        width: 120,
                        type: 'text',
                        value: '',
                        defautvalue: '',
                    }, {
                        title: '车辆编码',
                        field: 'cCAYTRUCK_NO',
                        fixed: false,
                        width: 120,
                        type: 'text',
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'text',
                        title: '创建人',
                        field: 'cESTUSER_NM',
                        value: 'immobilization',
                        width: 150,
                        
                    }, {
                        type: 'date',  //类型 -> 日期
                        title: '创建时间',
                        field: 'tESTDATA_DT',
                        fixed: false,
                        width: 150,
                        value: 'immobilization',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                        
                    }, {
                        type: 'text',
                        title: '维护人',
                        field: 'cUSER_NM',
                        value: 'immobilization',
                        width: 150,
                        
                    }, {
                        type: 'date',  //类型 -> 日期
                        title: '维护时间',
                        field: 'tMODIFY_DT',
                        fixed: false,
                        width: 150,
                        value: 'immobilization',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                        
                    },
                ],
                pagination_show: true,
                tableData: []
            }
        }
    },
    methods:{
        tableSearch(){
            let params = {
                ...this.$refs.form.searchdata,
                page_index: this.pageInfo.index,
                page_size: this.pageInfo.size
            }
            fetchSuppliercars(params).then(res=>{
                this.table.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.pageInfo = res.data.page
            })
        },
        handleselectedRows(rows){
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data:{
                    idx: this.selectedRows.map(v=>v.idx)
                }
            };
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                delSuppliercars(params).then(res=>{
                    this.$message.success('删除成功!')
                    this.tableSearch()
                })
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                alert("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let idx = this.isAdd ? 0 : this.editIdx
            let currentData = this.$refs.table.tableData[idx]
            let editdata = [{
                ...currentData,
                idx: !!currentData.idx ? currentData.idx: '',
                cSTOP_TAG: currentData.cSTOP_TAG ? 1: 0,
                cDEFAULT: currentData.cDEFAULT ? 1 : 0
            }]
            editSuppliercars({data: {lstdata: editdata}}).then(res=>{
                this.isEdit = false
                this.tableSearch()
            })
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>
