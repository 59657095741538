<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form ref="form" :searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" :loading="loading" ref="table" :height="550" :pageInfo="pageInfo" :isEdit="isEdit" :tablerule="rules" @selectedRows="handleselectedRows"  @search="handlePaginationSearch"></mytable>
    </div>
</template>

<script>
    import _ from 'lodash'
    import transportMix from '@/mixins/transport'
    import {fetchModes,editModes,delModes} from '@/api/transport/base'
    export default {
        mixins: [transportMix],
        data() {
            return {
                appName: '运输方式设置',
                table: {
                    //主表配置
                    tableRole: [
                        {
                            title: '序号', 	//表头
                            field: 'idx', 	//表格展示字段 (主表字段)
                            fixed: true, 	//是否浮动
                            width: 80, 		//列宽
                        }, {
                            title: '运输方式编码',
                            field: 'cCAYTYPE_NO',
                            type: 'text',
                            fixed: false,
                        }, {
                            type: 'text',
                            title: '运输方式名称',
                            field: 'cCAYTYPE_NM',
                            fixed: false,
                            width: 130,
                            value: '',
                            defautvalue: '',
                            zjm:'BOPOMOFO',
                        }
                        , {
                            type: 'text',
                            title: '助记码',
                            field: 'BOPOMOFO',
                            fixed: false,
                            width: 130,
                            value: '',
                            defautvalue: '',
                        },
                        {
                            type: 'text',
                            title: '系统',
                            field: 'cSYS_TAG',
                            fixed: false,
                            width: 130,
                            value: '',
                            defautvalue: '自定义',
                        },
                        {
                            type: 'checkbox', //类型 -> 单选
                            title: '缺省',
                            field: 'cDEFAULT',
                            fixed: false,
                            width: 100,
                            value: '',
                        },
                         {
                            type: 'checkbox', //类型 -> 单选
                            title: '停用',
                            field: 'cSTOP_TAG',
                            fixed: false,
                            width: 100,
                            value: '',
                            insertfieldtime: 'tSTOP_DT', //选中后,带出日期到该行其他字段
                        }, {
                            type: 'date',  //类型 -> 日期
                            title: '停用时间',
                            field: 'tSTOP_DT',
                            fixed: false,
                            width: 150,
                            value: '',
                            noedit: true, 	//是否禁止编辑 值为false 则 禁止编辑, true 或 删除此项 则可编辑
                            format: "yyyy-MM-dd", //日期格式设置
                        }, {
                            type: 'text',
                            title: '备注',
                            field: 'cREMARK',
                            fixed: false,
                            width: 130,
                            value: '',
                            defautvalue: '',
                        }, {
                            type: 'text',
                            title: '创建人',
                            field: 'cESTUSER_NM',
                            value: 'immobilization',
                            defautvalue: '',
                            width: 150,
                        }, {
                            type: 'date',  //类型 -> 日期
                            title: '创建时间',
                            field: 'tESTDATA_DT',
                            fixed: false,
                            width: 150,
                            value: 'immobilization',
                            defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                            format: "yyyy-MM-dd", //日期格式设置
                        }, {
                            type: 'text',
                            title: '维护人',
                            field: 'cUSER_NM',
                            value: 'immobilization',
                            width: 150,
                        }, {
                            type: 'date',  //类型 -> 日期
                            title: '维护时间',
                            field: 'tMODIFY_DT',
                            fixed: false,
                            width: 150,
                            value: 'immobilization',
                            defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                            format: "yyyy-MM-dd", //日期格式设置
                        },
                    ],
                    tableData: [],
                    pagination_show: true
                },
                searchBox: [
                    {
                        type: 'text', 		//类型 -> 输入框
                        title: '运输方式',	//输入框标题
                        dec: '输入运输方式',	//输入框提示
                        field: 'cCAYTYPE_NM', 	//对应筛选字段 (主表字段)
                        value: '',	//固定项
                    },
                ]
            }
        },
        methods: {
            tableSearch(){
                let params = {
                    ...this.$refs.form.searchdata,
                    page_index: this.pageInfo.index,
                    page_size: this.pageInfo.size
                }
                this.loading = true
                fetchModes(params).then(res=>{
                    this.loading = false
                    this.table.tableData = res.data.data.map(v=>({
                        ...v,
                        cSTOP_TAG: parseInt(v.cSTOP_TAG) == 1,
                        cDEFAULT: parseInt(v.cDEFAULT) == 1,
                        isEdit: false
                    }))
                    this.pageInfo = res.data.page
                })
            },
            handleselectedRows(rows){
                this.selectedRows = rows
            },
            //新增数据
            addRow() {
                this.isEdit = true
                this.isAdd = true
                this.commonAdd(this.table)
            },
            //删除数据
            delRow() {
                let params = {
                    data: {
                        idx: this.selectedRows.map(v=>v.idx)
                    } 
                };
                if (this.selectedRows.length == 0) {
                    this.$message.error("未选择删除数据！");
                } else {
                    delModes(params).then(res=>{
                        this.$message.success('删除成功!')
                        this.tableSearch()
                    })
                }
            },
            //修改数据
            editRow() {
                if (this.selectedRows.length == 0) {
                    this.$message.error("请选择编辑行！");
                }else if(this.selectedRows.length == 1){
                    this.isEdit = true;
                    this.isAdd = false
                    this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                    this.table.tableData[this.editIdx].isEdit = true
                }else{
                    this.$message.error('一次只可以编辑一行数据！')
                }
            },
            //保存数据
            saveRow() {
                let idx = this.isAdd ? 0 : this.editIdx
                let currentData = this.$refs.table.tableData[idx]
                let editdata = [{
                    ...currentData,
                    idx: !!currentData.idx ? currentData.idx: '',
                    cSTOP_TAG: currentData.cSTOP_TAG ? 1: 0,
                    cDEFAULT: currentData.cDEFAULT ? 1 : 0
                }]
                editModes({data: {lstdata: editdata}}).then(res=>{
                    this.isEdit = false
                    this.tableSearch()
                })
            },
            cancel() {
                this.isEdit = false;
                if(this.isAdd){
                    this.table.tableData.shift();
                }else{
                    this.table.tableData[this.editIdx].isEdit = false
                }
                this.$refs.table.$refs.mytable.clearSelection();
            }
        }
    }
</script>