<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form ref="form" :searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" ref="table" :height="550" :pageInfo="pageInfo" :isEdit="isEdit" :tablerule="rules" @selectedRows="handleselectedRows"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
import {fetchVehicleSize,editVehicleSize,delVehicleSize} from '@/api/transport/base'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '车辆尺寸设置',
            searchBox: [
                {
                    //基础设置
                    type: 'select_api', //类型 -> 输入框 + 弹出表格 
                    title: '车辆类别', 		//输入框标题
                    dec: '输入车辆类别', //输入框提示
                    option: [], //固定项
                    value: '', //固定项
                    action: 'vehicle/kind/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                    optionBoxWidth: 300,
                    optionTableRole: [
                        {
                            title: '序号', 			//表头名称
                            field: 'idx', 		//显示字段 (调用对象基础表的字段)
                            width: 150,				//列宽
                            toInsert: 'idx', 	//带出写入字段 (选中后,要将此字段的值作为筛选参数)
                        }, {
                            title: '车辆类别名称',
                            field: 'cVEHKIND_NM',
                            width: 150,
                            toInsert: 'cVEHKIND_NM',
                        },
                    ],
                    showfield: 'cVEHKIND_NM', 	//在内表格中显示的字段 (调用对象基础表的字段)
                    filtefield: 'cVEHKIND_NM', 	//筛选字段 当输入框的值改变时,用来跟显示字段比对筛选的字段(调用对象基础表字段)
                    //是否将部分字段作为全局筛选条件,如果是,则增加下方格式代码(此全局筛选条件,将在表格中被使用)
                    insert_deafault_field: {
                        'cVEHKIND_NM': 'cVEHKIND_NM', //主表字段':'调用对象基础表字段
                    },
                    //
                },
                {
                    type: 'text', 		//类型 -> 输入框
                    title: '车辆尺寸名称',	//输入框标题
                    dec: '输入车辆尺寸名称',	//输入框提示
                    field: 'cVEHSIZE_NM', 	//对应筛选字段 (主表字段)
                    // icon: 'el-icon-user-solid',	//小图标
                    value: '',	//固定项
                },
            ],
            table: {
                //主表配置
                tableRole: [
                    {
                        title: '序号', 	//表头
                        field: 'idx', 	//表格展示字段 (主表字段)
                        fixed: true, 	//是否浮动
                        width: 80, 		//列宽
                        noedit: false, 	//是否禁止编辑 值为false 则 禁止编辑, true 或 删除此项 则可编辑
                        defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                    },
                    {
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '车辆类别',
                        field: 'cVEHKIND_NM',
                        fixed: false,
                        width: 150,
                        sort: false,
                        option: [],
                        value: '',
                        action: 'vehicle/kind/search',
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'idx',
                                width: 150,
                                stype: 'value',
                            }, {
                                title: '车辆类别名称',
                                field: 'cVEHKIND_NM',
                                width: 150,
                                toInsert: 'cVEHKIND_NM',
                                stype: 'label',
                            },
                        ],
                        showfield: 'cVEHKIND_NM',
                        filtefield: 'cVEHKIND_NM',
                        hasCheck: true,
                    },
                    {
                        type: 'text',
                        title: '车辆尺寸名称',
                        field: 'cVEHSIZE_NM',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                        zjm:'BOPOMOFO',
                    },
                    {
                        type: 'text', //类型 -> 数字
                        title: '车辆尺寸编码',
                        field: 'cVEHSIZE_NO',
                        fixed: false,
                        width: 100,
                        value: '', //日期型 默认值为 0.000
                        defautvalue: '',
                    },
                    {
                        type: 'text',
                        title: '助记码',
                        field: 'BOPOMOFO',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    },
                    {
                        type: 'number', //类型 -> 数字
                        title: '长',
                        field: 'nLENGTH',
                        fixed: false,
                        width: 100,
                        value: '.000', //日期型 默认值为 0.000
                    },
                    {
                        type: 'number', //类型 -> 数字
                        title: '宽',
                        field: 'nWIDTH',
                        fixed: false,
                        width: 100,
                        value: '.000', //日期型 默认值为 0.000
                    },
                    {
                        type: 'number', //类型 -> 数字
                        title: '高',
                        field: 'nHEIGHT',
                        fixed: false,
                        width: 100,
                        value: '.000', //日期型 默认值为 0.000
                    },
                    {
                        type: 'number', //类型 -> 数字
                        title: '容积',
                        field: 'nVOLUME',
                        fixed: false,
                        width: 100,
                        value: '.000', //日期型 默认值为 0.000
                    },
                    {
                        type: 'number', //类型 -> 数字
                        title: '载重/吨',
                        field: 'nLOAD_QTY',
                        fixed: false,
                        width: 100,
                        value: '.00', //日期型 默认值为 0.000
                    },
                    {
                        type: 'text',
                        title: '系统',
                        field: 'cSYS_TAG',
                        fixed: false,
                        width: 130,
                        value: '',
                    },
                    {
                        type: 'checkbox', //类型 -> 单选
                        title: '缺省',
                        field: 'cDEFAULT',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                        // insertfieldtime: 'tSTOP_DT', //选中后,带出日期到该行其他字段
                    },
                    {
                        type: 'checkbox', //类型 -> 单选
                        title: '停用',
                        field: 'cSTOP_TAG',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                        insertfieldtime: 'tSTOP_DT', //选中后,带出日期到该行其他字段
                    },
                    {
                        type: 'date',  //类型 -> 日期
                        title: '停用时间',
                        field: 'tSTOP_DT',
                        fixed: false,
                        width: 150,
                        value: '',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                    },
                    {
                        type: 'text',
                        title: '备注',
                        field: 'cREMARK',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    },
                    {
                        type: 'text',
                        title: '创建人',
                        field: 'cESTUSER_NM', //接口不知道
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                        noedit: true,
                    },
                    {
                        type: 'date',  //类型 -> 日期
                        title: '创建时间',
                        field: 'tESTDATA_DT',//接口不知道
                        fixed: false,
                        width: 150,
                        value: '',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                        noedit: true,
                    },
                    {
                        type: 'text',
                        title: '维护人',
                        field: 'cUSER_NM', //接口不知道
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                        noedit: true,
                    },
                    {
                        type: 'date',  //类型 -> 日期
                        title: '维护时间',
                        field: 'tMODIFY_DT',//接口不知道
                        fixed: false,
                        width: 150,
                        value: '',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置 
                        noedit: true,
                    }
                ],
                pagination_show: true,
                tableData: []
            }
        }
    },
    methods:{
        tableSearch(){
            let params = {
                ...this.$refs.form.searchdata,
                page_index: this.pageInfo.index,
                page_size: this.pageInfo.size
            }
            fetchVehicleSize(params).then(res=>{
                this.table.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.pageInfo = res.data.page
            })
        },
        handleselectedRows(rows){
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data:{
                    idx: this.selectedRows.map(v=>v.idx)
                }
            };
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                delVehicleSize(params).then(res=>{
                    this.$message.success('删除成功!')
                    this.tableSearch()
                })
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                this.$message.error("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let idx = this.isAdd ? 0 : this.editIdx
            let currentData = this.$refs.table.tableData[idx]
            let editdata = [{
                ...currentData,
                idx: !!currentData.idx ? currentData.idx: '',
                cSTOP_TAG: currentData.cSTOP_TAG ? 1: 0,
                cDEFAULT: currentData.cDEFAULT ? 1 : 0
            }]
            editVehicleSize({data: {lstdata: editdata}}).then(res=>{
                this.isEdit = false
                this.tableSearch()
            })
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>