<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form ref="form" :searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="toptable" ref="toptable" :height="200" :pageInfo="pageInfo" :isEdit="isEdit" :tablerule="rules" @selectedRows="handletopselectedRows"></mytable>
        <mytable :tablelet="bottomtable" ref="bottomtable" :pageInfo="bottomtable.pageInfo" :height="300" :isEdit="isEdit" :tablerule="rules" @selectedRows="handlebottomselectedRows"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
import {fetchSupplierpaper,editSupplierpaper,delSupplierpaper,fetchSuppliercars,editSuppliercars,delSuppliercars} from '@/api/transport/base'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '承运商简易资料',
            //顶部筛选配置
            searchBox: [
                {
                    type: 'select_api', //类型 -> 输入框 + 弹出表格 
                    title: '承运商类别',
                    icon: 'el-icon-menu',
                    option: [], //固定项
                    value: '',  //固定项
                    action: 'supplier/kind/search',
                    optionTableRole: [
                        {
                            title: '编号',			 //表头名称
                            field: 'cSUPLKIND_NO', 	 //显示字段 (调用对象基础表的字段)
                            width: 150,				 //列宽
                            toInsert: 'cSUPLKIND_NO', //带出写入字段 (选中后,要将此字段的值作为筛选参数)
                        }, {
                            title: '承运商分类',
                            field: 'cSUPLKIND_NM',
                            width: 150,
                        },
                    ],
                    showfield: 'cSUPLKIND_NM',
                    filtefield: 'cSUPLKIND_NM',
                    //因为与下面的"承运商"关联 所以当前值变化后,要将关联的其他列的字段清空
                    clearfield: ['cSUPPLIER_NO', 'cSUPPLIER_NM'], //为空时,需要 关联清空的字段 (主表字段)
                    //
                },  {
                    type: 'text',
                    title: '承运商名称',
                    field: 'cSUPPLIER_NM',
                    fixed: false,
                    width: 120,
                    value: '',
                },
            ],
            toptable:{
                //主表配置
                tableRole: [
                    {
                        title: '序号', 	//表头
                        field: 'idx', 	//表格展示字段 (主表字段)
                        fixed: true, 	//是否浮动
                        width: 80, 		//列宽
                        noedit: true, 	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                        defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                    }, {
                        type: 'text',
                        title: '承运编码',
                        field: 'cSUPPLIER_NO',
                        fixed: false,
                        width: 120,
                        value: '',
                    }, {
                        type: 'text',
                        title: '承运商名称',
                        field: 'cSUPPLIER_NM',
                        fixed: false,
                        width: 200,
                        value: '',
                    }, {
                        type: 'text',
                        title: '简称',
                        field: 'cSUPPLIER_SNM',
                        fixed: false,
                        width: 120,
                        value: '',
                    }, {
                        type: 'select_api',
                        title: '类别',
                        field: 'cSUPLKIND_NM',
                        fixed:false,
                        width:120,
                        option: [], //固定项
                        value: '', //固定项
                        action:'supplier/kind/search',
                        optionTableRole: [
                            {
                                title: '序号', 	//表头
                                field: 'idx', 	//表格展示字段 (主表字段)
                                fixed: true, 	//是否浮动
                                width: 80, 		//列宽
                            },
                            {
                                title: '类别编码',
                                field: 'cSUPLKIND_NO',
                                width: 150,
                                stype:'value',

                            }, {
                                title: '类别名称',
                                field: 'cSUPLKIND_NM',
                                width: 150,
                                toInsert: 'cSUPLKIND_NM',
                                stype:'label',
                            }
                        ],
                        showfield: 'cSUPLKIND_NM', //展示字段
                        filtefield: 'cSUPLKIND_NM', //筛选字段(用于选择的数据的某个字段)
                        //关联上级字段 (不会预加载,仅当点击此选项的输入框时,自动加载)
                        // parentField: [
                        //     ['', ''], //['主表表字段','调用对象基础表字段']   
                        // ],
                    }, {
                        type: 'text',
                        title: '联系人',
                        field: 'cLINKMAN',
                        fixed: false,
                        width: 120,
                        value: '',
                    }, {
                        type: 'text',
                        title: '联系电话',
                        field: 'cTEL_NO',
                        fixed: false,
                        width: 120,
                        value: '',
                    }, {
                        type: 'text',
                        title: '手机',
                        field: 'cMOBIL_TEL',
                        fixed: false,
                        width: 120,
                        value: '',
                    }, {
                        type: 'text',
                        title: '传真电话',
                        field: 'cFAX_NO',
                        fixed: false,
                        width: 120,
                        value: '',
                    }, {
                        type: 'text',
                        title: '备注',
                        field: 'cREMARK',
                        fixed: false,
                        width: 120,
                        value: '',
                    }, {
                        type: 'select_api',
                        title: '省份',
                        field: 'cPROV_NM',
                        fixed: false,
                        width: 150,
                        option: [],
                        value: '',
                        action: 'info/prov/search',
                        optionTableRole: [
                            {
                                title: '省份代码',
                                field: 'cPROV_NO',
                                width: 150,
                                toInsert: 'cPROV_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '省份名称',
                                field: 'cPROV_NM',
                                width: 150,
                                toInsert: 'cPROV_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cPROV_NM', //展示字段
                        filtefield: 'cPROV_NM', //筛选字段(用于选择的数据的某个字段)
                        // default_prama: [
                        //     ['cSTPROV_NM', 'cPROV_NM']
                        // ],
                        clearfield: ['cCITY_NO', 'cCITY_NM'], //为空时,需要 关联清空的字段 

                    }, {
                        title: '所在城市',
                        field: 'cCITY_NM',
                        fixed: false,
                        width: 150,
                        type: 'select_api',
                        option: [],
                        value: '',
                        optionTableRole: [
                            {
                                title: '城市代码',
                                field: 'cCITY_NO',
                                width: 150,
                                toInsert: 'cCITY_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '城市名称',
                                field: 'cCITY_NM',
                                width: 150,
                                toInsert: 'cCITY_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cCITY_NM', //展示字段
                        filtefield: 'cCITY_NM', //筛选字段(用于选择的数据的某个字段)
                        action: 'info/city/search', //调用方法
                        parentField: [
                            ['cPROV_NM', 'cPROV_NM'], //['数据字段','基础表字段']   关联上级字段 (不会预加载)
                        ],

                    }, {
                        type: 'text',
                        title: '地址',
                        field: 'cADDRESS',
                        fixed: false,
                        width: 150,
                        value: '',
                    }, {
                        type: 'text',
                        title: 'QQ号码',
                        field: 'cQQ_NO',
                        fixed: false,
                        width: 150,
                        value: '',
                    }, {
                        type: 'checkbox', //类型 -> 单选
                        title: '停用',
                        field: 'cSTOP_TAG',  //表格里面没有字段
                        //
                        fixed: false,
                        width: 100,
                        //
                        value: '',
                        truenumber: '1',
                        falsenumber: '0',
                        insertfieldtime: '',
                    }, {
                        type: 'text',
                        title: '电子邮箱',
                        field: 'E_mail',
                        fixed: false,
                        width: 120,
                        value: '',
                    }, {
                        type: 'text',
                        title: '创建人',
                        field: 'cESTUSER_NM', // 字段未有
                        value: 'immobilization',
                        width: 150,
                        //  noedit:true,
                    }, {
                        type: 'date',  //类型 -> 日期
                        title: '创建时间',
                        field: 'tESTDATA_DT',// 字段未有
                        fixed: false,
                        width: 150,
                        value: 'immobilization',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                    //    noedit:true,
                    }, {
                        type: 'text',
                        title: '维护人',
                        field: 'cUSER_NM', // 字段未有
                        value: 'immobilization',
                        width: 150,
                        // noedit:true,
                    }, {
                        type: 'date',  //类型 -> 日期
                        title: '维护时间',
                        field: 'tMODIFY_DT',// 字段未有
                        fixed: false,
                        width: 150,
                        value: 'immobilization',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                    //    noedit:true,
                    }, {
                        type: 'text',
                        title: 'BoPolkmofo',
                        field: 'cESTUSER_NM', // 字段未有
                        value: '',
                        width: 150,
                    }
                ],
                pagination_show: true,
                tableData: []
            },
            bottomtable:{
                //顶部筛选配置
                tableRole: [
                    {
                        title: '序号', 	//表头
                        field: 'idx', 	//表格展示字段 (主表字段)
                        fixed: true, 	//是否浮动
                        width: 80, 		//列宽
                        noedit: true, 	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                        defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                    }, {
                        type: 'text',
                        title: '车牌号码',
                        field: 'cVEHPLATE_NO',
                        fixed: false,
                        width: 120,
                        value: '',
                    }, {
                        type: 'select_api',
                        title: '车辆类别',
                        field: 'cVEHKIND_NM',
                        option: [], //固定项
                        value: '', //固定项
                        action: 'vehicle/kind/search',
                        optionTableRole: [
                            {
                                title: '序号', 	//表头
                                field: 'idx', 	//表格展示字段 (主表字段)
                                fixed: true, 	//是否浮动
                                width: 80, 		//列宽
                                stype:'value',
                            },
                            {
                                title: '类别名称',
                                field: 'cVEHKIND_NM',
                                width: 150,
                                toInsert: 'cVEHKIND_NM',
                                stype: 'label',


                            },
                        ],
                        showfield: 'cVEHKIND_NM', //展示字段
                        filtefield: 'cVEHKIND_NM', //筛选字段(用于选择的数据的某个字段)
                        //关联上级字段 (不会预加载,仅当点击此选项的输入框时,自动加载)
                        // parentField: [
                        //     ['cSUPLKIND_NM', 'cSUPLKIND_NM'], //['主表表字段','调用对象基础表字段']   
                        // ],
                    }, {
                        type: 'select_api',
                        title: '尺寸',
                        field: 'cVEHSIZE_NM',
                        width:150,
                        option: [], //固定项
                        value: '', //固定项
                        action: 'vehicle/size/search',
                        optionTableRole: [
                            {
                                title: '序号', 	//表头
                                field: 'idx', 	//表格展示字段 (主表字段)
                                fixed: true, 	//是否浮动
                                width: 80, 		//列宽
                            },
                            {
                                title: '车辆尺寸',
                                field: 'cVEHSIZE_NM',
                                width: 120,
                                toInsert: 'cVEHSIZE_NM',
                                stype: 'value',
                            },
                            {
                                title: '类别',
                                field: 'cVEHKIND_NM',
                                width: 120,
                                toInsert: 'cVEHKIND_NM',
                                stype: 'label',
                            },
                        ],
                        showfield: 'cVEHSIZE_NM', //展示字段
                        filtefield: 'cVEHSIZE_NM', //筛选字段(用于选择的数据的某个字段)
                        //关联上级字段 (不会预加载,仅当点击此选项的输入框时,自动加载)
                        // parentField: [
                        //     ['', ''], //['主表表字段','调用对象基础表字段']   
                        // ],
                    }, {
                        type: 'text',
                        title: '车辆描述',
                        field: 'cCAYTRUCK_NM',
                        fixed: false,
                        width: 120,
                        value: '',
                    }, {
                        type: 'number',
                        title: '长',
                        field: 'nLENGTH',
                        fixed: false,
                        width: 80,
                        value: '',
                    }, {
                        type: 'number',
                        title: '宽',
                        field: 'nWIDTH',
                        fixed: false,
                        width: 80,
                        value: '',
                    }, {
                        type: 'number',
                        title: '高',
                        field: 'nHEIGHT',
                        fixed: false,
                        width: 80,
                        value: '',
                    }, {
                        type: 'number',
                        title: '容积',
                        field: 'nVOLUME',
                        fixed: false,
                        width: 150,
                        value: '',
                    }, {
                        type: 'number',
                        title: '重载/吨',
                        field: 'nLOAD_QTY',
                        fixed: false,
                        width: 150,
                        value: '',
                    },  {
                        type: 'select_api', //类型 -> 输入框 + 弹出表格 
                        title: '产权别',
                        field: 'cSUPLKIND_NM',
                        width: 120,
                        option: [], //固定项
                        value: '',  //固定项supplier/kind/search
                        action: 'supplier/kind/search', //请求对象接口地址 -> 对应要调用的基础表接口文档中的地址(/db03/后的部分)
                        optionTableRole: [
                            {
                                title: '编号',			 //表头名称
                                field: 'cSUPLKIND_NO', 	 //显示字段 (调用对象基础表的字段)
                                width: 150,				 //列宽
                                // toInsert: 'cSUPLKIND_NO', //带出写入字段 (选中后,要将此字段的值作为筛选参数)
                                stype: 'value',
                            }, {
                                title: '承运商分类',
                                field: 'cSUPLKIND_NM',
                                width: 150,
                                toInsert: 'cSUPLKIND_NM',
                                stype: 'label',
                            },
                        ],
                        showfield: 'cSUPLKIND_NM',
                        filtefield: 'cSUPLKIND_NM',
                        // hasCheck: true,
                        // default_prama: [
                        //     ['cSUPLKIND_NM', 'cSUPLKIND_NM']
                        // ],
                    }, {
                        type: 'text',
                        title: '承运商编码',
                        field: 'cSUPPLIER_NO',
                        fixed: false,
                        width: 120,
                        value: '',
                    }, {
                        type: 'text',
                        title: '司机',
                        field: 'cVEHMAN_NM',
                        fixed: false,
                        width: 120,
                        value: '',
                    }, {
                        type: 'text',
                        title: '手机',
                        field: 'cMOBIL_TEL',
                        fixed: false,
                        width: 120,
                        value: '',
                    }, {
                        type: 'data',
                        title: '出厂日期',
                        field: 'cBUY_DATE',
                        fixed: false,
                        width: 120,
                        value: '',
                    },{
                        type: 'text1',
                        title: '系统',
                        field: 'cSYS_TAG',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '',
                    }, {
                        type: 'checkbox', //类型 -> 单选
                        title: '缺省',
                        field: 'cDEFAULT',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                        // insertfieldtime: 'tSTOP_DT', //选中后,带出日期到该行其他字段
                    }, {
                        type: 'checkbox', //类型 -> 单选
                        title: '停用',
                        field: 'cSTOP_TAG',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                        // insertfieldtime: 'tSTOP_DT', //选中后,带出日期到该行其他字段
                    }, {
                        type: 'text',
                        title: '当前状态',
                        field: 'cVEH_STATUS',
                        fixed: false,
                        width: 120,
                        value: '',
                    }, {
                        type: 'text',
                        title: '备注',
                        field: 'cREMARK',
                        fixed: false,
                        width: 120,
                        value: '',
                    }, {
                        type: 'text',
                        title: '车辆编码',
                        field: 'cCAYTRUCK_NO',
                        fixed: false,
                        width: 120,
                        value: '',
                    }, {
                        type: 'text',
                        title: '创建人',
                        field: 'cESTUSER_NM', // 字段未有
                        value: 'immobilization',
                        width: 120,
                        //   noedit: true,
                    }, {
                        type: 'date',  //类型 -> 日期
                        title: '创建时间',
                        field: 'tESTDATA_DT',// 字段未有
                        fixed: false,
                        width: 150,
                        value: 'immobilization',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                        //noedit: true,
                    }, {
                        type: 'text',
                        title: '维护人',
                        field: 'cUSER_NM', // 字段未有
                        value: 'immobilization',
                        width: 120,
                        //  noedit: true,
                    }, {
                        type: 'date',  //类型 -> 日期
                        title: '维护时间',
                        field: 'tMODIFY_DT',// 字段未有
                        fixed: false,
                        width: 150,
                        value: 'immobilization',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                        // noedit: true,
                    },
                ],
                pagination_show: true,
                tableData: [],
                pageInfo: {
                    index: 0,
                    page: 1,
                    total: 0
                }
            }
        }
    },
    methods:{
        tableSearch(){
            let params = {
                ...this.$refs.form.searchdata,
                page_index: this.pageInfo.index,
                page_size: this.pageInfo.size
            }
            fetchSupplierpaper(params).then(res=>{
                this.toptable.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.pageInfo = res.data.page
            })
            this.getCars()
        },
        getCars(){
            fetchSuppliercars().then(res=>{
                this.bottomtable.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.bottomtable.pageInfo = res.data.page
            })
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            this.commonAdd(this.toptable)
            this.commonAdd(this.bottomtable)
        },
        //删除数据
        delRow() {
            let params = {
                data: {
                    idx: this.selectedRows.map(v=>v.idx)
                } 
            };
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else { 
                delSuppliercars(params).then(res=>{
                    this.isEdit = false
                    this.tableSearch()
                })
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                this.$message.error("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.toptable.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.toptable.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let data = this.tableData;
            let lstdata = [];
            if (!this.isAdd) {//编辑
            } else {//新增
            }
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.toptable.tableData.shift();
            }else{
                this.toptable.tableData[this.editIdx].isEdit = false
            }
            this.$refs.toptable.$refs.mytable.clearSelection();
        },
        handletopselectedRows(rows){
            console.log(rows)
        },
        handlebottomselectedRows(rows){
            console.log(rows)
        } 
    }
}
</script>