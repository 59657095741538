<template>
    <div class="top border ">
        <div class="f-14 col-main f-b">{{appname}}</div>
        <div class="dis-flex flex-x-end flex-y-center">
            <el-button :disabled="isedit" type="warning"  @click="addRow"  icon="el-icon-circle-plus-outline" size="mini" >新增</el-button>
            <el-button :disabled="isedit" type="primary" @click="editRow" icon="el-icon-edit" size="mini">编辑</el-button>
            <el-button :disabled="isedit" type="danger" @click="delRow" icon="el-icon-delete" size="mini">删除</el-button>
            <el-button :disabled="!isedit" type="info" @click="cancel" icon="el-icon-circle-close" size="mini">取消</el-button>
            <el-button :disabled="!isedit" type="success" @click="saveRow" icon="el-icon-circle-check" size="mini">保存</el-button>
        </div>
    </div>
</template>
<script>
export default{
    props: {
        isedit:{
            type: Boolean,
            default: false
        },
        appname:{
            type: String,
            default: ''
        },
    },
    methods: {
        //增
        addRow(){
            this.$parent.addRow();  
        },
        //删
        delRow(){
            this.$parent.delRow();  
        },
        //改
        editRow(){
            this.$parent.editRow();  
        },
        //保存
        saveRow(){
            this.$parent.saveRow();  
        },
        cancel(){
            this.$parent.cancel();  
        }
    }
}
</script>
<style scoped>
    .top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }
    .f-b{
        font-weight: bold;
    }
</style>
