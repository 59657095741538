<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form :searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" ref="table" :height="300" :isEdit="isEdit" :tablerule="rules" @selectedRows="handleselectedRows"></mytable>
        <el-row :gutter="10">
            <el-col :span="6">
                <mytable :tablelet="cost" ref="cost" :height="400"></mytable>
            </el-col>
            <el-col :span="4">
                <el-tree :data="treeData" :props="defaultProps" @node-click="handleNodeClick">
                </el-tree>
            </el-col>
            <el-col :span="14">
                <mytable :tablelet="quote" ref="quote" :height="200"></mytable>
                <mytable :tablelet="kpi" ref="kpi" :height="200"></mytable>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
// import Tree from '@/components/common/tree'
export default{
    mixins: [transportMix],
    // components: {Tree},
    data(){
        return {
            appName: '车辆紧急调度',
            searchBox: [
                {
                    title: '客户',
                    dec: '输入客户名称',
                    field: 'cCUST_SNM',
                    icon: 'el-icon-user-solid',
                    type: 'select_api',
                    option:[],
                    value: '',
                    action: 'cust/search', //调用方法
                    //选择内表格布局规则
                    optionTableRole: [
                        {
                            title: '编号',
                            field: 'cCUST_NO',
                            width: 150,
                            toInsert:'cCUST_NO', //带出写入
                        },{
                            title: '客户名称',
                            field: 'cCUST_NM',
                            width: 150,
                        },
                    ], 
                    showfield:'cCUST_NM', //展示字段
                    filtefield:'cCUST_NM', //筛选字段(用于选择的数据的某个字段)
                    parentField:false,
                    hasCheck:true, //是否检查所输入内容为在选择列表中的项
                    insert_deafault_field:{
                        'cCUST_NO':'cCUST_NO', //数据字段','基础表字段
                    },
                },
                {
                    type: 'text',
                    title: '叫车人员',
                    dec: '输入叫车人员',
                    field: 'cEMP_NO',
                    icon: 'el-icon-user-solid',
                    value: '',
                },{
                    type: 'date',
                    title: '年月',
                    dec: '输入年月',
                    field: 'cCAYING_DATE',
                    icon: 'el-icon-user-solid',
                    value: '',
                    format: 'yyyy-MM/dd',
                },{
                    type: 'text',
                    title: '现场手机',
                    dec: '输入现场手机号码',
                    field: 'cEMPMOBIL_TEL',
                    icon: 'el-icon-user-solid',
                    value: '',
                },{
                    type: 'checkbox',
                    title: '结算类别',
                    field: 'cCAY_STATUS',
                    icon: 'el-icon-message-solid',
                    value: [],
                    option: [{
                        value: 0,
                        label: '未结算'
                    }, {
                        value: 1,
                        label: '已结算'
                    }, {
                        value: 3,
                        label: '结算完'
                    }],
                },{
                    type: 'checkbox',
                    title: '状态',
                    field: 'orderstate',
                    icon: 'el-icon-message-solid',
                    value: [],
                    option: [{
                        value: 0,
                        label: '待调度'
                    }, {
                        value: 1,
                        label: '已调度'
                    }, {
                        value: 3,
                        label: '已关联'
                    }],
                },{
                    type: 'daterange', //时间范围
                    title: '调车时间',
                    field: 'cCAY_STATUS',
                    icon: 'el-icon-date',
                    value: '',
                },{
                    title: '承运别',
                    field: 'cSUPLKIND_NO',
                    icon: 'el-icon-menu',
                    //
                    fixed: false,
                    width: 200,
                    sort: false,
                    //
                    type: 'select_api',
                    option: [],
                    value: '',
                    //选择内表格布局规则
                    optionTableRole: [
                        {
                            title: '编号',
                            field: 'cSUPLKIND_NO',
                            width: 150,
                            toInsert:'cSUPLKIND_NO', //带出写入
                        },{
                            title: '承运商分类',
                            field: 'cSUPLKIND_NM',
                            width: 150,
                        },
                    ], 
                    showfield:'cSUPLKIND_NM', //展示字段
                    filtefield:'cSUPLKIND_NM', //筛选字段(用于选择的数据的某个字段)
                    clearfield:['cSUPPLIER_NO','cSUPPLIER_NM'], //为空时,需要 关联清空的字段 
                    //
                    action: 'supplier/kind/search', //调用方法
                },{
                    title: '承运商',
                    field: 'cSUPPLIER_NM',
                    icon: 'el-icon-user-solid',
                    //
                    fixed: false,
                    width: 230,
                    sort: false,
                    //
                    type: 'select_api',
                    option: [],
                    value: '',
                    //选择内表格布局规则
                    optionTableRole: [
                        {
                            title: '编号',
                            field: 'cSUPPLIER_NO',
                            width: 150,
                            toInsert:'cSUPPLIER_NO', //带出写入
                        },{
                            title: '承运商',
                            field: 'cSUPPLIER_NM',
                            width: 150,
                        }
                    ], 
                    showfield:'cSUPPLIER_NM', //展示字段
                    filtefield:'cSUPPLIER_NM', //筛选字段(用于选择的数据的某个字段)
                    //``
                    action: 'supplier/search', //调用方法
                    parentField: [
                        ['cSUPLKIND_NO','cSUPLKIND_NO'], //['数据字段','基础表字段']   关联上级字段 (不会预加载)
                    ],     
                }
            ],
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            table: {
                searchPath:'order/cayuing/search',
                insertPath:'order/cayuing/update',
                selectChange: 'hasSelect', //选中项处理 父级方法
                // 调度车规则
                tableRole: [
                    {
                        title: 'ID',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    }, {
                        title: '订单状态',
                        field: 'orderstate',
                        //
                        fixed: false,
                        width: 110,
                        sort: false,
                        //
                        type: 'select',
                        option: [{
                            value: '0',
                            label: '未调度'
                        }, {
                            value: '1',
                            label: '已调度'
                        }, {
                            value: '2',
                            label: '已完成'
                        }],
                        value: '',
                        noedit:true,
                    },{
                        title: '叫车日期',
                        field: 'cCAYING_DATE',
                        //
                        fixed: false,
                        width: 150,
                        sort: false,
                        //
                        type: 'date',
                        value: '',
                        defautvalue:this.$getNowTime(true,true,true),
                        format:"yyyyMMdd",
                    },{
                        title: '账款年月',
                        field: 'cFIN_MON',
                        //
                        fixed: false,
                        width: 150,
                        sort: false,
                        //
                        type: 'text',
                        value: '',
                        defautvalue:this.$getNowTime(true,true,false),
                        format:"yyyyMMdd",
                    },{
                        title: '叫车人员',
                        field: 'cEMP_NO',
                        fixed: false,
                        width: 150,
                        sort: false,
                        //type: 'select_api',
                        type: 'text',
                        option: [],
                        value: '',
                        //选择内表格布局规则
                        optionTableRole: [
                            {
                                title: '编号',
                                field: 'cEMP_NO',
                                width: 150,
                                toInsert:'cEMP_NO', //带出写入
                                stype:'value' 
                            },{
                                title: '姓名',
                                field: 'cEMP_NM',
                                width: 150,
                                toInsert:'cEMP_NM', //带出写入
                                stype:'label'
                            },{
                                title: '手机',
                                field: 'cMOBILE_TEL',
                                width: 150,
                                toInsert:'cEMPMOBIL_TEL', //带出写入
                            },
                        ], 
                        showfield:'cEMP_NM', //展示字段
                        filtefield:'cEMP_NM', //筛选字段(用于选择的数据的某个字段)
                        hasCheck:true, //是否检查所输入内容为在选择列表中的项
                        //
                        action: 'info/emp/search', //调用方法
                        //
                        extra_prama: [['cDUTY_NO','=',1024]], //特别指定筛选测试
                        //
                    },{
                        title: '叫车手机',
                        field: 'cEMPMOBIL_TEL',
                        fixed: false,
                        width: 150,
                        sort: false,
                        type: 'text',
                        value: '',
                    },{
                        title: '客户',
                        field: 'cCUST_NO',
                        fixed: false,
                        width: 200,
                        sort: false,
                        type: 'text',
                        option: [],
                        value: '',
                        //选择内表格布局规则
                        optionTableRole: [
                            {
                                title: '编号',
                                field: 'cCUST_NO',
                                width: 150,
                                toInsert:'cCUST_NO', //带出写入
                                stype:'value'
                            },{
                                title: '客户名称',
                                field: 'cCUST_NM',
                                width: 150,
                                toInsert:'cCUST_NM', //带出写入
                                stype:'label'
                            },
                        ], 
                        showfield:'cCUST_NM', //展示字段
                        filtefield:'cCUST_NM', //筛选字段(用于选择的数据的某个字段)
                        hasCheck:true,
                        //
                        action: 'cust/search', //调用方法
                        default_prama:[['cCUST_NO','cCUST_NO']]
                        //
                    },{
                        title: '货物量', //件数
                        field: 'nMAT_QTY',
                        //
                        fixed: false,
                        width: 100,
                        sort: false,
                        //
                        type: 'number',
                        value: '0.000',
                    },{
                        title: '单位', //件数
                        field: 'cUNIT_NM',
                        //
                        fixed: false,
                        width: 70,
                        sort: false,
                        //
                        type: 'text',
                        value: '',
                        defautvalue: '吨',
                    },{
                        title: '起始地', //件数
                        field: 'cLADING_ADDR',
                        //
                        fixed: false,
                        width: 300,
                        sort: false,
                        //
                        //type: 'area',
                        type:'text',
                        option: [], //默认省级
                        areaoption:[], //区县级别表格
                        value: '',
                        //选择内表格布局规则
                        optionTableRole: [
                            {
                                title: '属地代码',
                                field: 'cAPANA_NO',
                                width: 70,
                                toInsert:'cSTAPANA_NO', //带出写入
                            },{
                                title: '属地简称',
                                field: 'cAPANA_SNM',
                                width: 200,
                                toInsert:'cSTAPANA_NM', //带出写入
                            },{
                                title: '属地名称',
                                field: 'cAPANA_NM',
                                width: 200,
                                toInsert:'cLADING_ADDR', //带出写入
                            },{
                                title: '省份',
                                field: 'cPROV_NO',
                                width: 50,
                                toInsert:'cSTPROV_NO', //带出写入
                            },{
                                title: '省份名称',
                                field: 'cPROV_NM',
                                width: 100,
                                toInsert:'cSTPROV_NM', //带出写入
                            },{
                                title: '城市',
                                field: 'cCITY_NO',
                                width: 70,
                                toInsert:'cSTCITY_NO', //带出写入
                            },{
                                title: '城市名称',
                                field: 'cCITY_NM',
                                width: 110,
                                toInsert:'cSTCITY_NM', //带出写入
                            },
                        ], 
                        showfield:'cLADING_ADDR', //展示字段
                        //
                        action: 'area', //调用方法
                        //
                    },{
                        title: '目的地',
                        field: 'cDESTINATION',
                        //
                        fixed: false,
                        width: 300,
                        sort: false,
                        //
                        type: 'area',
                        option: [], //默认省级
                        areaoption:[], //区县级别表格
                        value: '',
                        //选择内表格布局规则
                        optionTableRole: [
                            {
                                title: '属地代码',
                                field: 'cAPANA_NO',
                                width: 70,
                                toInsert:'cEDAPANA_NO', //带出写入
                            },{
                                title: '属地简称',
                                field: 'cAPANA_SNM',
                                width: 200,
                                toInsert:'cEDAPANA_NM', //带出写入
                            },{
                                title: '属地名称',
                                field: 'cAPANA_NM',
                                width: 200,
                                toInsert:'cDESTINATION', //带出写入
                            },{
                                title: '省份',
                                field: 'cPROV_NO',
                                width: 50,
                                toInsert:'cEDPROV_NO', //带出写入
                            },{
                                title: '省份名称',
                                field: 'cPROV_NM',
                                width: 100,
                                toInsert:'cEDPROV_NM', //带出写入
                            },{
                                title: '城市',
                                field: 'cCITY_NO',
                                width: 70,
                                toInsert:'cEDCITY_NO', //带出写入
                            },{
                                title: '城市名称',
                                field: 'cCITY_NM',
                                width: 110,
                                toInsert:'cEDCITY_NM', //带出写入
                            },
                        ], 
                        showfield:'cDESTINATION', //展示字段
                        //
                        action: 'area', //调用方法
                        //
                    },{
                        title: '运费总额', //件数
                        field: 'nMAIN_AMT',
                        noedit:true,
                        //
                        fixed: false,
                        width: 70,
                        sort: false,
                        //
                        type: 'number',
                        value: '',
                        defautvalue: 0,
                    },{
                        title: '计划预付', //件数
                        field: 'nPRE_AMT',
                        noedit:true,
                        //
                        fixed: false,
                        width: 70,
                        sort: false,
                        //
                        type: 'number',
                        value: '',
                        defautvalue: 0,
                    },{
                        title: '已付金额', //件数
                        field: 'nPAY_AMT',
                        noedit: true,
                        //
                        fixed: false,
                        width: 70,
                        sort: false,
                        //
                        type: 'number',
                        value: '',
                        defautvalue: 0,
                    },{
                        title: '车辆尺寸',
                        field: 'cVEHSIZE_NM',
                        //
                        fixed: false,
                        width: 200,
                        sort: false,
                        //
                        type: 'select_api',
                        option: [],
                        value: '',
                        //选择内表格布局规则
                        optionTableRole: [
                            {
                                title: '编号',
                                field: 'cVEHSIZE_NO',
                                width: 150,
                                toInsert:'cCUST_NO', //带出写入
                                stype:'value'
                            },{
                                title: '车辆尺寸',
                                field: 'cVEHSIZE_NM',
                                width: 150,
                                toInsert:'cVEHSIZE_NM', //带出写入
                                stype:'label',
                                toFunctionIncert:['getchangdu','cVEHLEN_NM'], //函数处理后带出写入  ['数据字段','方法','带出字段']
                            },
                        ], 
                        showfield:'cVEHSIZE_NM', //展示字段
                        filtefield:'cVEHSIZE_NM', //筛选字段(用于选择的数据的某个字段)
                        //
                        action: 'vehicle/size/search', //调用方法
                        //
                    },{
                        title: '车辆长度',
                        field: 'cVEHLEN_NM',
                        //
                        fixed: false,
                        width: 200,
                        sort: false,
                        //
                        type: 'select_api',
                        option: [],
                        value: '',
                        //选择内表格布局规则
                        optionTableRole: [
                            {
                                title: '编号',
                                field: 'cVEHLEN_NO',
                                width: 150,
                                toInsert:'cVEHLEN_NO', //带出写入
                                stype:'value'
                            },{
                                title: '车辆长度',
                                field: 'cVEHLEN_NM',
                                width: 150,
                                toInsert:'cVEHLEN_NM', //带出写入
                                stype:'label'
                            },
                        ], 
                        showfield:'cVEHLEN_NM', //展示字段
                        filtefield:'cVEHLEN_NM', //筛选字段(用于选择的数据的某个字段)
                        //
                        action: 'vehicle/len/search', //调用方法
                        //
                    },

                    {
                        title: '司机',
                        field: 'cVEHMAN_NM',
                        //
                        fixed: false,
                        width: 200,
                        sort: false,
                        //
                        type: 'select_api',
                        option: [],
                        value: '',
                        //选择内表格布局规则
                        action: 'supplier/cay/track/search', //调用方法
                        maxNum:200,
                        optionBoxWidth:600,
                        optionTableRole: [
                            {
                                title: '编号',
                                field: 'cCORP_NO',
                                width: 150,
                                stype:'value'
                            },{
                                title: '司机',
                                field: 'cVEHMAN_NM',
                                width: 80,
                                toInsert:'cVEHMAN_NM', //带出写入
                                stype:'label'
                            },{
                                title: '司机手机',
                                field: 'cMOBIL_TEL',
                                width: 100,
                                toInsert:'cMOBIL_TEL', //带出写入
                            },{
                                title: '车牌号码',
                                field: 'cVEHPLATE_NO',
                                width: 70,
                                toInsert:'cVEHPLATE_NO', //带出写入
                            }
                        ], 
                        showfield:'cVEHMAN_NM', //展示字段
                        filtefield:'cVEHMAN_NM', //筛选字段(用于选择的数据的某个字段)
                    },{
                        title: '车牌号码', //件数
                        field: 'cVEHPLATE_NO',
                        //
                        fixed: false,
                        width: 120,
                        sort: false,
                        //
                        type: 'text',
                        value: '',
                        defautvalue: '',
                    },{
                        title: '司机手机', //件数
                        field: 'cMOBIL_TEL',
                        //
                        fixed: false,
                        width: 130,
                        sort: false,
                        //
                        type: 'text',
                        value: '',
                        defautvalue: '',
                    },{
                        title: '承运商分类',
                        field: 'cSUPLKIND_NO',
                        //
                        fixed: false,
                        width: 200,
                        sort: false,
                        //
                        type: 'select_api',
                        option: [],
                        value: '',
                        //选择内表格布局规则
                        optionTableRole: [
                            {
                                title: '编号',
                                field: 'cSUPLKIND_NO',
                                width: 150,
                                toInsert:'cSUPLKIND_NO', //带出写入
                            },{
                                title: '承运商分类',
                                field: 'cSUPLKIND_NM',
                                width: 150,
                                toInsert:'cSUPLKIND_NM', //带出写入
                            },
                        ], 
                        showfield:'cSUPLKIND_NM', //展示字段
                        filtefield:'cSUPLKIND_NM', //筛选字段(用于选择的数据的某个字段)
                        clearfield:['cSUPPLIER_NO','cSUPPLIER_NM','cSUPPLIER_NM','cSUPLINK_MAN','cSUPLTEL_NO','cSUPMOBIL_TEL'], //为空时,需要 关联清空的字段 
                        //
                        action: 'supplier/kind/search', //调用方法
                    },{
                        title: '承运商/个人',
                        field: 'cSUPPLIER_SNM',
                        //
                        fixed: false,
                        width: 230,
                        sort: false,
                        //
                        type: 'select_api',
                        option: [],
                        value: '',
                        //选择内表格布局规则
                        optionTableRole: [
                            {
                                title: '编号',
                                field: 'cSUPPLIER_NO',
                                width: 150,
                                toInsert:'cSUPPLIER_NO', //带出写入
                            },{
                                title: '承运商',
                                field: 'cSUPPLIER_NM',
                                width: 150,
                                toInsert:'cSUPPLIER_NM', //带出写入
                            },{
                                title: '承运联系人',
                                field: 'cLINKMAN',
                                width: 150,
                                toInsert:'cSUPLINK_MAN', //带出写入
                            },{
                                title: '承运电话',
                                field: 'cTEL_NO', //cTEL_NO
                                width: 150,
                                toInsert:'cSUPLTEL_NO', //带出写入
                            },{
                                title: '承运手机',
                                field: 'cMOBIL_TEL', //cTEL_NO
                                width: 150,
                                toInsert:'cSUPMOBIL_TEL', //带出写入
                            },
                        ], 
                        showfield:'cSUPPLIER_NM', //展示字段
                        filtefield:'cSUPPLIER_NM', //筛选字段(用于选择的数据的某个字段)
                        //``
                        action: 'supplier/search', //调用方法
                        parentField: [
                            ['cSUPLKIND_NO','cSUPLKIND_NO'], //['数据字段','基础表字段']   关联上级字段 (不会预加载)
                        ],     

                    },{
                        title: '承运联系人', //件数
                        field: 'cSUPLINK_MAN',
                        //
                        fixed: false,
                        width: 130,
                        sort: false,
                        //
                        type: 'text',
                        value: '',
                        defautvalue: '',
                    },{
                        title: '承运电话', //件数
                        field: 'cSUPLTEL_NO',
                        //
                        fixed: false,
                        width: 130,
                        sort: false,
                        //
                        type: 'text',
                        value: '',
                        defautvalue: '',
                    },{
                        title: '承运手机', //件数
                        field: 'cSUPMOBIL_TEL',
                        //
                        fixed: false,
                        width: 130,
                        sort: false,
                        //
                        type: 'text',
                        value: '',
                        defautvalue: '',
                    },{
                        title: '备注', //件数
                        field: 'cREMARK',
                        //
                        fixed: false,
                        width: 400,
                        sort: false,
                        //
                        type: 'text',
                        value: '',
                        defautvalue: '',
                    },{
                        title: '货物类型', //件数
                        field: 'cGOODS_NM',
                        //
                        fixed: false,
                        width: 400,
                        sort: false,
                        //
                        type: 'text',
                        value: '',
                        defautvalue: '',
                    },{
                        title: '启运省份',
                        field: 'cSTPROV_NO',
                        //
                        fixed: false,
                        width: 200,
                        sort: false,
                        //
                        type: 'select_api',
                        option: [],
                        value: '',
                        //选择内表格布局规则
                        optionTableRole: [
                            {
                                title: '编号',
                                field: 'cPROV_NO',
                                width: 150,
                                toInsert:'cSTPROV_NO', //带出写入
                            },{
                                title: '省份',
                                field: 'cPROV_NM',
                                width: 150,
                                toInsert:'cSTPROV_NM', //带出写入
                            },
                        ], 
                        showfield:'cSTPROV_NM', //展示字段
                        filtefield:'cSTPROV_NM', //筛选字段(用于选择的数据的某个字段)
                        clearfield:['cSTCITY_NO','cSTCITY_NM','cSTAPANA_NO','cSTAPANA_NM'], //为空时,需要 关联清空的字段 
                        //``
                        action: 'info/prov/search', //调用方法
                        
                    },{
                        title: '起运城市',
                        field: 'cSTCITY_NM',
                        //
                        fixed: false,
                        width: 230,
                        sort: false,
                        //
                        type: 'select_api',
                        option: [],
                        value: '',
                        //选择内表格布局规则
                        optionTableRole: [
                            {
                                title: '编号',
                                field: 'cCITY_NO',
                                width: 150,
                                toInsert:'cSTCITY_NO', //带出写入
                            },{
                                title: '城市名称',
                                field: 'cCITY_NM',
                                width: 150,
                                toInsert:'cSTCITY_NM', //带出写入
                            }
                        ], 
                        showfield:'cSTCITY_NM', //展示字段
                        filtefield:'cSTCITY_NM', //筛选字段(用于选择的数据的某个字段)
                        //``
                        action: 'info/city/search', //调用方法
                        parentField:[
                            ['cSTPROV_NO','cPROV_NO'], //['数据字段','基础表字段']   关联上级字段 (不会预加载)
                        ],
                        clearfield:['cSTAPANA_NO','cSTAPANA_NM'], //为空时,需要 关联清空的字段 

                    },{
                        title: '起运县区',
                        field: 'cSTCITY_NM',
                        //
                        fixed: false,
                        width: 230,
                        sort: false,
                        //
                        type: 'select_api',
                        option: [],
                        value: '',
                        //选择内表格布局规则
                        optionTableRole: [
                            {
                                title: '编号',
                                field: 'cAPANA_NO',
                                width: 150,
                                toInsert:'cSTAPANA_NO', //带出写入
                            },{
                                title: '县区',
                                field: 'cAPANA_NM',
                                width: 150,
                                toInsert:'cSTAPANA_NM', //带出写入
                            }
                        ], 
                        optionTableWidth:400, //内表格宽度
                        showfield:'cSTAPANA_NM', //展示字段
                        filtefield:'cSTAPANA_NM', //筛选字段(用于选择的数据的某个字段)
                        //``
                        action: 'info/apana/search', //调用方法
                        parentField:[
                            ['cSTCITY_NO','cCITY_NO'], //['数据字段','基础表字段']
                        ] //关联上级字段 (不会预加载)

                    },{
                        title: '目的省份',
                        field: 'cEDPROV_NO',
                        //
                        fixed: false,
                        width: 200,
                        sort: false,
                        //
                        type: 'select_api',
                        option: [],
                        value: '',
                        //选择内表格布局规则
                        optionTableRole: [
                            {
                                title: '编号',
                                field: 'cPROV_NO',
                                width: 150,
                                toInsert:'cEDPROV_NO', //带出写入
                            },{
                                title: '省份',
                                field: 'cPROV_NM',
                                width: 150,
                                toInsert:'cEDPROV_NM', //带出写入
                            },
                        ], 
                        showfield:'cEDPROV_NM', //展示字段
                        filtefield:'cEDPROV_NM', //筛选字段(用于选择的数据的某个字段)
                        clearfield:['cEDCITY_NO','cEDCITY_NM','cEDAPANA_NO','cEDAPANA_NM'], //为空时,需要 关联清空的字段 
                        //``
                        action: 'info/prov/search', //调用方法
                        
                    },{
                        title: '目的城市',
                        field: 'cEDCITY_NO',
                        //
                        fixed: false,
                        width: 230,
                        sort: false,
                        //
                        type: 'select_api',
                        option: [],
                        value: '',
                        //选择内表格布局规则
                        optionTableRole: [
                            {
                                title: '编号',
                                field: 'cCITY_NO',
                                width: 150,
                                toInsert:'cEDCITY_NO', //带出写入
                            },{
                                title: '城市名称',
                                field: 'cCITY_NM',
                                width: 150,
                                toInsert:'cEDCITY_NM', //带出写入
                            }
                        ], 
                        showfield:'cEDCITY_NM', //展示字段
                        filtefield:'cEDCITY_NM', //筛选字段(用于选择的数据的某个字段)
                        //``
                        action: 'info/city/search', //调用方法
                        parentField:[
                            ['cEDPROV_NO','cPROV_NO'], //['数据字段','基础表字段']   关联上级字段 (不会预加载)
                        ],
                        clearfield:['cEDAPANA_NO','cEDAPANA_NM'], //为空时,需要 关联清空的字段 

                    },{
                        title: '目的县区',
                        field: 'cEDAPANA_NO',
                        //
                        fixed: false,
                        width: 230,
                        sort: false,
                        //
                        type: 'select_api',
                        option: [],
                        value: '',
                        //选择内表格布局规则
                        optionTableRole: [
                            {
                                title: '编号',
                                field: 'cAPANA_NO',
                                width: 150,
                                toInsert:'cEDAPANA_NO', //带出写入
                            },{
                                title: '县区',
                                field: 'cAPANA_NM',
                                width: 150,
                                toInsert:'cEDAPANA_NM', //带出写入
                            }
                        ], 
                        optionTableWidth:400, //内表格宽度
                        showfield:'cEDAPANA_NM', //本表展示字段
                        filtefield:'cEDAPANA_NM', //本表筛选字段(用于选择的数据的某个字段)
                        //``
                        action: 'info/apana/search', //api调用方法
                        parentField:[
                            ['cEDCITY_NO','cCITY_NO'], //['本表字段','基础表字段']
                        ] //关联上级字段 (不会预加载)

                    },
                ],
                pagination_show: true,
                tableData: []
            },
            //費用表
            cost:{
                searchPath:'api/order/caychg/search',
                insertPath:'api/order/caychg/set',
                //是否预加载数据 true为不加载数据
                //noInitGetData: true,
                pagination_show:false,//隐藏分页
                tableRole: [
                    {
                        title: '序号',
                        field: 'idx',
                        fixed: false,
                        width: 80,
                        sort: false,
                        noedit: true,
                        defautvalue:0,
                    },
                    {
                        title: '费用项目',
                        field: 'cPAYITEM_NM',
                        fixed: false,
                        width: 100,
                        sort: false,
                        type: 'select_api',
                        option: [],
                        value: '',
                        //选择内表格布局规则
                        action: 'api/account/payitem/search', //调用方法
                        maxNum:200,
                        optionBoxWidth:600,
                        optionTableRole: [
                            {
                                title: '序号',
                                field: 'idx',
                                width: 150,
                                stype:'value'
                            },{
                                title: '项目编码',
                                field: 'cPAYITEM_NO',
                                width: 150,
                                toInsert:'cPAYITEM_NO', //带出写入
                                stype:'label'
                            },{
                                title: '项目名称',
                                field: 'cPAYITEM_NM',
                                width: 150,
                                toInsert:'cPAYITEM_NM', //带出写入
                            }
                        ],
                        showfield:'cPAYITEM_NM', //展示字段
                        filtefield:'cPAYITEM_NM', //筛选字段(用于选择的数据的某个字段)
                    },
                    {
                        title: '项目金额',
                        field: 'cCAY_AMT',
                        fixed: false,
                        width: 100,
                        sort: false,
                        type: 'number',
                        value: '0.000',
                    }
                ]
            },
            //报价表
            kpi:{
                searchPath:'api/vehicle/len/search',
                pagination_show:false,//隐藏分页
                //是否预加载数据 true为不加载数据
                noInitGetData: true,
                tableRole: [
                    {
                        title: '序号',
                        field: 'idx',
                        fixed: false,
                        width: 80,
                        sort: false,
                        noedit: true,
                        defautvalue:0,
                    },
                    {
                        title: 'KPI指标',
                        field: 'idx',
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '件数',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '箱数',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '重量',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '体积',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '单数',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: 'KPI比率',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '承运商扣款',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '已扣款',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '需赔金额',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '问题描述',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '承运商',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '状态',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '转扣款给承运别',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '转扣款给承运商',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '创建时间',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '维护时间',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    }
                ]
            },
            //quote表
            quote:{
                searchPath:'api/vehicle/len/search',
                pagination_show:false,//隐藏分页
                //是否预加载数据 true为不加载数据
                noInitGetData: true,
                tableRole: [
                    {
                        title: '序号',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },
                    {
                        title: '报价单位/个人',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '报价金额',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '成交金额',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '选择',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '备注',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '调车单号',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '创建时间',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    },{
                        title: '维护时间',
                        field: 'idx',
                        //
                        fixed: false,
                        width: 80,
                        sort: false,
                        //
                        noedit: true,
                        defautvalue:0,
                    }
                ]
            },
            treeData: []  //树形结构图
        }
    },
    methods:{
        tableSearch(){
        },
        handleselectedRows(rows){
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data:{
                    idx: this.selectedRows.map(v=>v.idx)
                }
            };
            if (this.selectedRows.length == 0) {
                alert("未选择删除数据！");
            } else {
                
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                this.$message.error("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let idx = this.isAdd ? 0 : this.editIdx
            let params = {
                ...this.$refs.table.tableData[idx]
            }
            if (!this.isAdd) {//编辑
                
            } else {//新增
            }
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        },
        handleNodeClick(node){
            console.log(node)
        }

    }
}
</script>