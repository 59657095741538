import { get, post } from '@/utils'

// 仓储片区设置
export const fetchWarets = (params)=>{
    return get('api/ware/waret/search',params)
}

export const editWarets = (params)=>{
    return post('api/ware/waret/update',params)
}

export const delWarets = (params)=>{
    return get('api/ware/waret/del',params)
}

// 仓库资料设置
export const fetchWarehs = (params)=>{
    return get('api/ware/wareh/search',params)
}

export const editWarehs = (params)=>{
    return post('api/ware/wareh/update',params)
}

export const delWarehs = (params)=>{
    return get('api/ware/wareh/del',params)
}

// 仓库类别资料
export const fetchWPStates = (params)=>{
    return get('api/ware/WPState/search',params)
}

export const editWPStates = (params)=>{
    return post('api/ware/WPState/update',params)
}

export const delWPStates = (params)=>{
    return get('api/ware/WPState/del',params)
}

// 仓位划分标准
export const fetchwhposcls = (params)=>{
    return get('api/ware/whposcls/search',params)
}

export const editwhposcls = (params)=>{
    return get('api/ware/whposcls/update',params)
}

export const delwhposcls = (params)=>{
    return get('api/ware/whposcls/del',params)
}

// 仓库仓位划分
export const fetchwhpos = (params)=>{
    return get('api/ware/whpos/search',params)
}

export const editwhpos = (params)=>{
    return get('api/ware/whpos/update',params)
}

export const delwhpos = (params)=>{
    return post('api/ware/whpos/del',params)
}

// 虚拟仓位设置
export const fetchwhposclsvirtual = (params)=>{
    return get('api/ware/whpos/virtual/search',params)
}

export const editwhposclsvirtual = (params)=>{
    return post('api/ware/whpos/virtual/update',params)
}

export const delwhposclsvirtual = (params)=>{
    return post('api/ware/whpos/virtual/del',params)
}

// 客户货物
export const custGoods = (params)=>{
    return get('api/ware/stkincuscgoods/search',params)
}

export const custGoodsAdd = (params)=>{
    return post('api/ware/stkincuscgoods/add',params)
}

export const custGoodsedit = (params)=>{
    return post('api/ware/stkincuscgoods/update',params)
}

