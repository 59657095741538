import { get, post } from '@/utils'

// 订单调度分流相关接口

// 承运单
export const fetchSupplierOrder = (params)=>{
    return get('api/order/caying/search',params)
}

export const editSupplierOrder = (params)=>{
    return post('api/order/caying/update',params)
}

// 承运费用单
export const fetchSupplierOrderPrices = (params)=>{
    return get('api/order/caypurchg/search',params)
}

export const editSupplierOrderPrices = (params)=>{
    return post('api/order/caypurchg/update',params)
}

// 承运商承运价
export const fetchSuppliers = (params) =>{
    return get('api/supplier/cay/purprc/search',params)
}

// 车辆紧急调度单
export const fetchEmergencyOrders = (params) =>{
    return get('api/order/cayuing/search',params)
}

// 批量订单列表
export const fetchOrders = (params) =>{
    return get('api/order/detail/search',params)
}

// 货物明细列表
export const fetchGoods = (params)=>{
    return get('api/cay/mat/search',params)
}

// 承运商运输车辆
export const fetchSupplierCars= (params)=>{
    return get('api/supplier/cay/track/search',params)
}

// 计费项目
export const fetchPriceItems= ()=>{
    return get('')
}

// 提交订单调度分流
export const subitOrderTransport = (params)=>{
    return post('api/order/cayuing/add',params)
}