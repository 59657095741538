<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form ref="form" :searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="toptable" ref="toptable" :pageInfo="pageInfo"  :height="200" :isEdit="isEdit" :tablerule="rules" @selectedRows="handletopselectedRows"></mytable>
        <mytable :tablelet="bottomtable" ref="bottomtable" :pageInfo="bottomtable.pageInfo"  :height="300" :isEdit="isEdit" :tablerule="rules" @selectedRows="handlebottomselectedRows"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
import {fetchDeliveryUnit,editDeliveryUnit,delDeliveryUnit,fetchCustomAddress,editCustomAddress,delCustomAddress} from '@/api/transport/base'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '提货单位资料',
            //顶部筛选配置
            searchBox: [
                {
                    type: 'text',
                    title: '提货客户', 		//输入框标题
                    dec: '输入提货客户', //输入框提示
                    field: 'cPICKCO_NM',
                    option: [], //固定项
                    value: '', //固定项
                },
                {
                    //基础设置
                    type: 'select', //类型 -> 固定值选择 
                    title: '状态', 		//输入框标题
                    field: 'cSTOP_TAG',
                    option: [
                        {
                            value: '0',
                            label: '使用'
                        }, {
                            value: '1',
                            label: '停用'
                        }, {
                            value: '2',
                            label: '全部'
                        }
                    ],
                    value: '1', 		//固定项
                    noedit: false,	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                }
            ],
            toptable:{
                selectChange: 'hasSelect', //选中项处理 父级方法
                //主表配置
                tableRole: [
                    {
                        title: '序号', 	//表头
                        field: 'idx', 	//表格展示字段 (主表字段)
                        fixed: true, 	//是否浮动
                        width: 80, 		//列宽
                        noedit: true, 	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                        defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                    }, {
                        type: 'text',
                        title: '提货客户名称',
                        field: 'cPICKCO_NM',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'text',
                        title: '联系人',
                        field: 'cLINKMAN',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'text',
                        title: '联系固话',
                        field: 'cTEL_NO',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'text',
                        title: '联系手机',
                        field: 'cMOBIL_TEL',
                        fixed: false,
                        width: 100,
                        value: '',
                    },
                    {
                        type: 'select_api',
                        title: '客户名称',
                        field: 'cCUST_SNM',
                        fixed: false,
                        width: 200,
                        sort: false,
                        option: [],
                        value: '',
                        optionTableRole: [
                            {
                                title: '编号',
                                field: 'cCUST_NO',
                                width: 150,
                                toInsert: 'cCUST_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '客户名称',
                                field: 'cCUST_NM',
                                width: 150,
                                toInsert: 'cCUST_SNM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cCUST_SNM',
                        filtefield: 'cCUST_SNM',
                        hasCheck: true,
                        action: 'cust/search',
                    },
                    {
                        type: 'text',
                        title: '客户代码',
                        field: 'cCUST_NO',
                        value: '',
                        noedit: true,
                        width: 75,
                    },
                    // {
                    //     type: 'select_api',
                    //     title: '所属客户',
                    //     field: 'cCUST_NO',
                    //     fixed: false,
                    //     width: 100,
                    //     option: [],
                    //     value: '',
                    //     action: 'cust/search',
                    //     optionTableRole: [
                    //         {
                    //             title: '客户编号',
                    //             field: 'cCUST_NO',
                    //             width: 150,
                    //             toInsert: 'cCUST_NO',
                    //         }, {
                    //             title: '客户名称',
                    //             field: 'cCUST_SNM',
                    //             width: 150,
                    //             toInsert: 'cCUST_SNM',
                    //         }
                    //     ],
                    //     showfield: 'cCUST_NO',
                    //     filtefield: 'cCUST_NO',
                    //     clearfield: ['cCUST_SNM'],
                    // }, {
                    //     type: 'text',
                    //     title: '客户名称',
                    //     field: 'cCUST_SNM',
                    //     fixed: false,
                    //     width: 100,
                    //     value: '',
                    // }, 
                    {
                        type: 'checkbox',
                        title: '缺省',
                        field: 'cDEFAULT',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                    }, {
                        type: 'checkbox',
                        title: '停用',
                        field: 'cSTOP_TAG',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                    },
                    {
                        type: 'text',
                        title: '备注',
                        field: 'cREMARK',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'text',
                        title: '提货客户编码',
                        field: 'cPICKCO_NO',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'text',
                        title: '维护人',
                        field: 'cUSER_NM',
                        value: 'immobilization',
                        width: 150,
                        
                    }, {
                        type: 'date',  //类型 -> 日期
                        title: '维护时间',
                        field: 'tMODIFY_DT',
                        fixed: false,
                        width: 150,
                        value: 'immobilization',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                    },
                ],
                pagination_show: true,
                tableData: []
            },
            bottomtable: {
                tableRole: [
                    {
                        title: 'ID', 	//表头
                        field: 'idx', 	//表格展示字段 (主表字段)
                        fixed: true, 	//是否浮动
                        width: 80, 		//列宽
                        noedit: true, 	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                        defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                    }, {
                        type: 'select_api',
                        title: '提货国家',
                        field: 'cSTNATION_NM',
                        fixed: false,
                        width: 200,
                        option: [],
                        value: '',
                        action: 'info/nation/search',
                        optionTableRole: [
                            {
                                title: '国家代码',
                                field: 'cNATION_NO',
                                width: 150,
                                toInsert: 'cSTNATION_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '国家名称',
                                field: 'cNATION_NM',
                                width: 150,
                                toInsert: 'cSTNATION_NM',
                                stype: 'label',
                            }
                        ],
                        showfield: 'cNATION_NM', //展示字段
                        filtefield: 'cNATION_NM', //筛选字段(用于选择的数据的某个字段)
                        clearfield: ['cSTPROV_NO', 'cSTPROV_NM', 'cCITY_NO', 'cCITY_NM', 'cAPANA_NO', 'cAPANA_NM'], //为空时,需要 关联清空的字段 
                    }, {
                        type: 'select_api',
                        title: '提货省份',
                        field: 'cSTPROV_NM',
                        fixed: false,
                        width: 200,
                        option: [],
                        value: '',
                        action: 'info/prov/search',
                        optionTableRole: [
                            {
                                title: '省份代码',
                                field: 'cPROV_NO',
                                width: 150,
                                toInsert: 'cSTPROV_NO',
                                stype: 'value',
                            }, {
                                title: '省份名称',
                                field: 'cPROV_NM',
                                width: 150,
                                toInsert: 'cSTPROV_NM',
                                stype: 'label',
                            }
                        ],
                        showfield: 'cPROV_NM', //展示字段
                        filtefield: 'cPROV_NM', //筛选字段(用于选择的数据的某个字段)
                        clearfield: ['cSTCITY_NO', 'cSTCITY_NM', 'cAPANA_NO', 'cAPANA_NM'], //为空时,需要 关联清空的字段 
                    }, {
                        type: 'select_api',
                        title: '提货城市',
                        field: 'cSTCITY_NM',
                        fixed: false,
                        width: 200,
                        option: [],
                        value: '',
                        action: 'info/city/search',
                        optionTableRole: [
                            {
                                title: '城市代码',
                                field: 'cCITY_NO',
                                width: 150,
                                toInsert: 'cSTCITY_NO',
                                stype: 'value',
                            }, {
                                title: '城市名称',
                                field: 'cCITY_NM',
                                width: 150,
                                toInsert: 'cSTCITY_NM',
                                stype: 'label',
                            }
                        ],
                        showfield: 'cCITY_NM',
                        filtefield: 'cCITY_NM',
                        parentField: [
                            ['cSTPROV_NO', 'cPROV_NO'],
                        ],
                        clearfield: ['cSTAPANA_NO', 'cSTAPANA_NM'],
                    }, {
                        type: 'select_api',
                        title: '提货县区',
                        field: 'cSTAPANA_NM',
                        fixed: false,
                        width: 200,
                        option: [],
                        value: '',
                        action: 'info/apana/search',
                        optionTableRole: [
                            {
                                title: '县区代码',
                                field: 'cAPANA_NO',
                                width: 150,
                                toInsert: 'cSTAPANA_NO',
                                stype: 'value',

                            }, {
                                title: '县区名称',
                                field: 'cAPANA_NM',
                                width: 150,
                                toInsert: 'cSTAPANA_NM',
                                stype: 'label',
                            }
                        ],
                        showfield: 'cAPANA_NM',
                        filtefield: 'cAPANA_NM',
                        parentField: [
                            ['cSTCITY_NO', 'cCITY_NO'],
                        ]
                    }, {
                        type: 'text',
                        title: '联系固话',
                        field: 'cTEL_NO',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'text',
                        title: '联系手机',
                        field: 'cMOBIL_TEL',
                        fixed: false,
                        width: 100,
                        value: '',
                    }, {
                        type: 'checkbox',
                        title: '缺省',
                        field: 'cDEFAULT',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                        // insertfieldtime: 'tSTOP_DT', //选中后,带出日期到该行其他字段
                    }, {
                        type: 'checkbox',
                        title: '停用',
                        field: 'cSTOP_TAG',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1', //为真时 的值
                        falsenumber: '0', //为假时 的值
                        // insertfieldtime: 'tSTOP_DT', //选中后,带出日期到该行其他字段
                    }, {
                        type: 'text',
                        title: '备注',
                        field: 'cREMARK',
                        fixed: false,
                        width: 100,
                        value: '',
                    },
                    {
                        type: 'text',
                        title: '维护人',
                        field: 'cUSER_NM', //在主表中无法找到字段
                        fixed: false,
                        width: 130,
                        value: 'immobilization',
                        defautvalue: '',
                        //  noedit: true,
                    }, {
                        type: 'date',
                        title: '维护时间',
                        field: 'tMODIFY_DT', //在主表中无法找到字段
                        fixed: false,
                        width: 130,
                        value: 'immobilization',
                        defautvalue: '',
                        //noedit: true,
                    },
                ],
                pagination_show: true,
                tableData: [],
                pageInfo: {
                    index: 1,
                    total: 0,
                    size: 10
                },
            },
            bottomSelectedRows: []
        }
    },
    methods:{
        tableSearch(){
            let params = {
                ...this.$refs.form.searchdata,
                page_index: this.pageInfo.index,
                page_size: this.pageInfo.size
            }
            fetchDeliveryUnit(params).then(res=>{
                this.toptable.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.pageInfo = res.data.page
            })
            this.getPickoNations()
        },
        getPickoNations(){
            fetchCustomAddress().then(res=>{
                this.bottomtable.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.bottomtable.pageInfo = res.data.page
            }) 
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            this.commonAdd(this.toptable)
            this.commonAdd(this.bottomtable)
        },
        //删除数据
        delRow() {
            if (this.selectedRows.length == 0 || this.bottomSelectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                if(this.selectedRows.length > 0){
                    let params = {
                        data:{
                            idx: this.selectedRows.map(v=>v.idx)
                        }
                    };
                    delDeliveryUnit(params).then(res=>{
                        this.$message.success('删除成功!')
                    })
                }

                if(this.bottomSelectedRows.length > 0){
                    let params = {
                        data:{
                            idx: this.bottomSelectedRows.map(v=>v.idx)
                        }
                    };
                    delCustomAddress(params).then(res=>{
                        this.$message.success('删除成功!')
                    })
                }
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                this.$message.error("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.toptable.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.toptable.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let idx = this.isAdd ? 0 : this.editIdx
            let currentData = this.$refs.toptable.tableData[idx]
            let editdata = [{
                ...currentData,
                idx: !!currentData.idx ? currentData.idx: '',
                cSTOP_TAG: currentData.cSTOP_TAG ? 1: 0,
                cDEFAULT: currentData.cDEFAULT ? 1 : 0
            }]
            editDeliveryUnit({data: {lstdata: editdata}}).then(res=>{
                this.isEdit = false
                this.tableSearch()
            })
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.toptable.tableData.shift();
                this.bottomtable.tableData.shift();
            }else{
                this.toptable.tableData[this.editIdx].isEdit = false
                this.bottomtable.tableData[this.editIdx].isEdit = false
            }
            this.$refs.toptable.$refs.mytable.clearSelection();
            this.$refs.bottomtable.$refs.mytable.clearSelection();
        },
        handletopselectedRows(rows){
            this.selectedRows = rows
        },
        handlebottomselectedRows(rows){
            this.bottomSelectedRows = rows
        } 
    }
}
</script>