<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form ref="form" :searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" ref="table" :height="550" :pageInfo="pageInfo" :isEdit="isEdit" :tablerule="rules" @selectedRows="handleselectedRows"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
import {fetchCargo,editCargo,delCargo} from '@/api/transport/base'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '运输货物资料',
            //顶部筛选配置
            searchBox: [
                {
                    type: 'select_api', // 下拉选择
                    title: '客户',
                    dec: '输入客户名称',
                    option: [],
                    value: '',
                    action: 'cust/search',
                    optionBoxWidth: 300,
                    optionTableRole: [
                        {
                            title: '编号',
                            field: 'cCUST_NO',
                            width: 80,
                            toInsert: 'cCUST_NO',
                        }, {
                            title: '客户名称',
                            field: 'cCUST_SNM',
                            width: 150,
                            toInsert: 'cCUST_SNM',
                        },
                    ],
                    showfield: 'cCUST_SNM',
                    filtefield: 'cCUST_SNM',
                    // 是否将部分字段作为全局筛选条件，如果是，则增加下方格式代码（全局筛选条件，将在表格中使用）
                    insert_default_field: {
                        'cCUST_NO': 'cCUST_NO', // '主表字段':'调用对象基础表字段'
                    },
                },
                {
                    type: 'text',
                    title: '仓库存货',
                    field: 'cCAYMAT_NM', 	//(未知字段)
                    value: '',
                }, {
                    type: 'select', //类型 -> 固定值选择
                    title: '使用状态',
                    field: 'cSTOP_TAG',
                    fixed: false, 	//是否浮动
                    width: 50, 	//列宽
                    //筛选值
                    option: [{
                        value: '0',
                        label: '停用'
                    }, {
                        value: '1',
                        label: '使用中'
                    }, {
                        value: '2',
                        label: '全部'
                    }],
                    value: '1',
                }, {
                    type: 'select_api', // 下拉选择
                    title: '仓库存货客户',
                    option: [],
                    value: '',
                    action: 'cust/search',
                    optionBoxWidth: 300,
                    optionTableRole: [
                        {
                            title: '编号',
                            field: 'cCUST_NO',
                            width: 80,
                            toInsert: '',//(不知带出到那个字段) 字段未知
                        }, {
                            title: '客户名称',
                            field: 'cCUST_NM',
                            width: 150,
                            toInsert: '',//(不知带出到那个字段)
                        },
                    ],
                    showfield: 'cCUST_NM',
                    filtefield: 'cCUST_NM',
                    insert_default_field: {
                        // 'cCUST_NO': 'cCUST_NO', // '主表字段':'调用对象基础表字段'
                    },
                },
            ],
            table: {
                //主表配置
                tableRole: [
                    {
                        title: '序号', 	//表头
                        field: 'idx', 	//表格展示字段 (主表字段)
                        fixed: true, 	//是否浮动
                        width: 50, 		//列宽
                        noedit: true, 	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                        defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                    }, {
                        type: 'select_api',
                        title: '所属客户',
                        field: 'cCUST_SNM',
                        fixed: false,
                        width: 200,
                        sort: false,
                        option: [],
                        value: '',
                        optionBoxWidth: 250,
                        optionTableRole: [
                            {
                                title: '编号',
                                field: 'cCUST_NO',
                                width: 85,
                                toInsert: 'cCUST_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '客户名称',
                                field: 'cCUST_SNM',
                                width: 120,
                                toInsert: 'cCUST_SNM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cCUST_SNM',
                        filtefield: 'cCUST_SNM',
                        hasCheck: true,
                        action: 'cust/search',
                    }, {
                        type: 'text',
                        title: '客户编码',
                        field: 'cCUST_NO',
                        value: '',
                        noedit: true,
                        width: 100,
                    }, {
                        type: 'text',
                        title: '客户货物编码',
                        field: 'cCAYMAT_NO',
                        value: '',
                        width: 120,
                    }, {
                        type: 'text',
                        title: '货物名称',
                        field: 'cCAYMAT_NM',
                        value: '',
                        width: 200,
                    }, {
                        type: 'text',
                        title: '货物别名',
                        field: 'cCAYMAT_ALIASNM',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '货物英文名称',
                        field: 'cCAYMAT_ENM',
                        value: '',
                        width: 200,
                    }, {
                        type: 'text',
                        title: '规格说明',//字段未知
                        field: 'cCAYMATSPEC_NM',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '规格编码',//字段未知
                        field: 'cCAYMATSPEC_NO',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '海关编码',//字段未知
                        field: 'cHS_CODE',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '包装方式',
                        field: 'cPAKG_DESC',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '货物描述',
                        field: 'cDESCRIBE',  //字段未知
                        value: '',
                        width: 100,
                    }, {
                        type: 'select_api',
                        title: '存放单位',
                        field: 'cSTOUNIT_UM', //字段未知
                        fixed: false,
                        width: 120,
                        sort: false,
                        option: [],
                        value: '',
                        optionBoxWidth: 200,
                        optionTableRole: [
                            {
                                title: '单位名称',
                                field: 'cUNIT_NM',
                                width: 100,
                                toInsert: 'cSTOUNIT_UM', //带出写入
                                stype: 'value',
                            }, {
                                title: '单位类别',
                                field: '',
                                width: 100,
                                // toInsert: 'cUNIT_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cSTOUNIT_UM',
                        filtefield: 'cSTOUNIT_UM',
                        hasCheck: true,
                        action: 'info/unit/search',
                    }, {
                        type: 'select_api',
                        title: '计量单位',
                        field: 'cPIEUNIT_NM', //字段未知
                        fixed: false,
                        width: 120,
                        sort: false,
                        option: [],
                        value: '',
                        optionBoxWidth: 200,
                        optionTableRole: [
                                {
                                title: '单位名称',
                                field: 'cUNIT_NM',
                                width: 100,
                                toInsert: 'cUNIT_NM', //带出写入
                                stype: 'value',
                            }, {
                                title: '单位类别',
                                field: '',
                                width: 100,
                                // toInsert: 'cUNIT_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cUNIT_NM',
                        filtefield: 'cUNIT_NM',
                        hasCheck: true,
                        action: 'info/unit/search',
                    }, {
                        type: 'number',
                        title: '单件货数',
                        field: 'nUNIT_QTY',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '件数单位',
                        field: 'cPIEUNIT_NM',//不知道是不是
                        value: '',
                        width: 100,
                    }, {
                        type: 'number',
                        title: '单货再分个数',
                        field: 'nUNIT_QTY', //字段未知
                        value: '',
                        width: 100,
                    }, {
                        type: 'number',
                        title: '长',
                        field: 'nLENGTH',
                        value: '',
                        width: 50,
                    }, {
                        type: 'number',
                        title: '宽',
                        field: 'nWIDTH',
                        value: '',
                        width: 50,
                    }, {
                        type: 'number',
                        title: '高',
                        field: 'nHEIGHT',
                        value: '',
                        width: 50,
                    }, {
                        type: 'number',
                        title: '面积',
                        field: 'nAREA',
                        value: '',
                        width: 50,
                    }, {
                        type: 'number',
                        title: '容积',
                        field: 'nVOLUME',
                        value: '',
                        width: 50,
                    }, {
                        type: 'text',
                        title: '体积单位',
                        field: 'cVOLUNIT_NM',
                        value: '',
                        width: 50,
                    }, {
                        type: 'text',
                        title: '客户物料类别编码',
                        field: 'cCUSMATCLS_NO', //不知是不是
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '客户物料类别名称',
                        field: 'cCUSMATCLS_NM', //不知是不是
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '对应收货客户料号',
                        field: 'cCAYMATCLS_NO',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '条码',
                        field: 'cBAR_CODE',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '供应商编码',
                        field: 'cSUPPLIER_NO',
                        value: '',
                        //  noedit: true,
                        width: 120,
                    }, {
                        type: 'text',
                        title: '供应商名称',
                        field: 'cSUPPLIER_NM',
                        value: '',
                        //   noedit: true,
                        width: 120,
                    },  {
                        type: 'number',
                        title: '销售单价',
                        field: 'nUNIT_PRICE',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '打折单价',
                        field: 'nAGIO_PRICE',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '会员单价',
                        field: 'nMEMB_PRICE',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '成本单价',
                        field: 'nCOST_PRICE',
                        value: '',
                        width: 80,
                    }, {
                        type: 'select_api',
                        title: '出口国家',
                        field: 'cEXPNATION_NM',
                        fixed: false,
                        width: 150,
                        option: [],
                        value: '',
                        action: 'info/nation/search',
                        optionTableRole: [
                            {
                                title: '国家代码',
                                field: 'cNATION_NO',
                                width: 150,
                                toInsert: 'cEXPNATION_NO', //带出写入
                                stype: 'value',
                            }, {
                                title: '国家名称',
                                field: 'cNATION_NM',
                                width: 150,
                                toInsert: 'cSTNATION_NM', //带出写入
                                stype: 'label',
                            },
                        ],
                        showfield: 'cSTNATION_NM', //展示字段
                        filtefield: 'cSTNATION_NM', //筛选字段(用于选择的数据的某个字段)
                    }, {
                        type: 'text',
                        title: '出口国家说明',
                        field: 'cEXPORT_CUNTY', //字段未知
                        value: '',
                        width: 120,
                    }, {
                        type: 'select',
                        title: '使用范围',
                        field: 'cUSE_RANG',
                        fixed: false,
                        width: 100,
                        option: [{
                            value: '0',
                            label: '客户独用'
                        }, {
                            value: '1',
                            label: '指定公用'
                        }, {
                            value: '2',
                            label: '共同享用'
                        }],
                        value: '0',
                    }, {
                        type: 'text',
                        title: '指定共享客户列表',
                        field: 'cSHARECUST_NMS',
                        value: '',
                        width: 150,
                    }, {
                        type: 'text',
                        title: '入库默认状态',
                        field: 'cCAYSTATEIN_NO', //应该是下拉选择类型 但是下拉表格数据内容未知
                        value: '',
                        width: 150,
                    }, {
                        type: 'text',
                        title: '出库默认状态',
                        field: 'cCAYSTATEOUT_NO', //应该是下拉选择类型 但是下拉表格数据内容未知
                        value: '',
                        width: 150,
                    }, {
                        type: 'text',
                        title: '字符记录一',
                        field: 'cSTANDBY_ONE',
                        value: '',
                        width: 120,
                    }, {
                        type: 'text',
                        title: '字符记录二',
                        field: 'cSTANDBY_TWO',
                        value: '',
                        width: 120,
                    }, {
                        type: 'number',
                        title: '数字记录一',
                        field: 'nSTANDBY_ONE',
                        value: '',
                        width: 120,
                    }, {
                        type: 'number',
                        title: '数字记录二',
                        field: 'nSTANDBY_TWO',
                        value: '',
                        width: 120,
                    }, {
                        type: 'checkbox',
                        title: '停用',
                        field: 'cSTOP_TAG',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1',
                        falsenumber: '0',
                    }, {
                        type: 'text',
                        title: '备注',
                        field: 'cREMARK',
                        value: '',
                        width: 150,
                    }, {
                        type: 'text',
                        title: '存货系统编码',
                        field: 'cWHMATSTK_NO', //字段未知
                        value: '',
                        width: 150,
                    }, {
                        type: 'text',
                        title: '创建人',
                        field: 'cESTUSER_NM',
                        value: 'immobilization',
                        width: 150,
                        // noedit: true,
                    }, {
                        type: 'date',
                        title: '创建时间',
                        field: 'tESTDATA_DT',
                        fixed: false,
                        width: 150,
                        value: 'immobilization',
                        defautvalue: this.$getNowTime(true, true, true),
                        format: "yyyy-MM-dd",
                        //   noedit: true,
                    }, {
                        type: 'text',
                        title: '维护人',
                        field: 'cUSER_NM',
                        value: 'immobilization',
                        width: 150,
                        // noedit: true,
                    }, {
                        type: 'date',
                        title: '维护时间',
                        field: 'tMODIFY_DT',
                        fixed: false,
                        width: 150,
                        value: 'immobilization',
                        defautvalue: this.$getNowTime(true, true, true),
                        format: "yyyy-MM-dd",
                        // noedit: true,
                    },
                ],
                pagination_show: true,
                tableData: []
            },
        }
    },
    methods: {
        tableSearch(){
            let params = {
                ...this.$refs.form.searchdata,
                page_index: this.pageInfo.index,
                page_size: this.pageInfo.size
            }
            fetchCargo(params).then(res=>{
                this.table.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.pageInfo = res.data.page
            })
        },
        handleselectedRows(rows){
            this.selectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            this.commonAdd(this.table)
        },
        //删除数据
        delRow() {
            let params = {
                data: {
                    idx: this.selectedRows.map(v=>v.idx)
                } 
            };
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                delCargo(params).then(res=>{
                    this.$message.success('删除成功!')
                    this.tableSearch()
                })
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                this.$message.error("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            let idx = this.isAdd ? 0 : this.editIdx
            let currentData = this.$refs.table.tableData[idx]
            let editdata = [{
                ...currentData,
                idx: !!currentData.idx ? currentData.idx: '',
                cSTOP_TAG: currentData.cSTOP_TAG ? 1: 0,
                cDEFAULT: currentData.cDEFAULT ? 1 : 0
            }]
            editCargo({data: {lstdata: editdata}}).then(res=>{
                this.isEdit = false
                this.tableSearch()
            })
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }
}
</script>