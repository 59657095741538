<template>
    <el-form :inline="true" size="mini"  ref="tableSearch">
        <el-form-item v-for="(item, index) in searchbox" :label="item.title" :key="index">
            <!-- 文本框 -->
            <el-input 
                v-if="item.type == 'text'" 
                v-model="searchdata[item.field]" 
                @change="(val)=>apiChangeInputFocus(val,item)" 
                size="mini" 
                :name="item.field" 
                :prefix-icon="item.icon" 
                :placeholder="item.dec"
                :disabled="isedit"
            >
            </el-input>
            <!-- 日期框 -->
            <el-date-picker 
                v-if="item.type == 'date'" 
                @change="(val)=>apiChangeInputFocus(val,item)" 
                value-format="yyyy-MM-dd" 
                format="yyyy-MM-dd" 
                :name="item.field" 
                v-model="searchdata[item.field]"
                :placeholder="item.dec"
                :disabled="isedit"
            >
            </el-date-picker>
                
            <!-- 日期范围框 -->
            <el-date-picker
                v-if="item.type == 'daterange'" 
                v-model="searchdata[item.field]"
                @change="(val)=>apiChangeInputFocus(val,item)" 
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd" 
                format="yyyy-MM-dd"
                :disabled="isedit"
            >
            </el-date-picker>

            <!-- 多选框 -->
            <el-checkbox-group :disabled="isedit" @change="(val)=>apiChangeInputFocus(val,item)" v-if="item.type == 'checkbox'" v-model="item.value">
                <el-checkbox :key="index2" v-for="item2,index2 in item.option" :value="item2.value" :label="item2.value">{{item2.label}}</el-checkbox>
            </el-checkbox-group>

            <!-- 固定选择 -->
            <el-select filterable :disabled="isedit" @change="(val)=>apiChangeInputFocus(val,item)" v-if="item.type == 'select'" size="mini" v-model="item.value" >
                <el-option v-for="item3 in item.option" :key="item3.value" :label="item3.label" :value="item3.value">
                </el-option>
            </el-select>

            <el-popover
                v-if="item.type == 'select_api'" 
                placement="bottom-start"
                :width="item.optionBoxWidth ? item.optionBoxWidth : 400"
                trigger="click" 
                :disabled="isedit"
                v-model="item.visible"
            >  
                <el-table @row-click="(row,column,e)=>apiPopoverTableRowClick(row,column,e,item)" :data="myData(item)" size="mini" height="200" >
                    <el-table-column :key="index8" v-for="item8,index8 in item.optionTableRole" :width="item8.width" :fixed="item8.fixed" :label="item8.title" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div v-for="(item2,index8) in scope.row" >
                                <template  v-if="index8 == item8.field">
                                    <span>{{scope.row[index8]}}</span>
                                </template>
                            </div>
                        </template>    
                    </el-table-column>
                </el-table>
                <el-input 
                    :disabled="isedit"
                    @focus="apiSelectInputFocus($event,item)" 
                    @blur="(val)=>apiChangeInputFocus(val,item)" 
                    v-model="item.value"  
                    slot="reference" 
                    size="mini" 
                    placeholder="请点击选择"
                ></el-input>
            </el-popover>
        </el-form-item>
        <el-form-item style="margin-left: 5px;">
            <el-button @click="submitForm" :disabled="isedit" type="warning" size="mini" plain>搜索</el-button>
            <el-button @click="resetForm" size="mini" :disabled="isedit" plain>重置</el-button>
        </el-form-item>
    </el-form>
</template>    
<script>
import axios from '@/utils/request';
import _ from 'lodash'
export default{
    props: {
        searchbox: {
            type: Array,
            default: () => { return [] }
        },
        defaultpramas:{
            type: Object,
            default: () => { return {} }
        },
        isedit:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visible: false,
            searchdata:{}
        };
    },
    computed:{
        myData() {
            return function(role) {
                let data = role.option;
                let val = role.value;
                if (val && val !== '') {
                    return data.filter((item, i, arr) => {//筛选data
                        if(item[role.filtefield] !=null && item[role.filtefield].indexOf(val)>=0){
                            return true
                        }
                    })
                } else {
                    return data;
                }
            };
        },
    },
    methods: {
        //内 表格 被点击
        apiPopoverTableRowClick(row, column, e,role) {
            let feilds = this.scoperow;
            let _this = this;
            let tableRole = role.optionTableRole;
            tableRole.forEach(function(obj) {
                if (obj.toInsert) { //普通带出
                    console.log(obj.toInsert,'2323');
                   _this.searchdata[obj.toInsert] = row[obj.field] ;
                }
            })
            role.value = row[role.showfield];

            //添加全局检索参数
            if(role.hasOwnProperty('insert_deafault_field')){
                let arr = Object.entries(role.insert_deafault_field);
                arr.forEach(function(item2){
                    _this.$set(_this.defaultpramas, item2[0], row[item2[1]]);
                })
            }
            // console.log(this.$refs.popover)
            role.visible = !role.visible;
        },
        apiSelectInputFocus(e,role) {
            //启动表格
            let feilds = this.searchdata;
            let params = {
                action: 'api/'+role.action,
                pagesize: 200,
                page: 1
            };
            //判断是否是关联数据的下级
            if (role.parentField) {
                let where = [];
                role.parentField.forEach(function(item) {
                    let pfield = feilds[item[0]]; //数据字段值
                    where.push([item[1], '=', pfield]);
                })

                if (where.length > 0) {
                    params.extra_where = where;
                    axios.get(params.action,params).then(resalt=> {
                        role.option = resalt.data.data.data || [];
                        role.visible = true
                    });
                }
            }else{
                axios.get(params.action,params).then(resalt=> {
                    role.option = resalt.data.data.data || [];
                    role.visible = true
                });
            }
        },
        apiChangeInputFocus(val,role) {
            let feilds = this.searchdata;
            let _this = this;
            if(role.type === 'select_api'){
                if (val !== '') {
                    let arr = role.option;
                    console.log(arr.find(v => v[role.showfield] === val),'arr.find');
                    if (_this.visible){
                        let data = role.option;
                        let val = role.value;
                        let res= data.filter((item, i, arr) => {//筛选data
                            console.log(item,'indexof');
                            if(item[role.filtefield] !=null && item[role.filtefield].indexOf(val)>=0){
                                return true
                            }
                        })
                        if (res.length === 0){
                            if (!arr.find(v => v[role.showfield] === val)) {
                                this.$message.error('您输入的信息,不符规则!');
                                //清空对应 带入字段的值
                                this.clearInsertField(role);
                                this.$set(role, 'value', '');
                            }
                        }
                    }
                } else {
                    this.$set(role, 'value', '');
                    role.visible = false
                    this.clearInsertField(role);
                }
            }else{
                feilds[role.field] = val;
            }
        },
        //辅助
        //清空对应 带入字段的值
        clearInsertField(role) {
            let feilds = this.searchdata;
            let _this = this;
            if (role.optionTableRole) {
                role.optionTableRole.forEach(function(item) {
                    if (item.toInsert && item.toInsert !== '') {
                        delete feilds[item.toInsert]
                    }
                })
            }
            if (role.clearfield) {
                role.clearfield.forEach(function(item) {
                    delete feilds[item.toInsert]
                })
            }
        },

        //提交筛选数据
        submitForm() {
            console.log('submit',this.$parent)
            this.$parent.tableSearch();
        },
        resetForm() {
            this.searchdata = {}
            // this.$refs['tableSearch'].resetFields();
        },
    }
}
</script>
