import { get, post } from '@/utils'

export const fetchNations = (params) =>{
    return get('api/info/nation/search',params)
}

export const fetchProvs = (params) =>{
    return get('api/info/prov/search',params)
}

export const fetchCitys = (params) =>{
    return get('api/info/city/search',params)
}

export const fetchAreas = (params) =>{
    return get('api/info/apana/search',params)
}

export const fetchCustNames = (params)=>{
    return get('api/cust/search',params)
}

// 客户承运单位
export const fetchCustSupplier = (params)=>{
    return get('api/cust/stkcusupl/search',params)
}
