<template>
    <div>
        <div class="top border ">
            <div class="f-14 col-main f-b">{{appName}}</div>
            <div class="dis-flex flex-x-end flex-y-center">
                <el-button type="success" @click="saveRow" icon="el-icon-circle-check" size="mini">保存</el-button>
            </div>
        </div>
        <div class="top-form-wrapper">
            <top-form :searchbox="searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="table" ref="table" :height="200" :arraySpanMethod="arraySpanMethod" @selectedRows="handleselectedRows"></mytable>
        <el-tabs type="border-card" style="margin: 5px 0;" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="货物明细" name="table_1">
                <!-- 货物明细 -->
                <mytable :tablelet="goodstable" ref="goodstable" :height="200"></mytable>
            </el-tab-pane>
            <el-tab-pane label="车辆调派" name="table_2">
                <!-- 承运单 -->
                <el-table :data="cars" style="width: 100%" highlight-current-row>
                    <el-table-column prop="" label="拼车" width="50"></el-table-column>
                    <el-table-column prop="cCAYING_NO" label="拼车单号"></el-table-column>
                    <el-table-column prop="cCAYING_DATE" label="承运日期"></el-table-column>
                    <el-table-column prop="cFIN_MON" label="帐款年月"></el-table-column>
                    <el-table-column prop="cSUPLKIND_NM" label="承运商类别"></el-table-column>
                    <el-table-column prop="cSUPPLIER_SNM" label="承运商/个体"></el-table-column>
                    <el-table-column prop="cSUPPLIER_NO" label="承运商编码"></el-table-column>
                    <el-table-column prop="cSUPLINK_MAN" label="联系人"></el-table-column>
                    <el-table-column prop="cSUPLTEL_NO" label="承运电话"></el-table-column>
                    <el-table-column prop="cSUPMOBIL_TEL" label="承运手机"></el-table-column>
                    <el-table-column prop="cPURODR_NO" label="协议单号"></el-table-column>
                    <el-table-column prop="cVEHPLATE_NO" label="车牌号码"></el-table-column>
                    <el-table-column prop="cVEHKIND_NM" label="车辆类型"></el-table-column>
                    <el-table-column prop="cVEHSIZE_NM" label="车辆尺寸"></el-table-column>
                    <el-table-column prop="cVEHMAN_NM" label="司机/执行人"></el-table-column>
                    <el-table-column prop="cMOBIL_TE" label="司机手机"></el-table-column>
                    <el-table-column prop="nOVER_AMT" label="超支金额"></el-table-column>
                    <el-table-column prop="" label="协议金额"></el-table-column>
                    <el-table-column prop="nMAIN_AMT" label="应付金额"></el-table-column>
                    <el-table-column prop="nPAY_AMT" label="已付金额"></el-table-column>
                    <el-table-column prop="nBAL_AMT" label="未付金额"></el-table-column>
                    <el-table-column prop="nPLAN_AMT" label="计划预付"></el-table-column>
                    <el-table-column prop="nPRED_AMT" label="代付款"></el-table-column>
                    <el-table-column prop="nPRE_AMT" label="代收款"></el-table-column>
                    <el-table-column prop="" label="备注"></el-table-column>
                    <el-table-column prop="cCAYUING_NO" label="紧急调车单号"></el-table-column>
                    <el-table-column prop="" label="紧急调车应付款"></el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <el-row>
            <el-col :span="12">
                <!-- 车辆紧急调度单 -->
                <!-- 承运商信息 -->
                <el-table :data="suppliers" style="width: 100%" highlight-current-row>
                    <el-table-column prop="cCAYING_DATE" label="叫车日期"></el-table-column>
                    <el-table-column prop="cFIN_MON" label="账款年月"></el-table-column>
                    <el-table-column prop="cEMP_NM" label="叫车人员"></el-table-column>
                    <el-table-column prop="cCUST_NO" label="客户"></el-table-column>
                    <el-table-column prop="nMAT_QTY" label="货物量"></el-table-column>
                    <el-table-column prop="cUNIT_NM" label="单位"></el-table-column>
                    <el-table-column prop="cLADING_ADDR" label="起始地"></el-table-column>
                    <el-table-column prop="cDESTINATION" label="目的地"></el-table-column>
                    <el-table-column prop="nOUG_AMT" label="运费总额"></el-table-column>
                    <el-table-column prop="nPRE_AMT" label="计划预付"></el-table-column>
                    <el-table-column prop="nPAY_AMT" label="已付金额"></el-table-column>
                    <el-table-column prop="cVEHKIND_NM" label="车辆类型"></el-table-column>
                </el-table>
            </el-col>
            <el-col :span="12">
                <!-- 承运费用单 -->
                <el-col :span="16">
                    <el-table :data="feeList" style="width: 100%" highlight-current-row>
                        <el-table-column prop="idx" label="序号"></el-table-column>
                        <el-table-column prop="cPAYITEM_NM" label="费用项目"></el-table-column>
                        <el-table-column prop="cCAY_AMT" label="实际金额"></el-table-column>
                        <el-table-column prop="nPLAN_AMT" label="协议金额"></el-table-column>
                        <el-table-column prop="nOVER_AMT" label="超支金额"></el-table-column>
                        <el-table-column prop="cUNIT_NM" label="单位"></el-table-column>
                        <el-table-column prop="cPRC_BY" label="计费依据"></el-table-column>
                        <el-table-column prop="nUNIT_PRICE" label="单价"></el-table-column>
                        <el-table-column prop="nCAY_QTY" label="数量"></el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="8">
                    费用列表
                </el-col>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
import {fetchOrders,fetchSuppliers,fetchEmergencyOrders,fetchGoods,fetchSupplierCars,fetchPriceItems,subitOrderTransport} from '@/api/transport/wuliu'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '订单调度分流',
            //检索1
            searchBox: [
                {
                    title: '客户',
                    dec: '输入客户名称',
                    field: 'cCUST_SNM',
                    icon: 'el-icon-user-solid',
                    //
                    type: 'select_api',
                    option:[],
                    value: '',
                    //
                    action: 'cust/search', //调用方法
                    //选择内表格布局规则
                    optionTableRole: [
                        {
                            title: '编号',
                            field: 'cCUST_NO',
                            width: 150,
                            toInsert:'cCUST_NO', //带出写入
                        },{
                            title: '客户名称',
                            field: 'cCUST_NM',
                            width: 150,
                        },
                    ],
                    showfield:'cCUST_NM', //展示字段
                    filtefield:'cCUST_NM', //筛选字段(用于选择的数据的某个字段)
                    parentField:false,
                    hasCheck:true, //是否检查所输入内容为在选择列表中的项
                    insert_deafault_field:{
                        'cCUST_NO':'cCUST_NO', //数据字段','基础表字段
                    }
                },
                {
                    type: 'text',
                    title: '叫车人员',
                    dec: '输入叫车人员',
                    field: 'cEMP_NO',
                    icon: 'el-icon-user-solid',
                    value: '',
                },{
                    type: 'date',
                    title: '年月',
                    dec: '输入年月',
                    field: 'cCAYING_DATE',
                    icon: 'el-icon-user-solid',
                    value: '',
                    format: 'yyyy-MM/dd',
                },{
                    type: 'text',
                    title: '现场手机',
                    dec: '输入现场手机号码',
                    field: 'cEMPMOBIL_TEL',
                    icon: 'el-icon-user-solid',
                    value: '',
                },{
                    type: 'checkbox',
                    title: '结算类别',
                    field: 'cCAY_STATUS',
                    icon: 'el-icon-message-solid',
                    value: [],
                    option: [{
                        value: 0,
                        label: '未结算'
                    }, {
                        value: 1,
                        label: '已结算'
                    }, {
                        value: 3,
                        label: '结算完'
                    }],
                },{
                    type: 'checkbox',
                    title: '状态',
                    field: 'orderstate',
                    icon: 'el-icon-message-solid',
                    value: [],
                    option: [{
                        value: 0,
                        label: '待调度'
                    }, {
                        value: 1,
                        label: '已调度'
                    }, {
                        value: 3,
                        label: '已关联'
                    }],
                },{
                    type: 'daterange', //时间范围
                    title: '调车时间',
                    field: 'cCAY_STATUS',
                    icon: 'el-icon-date',
                    value: '',
                },{
                    title: '承运别',
                    field: 'cSUPLKIND_NO',
                    icon: 'el-icon-menu',
                    fixed: false,
                    width: 200,
                    sort: false,
                    type: 'select_api',
                    option: [],
                    value: '',
                    //选择内表格布局规则
                    optionTableRole: [
                        {
                            title: '编号',
                            field: 'cSUPLKIND_NO',
                            width: 150,
                            toInsert:'cSUPLKIND_NO', //带出写入
                        },{
                            title: '承运商分类',
                            field: 'cSUPLKIND_NM',
                            width: 150,
                        },
                    ],
                    showfield:'cSUPLKIND_NM', //展示字段
                    filtefield:'cSUPLKIND_NM', //筛选字段(用于选择的数据的某个字段)
                    clearfield:['cSUPPLIER_NO','cSUPPLIER_NM'], //为空时,需要 关联清空的字段
                    action: 'supplier/kind/search', //调用方法
                },{
                    title: '承运商',
                    field: 'cSUPPLIER_NM',
                    icon: 'el-icon-user-solid',
                    fixed: false,
                    width: 230,
                    sort: false,
                    type: 'select_api',
                    option: [],
                    value: '',
                    optionTableRole: [
                        {
                            title: '编号',
                            field: 'cSUPPLIER_NO',
                            width: 150,
                            toInsert:'cSUPPLIER_NO', //带出写入
                        },{
                            title: '承运商',
                            field: 'cSUPPLIER_NM',
                            width: 150,
                        }
                    ],
                    showfield:'cSUPPLIER_NM', //展示字段
                    filtefield:'cSUPPLIER_NM', //筛选字段(用于选择的数据的某个字段)
                    action: 'supplier/search', //调用方法
                    parentField: [
                        ['cSUPLKIND_NO','cSUPLKIND_NO'], //['数据字段','基础表字段']   关联上级字段 (不会预加载)
                    ],
                }
            ],
            table:{
                searchPath:'order/cayuing/search',
                insertPath:'order/cayuing/add',
                // 调度车规则
                tableRole: [
                    {
                        title: '序号',
                        field: 'idx',
                        fixed: true,
                        width: 50,
                    }, {
                        title: '托运订单号',
                        field: 'cCAYODR_NO',
                        fixed: false,
                        width: 110,
                    },{
                        title: '客户',
                        field: 'cCUST_NO',
                        fixed: false,
                        width: 110,
                    },{
                        title: '客户单号',
                        field: 'cCUSODR_NO',
                        fixed: false,
                        width: 150
                    },{
                        title: '订单日期',
                        field: 'cCAYODR_DATE',
                        fixed: false,
                        width: 150
                    },{
                        title: '状态',
                        field: '',
                        fixed: false,
                        width: 150
                    },
                    {
                        title: '规模',
                        field: '',
                        fixed: false,
                        width: 150
                    },{
                        title: '拼车',
                        field: 'cGETH_TAG',
                        fixed: false,
                        width: 150,
                        type: 'checkbox'
                    },{
                        title: '要求到达时间',
                        field: 'tLADING_DT',
                        fixed: false,
                        width: 150
                    },
                    {
                        title: '收获地址',
                        field: 'cDESTINATION',
                        fixed: false,
                        width: 150
                    },{
                        title: '收获省份',
                        field: 'cSTPROV_NM',
                        fixed: false,
                        width: 100
                    },{
                        title: '收获城市',
                        field: 'cSTCITY_NM',
                        fixed: false,
                        width: 150
                    },
                    {
                        title: '收获县区',
                        field: 'cSTNATION_NM',
                        fixed: false,
                        width: 150
                    },{
                        title: '收货客户',
                        field: 'cCUSCLIENT_NM',
                        fixed: false,
                        width: 150
                    },{
                        title: '收货手机',
                        field: 'cDESMOBIL_TEL',
                        fixed: false,
                        width: 150
                    },{
                        title: '货物类型',
                        field: '',
                        fixed: false,
                        width: 150
                    },
                    {
                        title: '客户运输单号',
                        field: '#cCUSCAY_NO',
                        fixed: false,
                        width: 150
                    },
                    {
                        title: '客户出库单号',
                        field: '',
                        fixed: false,
                        width: 150
                    },{
                        title: '客户采购单号',
                        field: '',
                        fixed: false,
                        width: 150
                    },{
                        title: '拼车单号',
                        field: 'cGETHVEH_NO',
                        fixed: false,
                        width: 150
                    },
                    {
                        title: '相邻日订单数',
                        field: 'cREVERT_TYPE',
                        fixed: false,
                        width: 150
                    },{
                        title: '总体积',
                        field: 'nVOL_QTY',
                        fixed: false,
                        width: 150
                    },{
                        title: '总件数',
                        field: 'nPIECE_QTY',
                        fixed: false,
                        width: 150
                    },{
                        title: '总箱数',
                        field: 'nBOX_QTY',
                        fixed: false,
                        width: 150
                    },{
                        title: '现场人员',
                        field: 'cEMP_NM',
                        fixed: false,
                        width: 150
                    },{
                        title: '现场人员手机',
                        field: 'cMOBILE_TEL',
                        fixed: false,
                        width: 150
                    },{
                        title: '提货类别',
                        field: '',
                        fixed: false,
                        width: 150
                    },{
                        title: '提货时间',
                        field: 'tLADING_DT',
                        fixed: false,
                        width: 150
                    },
                    {
                        title: '提货省份',
                        field: 'cEDPROV_NM',
                        fixed: false,
                        width: 150
                    },
                    {
                        title: '提货城市',
                        field: 'cEDCITY_NM',
                        fixed: false,
                        width: 150
                    },{
                        title: '提货县区',
                        field: 'cEDAPANA_NM',
                        fixed: false,
                        width: 150
                    },{
                        title: '提货地点',
                        field: 'cLADING_ADDR',
                        fixed: false,
                        width: 150
                    },{
                        title: '提货单位',
                        field: 'cLADING_CORP',
                        fixed: false,
                        width: 150
                    },{
                        title: '提货联系人',
                        field: 'cLADING_LINKMAN',
                        fixed: false,
                        width: 150
                    },
                    {
                        title: '提货电话',
                        field: 'cLADING_TEL',
                        fixed: false,
                        width: 150
                    },{
                        title: '提货手机',
                        field: 'cLADMOBIL_TEL',
                        fixed: false,
                        width: 150
                    },{
                        title: '收货联系人',
                        field: 'cTEL_NO',
                        fixed: false,
                        width: 150
                    },
                    {
                        title: '客户联系人',
                        field: 'cCUSLINK_MAN',
                        fixed: false,
                        width: 150
                    },
                    {
                        title: '客户电话',
                        field: 'cCUSTEL_NO',
                        fixed: false,
                        width: 150
                    },
                    {
                        title: '客户手机',
                        field: 'cCUSMOBIL_TEL',
                        fixed: false,
                        width: 150
                    },
                    {
                        title: '订单来源',
                        field: '',
                        fixed: false,
                        width: 150
                    },{
                        title: '信息来源',
                        field: '',
                        fixed: false,
                        width: 150
                    },{
                        title: '调车来源',
                        field: '',
                        fixed: false,
                        width: 150
                    },{
                        title: '范围',
                        field: 'cCAYRANG_NO',
                        fixed: false,
                        width: 150
                    },{
                        title: '方式',
                        field: 'cCAYTYPE_NO',
                        fixed: false,
                        width: 150
                    },
                    {
                        title: '停用',
                        field: 'iVEH_QTY ',
                        fixed: false,
                        width: 150,
                        type: 'checkbox'
                    },{
                        title: '回单方式',
                        field: '',
                        fixed: false,
                        width: 150
                    },{
                        title: '计费依据',
                        field: '',
                        fixed: false,
                        width: 150
                    },{
                        title: '计价单位',
                        field: 'cUNIT_NM',
                        fixed: false,
                        width: 150
                    },
                ],
                tableData: []
            },
            activeName: 'table_2',
            goodstable: {
                tableRole: [
                    {
                        title: '序号', 	//表头
                        field: 'idx', 	//表格展示字段 (主表字段)
                        fixed: true, 	//是否浮动
                        width: 50, 		//列宽
                        noedit: true, 	//是否禁止编辑 值为true 则 禁止编辑, false 或 删除此项 则可编辑
                        defautvalue: 0, 	//默认值,当为输入内容时的默认的值
                    }, {
                        type: 'text',
                        title: '所属客户',
                        field: 'cCUST_SNM',
                        fixed: false,
                        width: 200,
                        sort: false
                    }, {
                        type: 'text',
                        title: '客户编码',
                        field: 'cCUST_NO',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '客户货物编码',
                        field: 'cCAYMAT_NO',
                        value: '',
                        width: 120,
                    }, {
                        type: 'text',
                        title: '货物名称',
                        field: 'cCAYMAT_NM',
                        value: '',
                        width: 200,
                    }, {
                        type: 'text',
                        title: '货物别名',
                        field: 'cCAYMAT_ALIASNM',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '货物英文名称',
                        field: 'cCAYMAT_ENM',
                        value: '',
                        width: 200,
                    }, {
                        type: 'text',
                        title: '规格说明',//字段未知
                        field: '',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '规格编码',//字段未知
                        field: '',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '海关编码',//字段未知
                        field: 'cHS_CODE',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '包装方式',
                        field: 'cPAKG_DESC',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '货物描述',
                        field: 'cDESCRIBE',  //字段未知
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '存放单位',
                        field: 'cSTOUNIT_UM', //字段未知
                        fixed: false,
                        width: 120,
                    }, {
                        type: 'text',
                        title: '计量单位',
                        field: 'cPIEUNIT_NM', //字段未知
                        fixed: false,
                        width: 120,
                    }, {
                        type: 'text',
                        title: '单件货数',
                        field: 'nUNIT_QTY',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '件数单位',
                        field: 'cPIEUNIT_NM',//不知道是不是
                        value: '',
                        width: 100,
                    }, {
                        type: 'number',
                        title: '单货再分个数',
                        field: 'nUNIT_QTY', //字段未知
                        value: '',
                        width: 100,
                    }, {
                        type: 'number',
                        title: '长',
                        field: 'nLENGTH',
                        value: '',
                        width: 100,
                    }, {
                        type: 'number',
                        title: '宽',
                        field: 'nWIDTH',
                        value: '',
                        width: 100,
                    }, {
                        type: 'number',
                        title: '高',
                        field: 'nHEIGHT',
                        value: '',
                        width: 100,
                    }, {
                        type: 'number',
                        title: '面积',
                        field: 'nAREA',
                        value: '',
                        width: 100,
                    }, {
                        type: 'number',
                        title: '容积',
                        field: 'nVOLUME',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '体积单位',
                        field: 'cVOLUNIT_NM',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '条码',
                        field: 'cBAR_CODE',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '供应商编码',
                        field: 'cSUPPLIER_NO',
                        value: '',
                        //  noedit: true,
                        width: 120,
                    }, {
                        type: 'text',
                        title: '供应商名称',
                        field: 'cSUPPLIER_NM',
                        value: '',
                        //   noedit: true,
                        width: 120,
                    },  {
                        type: 'number',
                        title: '销售单价',
                        field: 'nUNIT_PRICE',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '打折单价',
                        field: 'nAGIO_PRICE',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '会员单价',
                        field: 'nMEMB_PRICE',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '成本单价',
                        field: 'nCOST_PRICE',
                        value: '',
                        width: 80,
                    }, {
                        type: 'text',
                        title: '出口国家',
                        field: 'cEXPNATION_NM',
                        fixed: false,
                        width: 150,
                    }, {
                        type: 'text',
                        title: '出口国家说明',
                        field: 'cEXPORT_CUNTY', //字段未知
                        value: '',
                        width: 120,
                    }, {
                        type: 'text',
                        title: '使用范围',
                        field: 'cUSE_RANG',
                        fixed: false,
                        width: 100,
                    }, {
                        type: 'text',
                        title: '指定共享客户列表',
                        field: 'cSHARECUST_NMS',
                        value: '',
                        width: 150,
                    }, {
                        type: 'text',
                        title: '入库默认状态',
                        field: 'cCAYSTATEIN_NO', //应该是下拉选择类型 但是下拉表格数据内容未知
                        value: '',
                        width: 150,
                    }, {
                        type: 'text',
                        title: '出库默认状态',
                        field: 'cCAYSTATEOUT_NO', //应该是下拉选择类型 但是下拉表格数据内容未知
                        value: '',
                        width: 150,
                    }, {
                        type: 'checkbox',
                        title: '停用',
                        field: 'cSTOP_TAG',
                        fixed: false,
                        width: 100,
                        value: '',
                        truenumber: '1',
                        falsenumber: '0',
                    }, {
                        type: 'text',
                        title: '备注',
                        field: 'cREMARK',
                        value: '',
                        width: 150,
                    }, {
                        type: 'text',
                        title: '存货系统编码',
                        field: 'cWHMATSTK_NO', //字段未知
                        value: '',
                        width: 150,
                    }, {
                        type: 'text',
                        title: '创建人',
                        field: 'cESTUSER_NM',
                        value: '',
                        width: 150,
                    }, {
                        type: 'date',
                        title: '创建时间',
                        field: 'tESTDATA_DT',
                        fixed: false,
                        width: 150,
                        value: '',
                    }, {
                        type: 'text',
                        title: '维护人',
                        field: 'cUSER_NM',
                        value: '',
                        width: 150,
                    }, {
                        type: 'date',
                        title: '维护时间',
                        field: 'tMODIFY_DT',
                        fixed: false,
                        width: 150,
                        value: '',
                    },
                ],
                tableData: []
            },
            cars: [], // 车辆调派
            suppliers: [], //承运单
            feesTree: [], //费用树
            feeList: [] //费用列表
        }
    },
    methods:{
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            console.log(row,column)
            if (rowIndex % 2 === 0) {
                if (columnIndex === 0) {
                    return [1, 2];
                } else if (columnIndex === 1) {
                    return [0, 0];
                }
            }
        },
        handleClick(row){
            let idx = parseInt(row.index) + 1
            this.activeName = 'table_' + idx
        },
        saveRow(){

        },
        handleselectedRows(rows){

        },
        tableSearch(){
            fetchOrders().then(res=>{
                this.table.tableData = res.data.data
                console.log(res)
            })
        },
    }
}
</script>