<template>
    <div>
        <top-banner :appname="appName" :isedit="isEdit"></top-banner>
        <div class="top-form-wrapper">
            <top-form ref="form":searchbox="toptable.searchBox" :isedit="isEdit"></top-form>
        </div>
        <mytable :tablelet="toptable" ref="toptable" :pageInfo="pageInfo" :height="250" :isEdit="isEdit" @selectedRows="handletopselectedRows"></mytable>
        <div class="top-form-wrapper">
            <top-form ref="bottomForm" :searchbox="bottomtable.searchBox"></top-form>
        </div>
        <mytable :tablelet="bottomtable" ref="bottomtable" :pageInfo="bottomtable.pageInfo" :height="200" :isEdit="isEdit" @selectedRows="handlebottomselectedRows"></mytable>
    </div>
</template>
<script>
import _ from 'lodash'
import transportMix from '@/mixins/transport'
import {fetchOutcus,editOutcus,delOutcus} from '@/api/storage/outbound'
export default{
    mixins: [transportMix],
    data(){
        return {
            appName: '发货单批量管理',
            toptable: {
                searchBox: [
                    {
                        type: 'select_api', // 下拉选择
                        title: '客户:',
                        dec: '输入客户名称',
                        option: [],
                        value: '',
                        action: 'cust/search',
                        optionTableWidth: 300,
                        optionTableRole: [{
                            title: '编号',
                            field: 'cCUST_NO',
                            width: 80,
                            toInsert: 'cCUST_NO',
                        }, {
                            title: '客户名称',
                            field: 'cCUST_NM',
                            width: 150,
                        }, ],
                        showfield: 'cCUST_NM',
                        filtefield: 'cCUST_NM',
                        // 是否将部分字段作为全局筛选条件，如果是，则增加下方格式代码（全局筛选条件，将在表格中使用）
                        insert_default_field: {
                            'cCUST_NO': 'cCUST_NO', // '主表字段':'调用对象基础表字段'
                        },
                    }, {
                        type: 'text',
                        title: '发货单号',
                        dec: '请输入发货单号',
                        field: 'cSTKOUTCUS_NO',
                        value: '',
                    }, {
                        type: 'text',
                        title: '订单编号',
                        dec: '请输入订单编号',
                        field: '',
                        value: '',
                    }, {
                        type: 'text',
                        title: '系统编号',
                        dec: '请输入系统编号',
                        field: '',
                        value: '',
                    }, {
                        type: 'daterange',
                        title: '发货日期',
                        field: 'cSTKOUTCUS_DATE',
                        icon: 'el-icon-date',
                        value: '',
                    }, {
                        type: 'select_api',
                        title: '收货国家',
                        width: 150,
                        option: [],
                        value: '',
                        action: 'info/nation/search',
                        optionTableWidth: 300,
                        optionTableRole: [{
                            title: '国家代码',
                            field: 'cNATION_NO',
                            width: 150,
                            toInsert: 'cNATION_NO', //带出写入
                        }, {
                            title: '国家名称',
                            field: 'cNATION_NM',
                            width: 150,
                            toInsert: 'cNATION_NM', //带出写入
                        }, ],
                        showfield: 'cNATION_NM', //展示字段
                        filtefield: 'cNATION_NM', //筛选字段(用于选择的数据的某个字段)
                        clearfield: ['cPROV_NO', 'cPROV_NO', ], //为空时,需要 关联清空的字段 

                    }, {
                        type: 'select_api',
                        title: '收货省份',
                        width: 200,
                        option: [],
                        value: '',
                        action: 'info/prov/search',
                        optionTableWidth: 300,
                        optionTableRole: [{
                            title: '省份代码',
                            field: 'cPROV_NO',
                            width: 150,
                            toInsert: 'cPROV_NO', //带出写入
                        }, {
                            title: '省份名称',
                            field: 'cPROV_NM',
                            width: 150,
                            toInsert: 'cPROV_NM', //带出写入
                            //     stype: 'label',
                        }, ],
                        showfield: 'cPROV_NM', //展示字段
                        filtefield: 'cPROV_NM', //筛选字段(用于选择的数据的某个字段)
                        parentField: [
                            ['cNATION_NO', 'cNATION_NO'], //['数据字段','基础表字段']   关联上级字段 (不会预加载)
                        ],
                    }, {
                        type: 'select_api', // 下拉选择
                        title: '仓储区域',
                        option: [],
                        value: '',
                        action: 'ware/waret/search', //接口
                        optionTableWidth: 230,
                        optionTableRole: [{
                            title: '编号',
                            field: 'idx',
                            width: 80,
                            toInsert: 'idx',
                        }, {
                            title: '仓储片区',
                            field: 'cWARET_NM',
                            width: 150,
                            toInsert: 'cWARET_NM',
                        }, ],
                        showfield: 'cWARET_NM',
                        filtefield: 'cWARET_NM',
                        insert_deafault_field: {
                            '': '', //主表字段':'调用对象基础表字段
                        },
                    }, {
                        type: 'select_api', // 下拉选择
                        title: '发货类别',
                        option: [],
                        value: '',
                        action: 'stk/outcls/search', //接口
                        optionTableWidth: 300,
                        optionTableRole: [{
                            title: '序号',
                            field: 'idx',
                            width: 50,
                        }, {
                            title: '出库类别编码',
                            field: 'cSTKOUTCLS_NO',
                            width: 100,
                            toInsert: '',
                        }, {
                            title: '出库类别名称',
                            field: 'cSTKOUTCLS_NM',
                            width: 150,
                        }, ],
                        showfield: 'cSTKOUTCLS_NM',
                        filtefield: 'cSTKOUTCLS_NM',
                        insert_deafault_field: {
                            '': '', //主表字段':'调用对象基础表字段
                        },
                    }, {
                        type: 'select',
                        title: '审核状态',
                        field: 'cAUDIT_TAG',
                        fixed: false,
                        width: 50,
                        option: [{
                            value: '0',
                            label: '未审核'
                        }, {
                            value: '1',
                            label: '已审核'
                        }, {
                            value: '2',
                            label: '全部'
                        }],
                        value: '1',
                    }
                ],
                 //主表配置
                 tableRole: [
                    {
                        title: '序号',
                        field: 'idx',
                        fixed: true,
                        width: 50,
                        noedit: true,
                        defautvalue: 0, //默认值,当为输入内容时的默认的值
                    },{
                        type: 'text',
                        title: '数据来源',
                        width: 150,
                        field: '',
                        value: '人工录入',
                        noedit: true,
                    }, {
                        type: 'select_api',
                        title: '客户',
                        field: 'cCUST_SNM',
                        fixed: false,
                        width: 150,
                        option: [],
                        value: '',
                        action: 'cust/search',
                        optionTableWidth: 300,
                        optionTableRole: [{
                            title: '序号',
                            field: 'idx',
                            width: 60,
                        }, {
                            title: '客户编码',
                            field: 'cCUST_NO',
                            width: 150,
                            toInsert: 'cCUST_NO',
                            stype: 'value',
                            width: 120,
                        }, {
                            title: '客户简称',
                            field: 'cCUST_SNM',
                            width: 120,
                            toInsert: 'cCUST_SNM',
                            stype: 'label',
                        }, ],
                        showfield: 'cCUST_SNM',
                        filtefield: 'cCUST_SNM',
                        hasCheck: true,
                        default_prama: [
                            ['cCUST_SNM', 'cCUST_SNM'], //['主表字段','调用对象基础表的字段']
                        ],
                    }, {
                        type: 'date',
                        title: '发货日期',
                        field: 'cSTKOUTCUS_DATE',
                        fixed: false,
                        width: 120,
                        value: '',
                        defautvalue: '', //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd",
                        noedit: true,
                    }, {
                        type: 'date',
                        title: '账款年月',
                        field: 'cFIN_MON',
                        fixed: false,
                        width: 120,
                        value: '',
                        defautvalue: '', //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM",
                        noedit: true,
                    }, {
                        type: 'text',
                        title: '发货单号',
                        width: 150,
                        field: 'cCUSOUTSTK_NO',
                        value: '',
                    }, {
                        type: 'text',
                        title: '客户订单号',
                        width: 150,
                        field: 'cCUSODR_NO',
                        value: '',
                    }, {
                        type: 'date',
                        title: '客户财务释放日期',
                        field: '',
                        fixed: false,
                        width: 150,
                        value: '',
                        defautvalue: '', //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd",
                        noedit: true,
                    }, {
                        type: 'select_api',
                        title: '发货类别',
                        field: 'cSTKOUTCLS_NM',
                        fixed: false,
                        width: 150,
                        option: [],
                        value: '',
                        action: 'stk/outcls/search',
                        optionTableWidth: 300,
                        optionTableRole: [{
                            title: '序号',
                            field: 'idx',
                            width: 60,
                        }, {
                            title: '出库类别编码',
                            field: 'cSTKOUTCLS_NO',
                            width: 120,
                            toInsert: 'cSTKOUTCLS_NO',
                            stype: 'value',
                        }, {
                            title: '出库类别名称',
                            field: 'cSTKOUTCLS_NM',
                            width: 120,
                            toInsert: 'cSTKOUTCLS_NM',
                            stype: 'label',
                        }, ],
                        showfield: 'cSTKOUTCLS_NM',
                        filtefield: 'cSTKOUTCLS_NM',
                        hasCheck: true,
                        default_prama: [
                            ['', ''], //['主表字段','调用对象基础表的字段']
                        ],
                    }, {
                        type: 'number',
                        title: '发货数量',
                        field: 'nMAT_QTY',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '体积',
                        field: 'nVOLUME',
                        value: '',
                        width: 80,
                    }, {
                        type: 'text',
                        title: '体积单位',
                        field: 'cVOLUNIT_NM',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '净重',
                        field: 'nNET_WEIGHT',
                        value: '',
                        width: 80,
                    }, {
                        type: 'number',
                        title: '毛重',
                        field: 'nGROSS_WEIGHT',
                        value: '',
                        width: 80,
                    }, {
                        type: 'text',
                        title: '重量单位',
                        field: 'cWTUNIT_NM',
                        value: '',
                        width: 80,
                    }, {
                        type: 'date',
                        title: '要求发货日',
                        field: 'cLADING_DATE',
                        fixed: false,
                        width: 120,
                        value: '',
                        defautvalue: '', //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd",
                        noedit: true,
                    }, {
                        type: 'date',
                        title: '要求到达日',
                        field: 'cREQARR_DATE',
                        fixed: false,
                        width: 120,
                        value: '',
                        defautvalue: '', //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd",
                        noedit: true,
                    }, {
                        type: 'select_api', // 下拉选择
                        title: '发货仓库',
                        option: [],
                        width: 150,
                        field: 'cWARET_NM',
                        value: '',
                        action: 'ware/waret/search', //接口
                        optionTableWidth: 300,
                        optionTableRole: [{
                            title: '序号',
                            field: 'idx',
                            width: 60,
                        }, {
                            title: '仓储编号',
                            field: 'cWARET_NO',
                            width: 120,
                            toInsert: 'cWARET_NO',
                            stype: 'value',
                        }, {
                            title: '仓储片区',
                            field: 'cWARET_NM',
                            width: 120,
                            toInsert: 'cWARET_NM',
                            stype: 'label',
                        }, ],
                        showfield: 'cWARET_NM',
                        filtefield: 'cWARET_NM',
                        hasCheck: true,
                        default_prama: [
                            ['', ''], //['主表字段','调用对象基础表的字段']
                        ],
                    }, {
                        type: 'select_api', // 下拉选择
                        title: '收货客户',
                        field: 'cCUSCLIENT_NM',
                        option: [],
                        width: 150,
                        value: '',
                        action: '', //接口
                        optionTableWidth: 400,
                        optionTableRole: [{
                            title: '序号',
                            field: 'idx',
                            width: 80,
                        }, {
                            title: '收货客户编码',
                            field: 'cCUSCLIENT_NO',
                            width: 160,
                            toInsert: 'cCUSCLIENT_NO',
                            stype: 'value',
                        }, {
                            title: '收货客户名称',
                            field: 'cCUSCLIENT_NM',
                            width: 160,
                            toInsert: 'cCUSCLIENT_NM',
                            stype: 'label',
                        }, ],
                        showfield: 'cCUSCLIENT_NM',
                        filtefield: 'cCUSCLIENT_NM',
                        hasCheck: true,
                        default_prama: [
                            ['', ''], //['主表字段','调用对象基础表的字段']
                        ],
                    }, {
                        type: 'text',
                        title: '收货客户编码',
                        field: 'cCUSCLIENT_NO',
                        value: '',
                        width: 130,
                        noedit: true,
                    }, {
                        type: 'date',
                        title: '收货客户下单日期',
                        field: '',
                        fixed: false,
                        width: 130,
                        value: '',
                        defautvalue: '', //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd",
                    }, {
                        type: 'text',
                        title: '收货客户订单号',
                        field: '',
                        value: '',
                        width: 150,
                    }, {
                        type: 'text',
                        title: '客户标注收获城市',
                        field: '',
                        value: '',
                        width: 150,
                    }, {
                        type: 'text',
                        title: '收货地址',
                        field: 'cDESTINATION',
                        value: '',
                        width: 250,
                    }, {
                        type: 'select_api',
                        title: '收货国家',
                        field: 'cEDNATION_NM',
                        fixed: false,
                        width: 150,
                        option: [],
                        value: '',
                        action: 'info/nation/search',
                        optionTableRole: [{
                            title: '国家代码',
                            field: 'cNATION_NO',
                            width: 150,
                            toInsert: 'cEDNATION_NO', //带出写入
                            stype: 'value',
                        }, {
                            title: '国家名称',
                            field: 'cNATION_NM',
                            width: 150,
                            toInsert: 'cEDNATION_NM', //带出写入
                            stype: 'label',
                        }, ],
                        showfield: 'cEDNATION_NM', //展示字段
                        filtefield: 'cEDNATION_NM', //筛选字段(用于选择的数据的某个字段)
                        clearfield: ['cEDPROV_NO', 'cEDPROV_NM', 'cEDCITY_NO', 'cEDCITY_NM', 'cEDAPANA_NO', 'cEDAPANA_NM'], //为空时,需要 关联清空的字段 
                        default_prama: [
                            ['', '']
                        ]
                    }, {
                        type: 'select_api',
                        title: '收货省份',
                        field: 'cEDPROV_NM',
                        fixed: false,
                        width: 150,
                        option: [],
                        value: '',
                        action: 'info/prov/search',
                        optionTableRole: [{
                            title: '省份代码',
                            field: 'cPROV_NO',
                            width: 100,
                            toInsert: 'cEDPROV_NO', //带出写入
                            stype: 'value',
                        }, {
                            title: '省份名称',
                            field: 'cPROV_NM',
                            width: 150,
                            toInsert: 'cEDPROV_NM', //带出写入
                            stype: 'label',
                        }, ],
                        showfield: 'cEDPROV_NM', //展示字段
                        filtefield: 'cEDPROV_NM', //筛选字段(用于选择的数据的某个字段)
                        parentField: [
                            ['cEDNATION_NO', 'cNATION_NO'], //['数据字段','基础表字段']   关联上级字段 (不会预加载)
                        ],
                        clearfield: ['cEDCITY_NO', 'cEDCITY_NM', 'cEDAPANA_NO', 'cEDAPANA_NM'], //为空时,需要 关联清空的字段 
                    }, {
                        title: '收货城市',
                        field: 'cEDCITY_NM',
                        fixed: false,
                        width: 150,
                        sort: false,
                        type: 'select_api',
                        option: [],
                        value: '',
                        optionTableRole: [{
                            title: '城市代码',
                            field: 'cCITY_NO',
                            width: 100,
                            toInsert: 'cEDCITY_NO', //带出写入
                            stype: 'value',
                        }, {
                            title: '城市名称',
                            field: 'cCITY_NM',
                            width: 150,
                            toInsert: 'cEDCITY_NM', //带出写入
                            stype: 'label',
                        }],
                        showfield: 'cEDCITY_NM', //展示字段
                        filtefield: 'cEDCITY_NM', //筛选字段(用于选择的数据的某个字段)
                        action: 'info/city/search', //调用方法
                        parentField: [
                            ['cEDPROV_NO', 'cPROV_NO'], //['数据字段','基础表字段']   关联上级字段 (不会预加载)
                        ],
                        clearfield: ['cEDAPANA_NO', 'cEDAPANA_NM'], //为空时,需要 关联清空的字段 
                    }, {
                        title: '收货县区',
                        field: 'cEDAPANA_NM',
                        fixed: false,
                        width: 150,
                        sort: false,
                        type: 'select_api',
                        option: [],
                        value: '',
                        optionTableRole: [{
                            title: '区县代码',
                            field: 'cAPANA_NO',
                            width: 100,
                            toInsert: 'cEDAPANA_NO', //带出写入
                            stype: 'value',
                        }, {
                            title: '县区名称',
                            field: 'cAPANA_SNM',
                            width: 150,
                            toInsert: 'cEDAPANA_NM', //带出写入
                            stype: 'label',
                        }],
                        optionTableWidth: 250, //内表格宽度
                        showfield: 'cEDAPANA_NM', //展示字段
                        filtefield: 'cEDAPANA_NM', //筛选字段(用于选择的数据的某个字段)
                        action: 'info/apana/search', //调用方法
                        parentField: [
                            ['cEDCITY_NO', 'cCITY_NO'], //['数据字段','基础表字段']
                        ]
                    }, {
                        type: 'text',
                        title: '联系人',
                        field: 'cLINKMAN',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '联系电话',
                        field: 'cTEL_NO',
                        value: '',
                        width: 150,
                    }, {
                        type: 'text',
                        title: '备注',
                        field: 'cREMARK',
                        value: '',
                        width: 100,
                    }, {
                        type: 'select',
                        title: '审核状态',
                        field: 'cAUDIT_TAG',
                        fixed: false,
                        width: 100,
                        option: [{
                            value: '0',
                            label: '未审核',
                        }, {
                            value: '1',
                            label: '已审核',
                        }, {
                            value: '2',
                            label: '全部',
                        }],
                        value: '0',
                        noedit: true,
                    }, {
                        type: 'text',
                        title: '件数单位',
                        field: '',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '客户运单类型',
                        field: '',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '客户组织代码',
                        field: '',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '发出仓库代码',
                        field: '',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '发出仓库名称',
                        field: '',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '发出仓库地址',
                        field: '',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '接收仓库代码',
                        field: '',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '接收仓库名称',
                        field: '',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '接收仓库地址',
                        field: '',
                        value: '',
                        width: 100,
                    }, {
                        type: 'text',
                        title: '审核人',
                        field: 'cAUDITUSER_NM',
                        value: '',
                        width: 100,
                        noedit: true,
                    }, {
                        type: 'date',
                        title: '审核时间',
                        field: 'tAUDIT_DT',
                        width: 150,
                        value: '',
                        defautvalue: '', //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd HH-mm-ss",
                        noedit: true,
                    }, {
                        type: 'text',
                        title: '对接物流系统单号',
                        field: '',
                        value: '',
                        width: 120,
                        noedit: true,
                    }, {
                        type: 'text',
                        title: '系统单号',
                        field: 'cSTKOUTCUS_NO',
                        value: '',
                        width: 100,
                        noedit: true,
                    }, {
                        type: 'text',
                        title: '创建人',
                        field: 'cESTUSER_NM',
                        value: '',
                        width: 150,
                        noedit: true,
                    }, {
                        type: 'date', //类型 -> 日期
                        title: '创建时间',
                        field: 'tESTDATA_DT',
                        //         fixed: false,
                        width: 150,
                        value: '',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                        noedit: true,
                    }, {
                        type: 'text',
                        title: '维护人',
                        field: 'cUSER_NM',
                        value: '',
                        width: 150,
                        noedit: true,
                    }, {
                        type: 'date', //类型 -> 日期
                        title: '维护时间',
                        field: 'tMODIFY_DT',
                        width: 150,
                        value: '',
                        defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                        format: "yyyy-MM-dd", //日期格式设置
                        noedit: true,
                    }],
                pagination_show: true,
                tableData: []
            },
            bottomtable: {
                searchBox: [
                    {
                        type: 'checkbox',
                        title: '货物明细：显示全部货物资料',
                        field: '',
                        value: [],
                        option: [{
                            value: 0,
                            label: ''
                        }],
                    }, {
                        type: 'checkbox',
                        title: '合并订单条件：相同客户发货单号',
                        field: '',
                        value: [],
                        option: [{
                            value: 0,
                            label: ''
                        }],
                    }, {
                        type: 'checkbox',
                        title: '相同客户订单号',
                        field: '',
                        value: [],
                        option: [{
                            value: 0,
                            label: ''
                        }],
                    }, {
                        type: 'checkbox',
                        title: '相同收货地址',
                        field: '',
                        value: [],
                        option: [{
                            value: 0,
                            label: ''
                        }],
                    }, {
                        type: 'checkbox',
                        title: '相同要求达到时间',
                        field: '',
                        value: [],
                        option: [{
                            value: 0,
                            label: ''
                        }],
                    },
                ],
                tableRole: [
                {
                    title: '序号',
                    field: 'idx',
                    fixed: true,
                    width: 80,
                    noedit: true,
                    defautvalue: 0,
                }, {
                    type: 'text',
                    title: '行号',
                    field: '',
                    // fixed: true,
                    width: 80,
                    value: '',
                    defautvalue: 0,
                }, {
                    type: 'text',
                    title: '货物编码',
                    field: 'cCUSMAT_NO',
                    //  fixed: true,
                    width: 120,
                    value: '',
                    defautvalue: 0,
                }, {
                    type: 'text',
                    title: '货物名称',
                    field: 'cSTKMAT_NM',
                    //    fixed: true,
                    width: 150,
                    value: '',
                    defautvalue: 0,
                }, {
                    type: 'text',
                    title: '规格说明',
                    field: 'cSTKMATSPEC_NM',
                    //   fixed: true,
                    width: 150,
                    defautvalue: 0,
                }, {
                    type: 'text',
                    title: '类别',
                    field: 'cSTKMATCLS_NM',
                    //     fixed: true,
                    width: 80,
                    noedit: true,
                    value: '',
                    defautvalue: 0,
                }, {
                    type: 'select',
                    title: '批次',
                    field: 'cBATCH_NO',
                    //       fixed: false,
                    width: 110,
                    option: [{
                        value: '0',
                        label: '不分批次'
                    }],
                    value: '',
                    // noedit: true,	
                }, {
                    type: 'text',
                    title: '条码',
                    field: 'cBAR_CODE',
                    //       fixed: false,
                    width: 130,
                    value: '',
                    defautvalue: '',
                }, {
                    type: 'text',
                    title: '包装编号',
                    field: 'cPACKAGE_NO',
                    //      fixed: false,
                    width: 130,
                    value: '',
                    defautvalue: '',
                }, {
                    type: 'date',
                    title: '先进先出日',
                    field: 'cFIFO_DATE',
                    //      fixed: false,
                    width: 150,
                    value: '',
                    defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                    format: "yyyy-MM-dd", //日期格式设置
                }, {
                    type: 'select_api', // 下拉选择
                    title: '货物状态',
                    field: 'cSTKSTATE_NM',
                    option: [],
                    value: '',
                    action: '', //接口
                    optionTableWidth: 300,
                    optionTableRole: [{
                        title: '编号',
                        field: 'idx',
                        width: 80,
                        toInsert: 'idx',
                        stype: 'value',
                    }, {
                        title: '货物状态',
                        field: 'cSTKSTATE_NM',
                        width: 150,
                        toInsert: 'cSTKSTATE_NM',
                        stype: 'label',
                    }, ],
                    showfield: 'cSTKSTATE_NM',
                    filtefield: 'cSTKSTATE_NM',
                    insert_default_field: {
                        '': '', // '主表字段':'调用对象基础表字段'
                    },
                }, {
                    type: 'select_api',
                    title: '单位',
                    field: 'cUNIT_NM',
                    //       fixed: false,
                    width: 80,
                    sort: false,
                    option: [],
                    value: '',
                    optionBoxWidth: 200,
                    optionTableRole: [{
                            title: '编号',
                            field: 'idx',
                            width: 80,
                            toInsert: 'idx',
                            stype: 'value',
                        },
                        {
                            title: '单位名称',
                            field: 'cUNIT_NM',
                            width: 150,
                            toInsert: 'cUNIT_NM', //带出写入
                            stype: 'label',
                        },
                    ],
                    showfield: 'cUNIT_NM',
                    filtefield: 'cUNIT_NM',
                    hasCheck: true,
                    action: 'info/unit/search',
                    default_prama: [
                        ['cUNIT_NM', 'cUNIT_NM']
                    ]
                }, {
                    type: 'number',
                    title: '数量',
                    field: 'nMAT_QTY',
                    value: '',
                    width: 80,
                }, {
                    type: 'number',
                    title: '存放件数',
                    field: 'nSTOUNIT_QTY',
                    value: '',
                    width: 80,
                }, {
                    type: 'number',
                    title: '零头数量',
                    field: 'nODD_QTY',
                    value: '',
                    width: 80,
                }, {
                    type: 'number',
                    title: '单件货物数',
                    field: 'nUNIT_QTY',
                    value: '',
                    width: 80,
                }, {
                    type: 'text',
                    title: '件数单位',
                    field: 'cPIEUNIT_NM',
                    //      fixed: false,
                    width: 130,
                    value: '',
                    defautvalue: '',
                }, {
                    type: 'number',
                    title: '单重',
                    field: 'nWEIGHT',
                    value: '',
                    width: 80,
                }, {
                    type: 'number',
                    title: '净重',
                    field: 'nNET_WEIGHT',
                    value: '',
                    width: 80,
                }, {
                    type: 'number',
                    title: '毛重',
                    field: 'nGROSS_WEIGHT',
                    value: '',
                    width: 80,
                }, {
                    type: 'text',
                    title: '重量单位',
                    field: 'cWTUNIT_NM',
                    //       fixed: false,
                    width: 130,
                    value: '',
                    defautvalue: '',
                }, {
                    type: 'number',
                    title: '长',
                    field: 'nLENGTH',
                    value: '',
                    width: 80,
                }, {
                    type: 'number',
                    title: '宽',
                    field: 'nWIDTH',
                    value: '',
                    width: 80,
                }, {
                    type: 'number',
                    title: '高',
                    field: 'nHEIGHT',
                    value: '',
                    width: 80,
                }, {
                    type: 'number',
                    title: '单体积',
                    field: '',
                    value: '',
                    width: 80,
                }, {
                    type: 'number',
                    title: '体积',
                    field: 'nVOLUME',
                    value: '',
                    width: 80,
                }, {
                    type: 'text',
                    title: '体积单位',
                    field: 'cVOLUNIT_NM',
                    //     fixed: false,
                    width: 130,
                    value: '',
                    defautvalue: '',
                }, {
                    type: 'text',
                    title: '存放单位',
                    field: 'cSTOUNIT_UM',
                    //    fixed: false,
                    width: 130,
                    value: '',
                    defautvalue: '',
                }, {
                    type: 'text',
                    title: '分配地点',
                    field: '',
                    //    fixed: false,
                    width: 130,
                    value: '',
                    defautvalue: '',
                }, {
                    type: 'text',
                    title: '编排序号',
                    field: '',
                    //    fixed: false,
                    width: 130,
                    value: '',
                    defautvalue: '',
                }, {
                    type: 'text',
                    title: '包装格式',
                    field: 'cPAKG_DESC',
                    //   fixed: false,
                    width: 130,
                    value: '',
                    defautvalue: '',
                }, {
                    type: 'text',
                    title: '字符备一',
                    field: 'cSTANDBY_ONE',
                    //     fixed: false,
                    width: 130,
                    value: '',
                    defautvalue: '',
                }, {
                    type: 'text',
                    title: '数字备一',
                    field: 'cSTANDBY_ONE',
                    //    fixed: false,
                    width: 130,
                    value: '',
                    defautvalue: '',
                }, {
                    type: 'text',
                    title: '备注',
                    field: 'cREMARK',
                    //    fixed: false,
                    width: 130,
                    value: '',
                    defautvalue: '',
                }, {
                    type: 'text',
                    title: '客户单号',
                    field: 'cCUSODR_NO',
                    //    fixed: false,
                    width: 130,
                    value: '',
                    defautvalue: '',
                }, {
                    type: 'text',
                    title: '发货单号',
                    field: 'cCUSOUTSTK_NO',
                    //   fixed: false,
                    width: 130,
                    value: '',
                    defautvalue: '',
                }, {
                    type: 'text',
                    title: '系统单号',
                    field: 'cSTKOUTCUS_NO',
                    //    fixed: false,
                    width: 130,
                    value: '',
                    defautvalue: '',
                }, {
                    type: 'text',
                    title: '系统料号',
                    field: 'cSTKMAT_NO',
                    //  fixed: false,
                    width: 130,
                    value: '',
                    defautvalue: '',
                }, {
                    type: 'date',
                    title: '发货日期',
                    field: 'cSTKOUTCUS_DATE',
                    // fixed: false,
                    width: 150,
                    value: '',
                    defautvalue: this.$getNowTime(true, true, true), //日期类(调用外部方法,生成默认值,不知道如何设置,可依此填写)
                    format: "yyyy-MM-dd", //日期格式设置
                }, {
                    type: 'checkbox',
                    title: '包装管理',
                    field: 'cPARKING_TAG',
                    // fixed: false,
                    width: 100,
                    value: '0',
                    truenumber: '1', //为真时 的值
                    falsenumber: '0', //为假时 的值
                }, {
                    type: 'text',
                    title: '对应物流项次',
                    field: '',
                    // fixed: false,
                    width: 130,
                    value: '',
                    defautvalue: '',
                }],
                pagination_show: true,
                tableData: [],
                pageInfo:{
                    index: 1,
                    page: 10,
                    total: 10
                }
            }
        }
    },
    methods: {
        tableSearch(){
            let params = {
                ...this.$refs.form.searchdata,
                page_index: this.pageInfo.index,
                page_size: this.pageInfo.size
            }
            fetchOutcus(params).then(res=>{
                this.toptable.tableData = res.data.data.map(v=>({
                    ...v,
                    isEdit: false
                }))
                this.pageInfo = res.data.page
            })
        },
        handletopselectedRows(rows){
            this.selectedRows = rows
        },
        handlebottomselectedRows(rows){
            this.bottomselectedRows = rows
        },
        //新增数据
        addRow() {
            this.isEdit = true
            this.isAdd = true
            let initData = _.cloneDeep(this.table.tableData)
            let role = this.table.tableRole;
            let data = {};
            role.forEach((item)=> {
                data[item.field] = item.value
                data.isEdit = true
            })
            initData.unshift(data)
            this.$set(this.table, 'tableData', initData);
        },
        //删除数据
        delRow() {
            let params = {
                data:{
                    idx: this.selectedRows.map(v=>v.idx)
                }
            };
            if (this.selectedRows.length == 0) {
                this.$message.error("未选择删除数据！");
            } else {
                delOutcus(params).then(res=>{
                    this.$message.success('删除成功')
                    this.tableSearch()
                })
            }
        },
        //修改数据
        editRow() {
            if (this.selectedRows.length == 0) {
                this.$message.error("请选择编辑行！");
            }else if(this.selectedRows.length == 1){
                this.isEdit = true;
                this.isAdd = false
                this.editIdx = this.table.tableData.findIndex(v=>v.idx == this.selectedRows[0].idx)
                this.table.tableData[this.editIdx].isEdit = true
            }else{
                this.$message.error('一次只可以编辑一行数据！')
            }
        },
        //保存数据
        saveRow() {
            
        },
        cancel() {
            this.isEdit = false;
            if(this.isAdd){
                this.table.tableData.shift();
            }else{
                this.table.tableData[this.editIdx].isEdit = false
            }
            this.$refs.table.$refs.mytable.clearSelection();
        }
    }

}
</script>