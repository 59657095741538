/**
 * @description 导出默认网路配置
 **/
const baseurl = 'http://118.178.232.24/'
// const baseurl = 'http://192.168.1.113'
// const baseurl= 'http://1.94.24.21/'  // 叶

const network = {
  // 默认的接口地址 如果是开发环境和生产环境走vab-mock-server，当然你也可以选择自己配置成需要的接口地址
  baseURL: process.env.NODE_ENV === 'development' ? baseurl : baseurl,
  //配后端数据的接收方式application/json;charset=UTF-8或者application/x-www-form-urlencoded;charset=UTF-8
  contentType: 'application/json;charset=utf-8',
  //消息框消失时间
  messageDuration: 3000,
  //最长请求时间
  requestTimeout: 5000,
  //操作正常code，支持String、Array、int多种类型
  successCode: [200, 0],
  //登录失效code
  invalidCode: 402,
  //无权限code
  noPermissionCode: 401,
}
module.exports = network
